import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { codesetGroupEnum } from 'visa-frontend-common/src/dataModel';
import { codesetCodeShape } from 'visa-frontend-common/src/components/UI/Form/CodesetCode/codesetDataModel';

import * as actions from './CodesetActions';
import { codesetCodesSelector } from './CodesetReducer';
import Loading from '../../Loading/LoadingContainer';
import i18n from '../../../../services/i18n';

const mapStateToProps = (
  state, { codesetGroup },
) => ({
  codesetCodes: codesetCodesSelector(state, codesetGroup),
});

const mapDispatchToProps = actions;

const withCodesetCodeContainer = (WrappedComponent) => {
  class CodesetCodeContainer extends React.PureComponent {
    componentDidMount() {
      if (!this.props.codesetCodes) {
        this.props.fetchCodesetCodes(this.props.codesetGroup);
        if (i18n.language() !== 'en') {
          this.props.fetchCodesetCodesInEnglish(this.props.codesetGroup);
        }
      }
    }

    wrappedComponentProps = () => {
      return R.compose(
        R.omit(['fetchCodesetCodes', 'codesetPredicate']),
        R.evolve({
          codesetCodes: R.when(
            () => this.props.codesetPredicate,
            R.filter(this.props.codesetPredicate),
          ),
        }),
      )(this.props);
    };

    render() {
      return (
        <>
          <Loading requestNames={[actions.codesetByGroupSet().type]} />
          {this.props.codesetCodes && <WrappedComponent {...this.wrappedComponentProps()} />}
        </>
      );
    }
  }

  CodesetCodeContainer.propTypes = {
    codesetCodes: PropTypes.arrayOf(codesetCodeShape),
    codesetGroup: codesetGroupEnum.isRequired,
    fetchCodesetCodes: PropTypes.func.isRequired,
    fetchCodesetCodesInEnglish: PropTypes.func.isRequired,
    codesetPredicate: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  };

  CodesetCodeContainer.defaultProps = {
    codesetCodes: null,
    codesetPredicate: null,
    value: null,
  };

  return connect(mapStateToProps, mapDispatchToProps)(CodesetCodeContainer);
};

export default withCodesetCodeContainer;
