import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Icon = (props) => {
  return (
    <i className={classnames('material-icons', props.className)} data-cy="material-icon" title={props.title} aria-label={props.ariaLabel} aria-hidden={!props.ariaLabel}>
      {props.name}
    </i>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
};

Icon.defaultProps = {
  className: null,
  title: null,
  ariaLabel: null,
};

export default Icon;
