import React from 'react';
import PropTypes from 'prop-types';
import { BooleanCheckboxGroup } from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import InputGroup from 'visa-frontend-common/src/components/UI/Form/InputGroup/InputGroup';
import i18n from '../../services/i18n';

import './Register.scss';

const PrivacyPolicy = (props) => {
  const acceptSelected = (event) => {
    props.privacyPolicyAcceptedSet(event.target.value);
  };
  return (
    <div>
      <div className="register__privacy-policy-container">
        <InputGroup>
          <BooleanCheckboxGroup
            name="privacyPolicyAccepted"
            label={i18n.t(
              'register.privacyPolicy.paragraph',
              {
                PrivacyPolicyLink: (
                  <a href={i18n.t('register.privacyPolicy.policyLink')} target="_blank" rel="noopener noreferrer">
                    {i18n.t('register.privacyPolicy.policyLabel')}
                  </a>
                ),
                DataProtectionLink: (
                  <a href={i18n.t('register.privacyPolicy.rightsLink')} target="_blank" rel="noopener noreferrer">
                    {i18n.t('register.privacyPolicy.rightsLabel')}
                  </a>
                ),
                postProcess: 'reactPostprocessor',
              },
            )}
            value={props.privacyPolicyAccepted}
            onChange={acceptSelected}
            required
          />
        </InputGroup>

      </div>
    </div>
  );
};

PrivacyPolicy.propTypes = {
  privacyPolicyAcceptedSet: PropTypes.func.isRequired,
  privacyPolicyAccepted: PropTypes.bool.isRequired,
};

export default PrivacyPolicy;
