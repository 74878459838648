import React from 'react';
import PropTypes from 'prop-types';

import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import { formFieldShapeFor } from 'visa-frontend-common/src/dataModel';
import { displayDate } from 'visa-frontend-common/src/services/datetime';
import { formEntryPermitShape } from 'visa-frontend-common/src/components/ApplicationForm/commonApplicationModel';

import PreviewValidationError from './PreviewValidationError';
import i18n from '../../../../services/i18n';
import paths from '../../../../paths';

const PreviewEntryPermitView = (props) => {
  const journeyDetailsPath = paths.application.tabs.journeyDetails;

  return (
    <>
      { props.entryPermitForFinalDestination.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.entryPermitForFinalDestination')} [29]`}
          validationError={props.entryPermitForFinalDestination.validationError}
          tabPath={journeyDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.entryPermitForFinalDestination')} [29]`}
          dataCy="labeled-entryPermitForFinalDestination"
          value={i18n.tBoolean(props.entryPermitForFinalDestination.value)}
        />}

      { props.entryPermitForFinalDestination.value &&
        <>
          { props.entryPermit.value.issuingAuthority.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.entryPermitIssuingAuthority')}
              validationError={props.entryPermit.value.issuingAuthority.validationError}
              tabPath={journeyDetailsPath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.entryPermitIssuingAuthority')}
              dataCy="labeled-entryPermitIssuingAuthority"
              value={props.entryPermit.value.issuingAuthority.value}
            />}

          { props.entryPermit.value.validFrom.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.entryPermitIssuingAuthority')}
              validationError={props.entryPermit.value.validFrom.validationError}
              tabPath={journeyDetailsPath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.entryPermitValidFrom')}
              dataCy="labeled-entryPermitValidFrom"
              value={displayDate(props.entryPermit.value.validFrom.value)}
            />}

          { props.entryPermit.value.validUntil.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.entryPermitIssuingAuthority')}
              validationError={props.entryPermit.value.validUntil.validationError}
              tabPath={journeyDetailsPath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.entryPermitValidUntil')}
              dataCy="labeled-entryPermitValidUntil"
              value={displayDate(props.entryPermit.value.validUntil.value)}
            />}
        </>}
    </>);
};

PreviewEntryPermitView.propTypes = {
  entryPermitForFinalDestination: formFieldShapeFor(PropTypes.bool).isRequired,
  entryPermit: formFieldShapeFor(formEntryPermitShape).isRequired,
};

export default PreviewEntryPermitView;
