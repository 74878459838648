import * as validators from 'visa-frontend-common/src/services/validator/validators';

const copyRules = {
  applicationId: [
    validators.required,
  ],
  details: [
    validators.required,
  ],
};

export default copyRules;
