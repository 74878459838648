import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import Icon from 'visa-frontend-common/src/components/UI/Icon/Icon';
import { displayDate, displayDateTimeAsDate, isPastDate } from 'visa-frontend-common/src/services/datetime';
import { eventToField } from 'visa-frontend-common/src/utils/commonUtils';
import { historyShape } from 'visa-frontend-common/src/dataModel';
import { fullName } from 'visa-frontend-common/src/utils/dataModelUtils';

import Loading from '../../components/UI/Loading/LoadingContainer';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import actions from './PendingActions';
import frontPageActions from '../FrontPage/FrontPageActions';
import {
  answerFormShape,
  checklistItemTypeShape,
  documentCountShape,
  pendingAnswerType,
  pendingClosedType,
  pendingRequestShape,
} from '../../ovafModel';
import paths from '../../paths';
import i18n from '../../services/i18n';
import {
  isAnsweredInOvaf,
  introText,
  isClosedOutsideOvaf,
  translateChecklistItemType,
  warningText,
} from './pendingUtils';
import S3DocumentUploadForm from './S3DocumentUploadFormContainer';
import PendingAnswerForm from './PendingAnswerForm';
import PendingAnswerView from './PendingAnswerView';
import Guide from '../../components/Guide/Guide';
import PendingRequestGuide from './PendingRequestGuide';
import PendingClosedView from './PendingClosedView';

import './Pending.scss';

const PendingRequestView = (props) => {
  const applicantName = fullName(
    props.pendingRequest.surname,
    props.pendingRequest.firstName,
  );

  return (
    <div className="pending-request">
      <div className="pending-request__application-info">
        <LabeledValue
          className="pending-request__application-info__value"
          labelText={i18n.t('pending.name')}
          value={applicantName}
        />
        <LabeledValue
          className="pending-request__application-info__value"
          labelText={i18n.t('pending.dateOfBirth')}
          value={displayDate(props.pendingRequest.dateOfBirth)}
        />
        <LabeledValue
          className="pending-request__application-info__value"
          labelText={i18n.t('pending.travelDocumentNumber')}
          value={props.pendingRequest.travelDocumentNumber}
        />
        <LabeledValue
          className="pending-request__application-info__value"
          labelText={i18n.t('pending.received')}
          value={displayDateTimeAsDate(props.pendingRequest.receivedAt)}
        />
        <LabeledValue
          className="pending-request__application-info__value"
          labelText={i18n.t('pending.dueDate')}
          value={displayDate(props.pendingRequest.dueDate)}
        />
        <LoadingButton
          class="secondary"
          icon="description"
          onClick={() => props.printApplication(props.pendingRequest.applicationId)}
          label={i18n.t('pending.viewApplication')}
          dataCy="pending-view-print-button"
          requestNames={[
            frontPageActions.createDownloadingApplicationPdfBlobAction(
              props.pendingRequest.applicationId,
            )().type,
          ]}
          className="pending-request__application-info__value"
        />
      </div>

      <div className="pending-request__general-info">
        <p>
          {introText(props.pendingRequest.reason,
            i18n.t(`pending.answer.location.${props.pendingRequest.deliveryTarget}`),
            props.pendingRequest.attachmentsAllowed)}
        </p>
        <p>
          {warningText(props.pendingRequest.reason,
            { dueDate: displayDate(props.pendingRequest.dueDate) })}
        </p>
      </div>

      <div className="pending-request__details">
        <h2>{i18n.t(`pending.reason.${props.pendingRequest.reason}`)}</h2>
        <p>{props.pendingRequest.notes}</p>
      </div>
    </div>
  );
};

PendingRequestView.propTypes = {
  pendingRequest: pendingRequestShape.isRequired,
  printApplication: PropTypes.func.isRequired,
};

class PendingRequest extends React.PureComponent {
  state = {
    uploadFormOpen: false,
  };

  componentDidMount() {
    this.props.fetchPendingRequest(this.props.pendingRequestId);
    this.props.fetchDocumentStatistics();
    this.props.fetchChecklistItemTypes();
    this.props.fetchPendingStatusHistory(this.props.pendingRequestId);
  }

  showUploadForm = () => {
    this.setState({ uploadFormOpen: true });
  };

  hideUploadForm = () => {
    this.setState({ uploadFormOpen: false });
    this.props.clearFields(['uploadForm']);
  };

  render() {
    const printApplication = R.propEq('espLocation', 'IRN', this.props.pendingRequest) ?
      this.props.printApplicationInBrowser :
      this.props.printApplication;

    const handleSelect = (event) => {
      this.props.answerFormFieldSet(
        eventToField(event),
        this.props.answerForm,
      );
      this.props.setDirty(true);
    };

    const handleSubmit = () => this.props.answerPendingRequest(
      this.props.answerForm,
      this.props.pendingRequestId,
      this.props.setDirty,
    );

    const handleAccept = documentKeys => this.props.acceptDocuments(
      this.props.pendingRequest.applicationId,
      this.props.pendingRequestId,
      documentKeys,
    );

    const pendingText = R.cond([
      [R.isNil, R.always('')],
      [R.prop('checklistItemType'), R.compose(
        translateChecklistItemType(this.props.checklistItemTypes),
        R.prop('checklistItemType'),
      )],
      [R.T, pendingRequest => i18n.t(`pending.reason.${pendingRequest.reason}`)],
    ]);

    const pendingDocumentCount = pendingRequestId => R.compose(
      R.propOr(0, 'count'),
      R.find(R.propEq('pendingRequestId', pendingRequestId)),
    );

    return (
      <div>
        <div className="pending-request__header">
          <div className="pending__navigation container">
            <Link to={paths.pending.root} className="pending__navigation-link">
              <Icon name="arrow_back" className="pending__navigation-link-icon" />
              <span>{i18n.t('pending.navigateToRequests')}</span>
            </Link>
          </div>
        </div>

        <Loading requestNames={actions.pendingRequestFetched().type}>
          {this.props.pendingRequest ?
            <>
              <div className="pending-request__header">
                <div className="container">
                  <h1 className="pending-request__title">
                    {`${i18n.t('pending.requestTitle')} ${pendingText(this.props.pendingRequest)}`}
                  </h1>
                </div>
              </div>
              <div className="container">
                <PendingRequestView
                  pendingRequest={this.props.pendingRequest}
                  translateChecklistItemType={
                    translateChecklistItemType(this.props.checklistItemTypes)
                  }
                  printApplication={printApplication}
                />
                { !R.includes(this.props.pendingRequest.status, R.keys(pendingClosedType)) &&
                  !R.includes(this.props.pendingRequest.status, R.keys(pendingAnswerType)) &&
                  !isPastDate(this.props.pendingRequest.dueDate) &&
                  <>
                    { this.state.uploadFormOpen &&
                      <S3DocumentUploadForm
                        requestName={
                          translateChecklistItemType(
                            this.props.checklistItemTypes,
                          )(this.props.pendingRequest.checklistItemType)
                        }
                        handleSubmit={handleAccept}
                        handleCancel={this.hideUploadForm}
                      />}
                    { !this.state.uploadFormOpen &&
                      <>
                        { this.props.pendingRequest.attachmentsAllowed &&
                          <Button
                            className="pending-request__upload-button"
                            class="primary"
                            label={i18n.t('pending.uploadButton')}
                            onClick={this.showUploadForm}
                            dataCy="document-upload-button"
                          />}
                        <PendingAnswerForm
                          answerForm={this.props.answerForm}
                          handleSelect={handleSelect}
                          handleSubmit={handleSubmit}
                          requestReason={this.props.pendingRequest.reason}
                          deliveryTarget={this.props.pendingRequest.deliveryTarget}
                          attachmentsAllowed={this.props.pendingRequest.attachmentsAllowed}
                        />
                      </>}
                  </>}
                { R.includes(this.props.pendingRequest.status, R.keys(pendingAnswerType)) &&
                  !isClosedOutsideOvaf(this.props.pendingRequest) &&
                  <PendingAnswerView
                    answer={this.props.pendingRequest.status}
                    documentCount={
                      pendingDocumentCount(
                        this.props.pendingRequest.pendingRequestId,
                      )(this.props.documentCounts)
                    }
                    requestReason={this.props.pendingRequest.reason}
                    history={this.props.history}
                    deliveryTarget={this.props.pendingRequest.deliveryTarget}
                  />}
                {R.not(isAnsweredInOvaf(this.props.pendingRequest)) &&
                  ((isClosedOutsideOvaf(this.props.pendingRequest) ||
                  isPastDate(this.props.pendingRequest.dueDate))) &&
                  <PendingClosedView
                    history={this.props.history}
                    pendingRequest={this.props.pendingRequest}
                    answerStatus={this.props.answerStatus}
                    documentCount={
                      pendingDocumentCount(
                        this.props.pendingRequest.pendingRequestId,
                      )(this.props.documentCounts)
                    }
                  />}
              </div>
            </> : null}
        </Loading>
        <Guide>
          <PendingRequestGuide />
        </Guide>
      </div>
    );
  }
}

PendingRequest.propTypes = {
  history: historyShape.isRequired,
  pendingRequestId: PropTypes.string.isRequired,
  pendingRequest: pendingRequestShape,
  answerForm: answerFormShape,
  documentCounts: PropTypes.arrayOf(documentCountShape),
  checklistItemTypes: PropTypes.arrayOf(checklistItemTypeShape),
  fetchPendingRequest: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  answerFormFieldSet: PropTypes.func.isRequired,
  answerPendingRequest: PropTypes.func.isRequired,
  acceptDocuments: PropTypes.func.isRequired,
  fetchDocumentStatistics: PropTypes.func.isRequired,
  fetchChecklistItemTypes: PropTypes.func.isRequired,
  printApplication: PropTypes.func.isRequired,
  printApplicationInBrowser: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  answerStatus: PropTypes.string,
  fetchPendingStatusHistory: PropTypes.func.isRequired,
};

PendingRequest.defaultProps = {
  pendingRequest: null,
  answerForm: null,
  documentCounts: [],
  checklistItemTypes: null,
  answerStatus: null,
};

export default PendingRequest;
