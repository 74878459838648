import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './GuideSubtitle.scss';

const GuideSubtitle = (props) => {
  const classNames = classnames(
    'guide-subtitle',
    props.className,
  );

  if (props.type === 'h3') return <h3 id={props.id} className={classNames} data-cy={props.dataCy}>{props.children}</h3>;
  return <h4 id={props.id} className={classNames} data-cy={props.dataCy}>{props.children}</h4>;
};

GuideSubtitle.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['h3', 'h4']),
  dataCy: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
};

GuideSubtitle.defaultProps = {
  type: 'h4',
  dataCy: null,
  className: null,
  id: null,
};

export default GuideSubtitle;
