import React from 'react';
import PropTypes from 'prop-types';

import Button from '../UI/Button/Button';

import './NotificationContent.scss';

const NotificationContent = (props) => {
  return (
    <div className="notification-content" data-cy={`${props.toastProps.type}-notification`}>
      <small>{props.data}</small>
      <Button
        icon="close"
        onClick={props.closeToast}
        className="notification-content__close-button"
        class="custom"
        dataCy="notification-close-button"
      />
    </div>
  );
};

NotificationContent.propTypes = {
  data: PropTypes.node.isRequired,
  closeToast: PropTypes.func.isRequired,
  // eslint-disable-next-line
  toastProps: PropTypes.object.isRequired, // https://fkhadra.github.io/react-toastify/render-what-you-want
};

export default NotificationContent;
