import { toast } from 'react-toastify';
import * as R from 'ramda';
import i18n from './i18n';

import { createNotificationTransition, notificationAutoCloseDelay } from '../components/Notification/Notification';
import NotificationContent from '../components/Notification/NotificationContent';

export const dismissNotification = (id) => {
  toast.dismiss(id);
};

const notify = (autoClose, type, id, content) => {
  if (toast.isActive(id)) {
    // restart active notification transition
    return toast.update(id, { transition: createNotificationTransition() });
  }

  const toastOptions = {
    toastId: id,
    type,
    data: content,
    autoClose,
  };

  return toast(NotificationContent, toastOptions);
};

export const notifySuccess = R.curry(notify)(notificationAutoCloseDelay, toast.TYPE.SUCCESS);
export const notifyInfo = R.curry(notify)(false, toast.TYPE.INFO);
export const notifyError = R.curry(notify)(false, toast.TYPE.ERROR);

export const notifySuccessWithoutAutoClose = R.curry(notify)(false, toast.TYPE.SUCCESS);

const itemKey = 'storedNotification';

export const putNotification = (messageKey) => {
  localStorage.setItem(itemKey, messageKey);
};

export const popNotification = () => {
  const messageKey = localStorage.getItem(itemKey);
  if (messageKey) {
    localStorage.removeItem(itemKey);
    notifySuccess('stored-notification', i18n.t(messageKey));
  }
};
