import * as R from 'ramda';

import { pickFirstKey } from '../../utils/commonUtils';
import formValidator from './formValidator';
import { arrayUpdateReducerFor } from '../../utils/reducerUtils';
/**
 * @param {array} arrayPath Path relative to form
 * @param {array} formPath Path relative to reducer state
 * @param rules form validation rules
 */
export const validatingSetArrayField = (arrayPath, formPath, rules) => (state, action) => {
  const { field, index } = action.payload;
  const arrayName = R.last(arrayPath);
  const fieldName = pickFirstKey(field);
  const form = R.path(formPath, state);
  const arrayFieldLens = R.lensPath(R.concat(arrayPath, ['value', index, fieldName]));

  const validatedArrayField = formValidator.validateField(
    R.set(R.compose(arrayFieldLens, R.lensProp('value')), field[fieldName].value, R.pick([arrayName], form)),
    form,
    rules[arrayName],
  );

  const payload = { index, fieldName, fieldValue: R.view(arrayFieldLens, validatedArrayField) };
  const arrayValuePath = R.concat(formPath, R.concat(arrayPath, ['value']));

  return arrayUpdateReducerFor(arrayValuePath)(state, R.assoc('payload', payload, action));
};

export default validatingSetArrayField;
