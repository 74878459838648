import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  formAddressDefaultProps,
  formAddressShape,
  formArrayFieldDefaultProps,
  formFieldDefaultProps,
  formFieldDefaultValue,
  formFieldShape,
  formFieldShapeFor,
} from '../../dataModel';
import { invitationFormShape } from './Invitations/InvitationsDataModel';

export const visaType = {
  A: 'A',
  C: 'C',
};

export const documentTypes = [
  'ADDITIONAL_DOCUMENTS',
  'ADS_DOCUMENTS',
  'AIR_CREW_DOCUMENTS',
  'APPLICANT_LIST',
  'BERRY_DOCUMENTS',
  'BORROW_PASSPORT_REQUEST',
  'BUSINESS_TRAVEL_DOCUMENTS',
  'DOCUMENTS_FOR_THIRD_COUNTRY_CITIZENS',
  'DRIVER_DOCUMENTS',
  'EMPLOYER_DOCUMENTS',
  'EU_NATIONALITY',
  'FAMILY_MEMBER',
  'FAMILY_TRAVEL',
  'GUARDIAN_DOCUMENTS',
  'HUKOU',
  'INVITATION_LETTER',
  'INVITING_PERSON_DOCUMENTS',
  'JOURNALIST_DOCUMENTS',
  'MEDICAL_INSURANCE',
  'MEDICAL_REASON_DOCUMENTS',
  'MINOR_BIRTH_CERTIFICATE',
  'NATIONAL_ID',
  'OTHER_SUBMISSION_DOCUMENTS',
  'OTHER_TRAVEL_DOCUMENTS',
  'PROOF_OF_ACCOMODATION',
  'PROOF_OF_CUSTODY_CHANGES',
  'PROOF_OF_ECONOMIC_ACTIVITY',
  'PROOF_OF_EMPLOYMENT',
  'PROOF_OF_FINANCIAL_MEANS',
  'PROOF_OF_ITINERARY',
  'PROOF_OF_PARTICIPATION',
  'PROOF_OF_PENSION',
  'PROOF_OF_RELATIONSHIP',
  'PROOF_OF_STUDIES',
  'PROPERTY_OWNER_DOCUMENTS',
  'SCIENCE_AND_CULTURE_DOCUMENTS',
  'SEAMEN_DOCUMENTS',
  'SEASONAL_WORK_DOCUMENTS',
  'SPORTS_EVENT_DOCUMENTS',
  'STUDY_DOCUMENTS',
  'TRAIN_CREW_DOCUMENTS',
  'TRANSPORT_RESERVATIONS',
  'TRAVEL_DOCUMENT',
  'TRAVEL_ORDER',
  'VISA_APPLICATION',
  'VISA_REVOKE',
];

export const minorChecklistItemTypes = [
  'PROOF_OF_CUSTODY_CHANGES',
  'GUARDIAN_DOCUMENTS',
  'MINOR_BIRTH_CERTIFICATE',
];

export const applicationChecklistItem = {
  checklistItemType: PropTypes.oneOf(documentTypes),
  applicantChecked: PropTypes.bool,
  submissionStatus: PropTypes.string,
  pendingDate: PropTypes.string,
  note: PropTypes.string,
};

export const applicationChecklistItemForm = {
  checklistItemType: formFieldShape.isRequired,
  applicantChecked: formFieldShape.isRequired,
  submissionStatus: formFieldShape.isRequired,
  pendingDate: formFieldShape.isRequired,
  note: formFieldShape.isRequired,
};

export const applicationChecklistItemFormWithMetadata = {
  ...applicationChecklistItemForm,
  attachmentTypeDescription: PropTypes.string,
  attachmentName: PropTypes.string,
};

export const checklistItemSubmissionStatusValues = {
  accepted: 'ACCEPTED',
  pending: 'PENDING',
  notAvailable: 'NOT_AVAILABLE',
};

export const formEntryPermitShape = PropTypes.exact({
  validFrom: formFieldShape.isRequired,
  validUntil: formFieldShape.isRequired,
  issuingAuthority: formFieldShape.isRequired,
});

export const applicationForm = {
  aurn: formFieldShape,
  vurn: formFieldShape,
  visaTypeRequested: formFieldShape.isRequired,
  representedCountry: formFieldShape,
  travelAgencyName: formFieldShape.isRequired,
  firstName: formFieldShape.isRequired,
  surname: formFieldShape.isRequired,
  surnameAtBirth: formFieldShape.isRequired,
  travelDocumentType: formFieldShape.isRequired,
  travelDocumentTypeSpecify: formFieldShape.isRequired,
  travelDocumentNumber: formFieldShape.isRequired,
  travelDocumentIssueDate: formFieldShape.isRequired,
  travelDocumentValidUntil: formFieldShape.isRequired,
  travelDocumentIssuedBy: formFieldShape,
  travelDocumentCountryIssued: formFieldShape.isRequired,
  formerSurnames: formFieldShape.isRequired,
  formerSurnamesInput: formFieldShape.isRequired,
  patronymicName: formFieldShape,
  dateOfBirth: formFieldShape.isRequired,
  placeOfBirth: formFieldShape.isRequired,
  countryOfBirth: formFieldShape.isRequired,
  currentNationality: formFieldShape.isRequired,
  nationalityAtBirth: formFieldShape.isRequired,
  otherNationalities: formFieldShape.isRequired,
  sex: formFieldShape.isRequired,
  maritalStatus: formFieldShape.isRequired,
  maritalStatusSpecify: formFieldShape.isRequired,
  nationalIdentityNumber: formFieldShape.isRequired,

  guardianFirstName: formFieldShape.isRequired,
  guardianSurname: formFieldShape.isRequired,
  guardianNationality: formFieldShape.isRequired,
  guardianAddress: formFieldShapeFor(formAddressShape).isRequired,
  guardianAddressIsDifferentThanApplicants: formFieldShapeFor(PropTypes.bool),
  guardianEmail: formFieldShape.isRequired,
  guardianPhonenumber: formFieldShape.isRequired,

  secondaryGuardianFirstName: formFieldShape.isRequired,
  secondaryGuardianSurname: formFieldShape.isRequired,
  secondaryGuardianNationality: formFieldShape.isRequired,
  secondaryGuardianAddress: formFieldShapeFor(formAddressShape).isRequired,
  secondaryGuardianAddressIsDifferentThanApplicants: formFieldShapeFor(PropTypes.bool),
  secondaryGuardianEmail: formFieldShape.isRequired,
  secondaryGuardianPhonenumber: formFieldShape.isRequired,

  euFirstname: formFieldShape.isRequired,
  euSurname: formFieldShape.isRequired,
  euDateOfBirth: formFieldShape.isRequired,
  euPassportNumber: formFieldShape.isRequired,
  euFamilyRelationshipCode: formFieldShape.isRequired,
  euFamilyRelationshipSpecify: formFieldShape.isRequired,
  euNationality: formFieldShape.isRequired,
  eucitizenFamily: formFieldShapeFor(PropTypes.bool).isRequired,
  // contactOrAccommodation field represents all top level invitation options in
  // contacts and accommodations and is only used in validation.
  contactOrAccommodation: formFieldShapeFor(PropTypes.bool).isRequired,
  invitingOrganization: formFieldShapeFor(invitationFormShape).isRequired,
  invitingOrganizationContact: formFieldShapeFor(invitationFormShape).isRequired,
  invitingPersons: formFieldShapeFor(PropTypes.arrayOf(invitationFormShape)).isRequired,
  accommodations: formFieldShapeFor(PropTypes.arrayOf(invitationFormShape)).isRequired,
  oneDayTripWithoutAccommodation: formFieldShapeFor(PropTypes.bool).isRequired,
  travelCostsApplicantInUse: formFieldShapeFor(PropTypes.bool).isRequired,
  travelCostsApplicant: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostApplicantOther: formFieldShape.isRequired,
  travelCostsSponsorInUse: formFieldShapeFor(PropTypes.bool).isRequired,
  sponsorHost: formFieldShape.isRequired,
  sponsorHostSpecify: formFieldShape.isRequired,
  sponsorOther: formFieldShape.isRequired,
  sponsorOtherSpecify: formFieldShape.isRequired,
  travelCostsSponsor: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostSponsorOther: formFieldShape.isRequired,
  address: formFieldShapeFor(formAddressShape).isRequired,
  secondaryAddress: formFieldShapeFor(formAddressShape).isRequired,
  email: formFieldShape.isRequired,
  phonenumbers: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  phonenumbersInput: formFieldShape.isRequired,
  residenceInOtherCountry: formFieldShapeFor(PropTypes.bool).isRequired,
  residencePermitNumber: formFieldShape.isRequired,
  residencePermitValidUntil: formFieldShape.isRequired,
  occupationCode: formFieldShape.isRequired,
  occupationOther: formFieldShape.isRequired,
  employerName: formFieldShape.isRequired,
  schoolName: formFieldShape.isRequired,
  employerAddress: formFieldShapeFor(formAddressShape).isRequired,
  employerPhonenumber: formFieldShape.isRequired,
  employerEmail: formFieldShape.isRequired,
  mainPurposeOfJourney: formFieldShape.isRequired,
  numberOfEntriesRequested: formFieldShape.isRequired,
  otherPurposesOfJourney: formFieldShape,
  journeyPurposeOther: formFieldShape,
  journeyPurposeDetails: formFieldShape,
  destinationStates: formFieldShape.isRequired,
  entryPermitForFinalDestination: formFieldShapeFor(PropTypes.bool).isRequired,
  entryPermit: formFieldShapeFor(formEntryPermitShape).isRequired,

  borderOfFirstEntryCountry: formFieldShape.isRequired,
  dateOfArrival: formFieldShape.isRequired,
  dateOfDeparture: formFieldShape.isRequired,
  fingerprintsAlreadyStored: formFieldShapeFor(PropTypes.bool).isRequired,
  fingerprintsStoredAt: formFieldShape.isRequired,
  fingerprintsVisaNumber: formFieldShape.isRequired,
};

export const applicationFormShape = PropTypes.exact(applicationForm);

export const formEntryPermitDefaultProps = {
  validFrom: formFieldDefaultProps,
  validUntil: formFieldDefaultProps,
  issuingAuthority: formFieldDefaultProps,
};

export const applicationFormDefaultProps = {
  aurn: formFieldDefaultProps,
  vurn: formFieldDefaultProps,
  visaTypeRequested: formFieldDefaultValue('C'),
  representedCountry: formFieldDefaultProps,
  travelAgencyName: formFieldDefaultProps,
  firstName: formFieldDefaultProps,
  surname: formFieldDefaultProps,
  surnameAtBirth: formFieldDefaultProps,
  travelDocumentType: formFieldDefaultProps,
  travelDocumentNumber: formFieldDefaultProps,
  travelDocumentIssueDate: formFieldDefaultProps,
  travelDocumentValidUntil: formFieldDefaultProps,
  travelDocumentIssuedBy: formFieldDefaultProps,
  travelDocumentCountryIssued: formFieldDefaultProps,
  travelDocumentTypeSpecify: formFieldDefaultProps,
  formerSurnames: formArrayFieldDefaultProps,
  formerSurnamesInput: formFieldDefaultProps,
  patronymicName: formFieldDefaultProps,
  dateOfBirth: formFieldDefaultProps,
  placeOfBirth: formFieldDefaultProps,
  countryOfBirth: formFieldDefaultProps,
  currentNationality: formFieldDefaultProps,
  nationalityAtBirth: formFieldDefaultProps,
  otherNationalities: formArrayFieldDefaultProps,
  sex: formFieldDefaultProps,
  maritalStatus: formFieldDefaultProps,
  maritalStatusSpecify: formFieldDefaultProps,
  nationalIdentityNumber: formFieldDefaultProps,

  guardianFirstName: formFieldDefaultProps,
  guardianSurname: formFieldDefaultProps,
  guardianNationality: formFieldDefaultProps,
  guardianAddress: R.assoc('value', formAddressDefaultProps, formFieldDefaultProps),
  guardianAddressIsDifferentThanApplicants: formFieldDefaultValue(false),
  guardianEmail: formFieldDefaultProps,
  guardianPhonenumber: formFieldDefaultProps,

  secondaryGuardianFirstName: formFieldDefaultProps,
  secondaryGuardianSurname: formFieldDefaultProps,
  secondaryGuardianNationality: formFieldDefaultProps,
  secondaryGuardianAddress: R.assoc('value', formAddressDefaultProps, formFieldDefaultProps),
  secondaryGuardianAddressIsDifferentThanApplicants: formFieldDefaultValue(false),
  secondaryGuardianEmail: formFieldDefaultProps,
  secondaryGuardianPhonenumber: formFieldDefaultProps,

  euFirstname: formFieldDefaultProps,
  euSurname: formFieldDefaultProps,
  euDateOfBirth: formFieldDefaultProps,
  euPassportNumber: formFieldDefaultProps,
  euFamilyRelationshipCode: formFieldDefaultProps,
  euFamilyRelationshipSpecify: formFieldDefaultProps,
  euNationality: formFieldDefaultProps,
  eucitizenFamily: formFieldDefaultValue(false),
  contactOrAccommodation: formFieldDefaultValue(false),
  invitingOrganization: formFieldDefaultProps,
  invitingOrganizationContact: formFieldDefaultProps,
  invitingPersons: formFieldDefaultValue([]),
  accommodations: formFieldDefaultValue([]),
  oneDayTripWithoutAccommodation: formFieldDefaultValue(false),
  travelCostsApplicantInUse: formFieldDefaultValue(false),
  travelCostsApplicant: formFieldDefaultValue([]),
  travelCostApplicantOther: formFieldDefaultProps,
  travelCostsSponsorInUse: formFieldDefaultValue(false),
  sponsorHost: formFieldDefaultValue(false),
  sponsorHostSpecify: formFieldDefaultProps,
  sponsorOther: formFieldDefaultValue(false),
  sponsorOtherSpecify: formFieldDefaultProps,
  travelCostsSponsor: formFieldDefaultValue([]),
  travelCostSponsorOther: formFieldDefaultProps,
  address: R.assoc('value', formAddressDefaultProps, formFieldDefaultProps),
  secondaryAddress: R.assoc('value', formAddressDefaultProps, formFieldDefaultProps),
  email: formFieldDefaultProps,
  phonenumbers: formArrayFieldDefaultProps,
  phonenumbersInput: formFieldDefaultProps,
  residenceInOtherCountry: formFieldDefaultValue(false),
  residencePermitNumber: formFieldDefaultProps,
  residencePermitValidUntil: formFieldDefaultProps,
  occupationCode: formFieldDefaultProps,
  occupationOther: formFieldDefaultProps,
  employerName: formFieldDefaultProps,
  schoolName: formFieldDefaultProps,
  employerAddress: R.assoc('value', formAddressDefaultProps, formFieldDefaultProps),
  employerPhonenumber: formFieldDefaultProps,
  employerEmail: formFieldDefaultProps,

  borderOfFirstEntryCountry: formFieldDefaultProps,
  dateOfArrival: formFieldDefaultProps,
  dateOfDeparture: formFieldDefaultProps,
  fingerprintsAlreadyStored: formFieldDefaultValue(false),
  fingerprintsStoredAt: formFieldDefaultProps,
  fingerprintsVisaNumber: formFieldDefaultProps,

  mainPurposeOfJourney: formFieldDefaultProps,
  numberOfEntriesRequested: formFieldDefaultProps,
  otherPurposesOfJourney: formArrayFieldDefaultProps,
  journeyPurposeOther: formFieldDefaultProps,
  journeyPurposeDetails: formFieldDefaultProps,
  destinationStates: formArrayFieldDefaultProps,
  entryPermitForFinalDestination: formFieldDefaultValue(false),
  entryPermit: R.assoc('value', formEntryPermitDefaultProps, formFieldDefaultProps),
};
