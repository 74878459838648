import http from 'visa-frontend-common/src/services/http';
import * as R from 'ramda';
import { generateIdsToList } from 'visa-frontend-common/src/services/idService';
import { isUnderage } from 'visa-frontend-common/src/services/datetime';
import { documentForMinor } from 'visa-frontend-common/src/utils/applicationDocumentUtils';

const baseUrl = '/api/applications';

const removeChecklistItemDescriptions = application => R.assoc(
  'applicationChecklistItems',
  R.map(R.dissoc('description'), application.applicationChecklistItems),
  application,
);

const omitListFieldInputFields = R.omit(['formerSurnamesInput', 'phonenumbersInput']);

const fillEmptyChecklistItemsForMinorIfAdult = R.compose(
  R.when(
    R.compose(R.not, isUnderage, R.prop('dateOfBirth')),
    R.over(
      R.lensProp('applicationChecklistItems'),
      R.map(R.when(
        R.compose(documentForMinor, R.prop('checklistItemType')),
        R.assoc('applicantChecked', false),
      )),
    ),
  ),
);

export const getApplication = applicationId =>
  http.fetchAsJSON(`${baseUrl}/${applicationId}`, { method: 'GET' })
    .then(R.evolve({
      accommodations: generateIdsToList('uid'),
      invitingPersons: generateIdsToList('uid'),
    }));

export const getFinalizedApplication = applicationId =>
  http.fetchAsJSON(`${baseUrl}/${applicationId}/completed`, { method: 'GET' });

export const getApplicationStatus = applicationId =>
  http.fetchAsJSON(`${baseUrl}/${applicationId}/status`, { method: 'GET' });

export const getCopyOfApplication = form =>
  http.fetchAsJSON(`${baseUrl}/${form.applicationId}/copy`, {
    method: 'GET',
    params: { details: R.join(',', form.details) },
  })
    .then(R.evolve({
      accommodations: generateIdsToList('uid'),
      invitingPersons: generateIdsToList('uid'),
    }));

export const getApplications = () =>
  http.fetchAsJSON(`${baseUrl}/find-all`, { method: 'GET' });

export const createApplication = (payload, token) => (
  http.fetchAsJSON(`${baseUrl}`, {
    method: 'POST',
    body: JSON.stringify({
      payload:
        R.compose(
          omitListFieldInputFields,
          removeChecklistItemDescriptions,
        )(payload),
      token,
    }),
  })
);

export const createApplicationForDone = (payload, token) => (
  http.fetchAsJSON(`${baseUrl}/done`, {
    method: 'POST',
    body: JSON.stringify({
      payload:
        R.compose(
          omitListFieldInputFields,
          removeChecklistItemDescriptions,
          fillEmptyChecklistItemsForMinorIfAdult,
        )(payload),
      token,
    }),
  })
);

export const updateApplication = (application, applicationId) => (
  http.fetchAsJSON(`${baseUrl}/${applicationId}`, {
    method: 'PUT',
    body: R.compose(
      JSON.stringify,
      omitListFieldInputFields,
      removeChecklistItemDescriptions,
    )(application),
  })
);

export const updateApplicationForDone = (application, applicationId) => (
  http.fetchAsJSON(`${baseUrl}/${applicationId}/done`, {
    method: 'PUT',
    body:
      R.compose(
        JSON.stringify,
        omitListFieldInputFields,
        removeChecklistItemDescriptions,
        fillEmptyChecklistItemsForMinorIfAdult,
      )(application),
  })
);

export const printApplication = applicationId =>
  http.fetchAsBlob(`${baseUrl}/${applicationId}/print`, { method: 'GET' });

export const printApplicationInBrowser = applicationId =>
  http.fetchAsText(`${baseUrl}/${applicationId}/print-in-browser`, { method: 'GET' });

export const deleteApplication = applicationId =>
  http.fetchAsJSON(`${baseUrl}/${applicationId}`, { method: 'DELETE' });

export const getApplicationGroups = () => http.fetchAsJSON('/api/application-groups', { method: 'GET' });

export const createApplicationGroup = () => http.fetchAsJSON('/api/application-groups', { method: 'POST' });

export const deleteApplicationGroup = groupId =>
  http.fetchAsJSON(`/api/application-groups/${groupId}`, { method: 'DELETE' });

export const addApplicationToGroup = form =>
  http.fetchAsJSON(`/api/application-groups/${form.groupId}`, { method: 'POST', body: form.applicationId });

export const removeApplicationFromGroup = (applicationId, groupId) =>
  http.fetchAsJSON(`/api/application-groups/${groupId}/${applicationId}`, { method: 'DELETE' });

export const getDocumentStatistics = () => {
  return http
    .fetchAsJSON(`${baseUrl}/document-statistics`, { method: 'GET' });
};
