import * as R from 'ramda';

import { handleActions } from 'redux-actions';
import { createSelector } from '../../utils/reducerUtils';
import actions from './paginationActions';

const initialState = {};

export const getPaginationFor = R.compose(R.path, R.insert(1, R.__, ['pagination']));
export const getRowsOnPageFor = R.compose(R.path, R.insert(1, R.__, ['pagination', 'rowsOnPage']));
export const getCurrentPageFor = R.compose(R.path, R.insert(1, R.__, ['pagination', 'currentPage']));

export const paginationSelectorFor = listName => createSelector(
  [getPaginationFor(listName)],
  R.when(R.isNil, R.always({})),
);

export default handleActions({
  [actions.listPaginationChanged]: (state, { payload }) => R.compose(
    R.assoc(payload.listName, R.__, state),
    R.pick(['rowsOnPage', 'currentPage']),
  )(payload),
}, initialState);
