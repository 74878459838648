import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as R from 'ramda';

import { actions } from 'visa-frontend-common/src/services/loading/loadingActions';
import Loading from 'visa-frontend-common/src/components/UI/Loading/Loading';
import { isSomeRequestLoadingSelector } from 'visa-frontend-common/src/services/loading/loadingSelectors';

const mapStateToProps = (state, ownProps) => ({
  requestLoading: isSomeRequestLoadingSelector(state, ownProps.requestNames),
});

const clearLoading = actions.CLEAR_LOADING;
const mapDispatchToProps = { clearLoading };

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Loading);
