import * as R from 'ramda';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { axiosInstance } from 'visa-frontend-common/src/services/http';
import { matchesPathList } from 'visa-frontend-common/src/utils/pathUtils';
import getUserConfirmation from 'visa-frontend-common/src/services/navigationprompt/getUserConfirmation';
import rootReducer from './reducers';
import paths from './paths';
// import registerServiceWorker from './registerServiceWorker';

// Serve assets from local cache
// Disabled for now to avoid unwanted demo effects
// registerServiceWorker();

// Redux devtools composer
const composeEnhancers = composeWithDevTools({});

// Create history API handle
const history = createBrowserHistory({ getUserConfirmation });
const redirectOn401 = (error) => {
  const currentPathIsPublic = matchesPathList([
    paths.checklist,
    paths.root,
    paths.accessibility.root,
    paths.guidePage.root,
    ...R.values(paths.register),
    ...R.values(paths.forgotPassword),
  ]);
  const unauthorizedResponse = error.response && error.response.status === 401;

  if (unauthorizedResponse && !currentPathIsPublic(window.location.pathname)) {
    window.location.assign(paths.root);
  }
  return Promise.reject(error.response);
};

axiosInstance.interceptors.response.use(response => response, redirectOn401);

const store = createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunk)),
);

export { store, history };
