import { connect } from 'react-redux';

import { applicationCopyFormSelector } from '../../pages/FrontPage/FrontPageReducer';
import actions from '../../pages/FrontPage/FrontPageActions';
import ApplicationCopyForm from './ApplicationCopyForm';

const mapStateToProps = state => ({
  form: applicationCopyFormSelector(state),
});

const mapDispatchToProps = {
  submitForm: actions.startCopyOfApplication,
  onChange: actions.setCopyFieldAndValidate,
  clearForm: actions.applicationCopyFormCleared,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationCopyForm);
