import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';

import './AccordionWarningHeaderContent.scss';

const AccordionWarningHeaderContent = (props) => {
  return (
    <div className="accordion-warning-header-content">
      <Icon
        className="accordion-warning-header-content__icon"
        name="warning"
        title={props.title || props.ariaLabel}
        ariaLabel={props.ariaLabel}
      />
    </div>
  );
};

AccordionWarningHeaderContent.propTypes = {
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
};

AccordionWarningHeaderContent.defaultProps = {
  title: null,
  ariaLabel: null,
};

export default AccordionWarningHeaderContent;
