import * as validators from 'visa-frontend-common/src/services/validator/validators';

const ApplicationGroupingValidationRules = {
  applicationId: [
    validators.required,
  ],
  groupId: [
    validators.required,
  ],
};

export default ApplicationGroupingValidationRules;
