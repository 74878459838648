import React from 'react';
import PropTypes from 'prop-types';
import InlineNotification from '../InlineNotification/InlineNotification';

const LoadingNotification = ({ loadingText }) => {
  return <InlineNotification type="wait" disableClose>{loadingText}</InlineNotification>;
};

LoadingNotification.propTypes = {
  loadingText: PropTypes.string.isRequired,
};

export default LoadingNotification;
