import http from 'visa-frontend-common/src/services/http';
import i18n from './i18n';

const getCodesetCodes = (codesetGroup, useCache = true, language = i18n.language()) => {
  return http.fetchAsJSON(`/api/codeset/codes/${codesetGroup}`, {
    params: { lang: language },
    method: 'GET',
    useCache,
  });
};

export default getCodesetCodes;
