import React from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import * as R from 'ramda';

import { historyShape, matchShape } from 'visa-frontend-common/src/dataModel';
import { InputGroup } from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import LanguageSelector from 'visa-frontend-common/src/components/LanguageSelector/LanguageSelector';
import Form from 'visa-frontend-common/src/components/UI/Form/Form';

import {
  checklistItemAttachmentShape,
  checklistPageLanguageSelectorOptions,
  staticChecklistShape,
} from '../../ovafModel';
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle';
import i18n from '../../services/i18n';
import paths from '../../paths';
import CodesetSelectContainer from '../../components/UI/Form/CodesetCode/CodesetSelectContainer';
import CountryChecklistChooser from './CountryChecklistChooser';
import ChecklistDetails from './ChecklistDetails';

import './ChecklistPage.scss';

class ChecklistPage extends React.PureComponent {
  componentDidMount() {
    const { espLocation } = this.props.match.params;
    if (espLocation) {
      this.props.fetchStaticChecklistsAndInstructions(espLocation);
    }

    const current = i18n.getLocaleFromLocalStorage();

    if (!checklistPageLanguageSelectorOptions
      .map(locale => locale.value).some(value => value === current)) {
      this.props.setChecklistPageLocale('en_GB');
    } else {
      this.props.setChecklistPageLocale(current);
    }
  }

  componentDidUpdate(prevProps) {
    const oldEspLocation = prevProps.match.params.espLocation;
    const newEspLocation = this.props.match.params.espLocation;
    if (oldEspLocation !== newEspLocation) {
      this.props.fetchStaticChecklistsAndInstructions(newEspLocation);
    }
  }

  render() {
    const {
      history,
      instructions,
      match,
      checklistPageLocale,
    } = this.props;
    const {
      espLocation,
      checklistId,
    } = match.params;

    const countryChecklist =
      this.props.checklists.find(cl => cl.checklistId === Number(checklistId));

    const titleText = () => i18n.t('checklist.title', { lng: checklistPageLocale });

    const documentTitleText = () => {
      if (this.props.match.params.espLocation && this.props.match.params.checklistId) {
        return `${titleText()} - ${this.props.match.params.espLocation} - ${this.props.match.params.checklistId}`;
      }
      if (this.props.match.params.espLocation && !this.props.match.params.checklistId) {
        return `${titleText()} - ${this.props.match.params.espLocation}`;
      }

      return titleText();
    };

    const printChecklist = espLocation === 'IRN' ? this.props.printChecklistInBrowser : this.props.printChecklist;

    return (
      <div className="checklist-page container" data-cy="checklist-root" id="main" role="main" aria-labelledby="checklist-title">
        <Form>
          <div className="row">
            <div className="col-12 col-md-9">
              <DocumentTitle>{documentTitleText()}</DocumentTitle>
              <h1 id="checklist-title">{titleText()}</h1>
            </div>
            <div className="col-4 col-md-3 checklist-page__language-selector">
              { checklistPageLocale &&
              <LanguageSelector
                languageSelectorOptions={checklistPageLanguageSelectorOptions}
                locale={checklistPageLocale}
                updateLocale={this.props.setChecklistPageLocale}
              />}
            </div>
          </div>
          <h2>{i18n.t('checklist.chooseCountryTitle', { lng: checklistPageLocale })}</h2>
          <InputGroup
            className="checklist-page__country-selector"
            label={i18n.t('checklist.countryLabel', { lng: checklistPageLocale })}
          >
            <CodesetSelectContainer
              name="espLocation"
              value={espLocation}
              codesetGroup="CountryOfSubmission"
              onChange={({ target }) => {
                history.push(
                  generatePath(
                    paths.checklist, {
                      espLocation: target.value,
                    },
                  ),
                );
              }}
            />
          </InputGroup>
          { espLocation && (
          <CountryChecklistChooser
            lang={checklistPageLocale}
            espLocation={espLocation}
            checklistId={checklistId}
            instructions={instructions}
            checklists={this.props.checklists}
          />
          )}
        </Form>
        <div className="checklist-page__checklist-details">
          { countryChecklist &&
            <ChecklistDetails
              lang={checklistPageLocale}
              espLocation={espLocation}
              checklist={countryChecklist}
              checklistItemAttachments={
                R.filter(
                  R.propEq('checklistId', countryChecklist.checklistId),
                  this.props.checklistItemAttachments,
                )
              }
              fetchChecklistItemAttachments={this.props.fetchChecklistItemAttachments}
              printChecklist={() => printChecklist(espLocation, checklistId)}
            /> }
        </div>
      </div>
    );
  }
}

ChecklistPage.propTypes = {
  match: matchShape.isRequired,
  history: historyShape.isRequired,
  fetchStaticChecklistsAndInstructions: PropTypes.func.isRequired,
  fetchChecklistItemAttachments: PropTypes.func.isRequired,
  instructions: PropTypes.string,
  checklists: PropTypes.arrayOf(staticChecklistShape),
  checklistItemAttachments: PropTypes.arrayOf(checklistItemAttachmentShape),
  printChecklist: PropTypes.func.isRequired,
  printChecklistInBrowser: PropTypes.func.isRequired,
  checklistPageLocale: PropTypes.string,
  setChecklistPageLocale: PropTypes.func.isRequired,
};

ChecklistPage.defaultProps = {
  instructions: null,
  checklists: [],
  checklistItemAttachments: [],
  checklistPageLocale: null,
};

export default ChecklistPage;
