import { createAction } from 'redux-actions';

const REQUEST = '_REQUEST';
const COMPLETED = '_COMPLETED';

const types = {
  CLEAR_LOADING: 'CLEAR_LOADING',
};

const actions = {
  CLEAR_LOADING: createAction(types.CLEAR_LOADING),
};

const loadingActionCreator = (resultAction) => {
  const resultActionType = resultAction().type;

  if (resultActionType.indexOf(COMPLETED) === -1) {
    throw Error(`resultAction type ${resultActionType} must end with suffix ${COMPLETED}`);
  }

  return createAction(resultActionType.replace(COMPLETED, REQUEST));
};

export {
  actions,
  types,
  loadingActionCreator,
};
