import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { codesetGroupEnum } from '../../../dataModel';
import LabeledContainer from './LabeledContainer';

import './Labeled.scss';

const LabeledCode = (props) => {
  const CodesetValueContainer = props.codesetValueContainer;

  return (
    <LabeledContainer
      labelText={props.labelText}
      className={props.className}
      noWrap={props.noWrap}
      indent={props.indent}
      dataCy={props.dataCy}
    >
      { props.code &&
        <small className={classnames('labeled__small', { 'labeled__bordered-page-element': props.withBottomBorder })}>
          <CodesetValueContainer
            codesetGroup={props.group}
            value={props.code}
            disableSmallTextWrapper
            displayText={props.displayText}
          />
        </small>}
    </LabeledContainer>
  );
};

LabeledCode.propTypes = {
  dataCy: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  className: PropTypes.string,
  group: codesetGroupEnum.isRequired,
  code: PropTypes.string,
  displayText: PropTypes.func,
  withBottomBorder: PropTypes.bool,
  noWrap: PropTypes.bool,
  indent: PropTypes.bool,
  codesetValueContainer: PropTypes.elementType.isRequired,
};

LabeledCode.defaultProps = {
  dataCy: null,
  className: null,
  code: null,
  displayText: null,
  withBottomBorder: false,
  noWrap: false,
  indent: false,
};

export default LabeledCode;
