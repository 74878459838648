import React from 'react';
import PropTypes from 'prop-types';

const TwoColumnRow = (props) => {
  const columns =
    React.Children.map(props.children, (child) => {
      if (!child) {
        return null;
      }

      const colElement =
        <div className="col-12 col-lg-6">
          {child}
        </div>;

      return (
        <>
          { props.oneColumn ?
            <div className="row">
              { colElement }
            </div> : colElement}
        </>
      );
    });

  return (
    <>
      { props.oneColumn ?
        columns :
        <div className="row">
          {columns}
        </div>}
    </>
  );
};

TwoColumnRow.propTypes = {
  oneColumn: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

TwoColumnRow.defaultProps = {
  oneColumn: false,
};

export default TwoColumnRow;
