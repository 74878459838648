import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LoadingIcon from 'visa-frontend-common/src/components/UI/Icon/LoadingIcon';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import Icon from 'visa-frontend-common/src/components/UI/Icon/Icon';
import Image from 'visa-frontend-common/src/components/UI/Image/Image';
import ValidationError from 'visa-frontend-common/src/components/UI/ValidationError/ValidationError';
import { formFileFieldShape } from 'visa-frontend-common/src/dataModel';
import { fitFilename } from 'visa-frontend-common/src/utils/downloadUtils';
import i18n from '../../services/i18n';
import { pendingRequestDocumentStatuses, statusShape } from '../../ovafModel';

import './DocumentPreview.scss';

// Renders File properties https://developer.mozilla.org/en-US/docs/Web/API/File#Properties
const FileProperties = (props) => {
  const removeButtonDisabled = R.and(
    R.or(!props.status.value, props.disabled),
    R.not(R.or(props.status.validationError, props.file.validationError)),
  );

  const textLength = 29;

  return (
    <div className={classNames('document-preview__details')}>
      <p>{props.file.value.name && fitFilename(textLength, props.file.value.name)}</p>
      <Button
        className="document-preview__remove-button"
        class={props.errorState() ? 'primary' : 'invisible'}
        color="red"
        icon="clear"
        label={i18n.t('common:remove')}
        onClick={props.handleRemove}
        dataCy="document-remove-button"
        disabled={removeButtonDisabled}
      />
    </div>
  );
};

FileProperties.propTypes = {
  file: formFileFieldShape.isRequired,
  status: statusShape.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired,
  errorState: PropTypes.func.isRequired,
};

// Component uses URL.createObjectURL() to display preview image when selected for upload
// (https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications#Example_Using_object_URLs_to_display_images)
class DocumentPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objectUrl: URL.createObjectURL(props.file.value),
    };
  }

  componentDidUpdate(prevProps) {
    if (!R.equals(this.props.file, prevProps.file)) {
      this.createObjectUrl();
    }
  }

  // Creates an objectUrl of the document file
  createObjectUrl = () => {
    const documentObjectUrl = URL.createObjectURL(this.props.file.value);
    this.setState({ objectUrl: documentObjectUrl });
  };

  // If an objectUrl has been created, release it after image have been loaded
  // (for optimal performance and memory usage)
  handleOnLoad = () => {
    if (this.state.objectUrl) {
      URL.revokeObjectURL(this.state.objectUrl);
    }
  };

  errorState = () => R.or(
    Boolean(this.props.file.validationError),
    R.includes(
      this.props.status.value,
      [pendingRequestDocumentStatuses.ERROR, pendingRequestDocumentStatuses.REJECTED],
    ),
  );

  render() {
    const documentSize = R.compose(
      R.unless(R.isNil, R.ifElse(
        R.gt(1000000),
        R.compose(R.concat(R.__, ` ${i18n.t('common:kilobyteSymbol')}`), R.toString, Math.round, R.divide(R.__, 1000)),
        R.compose(R.concat(R.__, ` ${i18n.t('common:megabyteSymbol')}`), x => x.toFixed(1), R.divide(R.__, 1000000)),
      )),
      R.path(['value', 'size']),
    )(this.props.file);

    const statusText = R.cond([
      [R.equals(pendingRequestDocumentStatuses.ERROR), R.always(i18n.t('pending.attachmentStatus.ERROR'))],
      [R.equals(pendingRequestDocumentStatuses.REJECTED), R.always(i18n.t('pending.attachmentStatus.REJECTED'))],
      [R.T, R.always('')],
    ])(this.props.status.value);

    return (
      <div className="document-preview">
        <div className={classNames('document-preview__thumbnail-container', {
          'document-preview__thumbnail-container--error': this.errorState(),
        })}
        >
          {!this.props.status.value && !this.errorState() && <div className="document-preview__loading"><LoadingIcon /></div>}
          { R.includes(this.props.file.value.type, ['image/jpeg', 'image/png']) &&
            <Image
              className={classNames('document-preview__thumbnail', {
                'document-preview__thumbnail--loading': !this.props.status.value && !this.errorState(),
              })}
              url={this.state.objectUrl}
              alt={this.props.file.value.name}
              onLoad={this.handleOnLoad}
            /> }
          { !R.includes(this.props.file.value.type, ['image/jpeg', 'image/png']) &&
            <Icon
              name="description"
              title={this.props.file.value.name}
              className="document-preview__icon-thumbnail"
            /> }
          { (this.props.file.validationError || this.errorState()) &&
            <div className="document-preview__validation-error-container">
              <Icon name="error" className="document-preview__validation-error-icon" />
              {this.props.status.value && statusText }
              <ValidationError
                className="document-preview__validation-error"
                validationError={this.props.file.validationError}
              />
            </div>}
          <p className="document-preview__size"><small>{documentSize}</small></p>
        </div>
        <FileProperties
          file={this.props.file}
          status={this.props.status}
          handleRemove={this.props.handleRemove}
          disabled={this.props.disabled}
          errorState={this.errorState}
        />
      </div>
    );
  }
}

DocumentPreview.propTypes = {
  file: formFileFieldShape.isRequired,
  status: statusShape.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default DocumentPreview;
