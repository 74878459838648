import React from 'react';
import PropTypes from 'prop-types';

import './InnerInputContainer.scss';

const withInnerInputContainer = (WrappedComponent) => {
  const InnerInputContainer = React.forwardRef(({ contentAfter, ...inputProps }, ref) => (
    <div className="inner-input-container">
      <WrappedComponent ref={ref} {...inputProps} />
      { contentAfter && <div className="inner-input-container__content-after">{contentAfter}</div> }
    </div>
  ));

  InnerInputContainer.propTypes = {
    contentAfter: PropTypes.node,
  };
  InnerInputContainer.defaultProps = {
    contentAfter: null,
  };

  return InnerInputContainer;
};

export default withInnerInputContainer;
