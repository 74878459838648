import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';

import {
  RadioButtonGroup,
  InputGroup,
  booleanRadioOptions,
} from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import { booleanToString, notNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';
import Linkify from 'visa-frontend-common/src/components/UI/Linkify/Linkify';

import { applicationChecklistItemFormWithMetadata, checklistItemAttachmentShape } from '../../ovafModel';
import ChecklistItemAttachmentContainer from './ChecklistItemAttachmentContainer';

import './ApplicationChecklistItem.scss';

const ApplicationChecklistItem = (props) => {
  const indexOfFirstItem = R.findIndex(R.propEq('attachmentTypeDescription', props.item.attachmentTypeDescription))(props.applicationChecklistItems);
  const isFirstItemByAttachmentTypeDescription = props.index === indexOfFirstItem;

  return (
    <div key={props.item.checklistItemType.value} className="application-checklist-item">
      { isFirstItemByAttachmentTypeDescription &&
        <div className="row">
          <div className="col-12">
            <h4 className="application-checklist-item__attachment-group">
              {props.item.attachmentTypeDescription}
            </h4>
          </div>
        </div>}
      <div className="row">
        <div className={classnames('col-xl-2', 'col-3', 'application-checklist-item__left-col')}>
          <InputGroup
            label=" "
            validationError={props.item.applicantChecked.validationError}
            className="application-checklist-item__input-group"
          >
            <RadioButtonGroup
              name={`applicantChecked-${props.index}`}
              orientation="horizontal"
              value={booleanToString(props.item.applicantChecked.value)}
              onChange={props.handleApplicantCheckedChangeFor(props.index)}
              options={booleanRadioOptions}
              required
            />
          </InputGroup>
        </div>
        <div className={classnames('col-xl-10', 'col-9', 'application-checklist-item__description')}>
          <h4>{props.item.attachmentName}</h4>
          <p>
            <Linkify>{props.item.description.value}</Linkify>
          </p>
          {notNilOrEmpty(props.attachedFiles) &&
            <ul className="application-checklist-item__attached-files">
              {props.attachedFiles.map(attachment =>
                <ChecklistItemAttachmentContainer
                  key={attachment.checklistItemAttachmentId}
                  attachment={attachment}
                />)}
            </ul>}
        </div>
      </div>
    </div>
  );
};

ApplicationChecklistItem.propTypes = {
  handleApplicantCheckedChangeFor: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.exact(applicationChecklistItemFormWithMetadata).isRequired,
  attachedFiles: PropTypes.arrayOf(checklistItemAttachmentShape).isRequired,
  applicationChecklistItems:
    PropTypes.arrayOf(PropTypes.exact(applicationChecklistItemFormWithMetadata)).isRequired,
};

export default ApplicationChecklistItem;
