import React from 'react';
import * as R from 'ramda';

import Image from 'visa-frontend-common/src/components/UI/Image/Image';
import logoFI from 'visa-frontend-common/src/static/images/co-founded-by-eu-fi-horizontal.png';
import logoRU from 'visa-frontend-common/src/static/images/co-founded-by-eu-ru-horizontal.png';
import logoEn from 'visa-frontend-common/src/static/images/co-founded-by-eu-en-horizontal.png';
import logoSv from 'visa-frontend-common/src/static/images/co-founded-by-eu-sv-horizontal.png';
import i18n from '../../services/i18n';

import './logo.scss';

const CoFundedByEULogo = () => {
  const logo = R.cond([
    [R.equals('fi'), R.always(logoFI)],
    [R.equals('ru'), R.always(logoRU)],
    [R.equals('sv'), R.always(logoSv)],
    [R.T, R.always(logoEn)],
  ])(i18n.language());

  return (
    <Image
      className="co-funded-by-eu-logo"
      url={logo}
      alt={i18n.t('CoFundedByEULogo')}
    />
  );
};

export default CoFundedByEULogo;
