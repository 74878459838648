import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as R from 'ramda';

import TableBodyCell from 'visa-frontend-common/src/components/UI/Table/TableBodyCell';
import TableRow from 'visa-frontend-common/src/components/UI/Table/TableRow';
import { Table } from 'visa-frontend-common/src/components/UI/Table/Table';
import { notNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';
import { fullName } from 'visa-frontend-common/src/utils/dataModelUtils';
import {
  displayDate,
  displayDateTimeAsDate,
  isPastDate,
} from 'visa-frontend-common/src/services/datetime';

import {
  checklistItemTypeShape,
  documentCountShape,
  pendingAnswerType,
  pendingRequestShape,
  pendingRequestStatuses,
} from '../../ovafModel';
import i18n from '../../services/i18n';
import paths from '../../paths';
import { isClosedOutsideOvaf, pendingReasonType, translateChecklistItemType } from './pendingUtils';

import './PendingRequestList.scss';

const headerTexts = [
  { label: i18n.t('pending.subject') },
  { label: i18n.t('pending.name') },
  { label: i18n.t('pending.application') },
  { label: i18n.t('pending.travelDocumentNumber') },
  { label: i18n.t('pending.received') },
  { label: i18n.t('pending.dueDate') },
  { label: i18n.t('pending.status.title') },
];

const PendingRequestListRow = (props) => {
  const { pendingRequest } = props;

  const pendingLink = generatePath(
    paths.pending.view,
    { pendingId: pendingRequest.pendingRequestId },
  );

  const applicantName = fullName(
    pendingRequest.surname,
    pendingRequest.firstName,
  );

  const pendingStatus = R.cond([
    [R.both(
      R.compose(isPastDate, R.prop('dueDate')),
      R.propEq('status', pendingRequestStatuses.OPEN),
    ), R.always('EXPIRED')],
    [R.always(pendingRequest.unread), R.always(pendingRequestStatuses.NEW)],
    [R.T, R.always(pendingRequest.status)],
  ])(pendingRequest);

  const pendingText = pendingRequest.checklistItemType ?
    props.translateChecklistItemType(pendingRequest.checklistItemType) :
    i18n.t(`pending.reason.${pendingRequest.reason}`);

  const pendingStatusText = R.cond([
    [isClosedOutsideOvaf, R.always(i18n.t('pending.status.CLOSED'))],
    [R.propSatisfies(
      R.includes(R.__, R.keys(pendingAnswerType)),
      'status',
    ),
    request => i18n.t(`pending.status.${pendingReasonType(request.reason)}.${pendingStatus}`,
      {
        reason: R.toLower(i18n.t(`pending.reason.${request.reason}`)),
        count: props.documentCount,
        location: i18n.t(`pending.answer.location.${pendingRequest.deliveryTarget}`),
      })],
    [R.T, R.always(i18n.t(`pending.status.${pendingStatus}`, { count: props.documentCount }))],
  ])(pendingRequest);

  return (
    <TableRow
      className={classnames(
        'pending-request-list__link',
        { 'pending-request-list__link--unread': pendingRequest.unread && !isPastDate(pendingRequest.dueDate) },
      )}
    >
      <TableBodyCell className="pending-request-list__td">
        <Link to={pendingLink} data-cy="pending-link">
          { pendingText }
        </Link>
      </TableBodyCell>
      <TableBodyCell className="pending-request-list__td">
        <Link to={pendingLink} data-cy="pending-link">
          { applicantName }
        </Link>
      </TableBodyCell>
      <TableBodyCell className="pending-request-list__td">
        <Link to={pendingLink} data-cy="pending-link">
          { pendingRequest.vurn }
        </Link>
      </TableBodyCell>
      <TableBodyCell className="pending-request-list__td">
        <Link to={pendingLink} data-cy="pending-link">
          { pendingRequest.travelDocumentNumber }
        </Link>
      </TableBodyCell>
      <TableBodyCell className="pending-request-list__td--date">
        <Link to={pendingLink} data-cy="pending-link">
          { displayDateTimeAsDate(pendingRequest.receivedAt) }
        </Link>
      </TableBodyCell>
      <TableBodyCell className="pending-request-list__td--date">
        <Link to={pendingLink} data-cy="pending-link">
          { displayDate(pendingRequest.dueDate) }
        </Link>
      </TableBodyCell>
      <TableBodyCell className="pending-request-list__td--status">
        <Link to={pendingLink} data-cy="pending-link">
          { pendingStatusText }
        </Link>
      </TableBodyCell>
    </TableRow>
  );
};

PendingRequestListRow.propTypes = {
  pendingRequest: pendingRequestShape.isRequired,
  documentCount: PropTypes.number.isRequired,
  translateChecklistItemType: PropTypes.func.isRequired,
};

const PendingRequestList = (props) => {
  const bodyRows = props.pendingRequests.map(pending => (
    <PendingRequestListRow
      key={pending.pendingRequestId}
      pendingRequest={pending}
      documentCount={
        R.compose(
          R.propOr(0, 'count'),
          R.find(R.propEq('pendingRequestId', pending.pendingRequestId)),
        )(props.documentCounts)
      }
      translateChecklistItemType={translateChecklistItemType(props.checklistItemTypes)}
    />
  ));

  return notNilOrEmpty(props.pendingRequests) ? (
    <>
      <h2 className="pending-request-list__header">{props.title}</h2>
      <h4>
        {i18n.t('pending.count', { count: props.pendingRequests.length })}
      </h4>

      <Table
        listName="pending.pendingRequests"
        headerTexts={headerTexts}
        className="pending-request-list"
        thClassName="pending-request-list__th"
        dataCy={`pending-requests-${props.dataCyPostfix}`}
        bodyRows={bodyRows}
        emptyText={i18n.t('pending.empty')}
      />
    </>
  ) : null;
};

PendingRequestList.propTypes = {
  title: PropTypes.string.isRequired,
  pendingRequests: PropTypes.arrayOf(pendingRequestShape).isRequired,
  documentCounts: PropTypes.arrayOf(documentCountShape).isRequired,
  checklistItemTypes: PropTypes.arrayOf(checklistItemTypeShape).isRequired,
  dataCyPostfix: PropTypes.string.isRequired,
};

export default PendingRequestList;
