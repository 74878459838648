import React from 'react';
import PropTypes from 'prop-types';

import Accordion from 'visa-frontend-common/src/components/UI/Accordion/Accordion';
import TwoColumnRow from 'visa-frontend-common/src/components/UI/TwoColumnRow/TwoColumnRow';
import AccordionWarningHeaderContent from 'visa-frontend-common/src/components/UI/Accordion/AccordionWarningHeaderContent';

import PreviewApplication from './PreviewApplication/PreviewApplication';
import i18n from '../../services/i18n';
import { ovafApplicationFormShape, applicationChecklistItemFormWithMetadata } from '../../ovafModel';

class Finalize extends React.Component {
  componentDidMount() {
    this.props.validateAllTabs();
  }

  render() {
    return (
      <TwoColumnRow oneColumn>
        <Accordion
          expandButtonText={i18n.t('application.previewApplication.previewApplicationTitle')}
          headerContent={
            this.props.validationErrorNumber > 0 &&
            <AccordionWarningHeaderContent
              ariaLabel={i18n.t('common:accessibleIconLabels.validationErrorsApplication')}
            />
          }
          isOpenAtStart
          bodyContent={
            <PreviewApplication
              form={this.props.form}
              applicationChecklistItems={this.props.applicationChecklistItems}
            />
          }
        />
      </TwoColumnRow>
    );
  }
}

Finalize.propTypes = {
  validationErrorNumber: PropTypes.number,
  validateAllTabs: PropTypes.func.isRequired,
  form: ovafApplicationFormShape.isRequired,
  applicationChecklistItems:
    PropTypes.arrayOf(PropTypes.exact(applicationChecklistItemFormWithMetadata)),
};

Finalize.defaultProps = {
  validationErrorNumber: 0,
  applicationChecklistItems: [],
};

export default Finalize;
