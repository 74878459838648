import * as R from 'ramda';

import { matchesPathname, containsQueryParams } from './pathUtils';
import { isNilOrEmpty, notNilOrEmpty } from './commonUtils';

// https://github.com/remix-run/history/blob/dev/docs/api-reference.md#action
export const historyAction = {
  POP: 'POP',
  PUSH: 'PUSH',
  REPLACE: 'REPLACE',
};

/**
* Checks if navigation should be allowed.
* Mainly used with React Router's Prompt component https://v5.reactrouter.com/core/api/Prompt/message-func
* but can be also used separately
*
* @param location location-like object representing where should be navigated
* @param action action representing the type of change that occurred in the history stack
* @param dirtyForms list of dirtyForms declared in navigationPromptReducer
* @param [alwaysAllowedLocation] function to check if navigation to location is always allowed
* @param [preventOnPop] boolean indicating if navigation should be prevented on POP action
* @returns {(true|false|string)} boolean when navigation is allowed/prevented or stringified prompts
*/

const checkNavigationAttempt = (
  location,
  action,
  dirtyForms,
  alwaysAllowedLocation,
  preventOnPop,
) => {
  // Allow REPLACE type location change (caused by e.g. <Redirect />) if action is specified
  if (R.equals(action, historyAction.REPLACE)) return true;

  // If preventOnPop, prevent navigation when e.g. browser's back button is pressed
  if (preventOnPop && R.equals(action, historyAction.POP)) return false;

  const isExcluded = R.both(matchesPathname(location), containsQueryParams(location));
  const locationNotExcluded = R.ifElse(isNilOrEmpty, R.T, R.none(isExcluded));
  const locationNotAlwaysAllowed = R.ifElse(
    notNilOrEmpty,
    R.complement,
    R.always(R.T),
  )(alwaysAllowedLocation);

  const notExcludedDirtyForms = R.reduce((acc, dirtyForm) => R.ifElse(
    R.compose(locationNotExcluded, R.prop('excludedPaths')),
    R.append(R.__, acc),
    R.always(acc),
  )(dirtyForm), [], dirtyForms);

  if (notNilOrEmpty(notExcludedDirtyForms) && locationNotAlwaysAllowed(location)) {
    return JSON.stringify({
      prompts: R.map(R.pick(['promptTitle', 'promptListLabel', 'formName']), notExcludedDirtyForms),
    });
  }
  return true;
};

export default checkNavigationAttempt;
