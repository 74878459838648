import http from 'visa-frontend-common/src/services/http';

const baseUrl = '/api/register';

export const register = (payload, token) =>
  http.fetchAsJSON(`${baseUrl}`, { method: 'POST', body: JSON.stringify({ payload, token }) });

export const registerConfirm = confirmUser =>
  http.fetchAsJSON(`${baseUrl}-confirm`, { method: 'POST', body: JSON.stringify(confirmUser) });

export const resendConfirmationCode = form =>
  http.fetchAsJSON(`${baseUrl}-resend-code`, { method: 'POST', body: JSON.stringify(form) });
