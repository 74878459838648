import * as R from 'ramda';
import { pickFirstKey, pickValuesDeep } from '../../utils/commonUtils';
import formValidator from './formValidator';
import { apiThunkWithLoading } from '../../utils/actionUtils';

export const validatingSetFieldThunk =
(fieldChangeAction, rules) => (field, form) => (dispatch) => {
  const payload = formValidator.validateField(field, form, rules[pickFirstKey(field)]);
  return dispatch(fieldChangeAction(payload));
};

export const validatingSetArrayFieldThunk =
(arrayChangeAction, rules) => (arrayName, index, field, form) => (dispatch) => {
  const fieldName = pickFirstKey(field);
  const arrayFieldLens = R.lensPath([arrayName, 'value', index, fieldName]);
  const validatedArrayField = formValidator.validateField(
    R.set(R.compose(arrayFieldLens, R.lensProp('value')), field[fieldName].value, R.pick(arrayName, form)),
    form,
    rules[arrayName],
  );

  const payload = { index, fieldName, fieldValue: R.view(arrayFieldLens, validatedArrayField) };
  return dispatch(arrayChangeAction(payload));
};

export const validatingSubmitFormThunk =
(fieldChangeAction, sendApiMethod, completedAction, rules, allowErrorNotifications = true) =>
  (form, ...rest) => (dispatch) => {
    const validatedForm = formValidator.validateForm(form, rules);

    dispatch(fieldChangeAction(validatedForm));

    if (!formValidator.isFormValid(validatedForm)) {
      return Promise.resolve(Error('Invalid form'));
    }

    return dispatch(apiThunkWithLoading(
      sendApiMethod,
      completedAction,
      allowErrorNotifications,
    )(pickValuesDeep(validatedForm), ...rest));
  };

export const validatingSetFormPartWithFieldChangeThunk =
(fieldChangeAction, rules) => (formPart, form) => (dispatch) => {
  const payload = formValidator.validateFormPart(formPart, form, rules);
  return dispatch(fieldChangeAction(payload));
};

export const validatingSetTabThunk =
  (fieldChangeAction, tabChangeAction, fieldsPerTab, rules) => (form, tabName) => (dispatch) => {
    const part = R.pick(fieldsPerTab[tabName], form);
    const validatedFormPart = formValidator.validateFormPart(part, form, rules);
    dispatch(fieldChangeAction(validatedFormPart));

    const validatedTab =
      formValidator.validateTab(validatedFormPart, fieldsPerTab[tabName]);
    return dispatch(tabChangeAction({ [tabName]: validatedTab }));
  };
