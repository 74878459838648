import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { formFieldShape, historyShape } from 'visa-frontend-common/src/dataModel';
import PropTypes from 'prop-types';
import paths from '../../paths';
import RegisterForm from './RegisterForm';
import RegisterConfirmForm from './RegisterConfirmForm';
import RegisterEmailSent from './RegisterEmailSent';
import RegisterCodeResendForm from './RegisterCodeResendForm';
import { registerFormShape } from '../../dataModel';

const Register = (props) => {
  return (
    <div className="container" id="main" tabIndex="-1">
      <Switch>
        <Route path={paths.register.codeSent}>
          <RegisterEmailSent
            emailSent={props.emailSent}
          />
        </Route>
        <Route path={paths.register.codeResend}>
          <RegisterCodeResendForm
            registerCodeResendForm={props.registerCodeResendForm}
            registerCodeResendSetFieldAndValidate={props.registerCodeResendSetFieldAndValidate}
            registerCodeResendSubmit={props.registerCodeResendSubmit}
            registerCodeResendClearFields={props.registerCodeResendClearFields}
          />
        </Route>
        <Route path={paths.register.confirm}>
          <RegisterConfirmForm
            registerConfirmForm={props.registerConfirmForm}
            registerConfirmSetFieldAndValidate={props.registerConfirmSetFieldAndValidate}
            registerConfirmSubmit={props.registerConfirmSubmit}
            registerConfirmClearFields={props.registerConfirmClearFields}
          />
        </Route>
        <Route path={paths.register.root}>
          <RegisterForm
            history={props.history}
            registerForm={props.registerForm}
            termsAccepted={props.termsAccepted}
            privacyPolicyAccepted={props.privacyPolicyAccepted}
            privacyPolicyAcceptedSet={props.privacyPolicyAcceptedSet}
            termsAcceptedSet={props.termsAcceptedSet}
            registerSetFieldAndValidate={props.registerSetFieldAndValidate}
            registerSubmit={props.registerSubmit}
            registerClearFields={props.registerClearFields}
          />
        </Route>
      </Switch>
    </div>
  );
};

Register.defaultProps = {
  emailSent: null,
};

Register.propTypes = {
  history: historyShape.isRequired,
  emailSent: PropTypes.string,

  registerCodeResendForm: PropTypes.exact({
    email: formFieldShape,
  }).isRequired,
  registerCodeResendSetFieldAndValidate: PropTypes.func.isRequired,
  registerCodeResendSubmit: PropTypes.func.isRequired,
  registerCodeResendClearFields: PropTypes.func.isRequired,

  registerConfirmForm: PropTypes.exact({
    email: formFieldShape,
    confirmationCode: formFieldShape,
  }).isRequired,
  registerConfirmSetFieldAndValidate: PropTypes.func.isRequired,
  registerConfirmSubmit: PropTypes.func.isRequired,
  registerConfirmClearFields: PropTypes.func.isRequired,

  registerForm: registerFormShape.isRequired,
  termsAccepted: PropTypes.bool.isRequired,
  termsAcceptedSet: PropTypes.func.isRequired,
  privacyPolicyAccepted: PropTypes.bool.isRequired,
  privacyPolicyAcceptedSet: PropTypes.func.isRequired,
  registerSetFieldAndValidate: PropTypes.func.isRequired,
  registerSubmit: PropTypes.func.isRequired,
  registerClearFields: PropTypes.func.isRequired,
};

export default Register;
