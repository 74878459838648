import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Subsection from '../UI/Subsection/Subsection';
import TwoColumnRow from '../UI/TwoColumnRow/TwoColumnRow';
import {
  formAddressShape,
  formFieldShape,
  formFieldShapeFor,
} from '../../dataModel';
import {
  InputGroup,
  Input,
  inputProps,
  BooleanCheckboxGroup,
} from '../UI/Form/FormComponents';
import i18n from '../../services/i18n';
import { notNilOrEmpty } from '../../utils/commonUtils';
import InlineNotification from '../UI/InlineNotification/InlineNotification';

const Guardian = (props) => {
  const CodesetSelectContainer = props.codesetSelectContainer;
  const textInputProps = inputProps('text', R.__, R.__, R.__, false, props.handleFieldChange);
  const required = props.namePrefix === 'guardian';

  return (
    <>
      <TwoColumnRow oneColumn={props.oneColumn}>
        <InputGroup
          label={i18n.t('common:applicationForm.guardians.guardianSurname')}
          required={required}
          validationError={props.surname.validationError}
          describedBy="personalDetailsGuide-guardianSubtitle"
        >
          <Input
            {...textInputProps(props.surname.value, `${props.namePrefix}Surname`, null)}
            uppercase
            required={required}
            autoFocus={props.autoFocus}
          />
        </InputGroup>
        <InputGroup
          label={i18n.t('common:applicationForm.guardians.guardianFirstName')}
          required={required}
          validationError={props.firstName.validationError}
          describedBy="personalDetailsGuide-guardianSubtitle"
        >
          <Input {...textInputProps(props.firstName.value, `${props.namePrefix}FirstName`, null)} uppercase required={required} />
        </InputGroup>
      </TwoColumnRow>

      <TwoColumnRow oneColumn={props.oneColumn}>
        <InputGroup
          label={i18n.t('common:applicationForm.guardians.guardianNationality')}
          required={required}
          validationError={props.nationality.validationError}
          describedBy="personalDetailsGuide-guardianSubtitle"
        >
          <CodesetSelectContainer
            name={`${props.namePrefix}Nationality`}
            value={props.nationality.value}
            codesetGroup="CountryOfNationality"
            onChange={props.handleFieldChange}
          />
        </InputGroup>
      </TwoColumnRow>

      <TwoColumnRow oneColumn={props.oneColumn}>
        {notNilOrEmpty(props.email.value) && props.showEmailReminder &&
          <InlineNotification
            type="info"
            disableClose
            className="occupation-and-residence__email-notification"
          >
            {i18n.t('common:applicationForm.checkEmailNotifier')}
          </InlineNotification>}

        <InputGroup
          label={i18n.t('common:applicationForm.guardians.guardianEmail')}
          required={props.emailRequired}
          validationError={props.email.validationError}
          describedBy="personalDetailsGuide-guardianSubtitle"
        >
          <Input {...textInputProps(props.email.value, `${props.namePrefix}Email`, null)} uppercase />
        </InputGroup>
        <InputGroup
          label={i18n.t('common:applicationForm.guardians.guardianPhonenumber')}
          required={required}
          validationError={props.phonenumber.validationError}
          describedBy="personalDetailsGuide-guardianSubtitle"
        >
          <Input {...textInputProps(props.phonenumber.value, `${props.namePrefix}Phonenumber`, null)} uppercase required={required} />
        </InputGroup>
      </TwoColumnRow>

      <TwoColumnRow oneColumn={props.oneColumn}>
        <>
          <InputGroup>
            <BooleanCheckboxGroup
              name={`${props.namePrefix}AddressIsDifferentThanApplicants`}
              value={props.addressIsDifferentThanApplicants.value}
              onChange={props.handleAddressChange}
              label={i18n.t('common:applicationForm.guardians.differentAddressThanApplicants')}
            />
          </InputGroup>

          { props.addressIsDifferentThanApplicants.value && (
            <Subsection>
              <InputGroup
                label={i18n.t('common:applicationForm.guardians.guardianStreet')}
                required={required}
                validationError={props.address.value.street.validationError}
                describedBy="personalDetailsGuide-guardianSubtitle"
              >
                <Input {...textInputProps(props.address.value.street.value, `${props.namePrefix}Address.street`, null)} uppercase required={required} />
              </InputGroup>
              <InputGroup
                label={i18n.t('common:applicationForm.guardians.guardianZip')}
                validationError={props.address.value.zip.validationError}
                describedBy="personalDetailsGuide-guardianSubtitle"
              >
                <Input {...textInputProps(props.address.value.zip.value, `${props.namePrefix}Address.zip`, null)} uppercase />
              </InputGroup>
              <InputGroup
                label={i18n.t('common:applicationForm.guardians.guardianCity')}
                required={required}
                validationError={props.address.value.city.validationError}
                describedBy="personalDetailsGuide-guardianSubtitle"
              >
                <Input {...textInputProps(props.address.value.city.value, `${props.namePrefix}Address.city`, null)} uppercase required={required} />
              </InputGroup>
              <InputGroup
                label={i18n.t('common:applicationForm.guardians.guardianCountry')}
                required={required}
                validationError={props.address.value.country.validationError}
                describedBy="personalDetailsGuide-guardianSubtitle"
              >
                <CodesetSelectContainer
                  name={`${props.namePrefix}Address.country`}
                  value={props.address.value.country.value}
                  codesetGroup="Country"
                  onChange={props.handleFieldChange}
                />
              </InputGroup>
            </Subsection>
          )}
        </>
      </TwoColumnRow>
    </>
  );
};

Guardian.propTypes = {
  namePrefix: PropTypes.oneOf(['guardian', 'secondaryGuardian']).isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  firstName: formFieldShape.isRequired,
  surname: formFieldShape.isRequired,
  nationality: formFieldShape.isRequired,
  address: formFieldShapeFor(formAddressShape).isRequired,
  email: formFieldShape.isRequired,
  phonenumber: formFieldShape.isRequired,
  addressIsDifferentThanApplicants: formFieldShapeFor(PropTypes.bool).isRequired,
  emailRequired: PropTypes.bool,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
  autoFocus: PropTypes.bool,
  showEmailReminder: PropTypes.bool,
};

Guardian.defaultProps = {
  oneColumn: false,
  autoFocus: false,
  emailRequired: false,
  showEmailReminder: false,
};

export default Guardian;
