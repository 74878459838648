import * as R from 'ramda';

/*
* Prevents TypeError with WebKit if selectionDirection, selectionStart or selectionEnd
* is referenced on some input objects (event.target).
*
* compatibleEvent catches selectionDirection, selectionStart and selectionEnd TypeErrors and omits
* the properties first to prevent the TypeErrors being thrown. The properties are then set to return
* null as declared in HTML spec (https://html.spec.whatwg.org/#textFieldSelection) for elements where
* the properties don't apply.
*
* Bug report: https://bugs.webkit.org/show_bug.cgi?id=156500
*/
const compatibleEvent = (event) => {
  const inputSelectionProps = ['selectionDirection', 'selectionStart', 'selectionEnd'];
  try {
    R.pick(inputSelectionProps, event.target);
  } catch (error) {
    if (R.is(TypeError, error)) {
      const inputSelectionPropsWithNullValues = R.zipObj(
        inputSelectionProps,
        R.repeat(null, R.length(inputSelectionProps)),
      );
      return R.compose(
        R.over(R.lensProp('target'), R.mergeLeft(inputSelectionPropsWithNullValues)),
        R.over(R.lensProp('target'), R.omit(inputSelectionProps)),
      )(event);
    }
  }
  return event;
};

export default compatibleEvent;
