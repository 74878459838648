import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import InputGroup from 'visa-frontend-common/src/components/UI/Form/InputGroup/InputGroup';
import Input, { inputProps } from 'visa-frontend-common/src/components/UI/Form/Input/Input';
import { BooleanCheckboxGroup } from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import { eventToField } from 'visa-frontend-common/src/utils/commonUtils';
import { historyShape } from 'visa-frontend-common/src/dataModel';
import InlineNotification from 'visa-frontend-common/src/components/UI/InlineNotification/InlineNotification';
import Form from 'visa-frontend-common/src/components/UI/Form/Form';

import i18n from '../../services/i18n';
import TermsAndConditions from './TermsAndConditions';
import { registerFormShape } from '../../dataModel';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import actions from './RegisterActions';
import PrivacyPolicy from './PrivacyPolicy';

import './RegisterForm.scss';

const emailLabel = i18n.t('register.registerForm.email');
const passwordLabel = i18n.t('register.registerForm.password');
const confirmPasswordLabel = i18n.t('register.registerForm.confirmPassword');
const travelAgencyLabel = i18n.t('register.registerForm.travelAgency');
const travelAgencyNameLabel = i18n.t('common:applicationForm.travelAgencyName');
const passwordRequirements = i18n.t('register.registerForm.passwordRequirements');

class RegisterForm extends React.Component {
  componentDidMount() {
    window.mtcaptchaConfig.renderQueue.push('mtcaptcha-1');
  }

  componentWillUnmount() {
    this.props.registerClearFields(['registerForm']);
  }

  handleInput = event => this.props.registerSetFieldAndValidate(
    eventToField(event),
    this.props.registerForm,
  );

  handleSubmit = () => this.props.registerSubmit(this.props.registerForm);

  handleTravelAgencyChange = (event) => {
    this.handleInput(event);

    if (!event.target.value) {
      this.props.registerClearFields(['registerForm.travelAgencyName']);
    }
  };

  render() {
    const textInputProps = inputProps('text', R.__, R.__, null, false, this.handleInput);
    const passwordInputProps = inputProps('password', R.__, R.__, null, false, this.handleInput);
    const {
      email,
      password,
      confirmPassword,
      isTravelAgency,
      travelAgencyName,
    } = this.props.registerForm;

    const captchaNotAvailable = !window.mtcaptcha;
    return (
      <div className="register-form">
        <h2>{i18n.t('register.registerForm.title')}</h2>

        <p className="register-form__required-info">
          <small>{i18n.t('register.requiredInfoText')}</small>
        </p>
        { captchaNotAvailable &&
          <InlineNotification type="warning" dataCy="inline-warning-captcha-not-available" disableClose>
            {i18n.t('applicationErrors.captchaNotAvailable')}
          </InlineNotification>}
        <Form>
          <InputGroup
            label={emailLabel}
            required
            validationError={email.validationError}
            className="register-form__email"
          >
            <Input {...textInputProps(email.value, 'email')} autoFocus />
          </InputGroup>
          <InputGroup
            label={passwordLabel}
            required
            withHelp={passwordRequirements}
            validationError={password.validationError}
            className="register-form__password"
          >
            <Input {...passwordInputProps(password.value, 'password')} />
          </InputGroup>
          <InputGroup
            label={confirmPasswordLabel}
            required
            validationError={confirmPassword.validationError}
            className="register-form__confirmPassword"
          >
            <Input {...passwordInputProps(confirmPassword.value, 'confirmPassword')} />
          </InputGroup>

          <InputGroup
            validationError={isTravelAgency.validationError}
          >
            <BooleanCheckboxGroup
              label={travelAgencyLabel}
              name="isTravelAgency"
              value={isTravelAgency.value}
              onChange={this.handleTravelAgencyChange}
            />
          </InputGroup>
          {
          isTravelAgency.value &&
          <InputGroup
            label={travelAgencyNameLabel}
            required
            validationError={travelAgencyName.validationError}
            className="register-form__travelAgencyName"
          >
            <Input
              {...textInputProps(travelAgencyName.value, 'travelAgencyName')}
              uppercase
            />
          </InputGroup>
        }
          <TermsAndConditions
            termsAccepted={this.props.termsAccepted}
            termsAcceptedSet={this.props.termsAcceptedSet}
          />
          <PrivacyPolicy
            privacyPolicyAcceptedSet={this.props.privacyPolicyAcceptedSet}
            privacyPolicyAccepted={this.props.privacyPolicyAccepted}
          />
          <div id="mtcaptcha-1" className="mtcaptcha" />

          <LoadingButton
            onClick={this.handleSubmit}
            title={i18n.t('register.registerForm.submit')}
            className="register-form__submit-button"
            icon="arrow_forward"
            label={i18n.t('register.registerForm.submit')}
            dataCy="register-form-button"
            requestNames={[actions.registerSent().type]}
            disabled={!(this.props.termsAccepted && this.props.privacyPolicyAccepted) ||
            captchaNotAvailable}
            type="submit"
          />
        </Form>
      </div>
    );
  }
}

export default RegisterForm;

RegisterForm.propTypes = {
  history: historyShape.isRequired,
  registerForm: registerFormShape.isRequired,
  termsAccepted: PropTypes.bool.isRequired,
  termsAcceptedSet: PropTypes.func.isRequired,
  privacyPolicyAccepted: PropTypes.bool.isRequired,
  privacyPolicyAcceptedSet: PropTypes.func.isRequired,
  registerSetFieldAndValidate: PropTypes.func.isRequired,
  registerSubmit: PropTypes.func.isRequired,
  registerClearFields: PropTypes.func.isRequired,
};
