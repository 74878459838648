import http from 'visa-frontend-common/src/services/http';

const apiChecklistsURL = '/api/checklists';

const getChecklistsForEspLocation = (espLocation) => {
  return http.fetchAsJSON(`${apiChecklistsURL}/${espLocation}/published`, { method: 'GET' });
};

const getStaticChecklistsForEspLocation = espLocation => (
  http.fetchAsJSON(
    `${apiChecklistsURL}/${espLocation}/static`,
    { method: 'GET' },
  )
);

const getChecklistItems = (checklistId) => {
  return http.fetchAsJSON(`${apiChecklistsURL}/${checklistId}/items`, { method: 'GET' });
};

const getChecklistItemAttachments = (espLocation, checklistName) => {
  return http.fetchAsJSON(`${apiChecklistsURL}/${espLocation}/items/attachments`, { method: 'GET', params: { checklistName } });
};

const getChecklistItemTypes = () => {
  return http.fetchAsJSON(`${apiChecklistsURL}/item-types`, { method: 'GET' });
};

const getChecklistCountrySpecificInstructions = (espLocation) => {
  return http.fetchAsJSON(`${apiChecklistsURL}/${espLocation}/country-specific-instructions`, { method: 'GET' });
};

const printChecklist = (espLocation, checklistId) => http.fetchAsBlob(`/api/checklists/${espLocation}/${checklistId}/print`);

const printChecklistInBrowser = (espLocation, checklistId) =>
  http.fetchAsText(`/api/checklists/${espLocation}/${checklistId}/print-in-browser`, { method: 'GET' });

const downloadAttachment = attachmentKey => http.fetchAsBlob(`${apiChecklistsURL}/items/attachments`, { params: { attachmentKey } });

export {
  getChecklistsForEspLocation,
  getStaticChecklistsForEspLocation,
  getChecklistItems,
  getChecklistItemAttachments,
  getChecklistItemTypes,
  getChecklistCountrySpecificInstructions,
  printChecklist,
  printChecklistInBrowser,
  downloadAttachment,
};
