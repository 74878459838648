import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  booleanToString,
  stringToBoolean,
} from '../../utils/commonUtils';
import TwoColumnRow from '../UI/TwoColumnRow/TwoColumnRow';
import Subsection from '../UI/Subsection/Subsection';
import i18n from '../../services/i18n';
import {
  DateInput,
  dateInputProps,
  Input,
  inputProps,
  InputGroup,
  RadioButtonGroup,
  booleanRadioOptions,
} from '../UI/Form/FormComponents';
import { formFieldShape, formFieldShapeFor } from '../../dataModel';
import EntryPermit from './Journey/EntryPermit';
import PurposesOfJourney from './Journey/PurposesOfJourney';
import { notEuropolOrSis } from '../../utils/dataModelUtils';

const JourneyDetails = (props) => {
  const handleFingerprintsAlreadyStoredChange = (event) => {
    const newValue = stringToBoolean(event.target.value);

    props.handleChange({
      target: {
        name: event.target.name,
        value: newValue,
      },
    });

    if (newValue === false) {
      props.clearFields(['form.fingerprintsStoredAt', 'form.fingerprintsVisaNumber']);
    }
  };

  const dateProps = dateInputProps(R.__, R.__, false, false, props.handleChange);
  const textInputProps = inputProps('text', R.__, R.__, R.__, false, props.handleChange);

  const destinationStatesLabel = `${i18n.t('common:applicationForm.destinationStates')} [25]`;
  const borderOfFirstEntryCountryLabel = `${i18n.t('common:applicationForm.borderOfFirstEntryCountry')} [26]`;
  const numberOfEntriesRequestedLabel = `${i18n.t('common:applicationForm.numberOfEntriesRequested')} [27]`;
  const fingerprintsAlreadyStoredLabel = `${i18n.t('common:applicationForm.fingerprintsAlreadyStored')} [28]`;
  const fingerprintsStoredAtLabel = i18n.t('common:applicationForm.fingerprintsStoredAt');
  const fingerprintsVisaNumberLabel = i18n.t('common:applicationForm.fingerprintsVisaNumber');
  const dateOfArrivalLabel = i18n.t('common:applicationForm.dateOfArrivalToSchengenArea');
  const dateOfDepartureLabel = i18n.t('common:applicationForm.dateOfDeparture');

  const { fingerprintsAlreadyStored } = props;
  const CodesetSelectContainer = props.codesetSelectContainer;
  const CodesetListField = props.codesetListFieldContainer;
  const fingerprintsOptions = R.reverse(booleanRadioOptions);

  return (
    <div role="form" aria-labelledby="applicationForm-journeyDetailsSubtitle">
      <PurposesOfJourney
        clearFields={props.clearFields}
        mainPurposeOfJourney={props.mainPurposeOfJourney}
        otherPurposesOfJourney={props.otherPurposesOfJourney}
        journeyPurposeOther={props.journeyPurposeOther}
        journeyPurposeDetails={props.journeyPurposeDetails}
        oneDayTripWithoutAccommodation={props.oneDayTripWithoutAccommodation}
        isAdsCapable={props.isAdsCapable}
        ads={props.ads}
        handleChange={props.handleChange}
        codesetSelectContainer={CodesetSelectContainer}
        codesetListFieldContainer={props.codesetListFieldContainer}
        oneColumn={props.oneColumn}
      />

      <TwoColumnRow oneColumn={props.oneColumn}>
        <InputGroup
          label={destinationStatesLabel}
          required
          validationError={props.destinationStates.validationError}
          describedBy="journeyDetailsGuide-destinationStates"
        >
          <CodesetListField
            name="destinationStates"
            values={props.destinationStates.value}
            codesetGroup="VisUserCountry"
            codesetPredicate={notEuropolOrSis}
            handleChange={props.handleChange}
          />
        </InputGroup>

        <InputGroup
          label={borderOfFirstEntryCountryLabel}
          required
          validationError={props.borderOfFirstEntryCountry.validationError}
          describedBy="journeyDetailsGuide-borderOfFirstEntryCountry"
        >
          <CodesetSelectContainer
            name="borderOfFirstEntryCountry"
            value={props.borderOfFirstEntryCountry.value}
            codesetGroup="VisUserCountry"
            codesetPredicate={notEuropolOrSis}
            onChange={props.handleChange}
          />
        </InputGroup>
      </TwoColumnRow>
      <TwoColumnRow oneColumn={props.oneColumn}>
        <InputGroup
          label={numberOfEntriesRequestedLabel}
          required
          validationError={props.numberOfEntriesRequested.validationError}
          describedBy="journeyDetailsGuide-numberOfEntriesRequested"
        >
          <CodesetSelectContainer
            name="numberOfEntriesRequested"
            value={props.numberOfEntriesRequested.value}
            codesetGroup="NumberOfEntries"
            onChange={props.handleChange}
            lang={i18n.language()}
          />
        </InputGroup>
      </TwoColumnRow>
      <TwoColumnRow oneColumn={props.oneColumn}>
        <InputGroup
          label={dateOfArrivalLabel}
          required
          withHelp={i18n.t('common:dateFormats.inputFormatLongDateDescription')}
          validationError={props.dateOfArrival.validationError}
          describedBy="journeyDetailsGuide-dateOfArrivalToSchengenArea"
        >
          <DateInput
            {...dateProps(props.dateOfArrival.value, 'dateOfArrival')}
            shortYearInFuture
            useLongDates
            required
          />
        </InputGroup>

        <InputGroup
          label={dateOfDepartureLabel}
          required
          withHelp={i18n.t('common:dateFormats.inputFormatLongDateDescription')}
          validationError={props.dateOfDeparture.validationError}
          describedBy="journeyDetailsGuide-dateOfDeparture"
        >
          <DateInput
            {...dateProps(props.dateOfDeparture.value, 'dateOfDeparture')}
            shortYearInFuture
            useLongDates
            required
          />
        </InputGroup>
      </TwoColumnRow>

      <TwoColumnRow oneColumn={props.oneColumn}>
        <>
          <InputGroup
            label={fingerprintsAlreadyStoredLabel}
            required
            validationError={fingerprintsAlreadyStored.validationError}
            describedBy="journeyDetailsGuide-fingerprintsAlreadyStored"
          >
            <RadioButtonGroup
              name="fingerprintsAlreadyStored"
              value={booleanToString(fingerprintsAlreadyStored.value)}
              onChange={handleFingerprintsAlreadyStoredChange}
              options={fingerprintsOptions}
              validationError={fingerprintsAlreadyStored.validationError}
              required
            />
          </InputGroup>

          {fingerprintsAlreadyStored.value &&
          <Subsection withoutTopMargin>
            <InputGroup
              label={fingerprintsStoredAtLabel}
              withHelp={i18n.t('common:dateFormats.inputFormatLongDateDescription')}
              validationError={props.fingerprintsStoredAt.validationError}
              describedBy="journeyDetailsGuide-fingerprintsAlreadyStored"
            >
              <DateInput
                {...dateProps(props.fingerprintsStoredAt.value, 'fingerprintsStoredAt')}
                useLongDates
              />
            </InputGroup>
            <InputGroup
              label={fingerprintsVisaNumberLabel}
              validationError={props.fingerprintsVisaNumber.validationError}
              describedBy="journeyDetailsGuide-fingerprintsAlreadyStored"
            >
              <Input
                {...textInputProps(props.fingerprintsVisaNumber.value, 'fingerprintsVisaNumber', null)}
                uppercase
              />
            </InputGroup>
          </Subsection>}
        </>
      </TwoColumnRow>

      <EntryPermit
        entryPermitForFinalDestination={props.entryPermitForFinalDestination}
        entryPermit={props.entryPermit}
        handleChange={props.handleChange}
        clearFields={props.clearFields}
        oneColumn={props.oneColumn}
      />
    </div>
  );
};

JourneyDetails.propTypes = {
  handleChange: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  destinationStates: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  borderOfFirstEntryCountry: formFieldShape.isRequired,
  numberOfEntriesRequested: formFieldShape.isRequired,
  dateOfArrival: formFieldShape.isRequired,
  dateOfDeparture: formFieldShape.isRequired,
  fingerprintsAlreadyStored: formFieldShapeFor(PropTypes.bool).isRequired,
  fingerprintsStoredAt: formFieldShape.isRequired,
  fingerprintsVisaNumber: formFieldShape.isRequired,
  entryPermitForFinalDestination: formFieldShape.isRequired,
  entryPermit: formFieldShape.isRequired,
  mainPurposeOfJourney: formFieldShape.isRequired,
  otherPurposesOfJourney: formFieldShape.isRequired,
  journeyPurposeOther: formFieldShape.isRequired,
  journeyPurposeDetails: formFieldShape.isRequired,
  oneDayTripWithoutAccommodation: formFieldShape.isRequired,
  isAdsCapable: PropTypes.bool.isRequired,
  ads: formFieldShape,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  codesetListFieldContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
};

JourneyDetails.defaultProps = {
  ads: null,
  oneColumn: false,
};

export default JourneyDetails;
