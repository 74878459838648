import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import LabeledContainer from './LabeledContainer';

import './Labeled.scss';

const LabeledValue = (props) => {
  return (
    <LabeledContainer
      labelText={props.labelText}
      labelIcon={props.labelIcon}
      iconFirst={props.iconFirst}
      className={props.className}
      noWrap={props.noWrap}
      indent={props.indent}
      dataCy={props.dataCy}
    >
      {props.children || props.value ? (
        <small className={classnames('labeled__small', { 'labeled__bordered-page-element': props.withBottomBorder })}>
          {props.children || props.value}
        </small>
      ) : null}
    </LabeledContainer>
  );
};

LabeledValue.propTypes = {
  dataCy: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  labelIcon: PropTypes.element,
  iconFirst: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  withBottomBorder: PropTypes.bool,
  children: PropTypes.node,
  noWrap: PropTypes.bool,
  indent: PropTypes.bool,
};

LabeledValue.defaultProps = {
  dataCy: null,
  labelIcon: null,
  iconFirst: false,
  className: null,
  value: null,
  withBottomBorder: false,
  children: null,
  noWrap: false,
  indent: false,
};

export default LabeledValue;
