import * as R from 'ramda';

const isOtherSpecificPurpose = codesetCode => R.includes(codesetCode, [
  'Drivers',
  'Seamen',
  'SeasonalWork',
  'BerryPicking',
]);

const isOtherPurpose = codesetCode => R.anyPass([
  R.equals('Other'),
  isOtherSpecificPurpose,
])(codesetCode);

const isOtherSelected = R.ifElse(
  R.is(Array),
  R.any(isOtherPurpose),
  isOtherPurpose,
);

const codesetNotOtherWhenOtherSelected = selected => R.ifElse(
  () => isOtherSelected(selected),
  value => R.complement(isOtherPurpose)(value.codesetCode),
  R.T,
);

const codesetNotOtherSpecificPurpose = value => R.complement(
  isOtherSpecificPurpose,
)(value.codesetCode);

const otherJourneyPurposeVisCode = '0007.01';

export {
  codesetNotOtherSpecificPurpose,
  codesetNotOtherWhenOtherSelected,
  otherJourneyPurposeVisCode,
};
