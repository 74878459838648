import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';

import compatibleEvent from '../../../../compatibilityFixes';
import { safeToUpper, transformEventValueBeforeHandle } from '../../../../utils/commonUtils';
import withDebouncedValue from '../../../debounce/DebouncedValue';

import './Input.scss';

const restoreInputCursorPosition = eventTarget => () => {
  const startPos = eventTarget.selectionStart;
  const endPos = eventTarget.selectionEnd;

  setTimeout(() => { eventTarget.setSelectionRange(startPos, endPos); }, 0);
};

const Input = React.forwardRef((props, ref) => {
  const handleChange = (event) => {
    return R.compose(
      R.when(R.always(props.uppercase), restoreInputCursorPosition(event.target)),
      transformEventValueBeforeHandle(
        props.onChange,
        R.compose(
          R.when(R.always(props.uppercase), safeToUpper),
          R.when(R.isEmpty, R.always(null)),
        ),
      ),
      compatibleEvent,
    )(event);
  };

  const handleBlur = props.onBlur && R.compose(
    transformEventValueBeforeHandle(
      props.onBlur,
      R.when(R.isEmpty, R.always(null)),
    ),
    compatibleEvent,
  );

  const handleOnKeyDown = props.onKeyDown && R.compose(
    transformEventValueBeforeHandle(
      props.onKeyDown,
      R.when(R.isEmpty, R.always(null)),
    ),
    compatibleEvent,
  );

  const value = (props.type === 'number' && props.value === 0) ?
    0 :
    props.value || '';

  return (
    <input
      id={props.id}
      type={props.type}
      className={classnames(
        'input',
        props.className,
        {
          'input--invalid': props.invalid,
          'input--in-table-row': props.inTableRow,
        },
      )}
      value={value}
      name={props.name}
      aria-describedby={props.describedBy}
      aria-label={props.ariaLabel}
      required={props.required}
      aria-invalid={props.invalid}
      onChange={handleChange}
      placeholder={props.placeholder}
      disabled={props.disabled}
      accept={props.accept}
      data-cy={props.dataCy}
      readOnly={props.readOnly}
      autoFocus={props.autoFocus}
      ref={ref}
      tabIndex={props.tabIndex}
      onBlur={handleBlur}
      onKeyDown={handleOnKeyDown}
      multiple={props.multiple}
    />
  );
});

export const inputProps = R.curry((
  type,
  value,
  name,
  placeholder,
  disabled,
  onChange,
) => {
  return {
    type,
    value,
    name,
    placeholder,
    disabled,
    onChange,
  };
});

export const InputShape = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['text', 'file', 'password', 'number']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  describedBy: PropTypes.string,
  ariaLabel: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  dataCy: PropTypes.string,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  tabIndex: PropTypes.number,
  invalid: PropTypes.bool,
  inTableRow: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  uppercase: PropTypes.bool,
  multiple: PropTypes.bool,
};

Input.propTypes = InputShape;

Input.defaultProps = {
  id: null,
  type: 'text',
  value: '',
  className: null,
  describedBy: null,
  ariaLabel: null,
  required: false,
  placeholder: null,
  disabled: false,
  accept: null,
  dataCy: null,
  readOnly: false,
  autoFocus: false,
  tabIndex: 0,
  invalid: false,
  inTableRow: false,
  onBlur: null,
  onKeyDown: null,
  uppercase: false,
  multiple: false,
};

export { Input as SimpleInput };
export default withDebouncedValue(Input);
