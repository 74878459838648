import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from 'visa-frontend-common/src/components/UI/Icon/Icon';
import Loading from '../../components/UI/Loading/LoadingContainer';
import { checklistItemTypeShape, documentCountShape, pendingRequestShape } from '../../ovafModel';
import paths from '../../paths';
import i18n from '../../services/i18n';
import actions from './PendingActions';
import PendingRequestList from './PendingRequestList';
import PendingGuide from './PendingGuide';
import Guide from '../../components/Guide/Guide';

import './Pending.scss';

class PendingRequests extends React.Component {
  componentDidMount() {
    this.props.fetchPendingRequests();
    this.props.fetchDocumentStatistics();
    this.props.fetchChecklistItemTypes();
  }

  render() {
    return (
      <div className="container">
        <div className="pending__navigation">
          <Link to={paths.root} className="pending__navigation-link">
            <Icon name="arrow_back" className="pending__navigation-link-icon" />
            <span>{i18n.t('pending.navigateToApplications')}</span>
          </Link>
        </div>

        <h1>{i18n.t('pending.title')}</h1>

        <Loading requestNames={[actions.pendingRequestsFetched().type]}>
          <PendingRequestList
            title={i18n.t('pending.openRequests')}
            pendingRequests={this.props.openRequests}
            documentCounts={this.props.documentCounts}
            checklistItemTypes={this.props.checklistItemTypes}
            dataCyPostfix="open"
          />
        </Loading>

        <Loading requestNames={[actions.pendingRequestsFetched().type]}>
          <PendingRequestList
            title={i18n.t('pending.answeredRequests')}
            pendingRequests={this.props.answeredRequests}
            documentCounts={this.props.documentCounts}
            checklistItemTypes={this.props.checklistItemTypes}
            dataCyPostfix="answered"
          />
        </Loading>

        <Loading requestNames={[actions.pendingRequestsFetched().type]}>
          <PendingRequestList
            title={i18n.t('pending.closedRequests')}
            pendingRequests={this.props.closedRequests}
            documentCounts={this.props.documentCounts}
            checklistItemTypes={this.props.checklistItemTypes}
            dataCyPostfix="closed"
          />
        </Loading>
        <Guide>
          <PendingGuide />
        </Guide>
      </div>
    );
  }
}

PendingRequests.propTypes = {
  openRequests: PropTypes.arrayOf(pendingRequestShape),
  answeredRequests: PropTypes.arrayOf(pendingRequestShape),
  closedRequests: PropTypes.arrayOf(pendingRequestShape),
  documentCounts: PropTypes.arrayOf(documentCountShape),
  checklistItemTypes: PropTypes.arrayOf(checklistItemTypeShape),
  fetchPendingRequests: PropTypes.func.isRequired,
  fetchDocumentStatistics: PropTypes.func.isRequired,
  fetchChecklistItemTypes: PropTypes.func.isRequired,
};

PendingRequests.defaultProps = {
  openRequests: [],
  answeredRequests: [],
  closedRequests: [],
  documentCounts: [],
  checklistItemTypes: [],
};

export default PendingRequests;
