import moment from 'moment';
import * as R from 'ramda';

import 'moment/locale/fi';
import 'moment/locale/en-gb';

import {
  hideJsonPseudo,
  revealJsonToLocalPseudo,
  hideLocalPseudo,
  revealLocalToJsonPseudo,
} from './pseudoDateUtils';

import i18n from './i18n';

const dateParseFormatJson = 'YYYY-MM-DD';
const dateTimeParseFormatJson = 'YYYY-MM-DDTHH:mm:ssZ';
const dateTimeWithMillisParseFormatJson = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
const timeParseFormatJson = 'HH:mm:ss';
const dateFormat = 'YYYY-MM-DD';
const localTimeFormat = 'HH:mm:ss';
const localTimeInMinutesFormat = 'HH:mm';

export const momentTimeUnit = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  ISOWEEK: 'ISOWEEK',
  DAY: 'DAY',
};

const changeLocale = locale => moment.locale(locale);

/**
 * @param {number} year - A two-digit year in range (currentYear-100, currentYear]
 */
const pastYearParser = R.compose(
  R.ifElse(
    R.lt((moment().year() - 2000)),
    R.add(1900),
    R.add(2000),
  ),
  R.curry(parseInt)(R.__, 10),
);

/**
 * @param {number} year - A two-digit year in range: (currentYear-99, currentYear+1]
 */
const pastYearPlusOneParser = R.compose(
  R.ifElse(
    R.lt((moment().year() - 1999)),
    R.add(1900),
    R.add(2000),
  ),
  R.curry(parseInt)(R.__, 10),
);

/**
 * @param {number} year - A two-digit year in range [currentYear, currentYear+100)
 */
const futureYearParser = R.compose(
  R.ifElse(
    R.lte((moment().year() - 2000)),
    R.add(2000),
    R.add(2100),
  ),
  R.curry(parseInt)(R.__, 10),
);

const localDateStringToJsonStringWithInputFormat =
  inputFormats => R.curry((dateStr, yearParser = pastYearParser) => {
    const tempDateStr = hideLocalPseudo(dateStr);
    moment.parseTwoDigitYear = yearParser;

    const dateMoment = moment(
      tempDateStr,
      inputFormats,
      true,
    );

    if (dateMoment.isValid()) {
      const formatted = dateMoment.format(dateParseFormatJson);
      return revealLocalToJsonPseudo(dateStr)(formatted);
    }

    return null;
  });

const inputFormats = {
  default: [
    'D.M.YYYY',
    'DMYY',
  ],
  longDates: [
    'D.M.YYYY',
    'DMYYYY',
  ],
};

/**
 * @param {string} dateStr - local long date: DD.MM.YYYY or DDMMYYYY
 */
const localLongDateStringToJsonString =
  localDateStringToJsonStringWithInputFormat(inputFormats.longDates);

/**
 * @param {string} dateStr - local date: DD.MM.YYYY or DDMMYY
 */
const localDateStringToJsonString =
  localDateStringToJsonStringWithInputFormat(inputFormats.default);

const jsonDateStringToLocalString = (dateStr, format) => {
  if (dateStr) {
    const tempDateStr = hideJsonPseudo(dateStr);

    const valueMoment = moment(tempDateStr, dateParseFormatJson, true);

    if (valueMoment.isValid()) {
      const formatted = valueMoment.format(format || i18n.t('common:dateFormats.displayFormat'));
      return revealJsonToLocalPseudo(dateStr, format)(formatted);
    }
  }

  return dateStr || '';
};

const toDateOrNull = (dateStr) => {
  const local = jsonDateStringToLocalString(dateStr, i18n.t('common:dateFormats.displayFormat'));

  if (!local) return null;
  return local === dateStr ? null : local;
};

const jsonDateTimeStringToLocalString = (dateTimeStr, format) => {
  if (dateTimeStr) {
    const dateValue = dateTimeStr.endsWith('Z') ? dateTimeStr : `${dateTimeStr}Z`;
    const valueMoment = moment(dateValue, dateTimeParseFormatJson, true);

    if (valueMoment.isValid()) {
      return valueMoment.format(format || i18n.t('common:dateTimeFormats.displayFormat'));
    }
  }

  return dateTimeStr || '';
};

/**
 * @param {string} dateTimeStr - date or datetime with or without time zone
 */
const jsonDateStringToEpoch = dateTimeStr => moment(dateTimeStr).valueOf();

const epochToJsonDateString = epoch => moment(epoch).format(dateParseFormatJson);

/**
 * @param {string} dateTimeStr - date or datetime with or without time zone
 *
 * @example
 * // Returns 1 (when the current date is 21.3.2024)
 * daysSince('2024-03-20');
 */
const daysSince = (dateTimeStr) => {
  return moment(new Date()).startOf('day').diff(moment(dateTimeStr).startOf('day'), 'days');
};

/**
 * @param {string} dateTimeStr - date or datetime with or without time zone
 *
 * @example
 * // Result 1 (when the current date is 21.3.2024)
 * daysUntil('2024-03-22');
 */
const daysUntil = (dateTimeStr) => {
  return moment(dateTimeStr).startOf('day').diff(moment(new Date()).startOf('day'), 'days');
};

const yearsBetween = (dateFrom, dateTo) => {
  return moment(dateTo).diff(moment(dateFrom), 'years');
};

const displayDate = (dateStr) => {
  return jsonDateStringToLocalString(dateStr);
};

/**
 * @param {string} dateTimeStr - with or without time zone
 */
const displayDateTime = (dateTimeStr) => {
  return jsonDateTimeStringToLocalString(dateTimeStr);
};

/**
 * @param {string} dateTimeStr - with or without time zone
 */
const displayDateTimeAsDate = (dateTimeStr) => {
  return jsonDateTimeStringToLocalString(dateTimeStr, i18n.t('common:dateFormats.displayFormat'));
};

/**
 * @param {string} dateTimeStr - with or without time zone
 */
const displayDateTimeAsTimeMinutes = (dateTimeStr) => {
  return jsonDateTimeStringToLocalString(dateTimeStr, i18n.t('common:timeFormats.displayFormatMinutes'));
};

/**
 * @param {string} dateTimeStr - with or without time zone
 */
const getLocalJsonTimeStringFromJsonDateTimeString = (dateTimeStr) => {
  const timeStr = jsonDateTimeStringToLocalString(dateTimeStr, timeParseFormatJson);
  const valueMoment = moment(timeStr, timeParseFormatJson, true);
  if (valueMoment.isValid()) {
    return valueMoment.format(timeParseFormatJson);
  }
  return null;
};

/**
 * @param {string} dateTimeStr - with time zone
 */
const displayDateTimeHours = (dateTimeStr) => {
  return jsonDateTimeStringToLocalString(dateTimeStr, i18n.t('common:timeFormats.displayFormatHours'));
};

const displayDateWithoutYear = (dateStr) => {
  return jsonDateStringToLocalString(dateStr, i18n.t('common:dateFormats.displayFormatWithoutYear'));
};

/**
 * @param {string} startDate - date or datetime with or without time zone
 *
 * @example
 * //Returns '20.3.2024 (2 pv sitten)' (when the current date is 22.03.2024)
 * getDaysSinceStartDateWithText('2024-03-20', false);
 */
const getDaysSinceStartDateWithText = (startDate, dateFormatIncludesTime = true) => {
  const daysSinceStartDate = daysSince(startDate);
  const daysText =
    daysSinceStartDate === 0 ? i18n.t('common:today') : `${daysSinceStartDate} ${i18n.t('common:daysAgo')}`;

  if (dateFormatIncludesTime) {
    return `${displayDateTimeAsDate(startDate)} (${daysText})`;
  }

  return `${displayDate(startDate)} (${daysText})`;
};

/**
 * @param {string} dateTimeStr - with or without time zone
 */
const displayDateTimeWithSeconds = (dateTimeStr) => {
  return jsonDateTimeStringToLocalString(dateTimeStr, i18n.t('common:dateTimeFormats.displayFormatWithSeconds'));
};

/**
 * @param {string} dateTimeStr - with time zone
 */
const displayDateTimeWithSecondUTCWithoutZone = (dateTimeStr) => {
  if (dateTimeStr) {
    const valueMoment = moment(dateTimeStr, dateTimeParseFormatJson, true).utc(false);

    if (valueMoment.isValid()) {
      return valueMoment.format(i18n.t('common:dateTimeFormats.displayFormatWithSeconds'));
    }
  }

  return dateTimeStr || '';
};

/**
 * @param {string} dateTimeStr - with time zone
 */
const displayDateTimeUTC = (dateTimeStr) => {
  if (dateTimeStr) {
    const valueMoment = moment(dateTimeStr, dateTimeParseFormatJson, true).utc(false);

    if (valueMoment.isValid()) {
      return valueMoment.format(i18n.t('common:dateTimeFormats.displayFormatWithZone'));
    }
  }

  return dateTimeStr || '';
};

/**
 * @param {string} dateTimeStr - with time zone and with or without milliseconds
 */
const displayDateTimeUTCWithoutZone = (dateTimeStr) => {
  if (dateTimeStr) {
    const valueMoment =
      moment(dateTimeStr, [dateTimeParseFormatJson, dateTimeWithMillisParseFormatJson], true)
        .utc(false);

    if (valueMoment.isValid()) {
      return valueMoment.format(i18n.t('common:dateTimeFormats.displayFormat'));
    }
  }

  return dateTimeStr || '';
};

/**
 * @param {string} dateTimeStr - with time zone
 */
const displayDateTimeWithoutZone = (dateTimeStr) => {
  if (dateTimeStr) {
    const valueMoment = moment(dateTimeStr, dateTimeParseFormatJson, true).utc(true);

    if (valueMoment.isValid()) {
      return valueMoment.format(i18n.t('common:dateTimeFormats.displayFormat'));
    }
  }

  return dateTimeStr || '';
};

const displayTime = (timeStr) => {
  if (timeStr) {
    const valueMoment = moment(timeStr, timeParseFormatJson, true);

    if (valueMoment.isValid()) {
      return valueMoment.format(i18n.t('common:timeFormats.displayFormatMinutes'));
    }
  }

  return timeStr || '';
};

const displayDateByTimeUnit = (dateStr, timeUnit, format) => {
  return moment(dateStr, dateParseFormatJson, true)
    .format(format || i18n.t(`common:dateFormats.displayFormatsByTimeUnit.${timeUnit}`));
};

const getDurationPart = (partCount, localizationPostfix) => {
  if (partCount === 0) {
    return null;
  }

  const unitKey = (partCount === 1) ?
    `common:dateFormats.duration.single.${localizationPostfix}` :
    `common:dateFormats.duration.plural.${localizationPostfix}`;

  return `${partCount} ${i18n.t(unitKey)}`;
};

/**
 * @param {string} duration - e.g. 'PT17592H2M'
 */
const displayDuration = (duration) => {
  if (duration && duration.length > 0) {
    const durationMoment = moment.duration(duration);

    const durationParts = [
      getDurationPart(
        durationMoment.years(),
        'year',
      ),
      getDurationPart(
        durationMoment.months(),
        'month',
      ),
      getDurationPart(
        durationMoment.days(),
        'day',
      ),
      getDurationPart(
        durationMoment.hours(),
        'hour',
      ),
      getDurationPart(
        durationMoment.minutes(),
        'minute',
      ),
    ];

    const existingParts = durationParts.filter(part => part !== null);

    return existingParts.join(' ');
  }

  return '';
};

const currentDateAsJsonString = () => {
  return moment().format(dateParseFormatJson);
};

const currentDateTimeAsJsonString = () => {
  return moment().format(dateTimeParseFormatJson);
};

const currentYear = () => {
  return moment().year();
};

const addMonthsToDate = R.curry((dateStr, months) => {
  const fromDate = moment(dateStr, dateParseFormatJson, true);
  return localDateStringToJsonString(fromDate.add(months, 'months'));
});

const addDaysToDate = R.curry((dateStr, days) =>
  localDateStringToJsonString(moment(dateStr, dateParseFormatJson, true).add(days, 'days')));

const subtractDaysFromDate = R.curry((dateStr, days) =>
  localDateStringToJsonString(moment(dateStr, dateParseFormatJson, true).subtract(days, 'days')));

const addMonthsToDateAndSubtractDay = R.curry((dateStr, months) => {
  const fromDate = moment(dateStr, dateParseFormatJson, true);
  return localDateStringToJsonString(fromDate.add(months, 'months').subtract(1, 'days'));
});

const addDaysToDateAndSubtractDay = R.curry((dateStr, days) => {
  const fromDate = moment(dateStr, dateParseFormatJson, true);
  return localDateStringToJsonString(fromDate.add(days, 'days').subtract(1, 'days'));
});

const subtractMonthsFromDate = R.curry((dateStr, months) => {
  const fromDate = moment(dateStr, dateParseFormatJson, true);
  return localDateStringToJsonString(fromDate.subtract(months, 'months'));
});

const dateRangeToMonthsIfOption = R.curry((options, startDateStr, endDateStr) => {
  const startDateValid = moment(startDateStr, dateParseFormatJson, true).isValid();
  const endDateValid = moment(endDateStr, dateParseFormatJson, true).isValid();
  if (!startDateValid || !endDateValid) {
    return null;
  }
  const optionValues = R.map(R.prop('type'), options);
  const months = R.map(month =>
    [addMonthsToDateAndSubtractDay(startDateStr, month), month], optionValues);

  const monthPair = R.find(month => R.equals(R.head(month), endDateStr), months);
  return monthPair ? R.last(monthPair) : null;
});

/**
 * @param {string} dateTimeStr - with time zone
 */
const addHoursToDateTime = R.curry((dateTimeStr, hours) => {
  const fromDateTime = moment(dateTimeStr, dateTimeParseFormatJson, true);
  return fromDateTime.add(hours, 'hours').format(dateTimeParseFormatJson);
});

/**
 * @param {string} dateTimeStr - with time zone
 */
const addTimeUnitsToDateTime = R.curry((dateTimeStr, timeUnit, units) => {
  const fromDateTime = moment(dateTimeStr, dateTimeParseFormatJson, true);
  return fromDateTime.add(units, timeUnit).format(dateTimeParseFormatJson);
});

const isUnderage = (birthdate, referenceDate = null) => {
  const date = referenceDate ? moment(referenceDate) : moment();
  return date.subtract(18, 'years').isBefore(hideJsonPseudo(birthdate));
};

const isPastDate = (dateStr, referenceDate = null) => {
  if (referenceDate) {
    return moment(referenceDate).isAfter(dateStr, 'day');
  }

  return moment().isAfter(dateStr, 'day');
};

/**
 * @param {string} dateTimeStr - with or without time zone
 * @param {string} [referenceDateTime=null] - with or without time zone
 */
const isPastDateTime = (dateTimeStr, referenceDateTime = null) => {
  if (referenceDateTime) {
    return moment(referenceDateTime).isAfter(dateTimeStr, 'minute');
  }

  return moment().isAfter(dateTimeStr, 'minute');
};

const isPriorDate = (dateStr, referenceDate = null) => {
  if (referenceDate) {
    return moment(referenceDate).isBefore(dateStr, 'day');
  }

  return moment().isBefore(dateStr, 'day');
};

const isAfterOrEqualDate = (dateStr, referenceDate = null) => {
  if (referenceDate) {
    return moment(referenceDate).isSameOrAfter(dateStr, 'day');
  }
  return moment().isSameOrAfter(dateStr, 'day');
};

const isBeforeOrEqualDate = (dateStr, referenceDate = null) => {
  if (referenceDate) {
    return moment(referenceDate).isSameOrBefore(dateStr, 'day');
  }
  return moment().isSameOrBefore(dateStr, 'day');
};

const isToday = (dateStr) => {
  return moment().isSame(dateStr, 'day');
};

/**
 * @param {moment.Moment} actualDate - moment date object
 * @param {moment.Moment} startDate - moment date object
 * @param {moment.Moment} endDate - moment date object
 */
const dateIsInRange = (actualDate, startDate, endDate) => {
  return actualDate != null && startDate != null && endDate != null &&
    startDate.isValid() && endDate.isValid() && actualDate.isValid() &&
    actualDate.isBetween(startDate, endDate, null, '[]');
};

const dateIsInOpenRange = (actualDateStr, startDateStr, endDateStr) => {
  if (actualDateStr === null) return false;
  if (startDateStr === null && endDateStr === null) return true;
  if (startDateStr === null) return isBeforeOrEqualDate(endDateStr, actualDateStr);
  if (endDateStr === null) return isAfterOrEqualDate(startDateStr, actualDateStr);
  return dateIsInRange(
    moment(actualDateStr, dateParseFormatJson, true),
    moment(startDateStr, dateParseFormatJson, true),
    moment(endDateStr, dateParseFormatJson, true),
  );
};

const startAndEndOfTimeUnit = (dateStr, timeUnit) => {
  const referenceDate = moment(dateStr, dateParseFormatJson, true);
  return {
    startDate: referenceDate.startOf(timeUnit).format(dateParseFormatJson),
    endDate: referenceDate.endOf(timeUnit).format(dateParseFormatJson),
  };
};

const subtractTimeUnitsFromDate = R.curry((dateStr, timeUnit, units) => {
  const fromDate = moment(dateStr, dateParseFormatJson, true);
  return localDateStringToJsonString(fromDate.subtract(units, timeUnit));
});

const subtractTimeUnitFromDate = (dateStr, timeUnit) => {
  return subtractTimeUnitsFromDate(dateStr, timeUnit, 1);
};

const addTimeUnitsToDate = R.curry((dateStr, timeUnit, units) => {
  const fromDate = moment(dateStr, dateParseFormatJson, true);
  return localDateStringToJsonString(fromDate.add(units, timeUnit));
});

const addTimeUnitToDate = (dateStr, timeUnit) => {
  return addTimeUnitsToDate(dateStr, timeUnit, 1);
};

const isTimeBetween = (actualTimeStr, startTimeStr, endTimeStr) => {
  const actualTime = moment(actualTimeStr, timeParseFormatJson);
  const startTime = moment(startTimeStr, timeParseFormatJson);
  const endTime = moment(endTimeStr, timeParseFormatJson);

  if (endTime.isBefore(startTime)) {
    const dayStartTime = moment('00:00:00', timeParseFormatJson);
    const dayEndTime = moment('23:59:59', timeParseFormatJson);

    return actualTime.isBetween(startTime, dayEndTime, null, '[]') ||
      actualTime.isBetween(dayStartTime, endTime, null, '[]');
  }

  return actualTime.isBetween(startTime, endTime, null, '[]');
};

/**
 * @param {string} dateFrom - date or datetime with or without time zone
 * @param {string} dateTo - date or datetime with or without time zone
 */
const durationBetween = (dateFrom, dateTo) => {
  return moment.duration(moment(dateTo).diff(moment(dateFrom)));
};

/**
 * @param {string} dateStr - date or datetime with or without time zone
 */
const startOfMonth = dateStr => moment(dateStr).startOf('month').format(dateParseFormatJson);

export {
  changeLocale,
  toDateOrNull,
  currentDateAsJsonString,
  currentDateTimeAsJsonString,
  currentYear,
  daysSince,
  daysUntil,
  yearsBetween,
  getDaysSinceStartDateWithText,
  displayDate,
  displayDateTime,
  displayDateTimeUTC,
  displayDateTimeUTCWithoutZone,
  displayDateTimeWithoutZone,
  displayDateTimeAsDate,
  displayDateTimeWithSeconds,
  displayDateTimeWithSecondUTCWithoutZone,
  displayTime,
  displayDuration,
  displayDateByTimeUnit,
  jsonDateStringToLocalString,
  jsonDateStringToEpoch,
  epochToJsonDateString,
  localLongDateStringToJsonString,
  localDateStringToJsonString,
  pastYearParser,
  pastYearPlusOneParser,
  futureYearParser,
  addMonthsToDate,
  addDaysToDate,
  subtractDaysFromDate,
  addMonthsToDateAndSubtractDay,
  addTimeUnitsToDate,
  dateRangeToMonthsIfOption,
  addHoursToDateTime,
  addTimeUnitsToDateTime,
  isPastDate,
  isPastDateTime,
  isPriorDate,
  isToday,
  isUnderage,
  dateFormat,
  localTimeFormat,
  localTimeInMinutesFormat,
  addDaysToDateAndSubtractDay,
  subtractMonthsFromDate,
  dateTimeParseFormatJson,
  dateParseFormatJson,
  dateIsInRange,
  dateIsInOpenRange,
  startAndEndOfTimeUnit,
  addTimeUnitToDate,
  subtractTimeUnitFromDate,
  subtractTimeUnitsFromDate,
  isAfterOrEqualDate,
  isBeforeOrEqualDate,
  getLocalJsonTimeStringFromJsonDateTimeString,
  displayDateTimeHours,
  displayDateWithoutYear,
  isTimeBetween,
  durationBetween,
  displayDateTimeAsTimeMinutes,
  startOfMonth,
};
