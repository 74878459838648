import React, { memo } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as R from 'ramda';

import Icon from 'visa-frontend-common/src/components/UI/Icon/Icon';
import Image from 'visa-frontend-common/src/components/UI/Image/Image';
import UserInfo from '../UserInfo/UserInfo';
import i18n from '../../services/i18n';
import paths from '../../paths';
import Logo from '../../static/images/UM_merkki_valkoinen_ilman_tyhjaa.png';

import './Header.scss';

const Header = memo((props) => {
  return (
    <nav className="header navbar navbar-expand-sm" role="banner">
      <div className="container">
        <Link className="header__navbar-brand navbar-brand" to={paths.root}>
          <Image
            url={Logo}
            alt={i18n.t('headerLogoAltText')}
            className="header__logo"
          />
          <div>
            <h1 className="header__logo-title">{i18n.t('logoTitle')}</h1>
            <h4 className="header__logo-subtitle">{i18n.t('logoSubTitle')}</h4>
          </div>
        </Link>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ms-auto">
            { props.hasRequests &&
              <PendingMenuItem
                path={paths.pending.root}
                name={i18n.t('pending.requests')}
                icon="email"
                amount={props.requestAmount}
                dataCyPostFix="pending"
              />}
          </ul>
          <div className="header__menu-item">
            <UserInfo username={props.username} />
          </div>
        </div>
      </div>
    </nav>
  );
});

const MenuItem = (props) => {
  const { amount } = props;
  const blockClassNames = classnames(
    'header__menu-item-block',
    {
      'header__menu-item-block--small': amount < 10,
      'header__menu-item-block--medium': (amount >= 10 && amount < 100),
      'header__menu-item-block--large': amount >= 100,
    },
  );

  const amountToShow = amount <= 999 ? amount : '...';
  return (
    <li className="header__menu-item">
      <NavLink
        to={props.path}
        className="header__menu-item-link d-block d-sm-inline"
        activeClassName="header__menu-item-link--active"
        data-cy={`menu-item-${props.dataCyPostFix}`}
      >
        <Icon name={props.icon} className="header__menu-item-icon" />
        <span className="d-sm-none d-lg-none d-xl-inline">{props.name}</span>
        { R.gt(amount, 0) &&
          <div className={blockClassNames}>
            <div>
              <div className="header__menu-item-block-amount">{amountToShow}</div>
            </div>
          </div>}
      </NavLink>
    </li>
  );
};

const PendingMenuItem = withRouter(MenuItem);

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  amount: PropTypes.number,
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  dataCyPostFix: PropTypes.string.isRequired,
};

MenuItem.defaultProps = {
  amount: null,
};

Header.propTypes = {
  username: PropTypes.string,
  hasRequests: PropTypes.bool,
  requestAmount: PropTypes.number,
};

Header.defaultProps = {
  username: null,
  hasRequests: false,
  requestAmount: null,
};

UserInfo.propTypes = {
  username: PropTypes.string,
};

UserInfo.defaultProps = {
  username: null,
};

export default Header;
