import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../services/i18n';

import GuideSubtitle from './GuideSubtitle';

import './GuideSubtitle.scss';

const GuideItem = (props) => {
  return (
    <>
      <GuideSubtitle>{props.subTitle}</GuideSubtitle>
      <p id={props.id} lang={i18n.language()}>
        <small>{props.children}</small>
      </p>
    </>
  );
};

GuideItem.propTypes = {
  id: PropTypes.string,
  subTitle: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

GuideItem.defaultProps = {
  id: null,
};

export default GuideItem;
