import { createAction } from 'redux-actions';
import { printChecklistInIframe } from 'visa-frontend-common/src/services/htmlPrintingService';

import { apiThunkWithLoading } from 'visa-frontend-common/src/utils/actionUtils';
import { downloadBlobAndOpenToNewWindow } from 'visa-frontend-common/src/utils/downloadUtils';
import * as checklistService from '../../services/checklistService';
import applicationActions from '../Application/ApplicationActions';

const checklistPrinted = createAction('CHECKLIST_PRINT_COMPLETED');

const createDownloadingChecklistPdfBlobAction = checklistId =>
  createAction(`CHECKLIST[${checklistId}]_PDF_DOWNLOAD_COMPLETED`);

const staticChecklistsFetched = createAction('STATIC_CHECKLISTS_FETCH_COMPLETED');
const checklistItemAttachmentsFetched = createAction('STATIC_CHECKLISTS_ITEM_ATTACHMENTS_FETCH_COMPLETED');
const setChecklistPageLocale = createAction('STATIC_CHECKLISTS_CURRENT_LOCALE_SET');

const fetchStaticChecklists = apiThunkWithLoading(
  checklistService.getStaticChecklistsForEspLocation,
  staticChecklistsFetched,
);

const fetchStaticChecklistsAndInstructions = espLocation => dispatch => Promise.all([
  dispatch(fetchStaticChecklists(espLocation)),
  applicationActions.fetchChecklistCountrySpecificInstructions(espLocation)(dispatch),
]);

const fetchChecklistItemAttachments = apiThunkWithLoading(
  checklistService.getChecklistItemAttachments,
  checklistItemAttachmentsFetched,
);

const printChecklist = (espLocation, checklistId) => apiThunkWithLoading(
  downloadBlobAndOpenToNewWindow(checklistService.printChecklist, true),
  createDownloadingChecklistPdfBlobAction(checklistId),
)(espLocation, checklistId);

const printChecklistInBrowser = apiThunkWithLoading(
  (espLocation, checklistId) => checklistService.printChecklistInBrowser(espLocation, checklistId)
    .then(checklistBody => printChecklistInIframe(checklistBody)),
  checklistPrinted,
);

const actions = {
  fetchStaticChecklists,
  fetchStaticChecklistsAndInstructions,
  staticChecklistsFetched,
  fetchChecklistItemAttachments,
  checklistItemAttachmentsFetched,
  setChecklistPageLocale,
  createDownloadingChecklistPdfBlobAction,
  printChecklist,
  printChecklistInBrowser,
};

export default actions;
