import { connect } from 'react-redux';
import {
  currentUserLocaleSelector,
  currentUserNameSelector,
} from '../Main/MainReducer';
import frontPageActions from '../FrontPage/FrontPageActions';
import AccountSettings from './AccountSettings';
import { registerFormSelector } from '../Register/RegisterReducer';
import { sentApplicationsSelector } from '../FrontPage/FrontPageReducer';
import registerActions from '../Register/RegisterActions';
import {
  saveUserAccountData,
  loadUserAccountData,
  loadingUserAccountData,
  savingUserAccountData,
  deleteUserAccount,
} from './AccountSettingsActions';
import {
  newPasswordDisabledSelector,
  confirmPasswordDisabledSelector,
  newPasswordValidationErrorSelector,
  confirmPasswordValidationErrorSelector,
  saveAccountSettingsDisabledSelector,
} from './AccountSettingsSelectors';

const mapStateToProps = state => ({
  locale: currentUserLocaleSelector(state),
  email: currentUserNameSelector(state),
  registerForm: registerFormSelector(state),
  userAccountLoadActionType: loadingUserAccountData().type,
  userAccountSaveActionType: savingUserAccountData().type,
  sentApplications: sentApplicationsSelector(state),
  newPasswordDisabled: newPasswordDisabledSelector(state),
  confirmPasswordDisabled: confirmPasswordDisabledSelector(state),
  newPasswordValidationError: newPasswordValidationErrorSelector(state),
  confirmPasswordValidationError: confirmPasswordValidationErrorSelector(state),
  saveAccountSettingsDisabled: saveAccountSettingsDisabledSelector(state),
});

const mapDispatchToProps = {
  updateLocale: frontPageActions.updateLocale,
  fetchApplications: frontPageActions.fetchApplications,
  registerSetFieldAndValidate: registerActions.registerSetFieldAndValidate,
  saveUserAccountData,
  loadUserAccountData,
  deleteUserAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
