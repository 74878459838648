import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { generateId } from '../../../services/idService';
import Button from '../../UI/Button/Button';
import {
  booleanToOption,
  optionToBoolean,
  notNilOrEmpty,
  eventToField,
  transformEventValueBeforeHandle,
} from '../../../utils/commonUtils';
import { formFieldDefaultValue, formFieldShape, formFieldShapeFor } from '../../../dataModel';
import TextWithHelp from '../../UI/TextWithHelp/TextWithHelp';
import ValidationError from '../../UI/ValidationError/ValidationError';
import TwoColumnRow from '../../UI/TwoColumnRow/TwoColumnRow';
import Divider from '../../UI/Divider/Divider';
import Subsection from '../../UI/Subsection/Subsection';
import TabSubtitle from '../../UI/TabSubtitle/TabSubtitle';
import { CheckboxGroup } from '../../UI/Form/FormComponents';
import InlineNotification from '../../UI/InlineNotification/InlineNotification';
import {
  invitationFormDefaultProps,
  invitationType,
  invitationFormShape,
} from './InvitationsDataModel';
import i18n from '../../../services/i18n';
import Invitation from './Invitation';
import RequiredText from '../../UI/RequiredText/RequiredText';

import './Invitations.scss';

const toggleOption = label => [
  {
    keyProp: 'YES',
    value: 'true',
    label,
  }];

const Invitations = (props) => {
  const invitationValidationError =
    R.compose(
      R.last,
      R.filter(R.compose(R.not, R.isNil)),
    )([
      props.contactOrAccommodation.validationError,
      props.invitingOrganization.validationError,
      props.invitingPersons.validationError,
      props.accommodations.validationError,
      props.oneDayTripWithoutAccommodation.validationError,
    ]);

  const handleChangeFor = fieldName => (event) => {
    props.handleChange(R.assocPath(['target', 'name'], `${fieldName}.${event.target.name}`, event));
  };

  const handleArrayChangeFor = index =>
    R.compose(R.curryN(3, props.handleArrayChange)('invitingPersons', index), eventToField);

  const handleAccommodationArrayChangeFor = index =>
    R.compose(R.curryN(3, props.handleArrayChange)('accommodations', index), eventToField);

  const invitationOfType = type =>
    R.compose(
      R.assoc('uid', formFieldDefaultValue(generateId())),
      R.assoc('invitationType', formFieldDefaultValue(type)),
    )(invitationFormDefaultProps);

  const invitationFieldOfType = (field, type, isArray = false) =>
    R.assocPath(
      [field, 'value'],
      R.when(
        R.always(isArray),
        R.append(R.__, []),
      )(invitationOfType(type)),
      {},
    );

  // Validate contacts and accommodation top level invitation options
  // and re-validate other dependent field oneDayTripWithoutAccommodation
  const validateDependentFields = () => {
    props.validateFields(['contactOrAccommodation', 'oneDayTripWithoutAccommodation']);
  };

  const handleInvitationOptionChangeFor = (field, type, isArray = false) => (event) => {
    const newValue = optionToBoolean(event.target.value);

    if (newValue) {
      props.initField(invitationFieldOfType(field, type, isArray));
    } else {
      props.clearFields([`form.${field}`]);
    }
    validateDependentFields();
  };

  const handleOrganizationOptionChange = (event) => {
    const newValue = optionToBoolean(event.target.value);

    if (newValue) {
      props.initField(invitationFieldOfType('invitingOrganization', invitationType.ORGANIZATION));
      props.initField(invitationFieldOfType('invitingOrganizationContact', invitationType.ORGANIZATION_CONTACT));
    } else {
      props.clearFields(['form.invitingOrganization', 'form.invitingOrganizationContact']);
    }
    validateDependentFields();
  };

  const handleOneDayTripOptionChange = (event) => {
    transformEventValueBeforeHandle(props.handleChange, optionToBoolean)(event);
    validateDependentFields();
  };

  const handleAdd = (fieldName, type) => () => {
    const newItems = R.compose(
      R.append(invitationOfType(type)),
      R.path([fieldName, 'value']),
    )(props);

    props.handleChange({
      target: {
        name: fieldName,
        value: newItems,
      },
    });
  };

  const handleRemove = (fieldName, index, type) => () => {
    const newItems = R.compose(
      R.when(
        R.isEmpty,
        R.append(invitationOfType(type)),
      ),
      R.remove(index, 1),
      R.path([fieldName, 'value']),
    )(props);

    props.handleChange({
      target: {
        name: fieldName,
        value: newItems,
      },
    });
  };

  const noInvitationOptions = R.adjust(
    0,
    R.assoc('autoFocus', props.autoFocus),
    toggleOption(i18n.t('common:applicationForm.invitations.options.NONE')),
  );

  const accommodationSelected = notNilOrEmpty(props.accommodations.value);
  const invitationPersonSelected = notNilOrEmpty(props.invitingPersons.value);
  const invitingOrganizationSelected = notNilOrEmpty(props.invitingOrganization.value);
  const oneDayTripWithoutAccommodationSelected = props.oneDayTripWithoutAccommodation.value;

  const oneDayTripWithoutAccommodationDisabled = accommodationSelected ||
    invitationPersonSelected ||
    invitingOrganizationSelected;

  const invitationsLabel =
    <RequiredText
      text={i18n.t('common:applicationForm.invitations.title')}
      isRequired={!props.eucitizenFamily.value}
    />;

  return (
    <div role="form" aria-labelledby="applicationForm-invitations-title">
      <TwoColumnRow oneColumn={props.oneColumn}>
        <>
          <TextWithHelp
            text={<h3 id="applicationForm-invitations-title">{invitationsLabel}</h3>}
            help={i18n.t('common:applicationForm.invitations.description')}
          />

          { props.eucitizenFamily.value &&
            <InlineNotification
              type="info"
              className="invitations__eu-citizen-notification"
              disableClose
            >
              {i18n.t('common:applicationForm.invitations.euCitizenNotification')}
            </InlineNotification>}

          <ValidationError
            className="invitations__validation-error"
            validationError={invitationValidationError}
          />
        </>
      </TwoColumnRow>

      <Divider fullWidth withTopMargin />

      <>
        <CheckboxGroup
          name="noInvitation"
          labelType="h3"
          value={booleanToOption(accommodationSelected)}
          options={noInvitationOptions}
          onChange={handleInvitationOptionChangeFor('accommodations', invitationType.ACCOMMODATION, true)}
          disabled={oneDayTripWithoutAccommodationSelected}
        />

        {accommodationSelected &&
          <>
            <TabSubtitle type="h4">{`${i18n.t('common:applicationForm.invitations.ACCOMMODATION.title')} [30]`}</TabSubtitle>
            {
              props.accommodations.value.map((invitation, i) => {
                const key = `Accommodation${invitation.uid.value}`;
                const accommodationInvitation = (<Invitation
                  invitation={invitation}
                  handleChange={handleAccommodationArrayChangeFor(i)}
                  handleRemove={handleRemove('accommodations', i, invitationType.ACCOMMODATION)}
                  codesetSelectContainer={props.codesetSelectContainer}
                  oneColumn={props.oneColumn}
                  showFaxnumber={props.showFaxnumber}
                  uppercase={props.uppercase}
                  inputIdPrefix={`${invitation.invitationType.value}-${i}`}
                />);

                return (
                  <React.Fragment key={key}>
                    {i === 0 && accommodationInvitation}
                    {i !== 0 &&
                      <Subsection>
                        <TabSubtitle type="h4">
                          {i18n.t('common:applicationForm.invitations.ACCOMMODATION.subtitle', { order: i + 1 })}
                        </TabSubtitle>
                        {accommodationInvitation}
                      </Subsection>}
                  </React.Fragment>
                );
              })
            }
            <Button
              type="button"
              label={i18n.t('common:applicationForm.invitations.ACCOMMODATION.add')}
              onClick={handleAdd('accommodations', invitationType.ACCOMMODATION)}
              icon="add"
              class="invisible"
              dataCy="accommodation-add-button"
            />
          </>}

        <Divider fullWidth />
      </>

      <CheckboxGroup
        name="invitingPersons"
        labelType="h3"
        value={booleanToOption(invitationPersonSelected)}
        options={toggleOption(i18n.t('common:applicationForm.invitations.options.PERSON'))}
        onChange={handleInvitationOptionChangeFor('invitingPersons', invitationType.PERSON, true)}
        disabled={oneDayTripWithoutAccommodationSelected}
      />

      { invitationPersonSelected &&
        <>
          <TabSubtitle type="h4">{`${i18n.t('common:applicationForm.invitations.PERSON.title')} [30]`}</TabSubtitle>
          {
            props.invitingPersons.value.map((invitation, i) => {
              const key = `InvitingPerson${invitation.uid.value}`;
              const personInvitation = (<Invitation
                invitation={invitation}
                handleChange={handleArrayChangeFor(i)}
                handleRemove={handleRemove('invitingPersons', i, invitationType.PERSON)}
                codesetSelectContainer={props.codesetSelectContainer}
                oneColumn={props.oneColumn}
                showFaxnumber={props.showFaxnumber}
                uppercase={props.uppercase}
                inputIdPrefix={`${invitation.invitationType.value}-${i}`}
              />);

              return (
                <React.Fragment key={key}>
                  { i === 0 && personInvitation }
                  { i !== 0 &&
                  <Subsection>
                    <TabSubtitle type="h4">{i18n.t('common:applicationForm.invitations.PERSON.subtitle', { order: i + 1 })}</TabSubtitle>
                    { personInvitation }
                  </Subsection>}
                </React.Fragment>
              );
            })
          }
          <Button
            type="button"
            label={i18n.t('common:applicationForm.invitations.PERSON.add')}
            onClick={handleAdd('invitingPersons', invitationType.PERSON)}
            icon="add"
            class="invisible"
            dataCy="person-add-button"
          />
        </>}

      <Divider fullWidth />

      <CheckboxGroup
        name="invitingOrganization"
        labelType="h3"
        value={booleanToOption(invitingOrganizationSelected)}
        options={toggleOption(i18n.t('common:applicationForm.invitations.options.ORGANIZATION'))}
        onChange={handleOrganizationOptionChange}
        disabled={oneDayTripWithoutAccommodationSelected}
      />

      { invitingOrganizationSelected &&
        <>
          <TabSubtitle type="h4">{`${i18n.t('common:applicationForm.invitations.ORGANIZATION.title')} [31]`}</TabSubtitle>
          <Invitation
            invitation={props.invitingOrganization.value}
            handleChange={handleChangeFor('invitingOrganization')}
            codesetSelectContainer={props.codesetSelectContainer}
            oneColumn={props.oneColumn}
            showFaxnumber={props.showFaxnumber}
            uppercase={props.uppercase}
            inputIdPrefix={props.invitingOrganization.value.invitationType.value}
          />
        </>}
      { notNilOrEmpty(props.invitingOrganizationContact.value) &&
        <>
          <TabSubtitle type="h4">{i18n.t('common:applicationForm.invitations.ORGANIZATION_CONTACT.title')}</TabSubtitle>
          <Invitation
            invitation={props.invitingOrganizationContact.value}
            handleChange={handleChangeFor('invitingOrganizationContact')}
            codesetSelectContainer={props.codesetSelectContainer}
            oneColumn={props.oneColumn}
            showFaxnumber={props.showFaxnumber}
            uppercase={props.uppercase}
            inputIdPrefix={props.invitingOrganizationContact.value.invitationType.value}
          />
        </>}

      <Divider fullWidth />

      <CheckboxGroup
        name="oneDayTripWithoutAccommodation"
        labelType="h3"
        value={booleanToOption(oneDayTripWithoutAccommodationSelected)}
        options={toggleOption(i18n.t('common:applicationForm.invitations.options.ONEDAYTRIP'))}
        onChange={handleOneDayTripOptionChange}
        disabled={oneDayTripWithoutAccommodationDisabled}
        describedBy="contactsAndAccomodationGuide-contactInfoGuideForField30oneDayTrip"
      />
    </div>
  );
};

Invitations.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleArrayChange: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
  initField: PropTypes.func.isRequired,
  eucitizenFamily: formFieldShapeFor(PropTypes.bool).isRequired,
  contactOrAccommodation: formFieldShape.isRequired,
  invitingOrganization: formFieldShapeFor(invitationFormShape).isRequired,
  invitingOrganizationContact: formFieldShapeFor(invitationFormShape).isRequired,
  invitingPersons: formFieldShapeFor(PropTypes.arrayOf(invitationFormShape)).isRequired,
  accommodations: formFieldShapeFor(PropTypes.arrayOf(invitationFormShape)).isRequired,
  oneDayTripWithoutAccommodation: formFieldShape.isRequired,
  showFaxnumber: PropTypes.bool,
  autoFocus: PropTypes.bool,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
  uppercase: PropTypes.bool,
};

Invitations.defaultProps = {
  autoFocus: true,
  oneColumn: false,
  showFaxnumber: true,
  uppercase: false,
};

export default Invitations;
