import { connect } from 'react-redux';

import {
  applicationGroupFormSelector,
  unfinishedApplicationGroupsSelector,
} from '../../pages/FrontPage/FrontPageReducer';
import actions from '../../pages/FrontPage/FrontPageActions';
import ApplicationGroupAddForm from './ApplicationGroupAddForm';

const mapStateToProps = state => ({
  form: applicationGroupFormSelector(state),
  groups: unfinishedApplicationGroupsSelector(state),
});

const mapDispatchToProps = {
  onChange: actions.applicationGroupFormFieldSet,
  onSubmit: actions.addApplicationToGroup,
  onCancel: actions.applicationGroupFormCleared,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationGroupAddForm);
