import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import UserInfoWrapper from 'visa-frontend-common/src/components/UI/UserInfo/UserInfo';
import Icon from 'visa-frontend-common/src/components/UI/Icon/Icon';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import i18n from '../../services/i18n';
import paths from '../../paths';

import './UserInfo.scss';

const LogoutButton = () => {
  const doLogout = () => {
    window.location = '/api/logout';
  };

  return (
    <Button
      type="submit"
      onClick={doLogout}
      label={i18n.t('logout')}
    />
  );
};

const AccountSettingsLink = ({ hideModal }) => (
  <li className="user-info__item" key="user-info-person-text">
    <Link
      className="user-info__item-link"
      to={paths.accountSettings.root}
      onClick={hideModal}
    >
      <Icon className="user-info__link-icon" name="person" />
      <small className="user-info__details-text">{i18n.t('accountSettings.title')}</small>
    </Link>
  </li>
);

AccountSettingsLink.propTypes = {
  hideModal: PropTypes.func,
};

AccountSettingsLink.defaultProps = {
  hideModal: () => {},
};

const UserInfo = (props) => {
  if (props.username) {
    const userInfoButtonContent =
      <>
        <Icon name="person" />
        <span className="user-info__button-label" data-cy="menu-user-info">
          {props.username}
        </span>
      </>;

    return <UserInfoWrapper
      buttonContent={userInfoButtonContent}
      accountInfoItems={[<AccountSettingsLink key="account-settings" />]}
      logoutButton={<LogoutButton />}
    />;
  }
  return null;
};

UserInfo.propTypes = {
  username: PropTypes.string.isRequired,
};

export default UserInfo;
