import React from 'react';
import PropTypes from 'prop-types';
import { __, defaultTo, isNil } from 'ramda';

import { formFieldShape, formFieldShapeFor, residenceOptions } from '../../dataModel';
import TwoColumnRow from '../UI/TwoColumnRow/TwoColumnRow';
import Subsection from '../UI/Subsection/Subsection';
import {
  dateInputProps,
  enumTypesToRadioOptions,
  inputProps,
  DateInput,
  Input,
  InputGroup,
  RadioButtonGroup,
} from '../UI/Form/FormComponents';
import i18n from '../../services/i18n';

const ResidencePermit =
({
  residenceInOtherCountry,
  residencePermitNumber,
  residencePermitValidUntil,
  handleChange,
  clearFields,
  oneColumn,
}) => {
  const residenceOptionToValue = (option) => {
    return option === 'OTHER';
  };

  const residenceValueToOption = (value) => {
    if (isNil(value)) return null;
    if (value === true) return 'OTHER';
    return 'CURRENT';
  };

  const shouldHaveResidencePermit = () => {
    return defaultTo(false)(residenceInOtherCountry.value);
  };

  const handleResidenceOptionChange = (event) => {
    const newValue = residenceOptionToValue(event.target.value);

    handleChange({
      target: {
        name: event.target.name,
        value: newValue,
      },
    });

    if (newValue === false) {
      clearFields(['form.residencePermitNumber', 'form.residencePermitValidUntil']);
    }
  };

  const textInputProps = inputProps('text', __, __, __, false, handleChange);
  const dateProps = dateInputProps(__, __, false, false, handleChange);
  const residenceInOtherCountryLabel = `${i18n.t('common:applicationForm.residenceInOtherCountryField.title')} [20]`;
  const residencePermitNumberLabel = i18n.t('common:applicationForm.residencePermitNumber');
  const residencePermitValidUntilLabel = i18n.t('common:applicationForm.residencePermitValidUntil');

  return (
    <TwoColumnRow oneColumn={oneColumn}>
      <>
        <InputGroup
          label={residenceInOtherCountryLabel}
          required
          validationError={residenceInOtherCountry.validationError}
          describedBy="occupationAndResidenceGuide-residenceInOtherCountry"
        >
          <RadioButtonGroup
            name="residenceInOtherCountry"
            value={residenceValueToOption(residenceInOtherCountry.value)}
            onChange={handleResidenceOptionChange}
            options={enumTypesToRadioOptions(residenceOptions)}
            validationError={residenceInOtherCountry.validationError}
            required
          />
        </InputGroup>

        {shouldHaveResidencePermit() && (
        <Subsection>
          <InputGroup
            label={residencePermitNumberLabel}
            validationError={residencePermitNumber.validationError}
            describedBy="occupationAndResidenceGuide-residenceInOtherCountry"
          >
            <Input {...textInputProps(residencePermitNumber.value, 'residencePermitNumber', null)} uppercase />
          </InputGroup>

          <InputGroup
            label={residencePermitValidUntilLabel}
            withHelp={i18n.t('common:dateFormats.inputFormatLongDateDescription')}
            validationError={residencePermitValidUntil.validationError}
            describedBy="occupationAndResidenceGuide-residenceInOtherCountry"
          >
            <DateInput
              {...dateProps(residencePermitValidUntil.value, 'residencePermitValidUntil', null)}
              shortYearInFuture
              useLongDates
              uppercase
            />
          </InputGroup>
        </Subsection>
        )}
      </>
    </TwoColumnRow>
  );
};

ResidencePermit.propTypes = {
  residenceInOtherCountry: formFieldShapeFor(PropTypes.bool).isRequired,
  residencePermitNumber: formFieldShape.isRequired,
  residencePermitValidUntil: formFieldShape.isRequired,
  handleChange: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  oneColumn: PropTypes.bool,
};

ResidencePermit.defaultProps = {
  oneColumn: false,
};

export default ResidencePermit;
