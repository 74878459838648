import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

// TODO: yleistys debouncesta, voisi ottaa propertynä bouncattavat arvot tms
// TODO: proptypet olisi kiva saada määriteltyä jollain kikalla
const debounceEvent = (callback, time = 300) => (...args) => {
  const timeout = setTimeout(callback, time, ...args);
  return () => clearTimeout(timeout);
};

const withDebouncedValue = (WrappedComponent) => {
  class DebouncedValue extends React.Component {
    pendingTimeouts = {};

    state = {
      value: this.props.value,
    };

    componentDidUpdate = (prevProps, prevState) => {
      if (prevProps.value !== this.props.value &&
        this.state === prevState &&
        this.state.value !== this.props.value) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(R.assoc('value', this.props.value));
      }
    };

    appendTimeouts = (event, timeout) => {
      this.pendingTimeouts = R.over(
        R.lensProp(event.target.name),
        R.compose(R.append(timeout), R.defaultTo([])),
        this.pendingTimeouts,
      );
    };

    clearTimeouts = (name) => {
      R.compose(
        R.map(R.call),
        R.defaultTo([]),
        R.prop(name),
      )(this.pendingTimeouts);
      this.pendingTimeouts = R.assoc(name, [], this.pendingTimeouts);
    };

    handleChange = (event) => {
      this.clearTimeouts(event.target.name);
      this.setState(R.assoc('value', event.target.value));
      this.appendTimeouts(event, debounceEvent(this.props.onChange)(event));
    };

    pushChanges = (event) => {
      if (this.props.value !== event.target.value) {
        this.clearTimeouts(event.target.name);
        this.props.onChange(event);
      }
    };

    handleBlur = (event) => {
      this.pushChanges(event);
      if (this.props.onBlur) {
        this.props.onBlur(event);
      }
    };

    handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        this.pushChanges(event);
      }

      if (this.props.onKeyDown) {
        this.props.onKeyDown(event);
      }
    };

    render() {
      return (
        <WrappedComponent
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          ref={this.props.forwardedRef}
          {...R.omit(['value', 'onChange', 'onBlur', 'onKeyDown', 'ref'], this.props)}
        />
      );
    }
  }

  DebouncedValue.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    // Internal property given automatically
    forwardedRef: PropTypes.exact({ current: PropTypes.instanceOf(Element) }),
  };
  DebouncedValue.defaultProps = {
    onBlur: null,
    onKeyDown: null,
    value: '',
    forwardedRef: null,
  };
  return React.forwardRef((props, ref) => <DebouncedValue {...props} forwardedRef={ref} />);
};

export default withDebouncedValue;
