import * as R from 'ramda';

export const currentPageOrFirstPage = (currentPage, lastPage) => R.when(
  R.lt(lastPage),
  R.always(1),
  currentPage,
);

export const splitListToPages = R.curry((rowsOnPage, list) =>
  R.splitEvery(rowsOnPage, list));

export const rowsForCurrentPage = R.curry((rowsOnPage, currentPage, list) => {
  const listSplittedToPages = splitListToPages(rowsOnPage, list);
  return listSplittedToPages[R.dec(currentPage)] || R.head(listSplittedToPages);
});

export const numberOfPagesForList = (rowsOnPage, listLength) => R.compose(
  Math.ceil,
  R.divide,
)(listLength, rowsOnPage);
