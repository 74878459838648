import React, { memo } from 'react';
import i18n from '../../services/i18n';

const PendingGuide = memo(() => {
  return (
    <div lang={i18n.language()}>
      <p>
        <small>{i18n.t('pending.pendingGuide.title')}</small>
      </p>

      <ul>
        <li>
          <small>{i18n.t('pending.pendingGuide.listDescription')}</small>
        </li>
      </ul>
    </div>
  );
});

export default PendingGuide;
