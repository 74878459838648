import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import i18n from '../../../services/i18n';
import HelpText from './HelpText';

import './TextWithHelp.scss';

class TextWithHelp extends React.Component {
  state = {
    helpVisible: false,
  };

  render() {
    return (
      <div className={classnames('text-with-help',
        { 'text-with-help--with-bottom-margin': this.props.withBottomMargin },
        { 'text-with-help--with-top-margin': this.props.withTopMargin })}
      >
        <div className={classnames('text-with-help__upper-content', this.props.contentClassName)}>
          { this.props.text }
          <Button
            icon={<Icon name="help_outline" ariaLabel={i18n.t('common:accessibleIconLabels.help')} />}
            class="invisible"
            className={classnames('text-with-help__button', this.props.buttonClassName)}
            onClick={() =>
              this.setState(prevState => ({
                helpVisible: !prevState.helpVisible,
              }))}
            tabIndex={-1}
          />
        </div>
        <HelpText
          helpId={this.props.helpId}
          helpText={this.props.help}
          helpVisible={this.state.helpVisible}
          helpTextVisibleClassName={this.props.helpTextVisibleClassName}
        />
      </div>
    );
  }
}

TextWithHelp.propTypes = {
  text: PropTypes.node,
  help: PropTypes.node.isRequired,
  helpId: PropTypes.string,
  withBottomMargin: PropTypes.bool,
  withTopMargin: PropTypes.bool,
  buttonClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  helpTextVisibleClassName: PropTypes.string,
};

TextWithHelp.defaultProps = {
  text: null,
  helpId: null,
  withBottomMargin: false,
  withTopMargin: false,
  buttonClassName: null,
  contentClassName: null,
  helpTextVisibleClassName: null,
};

export default TextWithHelp;
