import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { userLoggedInSelector } from '../Main/MainReducer';
import GuidePage from './GuidePage';
import actions from './GuidePageActions';

const mapStateToProps = state => ({
  userLoggedIn: userLoggedInSelector(state),
});

export default R.compose(
  withRouter,
  connect(mapStateToProps, actions),
)(GuidePage);
