import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import ApplicationForm from './ApplicationFormContainer';
import ApplicationFinalizedPage from './ApplicationFinalizedPageContainer';

import paths from '../../paths';

import './Application.scss';

const Application = (props) => {
  if (!props.userLoggedIn) {
    return null;
  }

  return (
    <div className="application" role="main">
      <Switch>
        <Route path={paths.application.completed} component={ApplicationFinalizedPage} />
        <Route path={paths.application.create} component={ApplicationForm} />
        <Route path={paths.application.update} component={ApplicationForm} />
        <Redirect to={paths.root} />
      </Switch>
    </div>);
};

Application.propTypes = {
  userLoggedIn: PropTypes.bool,
};

Application.defaultProps = {
  userLoggedIn: false,
};

export default Application;
