import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ReactPostprocessor from 'i18next-react-postprocessor';
import * as R from 'ramda';

import fiFI from '../languages/fi-FI.json';
import enGB from '../languages/en-GB.json';
import ruRU from '../languages/ru-RU.json';
import thTH from '../languages/th-TH.json';
import idID from '../languages/id-ID.json';
import faIR from '../languages/fa-IR.json';
import frFr from '../languages/fr-FR.json';
import sqAL from '../languages/sq-AL.json';
import arAR from '../languages/ar-AR.json';
import trTR from '../languages/tr-TR.json';
import neNP from '../languages/ne-NP.json';
import viVN from '../languages/vi-VN.json';
import ukUA from '../languages/uk-UA.json';
import zhCN from '../languages/zh-CN.json';
import esES from '../languages/es-ES.json';
import ptPT from '../languages/pt-PT.json';
import grGR from '../languages/gr-GR.json';
import svSE from '../languages/sv-SE.json';

const localI18next = i18next.createInstance();
localI18next.use(LanguageDetector).use(new ReactPostprocessor()).init({
  resources: {
    'fi-FI': fiFI,
    'en-GB': enGB,
    'ru-RU': ruRU,
    'th-TH': thTH,
    'id-ID': idID,
    'fa-IR': faIR,
    'fr-FR': frFr,
    'sq-AL': sqAL,
    'ar-AR': arAR,
    'tr-TR': trTR,
    'ne-NP': neNP,
    'vi-VN': viVN,
    'uk-UA': ukUA,
    'zh-CN': zhCN,
    'es-ES': esES,
    'pt-PT': ptPT,
    'gr-GR': grGR,
    'sv-SE': svSE,
  },
  interpolation: {
    format: (value, format) => {
      if (format === 'uppercase') return value.toUpperCase();
      if (format === 'lowercase') return value.toLowerCase();
      return value;
    },
    skipOnVariables: false,
  },
  initImmediate: true,
  ns: ['common'],
  detection: {
    order: ['localStorage'],
  },
});

const safeReplace = R.curry((pattern, replacement, string) =>
  R.when(R.is(String), R.replace(pattern, replacement))(string));

const safeReplaceUnderscoreWithDash = safeReplace('_', '-'); // fi_FI -> fi-FI for I18next

const t = (keys, options) => {
  return options ?
    localI18next.t(keys, R.evolve({ lng: safeReplaceUnderscoreWithDash })(options)) :
    localI18next.t(keys);
};

const language = () => localI18next.language.substr(0, 2);
const changeLanguage = (newLang, callback) =>
  localI18next.changeLanguage(
    safeReplaceUnderscoreWithDash(newLang),
    callback,
  );

const tBoolean = (bool) => {
  return bool ? t('common:true') : t('common:false');
};

const toFiLocaleStringOrNull = (number, options) => {
  if (!R.is(Number, number)) return null;
  return number.toLocaleString('fi', options);
};

const tDecimalWithFraction = R.curry((number, maxFractions) =>
  toFiLocaleStringOrNull(number, { style: 'decimal', useGrouping: false, maximumFractionDigits: maxFractions }));

const tDecimal = tDecimalWithFraction(R.__, 3);

const tMoney = (number, currency) =>
  toFiLocaleStringOrNull(number, { style: 'currency', currency });

export default {
  localI18next, t, language, changeLanguage, tBoolean, tDecimal, tDecimalWithFraction, tMoney,
};
