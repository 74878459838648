import * as R from 'ramda';

import { handleActions } from 'redux-actions';
import { createSelector } from '../../utils/reducerUtils';
import actions from './sortingActions';
import { sortByFunctionOrColumn } from './sorting';

const initialState = {};

export const getSortingFor = R.compose(R.path, R.insert(1, R.__, ['sorting']));
export const getSortedByFor = R.compose(R.path, R.insert(1, R.__, ['sorting', 'sortedBy']));
export const getSortDescendingFor = R.compose(R.path, R.insert(1, R.__, ['sorting', 'sortDescending']));

export const sortingSelectorFor = listName => createSelector(
  [getSortingFor(listName)],
  R.when(R.isNil, R.always({})),
);

export const sortedListSelectorFor = (listName, listSelector, customSortableColumns) =>
  createSelector(
    [getSortedByFor(listName), getSortDescendingFor(listName), listSelector],
    sortByFunctionOrColumn(customSortableColumns),
  );
export default handleActions({
  [actions.listSortChanged]: (state, { payload }) => R.compose(
    R.assoc(payload.listName, R.__, state),
    R.pick(['sortedBy', 'sortDescending']),
  )(payload),
}, initialState);
