import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { popNotification } from 'visa-frontend-common/src/services/pushNotificationService';
import { locationShape } from 'visa-frontend-common/src/dataModel';
import Loading from '../../components/UI/Loading/LoadingContainer';
import paths from '../../paths';
import Register from '../Register/RegisterContainer';
import ForgotPassword from '../ForgotPassword/ForgotPasswordContainer';
import Application from '../Application/ApplicationContainer';
import FrontPage from '../FrontPage/FrontPageContainer';
import Accessibility from '../Accessibility/Accessibility';
import AccountSettingsContainer from '../AccountSettings/AccountSettingsContainer';
import actions from './MainActions';
import ChecklistPage from '../ChecklistPage/ChecklistPageContainer';
import GuidePage from '../GuidePage/GuidePageContainer';
import Pending from '../Pending/Pending';
import PendingRequest from '../Pending/PendingRequestContainer';

class Main extends React.Component {
  componentDidMount() {
    popNotification();
    this.props.fetchCurrentUser()
      .then(R.unless(R.is(Error), () => {
        this.props.fetchPendingRequests();
      }));
    // TODO: should not 401
    this.props.fetchFeatureFlags();

    if (this.props.location.pathname === paths.logout) {
      this.props.logoutDone();
    }

    this.props.loadCaptcha();
  }

  componentDidUpdate() {
    if (this.props.location.pathname === paths.logout) {
      this.props.logoutDone();
    }
  }

  render() {
    return (
      <Loading requestNames={[actions.featureFlagsFetched().type]}>
        { this.props.featureFlagsFetchIsDone &&
        <Switch>
          <Route
            path={paths.checklist}
            component={ChecklistPage}
          />
          <Route
            path={paths.accessibility.root}
            component={Accessibility}
          />
          <Route
            path={[paths.register.root, paths.register.confirm]}
            component={Register}
          />
          <Route
            path={[paths.forgotPassword.root, paths.forgotPassword.confirm]}
            component={ForgotPassword}
          />
          <Route
            path={paths.application.root}
            component={Application}
          />
          <Route
            path={paths.pending.root}
            component={Pending}
          />
          <Route
            path={paths.pending.view}
            component={PendingRequest}
          />
          <Route
            path={paths.accountSettings.root}
            component={AccountSettingsContainer}
          />
          <Route
            path={paths.guidePage.root}
            component={GuidePage}
          />
          <Route exact path={paths.root} component={FrontPage} />
          <Redirect to={paths.root} />
        </Switch>}
      </Loading>
    );
  }
}

Main.propTypes = {
  fetchCurrentUser: PropTypes.func.isRequired,
  fetchFeatureFlags: PropTypes.func.isRequired,
  fetchPendingRequests: PropTypes.func.isRequired,
  logoutDone: PropTypes.func.isRequired,
  location: locationShape.isRequired,
  featureFlagsFetchIsDone: PropTypes.bool.isRequired,
  loadCaptcha: PropTypes.func.isRequired,
};

export default Main;
