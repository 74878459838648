import * as R from 'ramda';
import { handleActions } from 'redux-actions';

import {
  createSelector,
  fieldSetReducerFor,
  resetReducerFor,
} from 'visa-frontend-common/src/utils/reducerUtils';
import { formFieldDefaultProps } from 'visa-frontend-common/src/dataModel';

import actions from './ForgotPasswordActions';

const initialState = {
  forgotPasswordForm: {
    email: formFieldDefaultProps,
  },
  forgotPasswordConfirmForm: {
    email: formFieldDefaultProps,
    password: formFieldDefaultProps,
    confirmPassword: formFieldDefaultProps,
    confirmationCode: formFieldDefaultProps,
  },
};

const fieldSetReducer = fieldSetReducerFor(initialState);

export const forgotPasswordFormSelector = createSelector([R.path(['forgotPassword', 'forgotPasswordForm'])], R.identity);
export const forgotPasswordConfirmFormSelector = createSelector([R.path(['forgotPassword', 'forgotPasswordConfirmForm'])], R.identity);

const reducer = handleActions({
  [actions.forgotPasswordFormFieldSet]: fieldSetReducer('forgotPasswordForm'),
  [actions.forgotPasswordConfirmFormFieldSet]: fieldSetReducer('forgotPasswordConfirmForm'),
  [actions.forgotPasswordClearFields]: resetReducerFor(initialState),
  [actions.forgotPasswordConfirmClearFields]: resetReducerFor(initialState),
}, initialState);

export default reducer;
