import React from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';
import classnames from 'classnames';

import NotificationIcon from './NotificationIcon';

import './Notification.scss';

export const notificationAutoCloseDelay = 5000;

export const createNotificationTransition = () => cssTransition({
  enter: 'notification--slide-in',
  exit: 'notification--fade-out',
  collapse: false,
});

const Notification = () => {
  return (
    <ToastContainer
      theme="colored"
      icon={NotificationIcon}
      closeButton={false}
      closeOnClick={false}
      className="notification__container"
      toastClassName="notification__toast"
      bodyClassName={classnames('container', 'notification__toast-body')}
      autoClose={notificationAutoCloseDelay}
      draggable={false}
      transition={createNotificationTransition()}
      hideProgressBar
      limit={3}
    />
  );
};

export default Notification;
