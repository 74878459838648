import * as R from 'ramda';
import { handleActions } from 'redux-actions';

import { createSelector } from 'visa-frontend-common/src/utils/reducerUtils';
import { notNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';

import { codesetByGroupSet, codesetByGroupInEnglishSet } from './CodesetActions';
import i18n from '../../../../services/i18n';

const initialState = {
  codesetCodesByGroup: {},
  codesetCodesInEnglishByGroup: {},
};

export const codesetCodesGroupSelector = createSelector([
  (state, group) => group,
  R.path(['codeset', 'codesetCodesByGroup'])], R.prop);

export const codesetCodesInEnglishGroupSelector = createSelector([
  (state, group) => group,
  R.path(['codeset', 'codesetCodesInEnglishByGroup'])], R.prop);

const ovafMultiLanguageCodesetGroups = [
  'Gender',
  'MainPurposesOfJourney',
  'MaritalStatus',
  'NumberOfEntries',
  'Occupation',
  'TravelDocumentType',
];

export const codesetCodesSelector = createSelector([
  (state, group) => group,
  codesetCodesGroupSelector,
  codesetCodesInEnglishGroupSelector,
], (group, codesetCodesInCurrentLanguage, codesetCodesInEnglish) => {
  if (
    notNilOrEmpty(codesetCodesInCurrentLanguage) &&
    notNilOrEmpty(codesetCodesInEnglish) &&
    R.not(R.equals(i18n.language(), 'en')) &&
    R.includes(group, ovafMultiLanguageCodesetGroups)
  ) {
    const codesetCodes = R.map((codesetValue) => {
      const englishText = R.compose(
        R.prop('text'),
        R.find(codesetInEnglishValue =>
          R.eqProps(
            'codesetCode',
            codesetInEnglishValue,
            codesetValue,
          )),
      )(codesetCodesInEnglish);

      return {
        ...codesetValue,
        text: `${englishText} – ${codesetValue.text}`,
      };
    })(codesetCodesInCurrentLanguage);

    return codesetCodes;
  }
  return codesetCodesInCurrentLanguage;
});

const groupByCodesetGroup = R.groupBy(R.prop('codesetGroup'));
const codesetCodesByGroup = (currentCodesetCodesByGroup, codesets) => {
  const newCodesetCodesByGroup = groupByCodesetGroup(codesets);
  const newState = {
    ...currentCodesetCodesByGroup,
    ...newCodesetCodesByGroup,
  };
  return R.equals(currentCodesetCodesByGroup, newState) ? currentCodesetCodesByGroup : newState;
};

export default handleActions({
  [codesetByGroupSet]: (state, action) => {
    if (!action.error) {
      return R.assoc(
        'codesetCodesByGroup',
        codesetCodesByGroup(state.codesetCodesByGroup, action.payload),
        state,
      );
    }
    return state;
  },
  [codesetByGroupInEnglishSet]: (state, action) => {
    if (!action.error) {
      return R.assoc(
        'codesetCodesInEnglishByGroup',
        codesetCodesByGroup(state.codesetCodesInEnglishByGroup, action.payload),
        state,
      );
    }
    return state;
  },
}, initialState);
