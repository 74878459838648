import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

import { dirtyFormsSelector } from '../../services/navigationprompt/navigationPromptReducer';
import { notNilOrEmpty } from '../../utils/commonUtils';
import checkNavigationAttempt from '../../utils/navigationPromptUtils';
import i18n from '../../services/i18n';
import { id } from '../UI/ConfirmationModal/ConfirmationModal';

class SingletonNavigationPrompt extends React.Component {
  componentDidUpdate(prevProps) {
    const prevPropsHasDirtyForm = notNilOrEmpty(prevProps.dirtyForms);
    const propsHasDirtyForm = notNilOrEmpty(this.props.dirtyForms);
    if (!prevPropsHasDirtyForm && propsHasDirtyForm) {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
    }
    if (prevPropsHasDirtyForm && !propsHasDirtyForm) {
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    event.preventDefault();
    /* Starting with Firefox 44, Chrome 51, Opera 38, and Safari 9.1,
    *  a generic string not under the control of the webpage will be shown
    *  instead of the returned string.
    *  https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes
    */
    const promptMessage = i18n.t('common:leavePrompt');
    // eslint-disable-next-line no-param-reassign
    event.returnValue = promptMessage; // Chrome requires returnValue to be set
    return promptMessage;
  };

  render() {
    const hasDirtyForm = notNilOrEmpty(this.props.dirtyForms);
    return (
      <Prompt
        when={hasDirtyForm}
        message={(location, action) => checkNavigationAttempt(
          location,
          action,
          this.props.dirtyForms,
          this.props.alwaysAllowedLocation,
          notNilOrEmpty(document.getElementById(id)),
        )}
      />
    );
  }
}

SingletonNavigationPrompt.propTypes = {
  dirtyForms: PropTypes.arrayOf(PropTypes.exact({
    formName: PropTypes.string.isRequired,
    excludedPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
    isDirty: PropTypes.bool.isRequired,
    promptTitle: PropTypes.string.isRequired,
    promptListLabel: PropTypes.string.isRequired,
  })).isRequired,
  alwaysAllowedLocation: PropTypes.func,
};

SingletonNavigationPrompt.defaultProps = {
  alwaysAllowedLocation: null,
};

export const defaultMapStateToProps = state => ({
  dirtyForms: dirtyFormsSelector(state),
});

export default SingletonNavigationPrompt;
