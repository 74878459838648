import * as R from 'ramda';
import { createAction } from 'redux-actions';

import { loadingActionCreator } from '../services/loading/loadingActions';
import { removeCompletedPostfix } from '../services/loading/loadingUtils';
import { notifyError, notifyInfo } from '../services/pushNotificationService';
import i18n from '../services/i18n';
import getUserConfirmation from '../services/navigationprompt/getUserConfirmation';
import checkNavigationAttempt, { historyAction } from './navigationPromptUtils';

export const apiThunkWithLoading = (apiMethod, resultAction, allowErrorNotifications = true) =>
  (parameter1, ...rest) => (dispatch) => {
    dispatch(loadingActionCreator(resultAction)());
    return apiMethod(parameter1, ...rest)
      .then(R.tap(R.compose(dispatch, resultAction)))
      .catch((result) => {
        if (allowErrorNotifications) {
          const errorNotificationId = removeCompletedPostfix(resultAction().type);
          notifyError(errorNotificationId, i18n.t(result.message));
        }

        return R.tap(R.compose(dispatch, resultAction))(result);
      });
  };

export const createFormArrayUpdateAction = type =>
  createAction(type, (arrayName, index, field) => ({ arrayName, index, field }));

/**
* A thunk to dispatch some main action and navigation action (push) afterwards.
* If there are dirty forms which should block the navigation,
* the user is prompted before any action is dispatched.
*
* If the user accepts the prompt, the main action will be dispatched and navigation afterwards.
* The prompted dirty forms will be also cleared (isDirty is set to false).
*
* If the user does not accept the prompt, no action will be dispatched.
*
* @param push push from connected-react-router
* @param setDirty action to dispatch dirtyForms
* @param dirtyFormsSelector selector function for dirtyForms
* @param mainAction main action to dispatch before navigation
* @param navigateTo pathname where should be navigated after mainAction succeeds
* @param [cancelNotificationMessage] notification message when user does not accept the prompt
*/
export const navigateAfterActionThunkFor = (push, setDirty, dirtyFormsSelector) =>
  (mainAction, navigateTo, cancelNotificationMessage) => (dispatch, getState) => {
    const allowNavigationOrMessage = checkNavigationAttempt(
      { pathname: navigateTo },
      historyAction.PUSH,
      dirtyFormsSelector(getState()),
    );
    const allowNavigation = R.equals(true, allowNavigationOrMessage);

    const dispatchMainAction = () => R.compose(
      R.unless(R.is(Promise), result => Promise.resolve(result)),
      dispatch,
    )(mainAction);
    const dispatchNavigation = () => R.compose(dispatch, push)(navigateTo);
    const dispatchActions = () => dispatchMainAction()
      .then(R.unless(R.is(Error), R.tap(dispatchNavigation)));

    if (!allowNavigation) {
      const message = allowNavigationOrMessage;
      const callback = (continueTransition, prompts) => {
        if (!continueTransition) {
          if (cancelNotificationMessage) notifyInfo('action-cancelled', cancelNotificationMessage);
          return false;
        }

        const dirtyFormsToClear = R.map(R.assoc('isDirty', false))(prompts);
        dispatch(setDirty(dirtyFormsToClear));
        return dispatchActions();
      };
      return getUserConfirmation(message, callback);
    }
    return dispatchActions();
  };

/**
* A thunk to dispatch navigation action (push) and some main action afterwards.
* If there are dirty forms which should block the navigation,
* the user is prompted before any action is dispatched.
*
* If the user accepts the prompt, navigation will be dispatched and the main action afterwards.
* The prompted dirty forms will be also cleared immediately with flushSync
* to prevent consecutive duplicate prompts.
*
* If the user does not accept the prompt, no action will be dispatched.
*
* @param push push from connected-react-router
* @param setDirty action to dispatch dirtyForms
* @param dirtyFormsSelector selector function for dirtyForms
* @param flushSync flushSync from react-dom
* @param mainAction main action to dispatch after navigation
* @param navigateTo pathname where should be navigated
* @param [cancelNotificationMessage] notification message when user does not accept the prompt
*/
export const actionAfterNavigateThunkFor = (push, setDirty, dirtyFormsSelector, flushSync) =>
  (mainAction, navigateTo, cancelNotificationMessage) => (dispatch, getState) => {
    const allowNavigationOrMessage = checkNavigationAttempt(
      { pathname: navigateTo },
      historyAction.PUSH,
      dirtyFormsSelector(getState()),
    );
    const allowNavigation = R.equals(true, allowNavigationOrMessage);

    const dispatchMainAction = () => R.compose(
      R.unless(R.is(Promise), result => Promise.resolve(result)),
      dispatch,
    )(mainAction);
    const dispatchNavigation = () => R.compose(dispatch, push)(navigateTo);
    const dispatchActions = () => {
      flushSync(dispatchNavigation); // flushSync to ensure navigation before main action
      return dispatchMainAction();
    };

    if (!allowNavigation) {
      const message = allowNavigationOrMessage;
      const callback = (continueTransition, prompts) => {
        if (!continueTransition) {
          if (cancelNotificationMessage) notifyInfo('action-cancelled', cancelNotificationMessage);
          return false;
        }

        const dirtyFormsToClear = R.map(R.assoc('isDirty', false))(prompts);
        // flushSync to ensure cleared dirtyForms before any action (navigation)
        flushSync(() => dispatch(setDirty(dirtyFormsToClear)));
        return dispatchActions();
      };
      return getUserConfirmation(message, callback);
    }
    return dispatchActions();
  };

export default apiThunkWithLoading;
