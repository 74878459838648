import React from 'react';
import * as R from 'ramda';
import { confirmAcceptance } from '../confirmationService';
import i18n from '../i18n';
import UserConfirmationLabelList from '../../components/NavigationPrompt/UserConfirmationLabelList';

/**
* A function to use to confirm navigation.
* Mainly used with Router components or with e.g. createBrowserHistory
* https://v5.reactrouter.com/web/api/BrowserRouter/getuserconfirmation-func
* https://github.com/remix-run/history/blob/v4/docs/Blocking.md#customizing-the-confirm-dialog
* but can be also used separately.
*
* @param message stringified prompts
* @param callback function which is called with boolean (to continue/abort) and with list of prompts
* @return {Promise} promise representing callback's return value when continued or Error
*/

const getUserConfirmation = (message, callback) => {
  const { prompts } = JSON.parse(message);

  const ifHasMultipleElse = R.ifElse(R.compose(R.lt(1), R.length));
  const confirmMessage = ifHasMultipleElse(
    R.always(i18n.t('common:leavePrompt')),
    R.compose(R.prop('promptTitle'), R.head),
  )(prompts);
  const modalChildren = ifHasMultipleElse(
    R.compose(labelList => <UserConfirmationLabelList labelList={labelList} />, R.pluck('promptListLabel')),
    R.always(null),
  )(prompts);

  const continueTransition = () => callback(true, prompts);
  const abortTransition = () => callback(false, prompts);

  return confirmAcceptance(confirmMessage, continueTransition, modalChildren)
    .then(R.when(R.is(Error), R.tap(abortTransition)));
};

export default getUserConfirmation;
