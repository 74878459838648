import React, { memo } from 'react';

import GuideSubtitle from '../../../components/Guide/GuideSubtitle';
import i18n from '../../../services/i18n';

const ApplicationDocumentsGuide = memo(() => {
  // Arabic text is currently written in English.
  const lang = i18n.language() === 'ar' ? 'en' : i18n.language();

  return (
    <>
      <GuideSubtitle id="guide-applicationDocuments" type="h3">{i18n.t('application.tabs.applicationDocuments')}</GuideSubtitle>

      <div lang={lang}>
        <p>
          <small>{i18n.t('application.guides.applicationDocumentsGuide.desc')}</small>
        </p>
      </div>
    </>
  );
});

export default ApplicationDocumentsGuide;
