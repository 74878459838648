import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Divider.scss';

const Divider = props =>
  (<hr
    className={classnames(
      'divider',
      { 'divider--full-view-width': props.fullWidth },
      { 'divider--full-width-in-toolkit': props.fullWidthInToolkit },
      { 'divider--with-top-margin': props.withTopMargin },
      { 'divider--with-bottom-margin': props.withBottomMargin },
      props.className,
    )}
  />);

Divider.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullWidthInToolkit: PropTypes.bool,
  withTopMargin: PropTypes.bool,
  withBottomMargin: PropTypes.bool,
};

Divider.defaultProps = {
  className: null,
  fullWidth: false,
  fullWidthInToolkit: false,
  withTopMargin: false,
  withBottomMargin: false,
};

export default Divider;
