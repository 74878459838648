import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { apiThunkWithLoading } from 'visa-frontend-common/src/utils/actionUtils';
import { validatingSetFieldThunk } from 'visa-frontend-common/src/services/validator/validatorActions';
import formValidator from 'visa-frontend-common/src/services/validator/formValidator';
import * as applicationService from '../../services/applicationService';
import * as checklistService from '../../services/checklistService';
import * as pendingRequestService from '../../services/pendingRequestService';
import * as documentService from '../../services/documentService';
import { pendingAnswerType, pendingRequestDocumentStatuses } from '../../ovafModel';
import documentUploadValidationRules from './documentUploadValidationRules';

const pendingRequestsFetched = createAction('PENDING_REQUESTS_FETCH_COMPLETED');
const pendingRequestFetched = createAction('PENDING_REQUEST_FETCH_COMPLETED');
const documentStatisticsFetched = createAction('PENDING_REQUEST_DOCUMENT_STATS_FETCH_COMPLETED');
const pendingRequestAnswered = createAction('PENDING_REQUEST_ANSWER_COMPLETED');
const pendingRequestRead = createAction('PENDING_REQUEST_READ_COMPLETED');
const answerFormFieldSet = createAction('PENDING_REQUEST_ANSWER_FORM_FIELD_SET');
const uploadFormFieldSet = createAction('PENDING_REQUEST_DOCUMENT_UPLOAD_FORM_FIELD_SET');
const documentUploadPreSignCompleted = createAction('PENDING_REQUEST_DOCUMENT_UPLOAD_PRESIGN_COMPLETED');
const documentUploadCompleted = createAction('PENDING_REQUEST_DOCUMENT_UPLOAD_COMPLETED');
const documentUploadSet = createAction('PENDING_REQUEST_DOCUMENT_UPLOAD_SET');
const documentCheckCompleted = createAction('PENDING_REQUEST_DOCUMENT_CHECK_COMPLETED');
const documentDeleteCompleted = createAction('PENDING_REQUEST_DOCUMENT_DELETE_COMPLETED');
const clearFields = createAction('PENDING_REQUEST_CLEAR_FIELDS');
const checklistItemTypesFetched = createAction('PENDING_CHECKLIST_ITEM_TYPES_FETCH_COMPLETED');
const pendingRequestHistoryFetched = createAction('PENDING_REQUEST_HISTORY_FETCH_COMPLETED');

const fetchPendingRequests = apiThunkWithLoading(
  pendingRequestService.getPendingRequests,
  pendingRequestsFetched,
);

const fetchDocumentStatistics = apiThunkWithLoading(
  applicationService.getDocumentStatistics,
  documentStatisticsFetched,
);

const markPendingRequestAsRead = apiThunkWithLoading(
  pendingRequestService.readPendingRequest,
  pendingRequestRead,
);

const fetchPendingStatusHistory = apiThunkWithLoading(
  pendingRequestService.getPendingStatusHistory,
  pendingRequestHistoryFetched,
);

const fetchPendingRequest = pendingId => dispatch => apiThunkWithLoading(
  pendingRequestService.getPendingRequest,
  pendingRequestFetched,
)(pendingId)(dispatch)
  .then(R.unless(R.is(Error), (response) => {
    dispatch(clearFields(['answerForm', 'uploadForm']));
    if (response.unread) {
      dispatch(markPendingRequestAsRead(pendingId));
    }
    return response;
  }));

const fetchChecklistItemTypes = apiThunkWithLoading(
  checklistService.getChecklistItemTypes,
  checklistItemTypesFetched,
);

const answerPendingRequest = (answer, pendingId, setDirty) => (dispatch) => {
  return dispatch(apiThunkWithLoading(
    R.compose(
      R.cond([
        [
          R.equals(pendingAnswerType.ACCEPTED),
          R.always(() => pendingRequestService.acceptPendingRequest(pendingId)),
        ],
        [
          R.equals(pendingAnswerType.DECLINED),
          R.always(() => pendingRequestService.declinePendingRequest(pendingId)),
        ],
        [
          R.equals(pendingAnswerType.READY),
          R.always(() => pendingRequestService.readyPendingRequest(pendingId)),
        ],
      ]),
      R.path(['answer', 'value']),
    )(answer),
    pendingRequestAnswered,
  )(answer))
    .then(R.unless(R.is(Error), () => {
      setDirty(false);
      dispatch(fetchPendingRequests());
      dispatch(fetchPendingRequest(pendingId));
    }));
};

const setUploadFormFieldAndValidate =
  validatingSetFieldThunk(uploadFormFieldSet, documentUploadValidationRules);

const uploadDocumentToS3 = apiThunkWithLoading(
  documentService.uploadDocument,
  documentUploadCompleted,
  false,
);

const checkDocument = (applicationId, pendingRequestId, key) => (dispatch, getState) => {
  return dispatch(apiThunkWithLoading(
    documentService.checkDocument,
    documentCheckCompleted,
  )(applicationId, pendingRequestId, key))
    .then(R.unless(R.is(Error), () => {
      dispatch(
        setUploadFormFieldAndValidate(
          getState().pending.uploadForm,
          getState().pending.uploadForm,
        ),
      );
    }));
};

const addDocument = (applicationId, pendingRequestId, key) => (dispatch) => {
  return dispatch(apiThunkWithLoading(
    documentService.addDocument,
    documentUploadCompleted,
  )(applicationId, pendingRequestId, key))
    .then(R.unless(R.is(Error), () => {
      dispatch(checkDocument(applicationId, pendingRequestId, key));
    }));
};

const uploadDocument = (document, applicationId, pendingRequestId) => (dispatch, getState) => {
  const validatedDocument = R.compose(
    R.over(
      R.lensPath(['documentFiles', 'value']),
      R.filter(R.pathEq(['uid', 'value'], document.uid.value)),
    ),
    R.pick(['documentFiles']),
  )(getState().pending.uploadForm);

  const formHasErrors = R.compose(R.not, R.isEmpty, formValidator.formErrors);
  const currentFileHasErrors = R.compose(
    R.complement(R.isEmpty),
    R.reject(R.isNil),
    R.pluck('validationError'),
    R.path([0, 1, 'value', 0]),
    formValidator.formErrors,
  );

  if (formHasErrors(validatedDocument) && currentFileHasErrors(validatedDocument)) {
    return;
  }
  dispatch(apiThunkWithLoading(
    documentService.fetchPreSignedS3URL,
    documentUploadPreSignCompleted,
  )(applicationId, pendingRequestId, document.uid.value))
    .then(R.unless(R.is(Error), ({ uid, key, url }) => {
      dispatch(uploadDocumentToS3(url, document.file.value))
        .then((result) => {
          const status = R.ifElse(
            R.is(Error),
            R.always(pendingRequestDocumentStatuses.ERROR),
            R.always(''),
          )(result);
          dispatch(documentUploadSet({
            uid,
            key,
            status,
          }));
          dispatch(addDocument(applicationId, pendingRequestId, key));
        });
    }));
};

const uploadDocuments = (documents, applicationId, pendingRequestId) => (dispatch) => {
  return Promise.all(
    R.map(
      document => dispatch(uploadDocument(document, applicationId, pendingRequestId)),
      documents,
    ),
  );
};

const acceptDocuments = (applicationId, pendingRequestId, attachmentKeys) => (dispatch) => {
  return dispatch(apiThunkWithLoading(
    documentService.acceptDocuments,
    pendingRequestAnswered,
  )(applicationId, pendingRequestId, attachmentKeys))
    .then(R.unless(R.is(Error), () => {
      dispatch(fetchPendingRequests());
      dispatch(fetchPendingRequest(pendingRequestId));
      dispatch(fetchDocumentStatistics());
    }));
};

const deleteDocument = apiThunkWithLoading(
  documentService.deleteDocument,
  documentDeleteCompleted,
);

const deleteAllDocuments = apiThunkWithLoading(
  documentService.deleteAllDocuments,
  documentDeleteCompleted,
);
const actions = {
  fetchPendingRequests,
  pendingRequestsFetched,
  fetchPendingRequest,
  pendingRequestFetched,
  fetchDocumentStatistics,
  documentStatisticsFetched,
  answerFormFieldSet,
  clearFields,
  answerPendingRequest,
  pendingRequestAnswered,
  uploadFormFieldSet,
  setUploadFormFieldAndValidate,
  uploadDocuments,
  documentUploadCompleted,
  documentUploadSet,
  documentCheckCompleted,
  acceptDocuments,
  deleteDocument,
  deleteAllDocuments,
  documentDeleteCompleted,
  fetchChecklistItemTypes,
  checklistItemTypesFetched,
  pendingRequestHistoryFetched,
  fetchPendingStatusHistory,
};

export default actions;
