import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  formFieldDefaultProps,
  formFileFieldDefaultProps,
} from 'visa-frontend-common/src/dataModel';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import Form from 'visa-frontend-common/src/components/UI/Form/Form';
import { BooleanCheckboxGroup, FileInput } from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import ValidationError from 'visa-frontend-common/src/components/UI/ValidationError/ValidationError';
import { eventToField, isNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';
import { generateId } from 'visa-frontend-common/src/services/idService';
import formValidator from 'visa-frontend-common/src/services/validator/formValidator';
import { confirmAcceptance } from 'visa-frontend-common/src/services/confirmationService';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import Loading from '../../components/UI/Loading/LoadingContainer';
import { pendingRequestDocumentStatuses, uploadFormShape } from '../../ovafModel';
import i18n from '../../services/i18n';
import actions from './PendingActions';
import DocumentPreview from './DocumentPreview';

import './DocumentUploadForm.scss';

class S3DocumentUploadForm extends React.Component {
  componentDidMount() {
    this.props.deleteAllDocuments(this.props.applicationId, this.props.pendingRequestId);
  }

  handleSelectUploadFile = (event) => {
    const newPhotoFiles = R.map(file => ({
      uid: R.assoc('value', generateId(), formFieldDefaultProps),
      key: formFieldDefaultProps,
      status: formFieldDefaultProps,
      pages: formFieldDefaultProps,
      file: R.assoc('value', file, formFileFieldDefaultProps),
    }))(event.target.files);

    if (!isNilOrEmpty(newPhotoFiles)) {
      const formUpdate = R.over(
        R.lensPath(['documentFiles', 'value']),
        R.concat(R.__, newPhotoFiles),
        this.props.uploadForm,
      );

      this.props.setUploadFormFieldAndValidate(formUpdate, this.props.uploadForm);
      this.props.setDirty(true);

      this.props.uploadDocuments(
        newPhotoFiles,
        this.props.applicationId,
        this.props.pendingRequestId,
      );
    }
  };

  handleRemoveUploadFile = index => () => {
    const removedDocument = R.nth(index, this.props.uploadForm.documentFiles.value);
    this.props.setUploadFormFieldAndValidate(
      { documentFiles: { value: R.remove(index, 1, this.props.uploadForm.documentFiles.value) } },
      this.props.uploadForm,
    );
    this.props.setDirty(true);
    if (removedDocument.key.value) {
      this.props.deleteDocument(
        this.props.applicationId, this.props.pendingRequestId, removedDocument.key.value,
      );
    }
  };

  handleConfirmChange = R.compose(
    () => this.props.setDirty(true),
    this.props.setUploadFormFieldAndValidate,
    eventToField,
  );

  render() {
    const allFilesValid = formValidator.isFormValid(this.props.uploadForm);

    const allFilesAccepted = R.all(
      R.pathEq(['status', 'value'], pendingRequestDocumentStatuses.ACCEPTED),
      this.props.uploadForm.documentFiles.value,
    );

    const handleCancel = R.ifElse(
      R.always(!isNilOrEmpty(this.props.uploadForm.documentFiles.value)),
      () => confirmAcceptance(
        i18n.t('pending.uploadCancelConfirm'),
        this.props.handleCancel,
      ),
      this.props.handleCancel,
    );

    const handleSubmit = () => R.compose(this.props.handleSubmit, R.pluck('value'), R.pluck('key'), R.defaultTo([]))(this.props.uploadForm.documentFiles.value);

    return (
      <Form className="document-upload-form">
        <h3 className="document-upload-form__title">{i18n.t('pending.uploadTitle')}</h3>
        <p>{i18n.t('pending.uploadLargeFileInfo')}</p>
        <FileInput
          className="document-upload-form__file-input"
          name="documentFiles"
          onChange={this.handleSelectUploadFile}
          accept="image/jpeg, image/png, application/pdf"
          dataCy="document-upload-file-select-button"
          buttonDisplayName={i18n.t('pending.uploadSelectButton')}
          multiple
        />

        <ValidationError
          className="document-upload-form__validation-error"
          validationError={this.props.uploadForm.documentFiles.validationError}
        />

        <div className="document-upload-form__document-list">
          {isNilOrEmpty(this.props.uploadForm.documentFiles.value) &&
            <p>{i18n.t('pending.uploadEmpty')}</p>}

          { R.addIndex(R.map)((documentFile, index) => (
            <DocumentPreview
              key={documentFile.uid.value}
              file={documentFile.file}
              status={documentFile.status}
              handleRemove={this.handleRemoveUploadFile(index)}
              disabled={this.props.isUploadInProgress}
            />
          ))(this.props.uploadForm.documentFiles.value)}
        </div>
        <BooleanCheckboxGroup
          label={i18n.t('pending.uploadConfirm', { type: this.props.requestName })}
          onChange={this.handleConfirmChange}
          value={this.props.uploadForm.uploadConfirmed.value}
          name="uploadConfirmed"
          disabled={this.props.isUploadInProgress}
        />

        <Loading
          requestNames={[actions.documentUploadCompleted().type]}
          loadingText={i18n.t('pending.uploadInProgress')}
        />

        <div className="document-upload-form__actions">
          <LoadingButton
            requestNames={[
              actions.documentUploadCompleted().type,
              actions.pendingRequestAnswered().type,
            ]}
            className="document-upload-form__actions__button"
            class="primary"
            icon="check"
            label={i18n.t('pending.uploadSendButton', { count: R.length(this.props.uploadForm.documentFiles.value) })}
            onClick={handleSubmit}
            disabled={
              !this.props.uploadForm.uploadConfirmed.value ||
              isNilOrEmpty(this.props.uploadForm.documentFiles.value) ||
              !allFilesValid ||
              !allFilesAccepted
            }
            dataCy="document-upload-submit-button"
          />
          <Button
            className="document-upload-form__actions__button"
            class="secondary"
            icon="clear"
            label={i18n.t('common:cancel')}
            onClick={handleCancel}
            disabled={this.props.isUploadInProgress}
          />
        </div>
      </Form>
    );
  }
}

S3DocumentUploadForm.propTypes = {
  applicationId: PropTypes.string.isRequired,
  pendingRequestId: PropTypes.string.isRequired,
  requestName: PropTypes.string.isRequired,
  uploadForm: uploadFormShape.isRequired,
  isUploadInProgress: PropTypes.bool.isRequired,
  setUploadFormFieldAndValidate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  deleteAllDocuments: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
};

export default S3DocumentUploadForm;
