import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import Modal from '../Modal/Modal';
import i18n from '../../../services/i18n';

import './UserInfo.scss';

class UserInfo extends React.Component {
  state = {
    modalIsOpen: false,
  };

  showModal = () => this.setState({ modalIsOpen: true });

  hideModal = () => this.setState({ modalIsOpen: false });

  render() {
    return (
      <div className="user-info" data-cy="user-info-container">
        <button
          type="button"
          className={classnames('user-info__button', { 'user-info__button--open': this.state.modalIsOpen })}
          data-cy="user-info-open-button"
          onClick={this.showModal}
          aria-label={i18n.t('common:accessibleIconLabels.userMenu')}
        >
          <div className="user-info__button-content">
            { this.props.buttonContent }
            <Icon name={this.state.modalIsOpen ? 'arrow_drop_up' : 'arrow_drop_down'} />
          </div>
        </button>
        <Modal
          unstyled
          overlayClassName="user-info__modal-overlay"
          contentClassName="container-fluid user-info__modal-content"
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.hideModal}
        >
          <div className="user-info__details" data-cy="user-info-details">
            <div className="container user-info__details-body">
              <ul className="user-info__details-itemlist">
                {
                  this.props.accountInfoItems.map(
                    item => React.cloneElement(item, { hideModal: this.hideModal }),
                  )
                }
              </ul>
              <ul className="user-info__details-itemlist">
                {
                  this.props.linkItems.map(
                    linkItem => React.cloneElement(linkItem, { hideModal: this.hideModal }),
                  )
                }
              </ul>
              { this.props.logoutButton }
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

UserInfo.propTypes = {
  buttonContent: PropTypes.element.isRequired,
  accountInfoItems: PropTypes.arrayOf(PropTypes.element).isRequired,
  linkItems: PropTypes.arrayOf(PropTypes.element),
  logoutButton: PropTypes.element.isRequired,
};

UserInfo.defaultProps = {
  linkItems: [],
};

export default UserInfo;
