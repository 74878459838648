export default `
@page {
  margin: 1cm 0 2.1cm 0;
  size: A4 portrait;

  @bottom-right {
    font-family: Finlandica, Arial, sans-serif;
    content: counter(page) ' / ' counter(pages);
    font-size: 12px;
    padding-right: 2mm;
  }

  @bottom-left {
    content: element(bottom-left)
  }
}

@page:first {
  @bottom-left {
    content: "(1) No logo is required for Norway, Iceland, Liechtenstein and Switzerland.";
    font-family: Arial, sans-serif;
    font-size: 12px;
    padding-left: 1.5mm;
  }
}

body {
  -webkit-print-color-adjust: exact !important;
}

#application {
  margin-left: 1cm;
  margin-right: 0.5cm;
  font-family: Arial, sans-serif;
  font-size: 9pt;
  color: #000000;
}

#application .application__page {
  page-break-after: always;
  page-break-inside: avoid;
}

#application .application__body {
  margin-top: 4mm;
  page-break-before: avoid
}

#application #first-page-form {
  width: 80%
}

#application table.form {
  table-layout: fixed;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 9pt;
  color: #000000;
  width: 100%;
  border: 1.8px solid #000000;
  margin-top: 1mm;
}

#application table.form th {
  font-weight: normal;
  text-align: left;
  vertical-align: top;
  padding-left: 1mm;
  font-style: italic;
}

#application table.form tr.group-header th {
  height: 4mm;
}

#application table.form tr {
  break-inside: avoid;
}

#application table.form tr.group-header {
}

#application table.form td {
  vertical-align: top;
  padding-bottom: 0mm;
  padding-left: 1mm;
  border: none;
  height: 10mm;
}

#application tr.border-bottom-row {
}

#application table.form td.application__marital-status-2nd-col {
  padding-left: 7mm;
}

#application table.form tr.border-bottom-row td {
  border-bottom: 1.8px solid #000000;
}

#application table.form tr.border-bottom-row th {
  border-bottom: 1.8px solid #000000;
}

#application table.form .border-right {
  border-right: 1.8px solid #000000;
}

#application .application__body label {
  display: block;
  font-size: 8pt;
  margin-bottom: 1mm;
  font-style: italic;
}

#application .form-input {
  padding-left: 1mm;
  padding-right: 1mm;
  word-wrap: break-word;
  min-height: 3mm;
}

#application .fine-print {
  border: 1.8px solid #000000;
  padding: 1.5mm;
  font-size: 8pt;
  text-align: justify;
}

#application #side {
  float: right;
  border-top: 1.8px solid #000000;
  margin-top: -0.1mm;  /* To align with table top border */
  width: 20%;
  font-size: 8pt;
}
#application #side * {
  font-style: normal;
}

#application #side label {
  padding-left: 1mm;
}

#application i {
  font-family: FreeSerif, sans-serif;
  margin-right: 0.5mm;
  font-style: normal;
  font-size: 15px;
}

#application span {
  display: inline-block;
}

#application ul {
  list-style-type: none;
  padding: 0;
  padding-left: 1mm;
  margin: 0;
}

#application li {
  height: 18px;
  white-space: nowrap;
}

#application #footnote-mark {
  font-size: 7pt;
  position: absolute;
  top: 3.5cm;
  left: 4.25cm;
}

#application #title {
  position: absolute;
  top: 1.5cm;
  left: 4.9cm;
}

#application #title h3 {
  margin-bottom: 0;
  margin-top: 0;
}

#application #vurn {
  position: absolute;
  top: -2mm;
  left: 1mm;
  text-align: center;
}

#application #eu-logo {
  position: relative;
  top: 9mm;
}

#application .avoid-page-break {
  page-break-inside: avoid;
}

#application #image-placeholder {
  border: 1.8px solid #000000;
  text-align: center;
  position: absolute;
  top: 1mm;
  right: 2mm;
  width: 3.5cm;
  height: 4.5cm;
  line-height: 4.5cm;
  font-size: 7pt;
}

#application .application__header {
  position: relative;
  padding-top: 1mm;
  height: 4.5cm;
  page-break-after: avoid;
}

#application #instructions {
  font-size: 7pt;
  position: absolute;
  bottom: -4mm;
  max-width: 12cm;
}

#application tr#signature {
  height: 3.5cm;
}

#application span.main-purpose-text {
  text-decoration: underline;
}


#checklist {
  margin-left: 0.65cm;
  margin-right: 0.65cm;

  font-family: Finlandica, Arial, sans-serif;
  font-size: 10pt;
  line-height: 13px;
  color: #000000;
}

#checklist i {
  font-family: FreeSerif, sans-serif;
  margin-right: 0.5mm;
  font-style: normal;
  font-size: 12pt;
  margin-bottom: -4px;
}

#checklist span {
  display: inline-block;
}

#checklist h1 {
  font-size: 11pt;
  line-height: 14px;
}

#checklist h2 {
  font-size: 10pt;
  line-height: 14px;
}

#checklist h3 {
  font-size: 10pt;
  line-height: 14px;
}

#checklist #checklist-title {
  margin-bottom: 0px;
}

#checklist #checklist-title h1 {
  display: inline-block;
}

#checklist .applicationId {
  font-weight: bold;
  padding-left: 2mm;
  margin-right: 4px;
}

#checklist .footer {
  position: running(bottom-left);
  display: block;
}

#checklist p {
  white-space: pre-line;
}

#checklist .warning-icon {
  font-family: FreeSerif;
  margin-left: 8px;
  margin-right: 5px;
}

#checklist .use-override-font {
  font-family: Finlandica, Arial, sans-serif;
}

#checklist .checklist-item {
  table-layout: fixed;
  font-size: 10pt;
  width: 100%;
  page-break-inside: avoid;
  border-collapse: collapse;
  border-top: #8f8f8f 1px solid;
  border-bottom: #8f8f8f 1px solid;
}

#checklist .checklist-item:first-child {
  border-top: 0;
}

#checklist .checklist-item-header th {
  font-size: 10pt;
  line-height: 24px;
  text-align: left;
  font-weight: 900;
}

#checklist th {
  border-bottom: #8f8f8f 1px solid;
}

#checklist .checkbox-row {
  line-height: 14px;
  height: 14px;
}

#checklist .checkbox-label {
  font-size: 10pt;
  margin-left: 2px;
  margin-right: 8px;
  vertical-align: middle;
  display: inline;
}

#checklist .attachment-row p {
  word-wrap: break-word;
}

#checklist .attachment-cell {
  padding-right: 4px;
}

#checklist .attachment-cell p {
  word-wrap: break-word;
  margin-top: -1px;
  margin-bottom: 0px;
}

#checklist .signature-layout {
  table-layout: fixed;
  font-size: 10pt;
  width: 100%;
  border-collapse: collapse;
  page-break-inside: avoid;
}

#checklist .signature-line {
  width: 80%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid black;
  margin: 1.1cm 0 0;
  padding: 0;
}

#checklist .pre-line {
  white-space: pre-line;
}

#checklist .vertical-align-top {
  vertical-align: top;
}

#checklist .spacer {
  height: 0.2cm;
}

#checklist .fw-bold {
  font-weight: bold;
}

#checklist .bg-gray {
  background-color: #efefef;
}

#checklist .bg-black {
  background-color: black;
  color: white;
}

#checklist .border-gray-left {
  border-left: #8f8f8f 1px solid;
}

#checklist .line-height-fix-for-chinese {
  line-height: 16px;
}
`;
