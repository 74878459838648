import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import InlineNotification from 'visa-frontend-common/src/components/UI/InlineNotification/InlineNotification';
import Linkify from 'visa-frontend-common/src/components/UI/Linkify/Linkify';
import { notNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import ChecklistItemAttachmentContainer from '../Application/ChecklistItemAttachmentContainer';
import i18n from '../../services/i18n';
import { checklistItemAttachmentShape, staticChecklistShape } from '../../ovafModel';

import actions from './ChecklistPageActions';
import './ChecklistDetails.scss';

class ChecklistDetails extends React.PureComponent {
  componentDidMount() {
    this.props.fetchChecklistItemAttachments(
      this.props.espLocation,
      this.props.checklist.name,
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checklist.checklistId !== this.props.checklist.checklistId) {
      this.props.fetchChecklistItemAttachments(
        this.props.espLocation,
        this.props.checklist.name,
      );
    }
  }

  render() {
    const renderAttachments = (documentType, attachments) => {
      return notNilOrEmpty(attachments) && (
        <ul className="checklist-details__attached-files">
          {R.compose(
            R.map(attachment => (<ChecklistItemAttachmentContainer
              key={attachment.checklistItemAttachmentId}
              attachment={attachment}
            />)),
            R.filter(R.propEq('checklistItemType', documentType)),
          )(attachments)}
        </ul>);
    };

    const renderDocumentList = (documents, attachments) => {
      if (documents.length === 1) {
        return documents.map(document => (
          <Fragment key={document.name}>
            <small>
              <pre className="small checklist-details__document-description">
                <Linkify>{document.description}</Linkify>
              </pre>
            </small>
            {renderAttachments(document.documentType, attachments)}
          </Fragment>
        ));
      }
      return documents.map(document => (
        <Fragment key={document.name}>
          <small>
            <h4 className="checklist-details__document-name small">
              {document.name}
            </h4>
            <pre className="small checklist-details__document-description">
              <Linkify>{document.description}</Linkify>
            </pre>
          </small>
          {renderAttachments(document.documentType, attachments)}
        </Fragment>
      ));
    };

    return (
      <div className="checklist-details">
        <InlineNotification type="info" className="checklist-details__info" disableClose>
          {i18n.t('checklist.printChecklistInfo', { lng: this.props.lang })}
        </InlineNotification>
        <div className="checklist-details__header">
          <h2 className="checklist-details__checklist-name">
            {this.props.checklist.name}
          </h2>
          <LoadingButton
            className="checklist-details__print-checklist-button"
            label={i18n.t('checklist.printChecklistButton', { lng: this.props.lang })}
            icon="print"
            onClick={this.props.printChecklist}
            class="primary"
            requestNames={[
              actions.createDownloadingChecklistPdfBlobAction(
                this.props.checklist.checklistId,
              )().type,
            ]}
            dataCy="print-checklist-button"
          />
        </div>
        {this.props.checklist.documentCategories.map(category => (
          <Fragment key={category.description}>
            <h3 className="checklist-details__document-type">
              {category.description}
            </h3>
            <div className="checklist-details__documents">
              {renderDocumentList(
                R.defaultTo([], category.documents),
                this.props.checklistItemAttachments,
              )}
            </div>
          </Fragment>
        ))}
      </div>
    );
  }
}

ChecklistDetails.propTypes = {
  espLocation: PropTypes.string.isRequired,
  checklist: staticChecklistShape.isRequired,
  checklistItemAttachments: PropTypes.arrayOf(checklistItemAttachmentShape).isRequired,
  fetchChecklistItemAttachments: PropTypes.func.isRequired,
  printChecklist: PropTypes.func.isRequired,
  lang: PropTypes.string,
};

ChecklistDetails.defaultProps = {
  lang: null,
};

export default ChecklistDetails;
