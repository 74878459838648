import * as R from 'ramda';
import i18n from '../../services/i18n';
import {
  pendingAnswerType,
  pendingClosedType,
  pendingRequestReasons,
  pendingRequestSources,
  pendingRequestStatuses,
} from '../../ovafModel';

export const translateChecklistItemType = checklistItemTypes => pendingItemType =>
  R.compose(
    R.prop('attachmentName'),
    R.find(R.propEq('checklistItemType', pendingItemType)),
  )(checklistItemTypes);

export const pendingReasonType = R.cond([
  [R.equals(pendingRequestReasons.APPLICATION_PAYMENT_CORRECTION), R.always('paymentCorrection')],
  [R.either(R.equals(pendingRequestReasons.APPLICATION_FACIAL_IMAGE),
    R.equals(pendingRequestReasons.APPLICATION_FINGERPRINTS)), R.always('biometrics')],
  [R.T, R.always('document')],
]);

export const answerText = (pendingReason, answer, params) =>
  R.ifElse(
    R.equals('biometrics'),
    R.always(i18n.t(`pending.answer.biometrics.${answer}`,
      {
        count: R.ifElse(
          R.equals(pendingRequestReasons.APPLICATION_FACIAL_IMAGE),
          R.always(1),
          R.always(2), // plural
        )(pendingReason),
        reason: R.toLower(i18n.t(`pending.reason.${pendingReason}`)),
        ...params,
      })),
    R.always(i18n.t(`pending.answer.${pendingReasonType(pendingReason)}.${answer}`, params)),
  )(pendingReasonType(pendingReason));

export const instructionsText = (pendingReason, answer) =>
  R.ifElse(
    R.equals('biometrics'),
    R.always(i18n.t(`pending.instructions.biometrics.${answer}`,
      { reason: R.toLower(i18n.t(`pending.reason.${pendingReason}`)) })),
    R.always(i18n.t(`pending.instructions.${pendingReasonType(pendingReason)}.${answer}`)),
  )(pendingReasonType(pendingReason));

export const introText = (pendingReason, deliveryTarget, attachmentsAllowed) =>
  R.ifElse(
    R.equals('biometrics'),
    R.always(i18n.t('pending.instructions.intro.biometrics',
      { reason: R.toLower(i18n.t(`pending.reason.${pendingReason}`)), location: deliveryTarget })),
    R.ifElse(
      R.both(R.equals('document'), R.always(R.not(attachmentsAllowed))),
      R.always(i18n.t('pending.instructions.intro.physicalDelivery', { location: deliveryTarget })),
      R.always(i18n.t(`pending.instructions.intro.${pendingReasonType(pendingReason)}`, { location: deliveryTarget })),
    ),
  )(pendingReasonType(pendingReason));

export const warningText = (pendingReason, params) =>
  R.ifElse(
    R.equals('biometrics'),
    R.always(i18n.t('pending.instructions.warning.biometrics',
      { reason: R.toLower(i18n.t(`pending.reason.${pendingReason}`)), params })),
    R.always(i18n.t(`pending.instructions.warning.${pendingReasonType(pendingReason)}`, params)),
  )(pendingReasonType(pendingReason));

export const deliveryText = (pendingReason, attachmentsAllowed, deliveryTarget) =>
  R.cond([
    [R.equals('biometrics'),
      R.always(i18n.t('pending.attachmentUploadInfo.biometrics',
        { location: deliveryTarget, type: R.toLower(i18n.t(`pending.reason.${pendingReason}`)) }))],
    [R.equals('paymentCorrection'),
      R.always(i18n.t('pending.attachmentUploadInfo.paymentCorrection',
        { location: deliveryTarget }))],
    [R.equals('document'),
      R.ifElse(
        R.always(attachmentsAllowed),
        R.always(i18n.t('pending.attachmentUploadInfo.attachmentsAllowed', { location: deliveryTarget })),
        R.always(i18n.t('pending.attachmentUploadInfo.physicalDelivery', { location: deliveryTarget, type: 'todo' })),
      )]])(pendingReasonType(pendingReason));

export const isClosedOutsideOvaf =
  R.either(
    R.propSatisfies(
      R.includes(R.__, R.keys(pendingClosedType)),
      'status',
    ),
    R.both(
      R.propEq('status', pendingRequestStatuses.READY),
      R.compose(
        R.not,
        R.propEq('source', pendingRequestSources.OVAF),
      ),
    ),
  );

export const isAnsweredInOvaf =
  R.both(
    R.propSatisfies(
      R.includes(R.__, R.keys(pendingAnswerType)),
      'status',
    ),
    R.propEq('source', pendingRequestSources.OVAF),
  );
