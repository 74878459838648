import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../services/i18n';
import InlineNotification from '../UI/InlineNotification/InlineNotification';

/**
 * Boundary for displaying error instead of content when error occurs. Catches errors from
 * React components and their children.
 *
 * Supports optional text prop that can be used to display custom error message.
 */
class ErrorBoundary extends React.Component {
  state = { error: null };

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { children, text } = this.props;

    if (error) {
      return (
        <>
          <InlineNotification type="warning" disableClose>
            {text || i18n.t('common:errors.unexpectedFrontendError')}
          </InlineNotification>
          <a href="/">{i18n.t('common:returnToMainPage')}</a>
        </>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  text: PropTypes.string,
};

ErrorBoundary.defaultProps = {
  text: null,
};

export default ErrorBoundary;
