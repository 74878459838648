import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import {
  createSelector,
  fieldSetReducerFor,
  fieldSetWithErrorReducerFor,
} from 'visa-frontend-common/src/utils/reducerUtils';
import { formFieldDefaultProps, formFieldDefaultValue } from 'visa-frontend-common/src/dataModel';
import { applicationStatus } from '../../ovafModel';
import actions from './FrontPageActions';

const initialState = {
  showFrontPageGuide: true,
  applications: [],
  applicationGroups: [],
  applicationGroupForm: {
    applicationId: formFieldDefaultProps,
    groupId: formFieldDefaultProps,
  },
  applicationCopyForm: {
    applicationId: formFieldDefaultProps,
    groupId: formFieldDefaultProps,
    details: formFieldDefaultValue([]),
  },
};

export const showFrontPageGuideSelector = createSelector(
  [R.path(['frontpage', 'showFrontPageGuide'])],
  R.identity,
);
export const draftApplicationsSelector = createSelector(
  [R.path(['frontpage', 'applications'])],
  R.filter(R.propEq('status', applicationStatus.DRAFT)),
);
export const sentApplicationsSelector = createSelector(
  [R.path(['frontpage', 'applications'])],
  R.filter(R.propEq('status', applicationStatus.DONE)),
);
export const inProcessingApplicationsSelector = createSelector(
  [R.path(['frontpage', 'applications'])],
  R.filter(R.propEq('status', applicationStatus.IN_PROCESSING)),
);

export const applicationGroupsSelector = createSelector(
  [R.path(['frontpage', 'applicationGroups'])],
  R.identity,
);
export const draftApplicationGroupsSelector = createSelector(
  [R.path(['frontpage', 'applicationGroups'])],
  R.filter(R.propEq('status', applicationStatus.DRAFT)),
);
export const sentApplicationGroupsSelector = createSelector(
  [R.path(['frontpage', 'applicationGroups'])],
  R.filter(R.propEq('status', applicationStatus.DONE)),
);
export const inProcessingApplicationGroupsSelector = createSelector(
  [R.path(['frontpage', 'applicationGroups'])],
  R.filter(R.propEq('status', applicationStatus.IN_PROCESSING)),
);
export const unfinishedApplicationGroupsSelector = createSelector(
  [R.path(['frontpage', 'applicationGroups'])],
  R.filter(
    R.propSatisfies(R.includes(R.__, [applicationStatus.DRAFT, applicationStatus.DONE]), 'status'),
  ),
);

export const applicationGroupFormSelector = createSelector(
  [R.path(['frontpage', 'applicationGroupForm'])],
  R.identity,
);

export const applicationCopyFormSelector = createSelector(
  [R.path(['frontpage', 'applicationCopyForm'])],
  R.identity,
);

const fieldSetReducer = fieldSetReducerFor(initialState);
const fieldSetWithErrorReducer = fieldSetWithErrorReducerFor(initialState);

const reducer = handleActions({
  [actions.applicationsFetched]: (state, action) =>
    fieldSetWithErrorReducer('applications', state.applications)(state, action),
  [actions.applicationGroupsFetched]: (state, action) =>
    fieldSetWithErrorReducer('applicationGroups', state.applicationGroups)(state, action),
  [actions.showFrontPageGuideSet]: (state) => {
    return {
      ...state,
      showFrontPageGuide: false,
    };
  },
  [actions.applicationGroupFormFieldSet]: fieldSetReducer('applicationGroupForm'),
  [actions.applicationGroupFormCleared]: (state) => {
    return {
      ...state,
      applicationGroupForm: initialState.applicationGroupForm,
    };
  },
  [actions.applicationCopyFormFieldSet]: fieldSetReducer('applicationCopyForm'),
  [actions.applicationCopyFormCleared]: (state) => {
    return {
      ...state,
      applicationCopyForm: initialState.applicationCopyForm,
    };
  },
}, initialState);

export default reducer;
