import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { toStringOrNull } from '../../../../utils/commonUtils';
import RadioButtonGroup from './RadioButtonGroup';

export const OTHER = 'OTHER';

const getSelectedOptionFromOptions = (value, options) => {
  return R.isNil(value) ?
    '' :
    R.compose(
      R.defaultTo(OTHER),
      R.prop('value'),
      R.find(R.propEq('value', value)),
    )(options);
};

class RadioButtonGroupWithOtherValueInput extends React.Component {
  state = {
    selectedOption: getSelectedOptionFromOptions(
      this.props.radioButtonGroupProps.value,
      this.props.radioButtonGroupProps.options,
    ),
    selectedOptionChanged: false,
  };

  optionFieldName = `${this.props.radioButtonGroupProps.name}Option`;

  otherFieldName = `${React.Children.only(this.props.children).props.name}Other`;

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!R.equals(nextProps.radioButtonGroupProps.value, prevState.selectedOption) &&
    !prevState.selectedOptionChanged) {
      const newSelectedOptions = getSelectedOptionFromOptions(
        nextProps.radioButtonGroupProps.value,
        nextProps.radioButtonGroupProps.options,
      );

      return { ...prevState, selectedOption: newSelectedOptions };
    }
    return null;
  }

  handleChange = (event) => {
    const currentOption = this.state.selectedOption;

    const optionEvent = R.evolve({
      target: { name: R.always(this.props.radioButtonGroupProps.name) },
    }, event);

    if (event.target.value !== OTHER) {
      this.props.onChange(optionEvent);

      if (currentOption === OTHER && this.props.onChangeFromOther) {
        this.props.onChangeFromOther(optionEvent);
      }
    } else if (this.props.onChangeToOther) {
      this.props.onChangeToOther(optionEvent);
    }

    // Sets selectedOption and selectedOptionChanged to mark that user interaction has occured
    this.setState({ selectedOption: event.target.value, selectedOptionChanged: true });
  };

  handleOtherChange = (event) => {
    const OtherInput = React.Children.only(this.props.children);
    OtherInput.props.onChange(R.evolve({
      target: { name: R.always(OtherInput.props.name) },
    }, event));
  };

  showOptionOtherEditor = () => {
    return this.state.selectedOption === OTHER;
  };

  // If the property requestedOptionValue is given (that is, we want to render the
  // option with "requested value" in different way than the other radiobuttons), we have to make
  // sure, that the given value is linked to "OTHER" -option in the case, where its
  // value is not among the other selectable options. For example, if the options available are
  // "30", "90" and "OTHER" - and the given value is "50", we render the option "OTHER"
  // in a different way.
  handleRequestedOptionValue = () => {
    if (!this.props.radioButtonGroupProps.requestedOptionValue) {
      return null;
    }

    const requestedValueIsAmongAvailableOptions = R.find((option) => {
      return option.value === toStringOrNull(this.props.radioButtonGroupProps.requestedOptionValue);
    }, this.props.radioButtonGroupProps.options);

    if (requestedValueIsAmongAvailableOptions) {
      return toStringOrNull(this.props.radioButtonGroupProps.requestedOptionValue);
    }

    return OTHER;
  };

  handleRequestedOptionLabelPostfix = () => {
    if (!this.handleRequestedOptionValue()) {
      return null;
    }

    if (this.handleRequestedOptionValue() === OTHER) {
      return this.props.radioButtonGroupProps.requestedOptionLabelOtherPostfix;
    }

    return this.props.radioButtonGroupProps.requestedOptionLabelPostfix;
  };

  render() {
    const OtherInput = React.Children.only(this.props.children);

    return (
      <>
        <RadioButtonGroup
          {...R.mergeRight(
            R.omit(['requestedOptionLabelOtherPostfix'], this.props.radioButtonGroupProps),
            {
              requestedOptionValue: this.handleRequestedOptionValue(),
              requestedOptionLabelPostfix: this.handleRequestedOptionLabelPostfix(),
            },
          )}
          name={this.optionFieldName}
          value={this.state.selectedOption}
          onChange={this.handleChange}
          invalid={this.props.invalid}
          required={this.props.required}
        />
        {
          this.showOptionOtherEditor() &&
          React.cloneElement(OtherInput, {
            name: this.otherFieldName,
            onChange: this.handleOtherChange,
            invalid: this.props.invalid,
          })
        }
      </>
    );
  }
}

RadioButtonGroupWithOtherValueInput.propTypes = {
  radioButtonGroupProps: PropTypes.exact(
    R.assoc('requestedOptionLabelOtherPostfix', PropTypes.string, RadioButtonGroup.propTypes),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onChangeToOther: PropTypes.func,
  onChangeFromOther: PropTypes.func,
  invalid: PropTypes.bool,
  required: PropTypes.bool,
};

RadioButtonGroupWithOtherValueInput.defaultProps = {
  onChangeToOther: null,
  onChangeFromOther: null,
  invalid: false,
  required: false,
};

export default RadioButtonGroupWithOtherValueInput;
