import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import {
  answeredPendingRequestsSelector,
  checklistItemTypesSelector,
  closedPendingRequestsSelector,
  documentStatisticsSelector,
  openPendingRequestsSelector,
} from './PendingReducer';
import actions from './PendingActions';
import PendingRequests from './PendingRequests';

const mapStateToProps = state => ({
  openRequests: openPendingRequestsSelector(state),
  answeredRequests: answeredPendingRequestsSelector(state),
  closedRequests: closedPendingRequestsSelector(state),
  documentCounts: documentStatisticsSelector(state),
  checklistItemTypes: checklistItemTypesSelector(state),
});

const mapDispatchToProps = actions;

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PendingRequests);
