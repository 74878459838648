import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const DocumentTitle = (props) => {
  return (
    <Helmet>
      <title>{props.children ? `Visa Application - ${props.children}` : 'Finland Visa Application'}</title>
    </Helmet>
  );
};

DocumentTitle.propTypes = {
  children: PropTypes.string,
};

DocumentTitle.defaultProps = {
  children: null,
};

export default DocumentTitle;
