import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Button from '../../Button/Button';
import { SimpleInput as Input } from '../Input/Input';
import i18n from '../../../../services/i18n';
import { isNilOrEmpty, nameToFormPath, notNilOrEmpty } from '../../../../utils/commonUtils';
import { formFieldDefaultValue, formFieldShape } from '../../../../dataModel';

import './ListField.scss';

class ListField extends React.Component {
  tabPressed = false;

  state = {
    listHeight: 0,
  };

  listRef = React.createRef();

  inputRef = React.createRef();

  componentDidMount() {
    this.updateListHeight();
  }

  componentDidUpdate(prevProps) {
    if (this.props.values.length !== prevProps.values.length) {
      this.updateListHeight();
    }
  }

  updateListHeight = () => {
    this.setState({
      listHeight: this.listRef.current ?
        this.listRef.current.getBoundingClientRect().height :
        0,
    });
    if (this.tabPressed) {
      this.tabPressed = false;
      this.inputRef.current.focus();
    }
  };

  toField = (name, value) => R.assocPath(
    nameToFormPath(name),
    formFieldDefaultValue(value),
    {},
  );

  handleAddItem = () => {
    if (this.props.inputField.validationError || !this.props.inputField.value) return;

    const newItems = R.append(this.props.inputField.value.trim(), this.props.values);

    this.props.updateListField(
      this.toField(this.props.name, newItems),
      this.toField(this.props.inputName, null),
    );

    setTimeout(this.updateListHeight, 100);
  };

  handleRemoveItem = (itemToBeRemoved) => {
    const newItems = this.props.values.filter((item) => {
      return item !== itemToBeRemoved;
    });

    this.props.updateListField(
      this.toField(this.props.name, newItems),
      this.toField(this.props.inputName, this.props.inputField.value),
    );

    setTimeout(this.updateListHeight, 100);
  };

  handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleAddItem();
    } else if (event.key === 'Tab') {
      this.tabPressed = true;
    }
  };

  render() {
    const idOrName = this.props.id || this.props.name;
    return (
      <>
        <div className="list-field__expanding-wrapper" style={{ height: this.state.listHeight }}>
          <ul ref={this.listRef}>
            {this.props.values.length > 0 && (
              this.props.values.map((item, index) => {
                const key = this.props.name + index;

                return (
                  <li key={key}>
                    <small>{item}</small>
                    <Button
                      type="button"
                      onClick={() => this.handleRemoveItem(item)}
                      class="invisible"
                      icon="close"
                      dataCy={`${idOrName}-list-field-remove-button-for-${item}`}
                      disabled={this.props.disabled}
                    />
                  </li>
                );
              })
            )}
          </ul>
        </div>

        <Input
          id={this.props.id || this.props.inputName}
          type="text"
          ref={this.inputRef}
          name={this.props.inputName}
          value={this.props.inputField.value}
          onChange={this.props.handleInputFieldChange}
          onBlur={this.handleAddItem}
          onKeyDown={this.handleOnKeyDown}
          uppercase={this.props.uppercase}
          required={this.props.required && !this.props.values.length}
          describedBy={this.props.describedBy}
          ariaLabel={this.props.label}
          invalid={this.props.invalid}
          disabled={this.props.disabled}
        />
        <Button
          type="button"
          label={i18n.t('common:add')}
          onClick={this.handleAddItem}
          icon="add"
          class="invisible"
          dataCy={`${idOrName}-list-field-add-button`}
          disabled={isNilOrEmpty(this.props.inputField.value) ||
            notNilOrEmpty(this.props.inputField.validationError)}
        />
      </>
    );
  }
}

ListField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  inputName: PropTypes.string.isRequired,
  inputField: formFieldShape.isRequired,
  handleInputFieldChange: PropTypes.func.isRequired,
  updateListField: PropTypes.func.isRequired,
  uppercase: PropTypes.bool,
  required: PropTypes.bool,
  describedBy: PropTypes.string,
  label: PropTypes.string,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
};

ListField.defaultProps = {
  id: null,
  values: [],
  uppercase: false,
  required: false,
  describedBy: null,
  label: null,
  invalid: false,
  disabled: false,
};

export default ListField;
