import { connect } from 'react-redux';

import actions from './ApplicationActions';
import ApplicationForm from './ApplicationForm';
import {
  formSelector,
  formTabsSelector,
  idFromPathSelector,
  applicationFetchWasSuccessfulSelector,
  validationErrorNumberSelector,
  checklistsSelector,
  applicationChecklistItemsSelector,
  checklistCountrySpecificInstructionsSelector,
  checklistItemAttachmentsSelector,
} from './ApplicationReducer';
import withNavigationPrompt from '../../components/NavigationPrompt/NavigationPrompt';
import paths from '../../paths';

const mapStateToProps = state => ({
  form: formSelector(state),
  formTabs: formTabsSelector(state),
  pathId: idFromPathSelector(state),
  applicationFetchWasSuccessful: applicationFetchWasSuccessfulSelector(state),
  validationErrorNumber: validationErrorNumberSelector(state),
  checklists: checklistsSelector(state),
  applicationChecklistItems: applicationChecklistItemsSelector(state),
  checklistCountrySpecificInstructions: checklistCountrySpecificInstructionsSelector(state),
  checklistItemAttachments: checklistItemAttachmentsSelector(state),
});

const mapDispatchToProps = { ...actions };

const formName = 'applicationForm';

export default connect(mapStateToProps, mapDispatchToProps)(
  withNavigationPrompt(
    ApplicationForm,
    formName,
    [paths.application.create, paths.application.update],
  ),
);
