import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../../Icon/Icon';
import { SimpleInput as Input } from '../Input/Input';
import i18n from '../../../../services/i18n';

import './FileInput.scss';

/*
  The label is styled as a button and used for selecting the file.
  The actual <input type="file"> is hidden as it's difficult to customize.
*/
const FileInput = props => (
  <div className={classnames('file-input', props.className)}>
    <label
      htmlFor={props.name}
      className={classnames(
        'button-invisible',
        'file-input__label',
        { 'file-input__label--disabled': props.disabled },
      )}
    >
      <div className="file-input__label-content">
        <Icon name={props.icon} />
        <span className="file-input__label-span">{props.buttonDisplayName}</span>
      </div>
      <Input
        id={props.name}
        dataCy={props.dataCy}
        type="file"
        className="file-input__input"
        value="" // This property is read-only in <input type="file">, because of security reasons.
        name={props.name}
        describedBy={props.describedBy}
        onChange={props.onChange}
        disabled={props.disabled}
        accept={props.accept}
        autoFocus={props.autoFocus}
        multiple={props.multiple}
      />
    </label>
    { props.showValue && <span className="file-input__value">{props.value}</span> }
  </div>
);

FileInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  describedBy: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  showValue: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string,
  buttonDisplayName: PropTypes.string,
  dataCy: PropTypes.string,
  multiple: PropTypes.bool,
};

FileInput.defaultProps = {
  value: '',
  describedBy: '',
  disabled: false,
  accept: '',
  showValue: false,
  autoFocus: false,
  className: null,
  icon: 'cloud_upload',
  buttonDisplayName: i18n.t('common:selectFile'),
  dataCy: null,
  multiple: false,
};

export default FileInput;
