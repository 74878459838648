import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TabSubtitle.scss';

const TabSubtitle = (props) => {
  const classNames = classnames(
    'tab-subtitle',
    { 'tab-subtitle--line-through': props.lineThrough },
    props.className,
  );

  if (props.type === 'h2') return <h2 id={props.id} className={classNames} data-cy={props.dataCy}>{props.children}</h2>;
  if (props.type === 'h3') return <h3 id={props.id} className={classNames} data-cy={props.dataCy}>{props.children}</h3>;
  return <h4 id={props.id} className={classNames} data-cy={props.dataCy}>{props.children}</h4>;
};

TabSubtitle.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['h2', 'h3', 'h4']),
  lineThrough: PropTypes.bool,
  dataCy: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
};

TabSubtitle.defaultProps = {
  type: 'h3',
  lineThrough: false,
  dataCy: null,
  className: null,
  id: null,
};

export default TabSubtitle;
