import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import { InputGroup, Selector } from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import { eventToField, isNilOrEmpty, transformEventValueBeforeHandle } from 'visa-frontend-common/src/utils/commonUtils';
import { fullName } from 'visa-frontend-common/src/utils/dataModelUtils';
import Form from 'visa-frontend-common/src/components/UI/Form/Form';

import { applicationGroupFormShape, applicationGroupShape } from '../../ovafModel';
import i18n from '../../services/i18n';

import './ApplicationGroupAddForm.scss';

const ApplicationGroupAddForm = (props) => {
  const separator = '|SEPARATOR|';
  const applicantName = application =>
    (application.firstName || application.surname ?
      fullName(application.surname, application.firstName) :
      i18n.t('common.missingNamePlaceholder'));
  const labelForGroup = group =>
    R.concat(
      `${i18n.t(
        'applicationGroups.selectLabel',
        { count: R.length(group.applications) },
      )}${separator}`,
      R.join(
        ', ',
        R.map(
          application => applicantName(application),
        )(group.applications),
      ),
    );

  const formatOptionLabel = ({ label }) => {
    const [title, description] = label.split(separator);
    return (
      <>
        <div><strong>{title}</strong></div>
        <span>{description}</span>
      </>
    );
  };

  const options = R.map(group => ({
    value: R.toString(group.groupId),
    label: labelForGroup(group),
  }))(props.groups);

  const onChange = transformEventValueBeforeHandle(
    R.compose(props.onChange, eventToField),
    parseInt,
  );

  return (
    <Form className="application-group-add-form">
      <InputGroup
        required
        validationError={props.form.groupId.validationError}
      >
        <Selector
          name="groupId"
          value={R.unless(isNilOrEmpty, R.toString)(props.form.groupId.value)}
          onChange={onChange}
          options={options}
          formatOptionLabel={formatOptionLabel}
        />
      </InputGroup>
      <div>
        <Button
          className="application-group-add-form__button"
          label={i18n.t('applicationGroups.linkSelectedApplication')}
          icon="check"
          onClick={() => props.onSubmit(props.form)}
          dataCy="add-application-to-group"
        />
        <Button
          class="secondary"
          className="application-group-add-form__button"
          label={i18n.t('common:cancel')}
          icon="clear"
          onClick={props.onCancel}
          dataCy="cancel-create-application-group"
        />
      </div>
    </Form>
  );
};

ApplicationGroupAddForm.propTypes = {
  groups: PropTypes.arrayOf(applicationGroupShape).isRequired,
  form: applicationGroupFormShape.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ApplicationGroupAddForm;
