import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import {
  CheckboxGroup,
  InputGroup,
  Selector,
} from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import Form from 'visa-frontend-common/src/components/UI/Form/Form';
import { eventToField, isNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';
import { fullName } from 'visa-frontend-common/src/utils/dataModelUtils';

import {
  applicationCopyFormShape,
  applicationDetail,
  applicationListItemShape,
  applicationStatus,
} from '../../ovafModel';
import i18n from '../../services/i18n';
import LoadingButton from '../UI/Button/LoadingButtonContainer';
import actions from '../../pages/FrontPage/FrontPageActions';

import './ApplicationCopyForm.scss';

const ApplicationCopyForm = (props) => {
  const applicantName = application =>
    (application.firstName || application.surname ?
      fullName(application.surname, application.firstName) :
      i18n.t('common.missingNamePlaceholder'));

  const options = R.compose(
    R.map(application => ({
      value: application.applicationId,
      label: applicantName(application),
    })),
    R.filter(
      R.propSatisfies(R.includes(R.__, [applicationStatus.DRAFT, applicationStatus.DONE]), 'status'),
    ),
  )(props.applications);

  const copyOptions = R.compose(
    R.map(key => ({
      keyProp: key,
      value: key,
      label: i18n.t(`applicationCopy.options.${key}`),
    })),
    R.keys,
  )(applicationDetail);
  const handleChange = event =>
    props.onChange(eventToField(event), props.form);

  return (
    <Form className="application-copy-form">
      <div className="row">
        <div className="col-12 col-lg-6">
          <InputGroup
            required
            validationError={props.form.applicationId.validationError}
            label={i18n.t('applicationCopy.application')}
          >
            <Selector
              name="applicationId"
              value={props.form.applicationId.value}
              onChange={handleChange}
              options={options}
            />
          </InputGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <InputGroup
            required
            validationError={props.form.details.validationError}
            label={i18n.t('applicationCopy.details')}
          >
            <CheckboxGroup
              name="details"
              value={props.form.details.value}
              onChange={handleChange}
              options={copyOptions}
              required
            />
          </InputGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <LoadingButton
            requestNames={[actions.applicationCopyFetched().type]}
            className="application-copy-form__button"
            label={i18n.t('applicationCopy.startFromTemplate')}
            icon="arrow_forward"
            onClick={() => props.submitForm(props.form)}
            disabled={isNilOrEmpty(props.form.applicationId.value)}
            dataCy="copy-application-to-group"
          />
          <Button
            class="secondary"
            className="application-copy-form__button"
            label={i18n.t('common:cancel')}
            icon="clear"
            onClick={props.clearForm}
            dataCy="cancel-copy-application"
          />
        </div>
      </div>
    </Form>
  );
};

ApplicationCopyForm.propTypes = {
  applications: PropTypes.arrayOf(applicationListItemShape).isRequired,
  form: applicationCopyFormShape.isRequired,
  onChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
};

export default ApplicationCopyForm;
