import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { confirmDeletion } from 'visa-frontend-common/src/services/confirmationService';
import { historyShape } from 'visa-frontend-common/src/dataModel';
import { notNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';
import Divider from 'visa-frontend-common/src/components/UI/Divider/Divider';

import i18n from '../../services/i18n';
import actions from './FrontPageActions';
import Loading from '../../components/UI/Loading/LoadingContainer';
import {
  applicationCopyFormShape,
  applicationGroupFormShape,
  applicationGroupShape,
  applicationListItemShape,
  applicationStatus,
} from '../../ovafModel';
import ApplicationListItem from '../../components/ApplicationListItem/ApplicationListItem';
import ApplicationDraftListItem from '../../components/ApplicationListItem/ApplicationDraftListItem';
import ApplicationInProcessingListItem from '../../components/ApplicationListItem/ApplicationInProcessingListItem';
import ApplicationGroupListItem from '../../components/ApplicationGroupListItem/ApplicationGroupListItem';
import IndividualApplicationGroupListItem
  from '../../components/ApplicationGroupListItem/IndividualApplicationGroupListItem';

import './FrontPageApplicationLists.scss';

const ApplicationList = (props) => {
  return (
    <>
      <h2 className="front-page-application-lists__title">{props.title}</h2>
      <Loading requestNames={[
        actions.applicationGroupsFetched().type,
        actions.applicationsFetched().type]}
      >
        <div className={props.className} data-cy={props.dataCy}>
          <div className="row">
            {
              props.applications.map(application => (
                <IndividualApplicationGroupListItem
                  key={application.applicationId}
                  application={application}
                  renderItem={props.renderApplication()}
                />
              ))
            }
            {
              R.isEmpty(props.applications) &&
              R.all(R.propSatisfies(R.isEmpty, 'applications'), props.applicationGroups) &&
              <div className="col-12 col-md-6">
                {i18n.t('applicationList.empty')}
              </div>
            }
          </div>
          <div className="row">
            {props.applicationGroups.map(group => (
              <ApplicationGroupListItem
                key={group.groupId}
                group={group}
                applicationCopyForm={props.applicationCopyForm}
                renderItem={props.renderApplication(group.groupId)}
                createApplicationInGroup={() => props.createApplicationInGroup(group.groupId)}
                deleteApplicationGroup={() => props.deleteApplicationGroup(group.groupId)}
                setFormField={props.setFormField}
                clearForm={props.clearForm}
              />
            ))}
          </div>
        </div>
      </Loading>
    </>
  );
};

ApplicationList.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  applications: PropTypes.arrayOf(applicationListItemShape).isRequired,
  applicationGroups: PropTypes.arrayOf(applicationGroupShape).isRequired,
  renderApplication: PropTypes.func.isRequired,
  deleteApplicationGroup: PropTypes.func.isRequired,
  createApplicationInGroup: PropTypes.func.isRequired,
  setFormField: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  applicationCopyForm: applicationCopyFormShape.isRequired,
  dataCy: PropTypes.string.isRequired,
};

class FrontPageApplicationLists extends React.Component {
  componentDidMount() {
    this.props.fetchApplicationGroups();
    this.props.fetchApplications();
  }

  handleDeleteApplication = applicationId => confirmDeletion(
    i18n.t('frontPage.confirmDelete'),
    () => this.props.deleteApplication(applicationId),
  );

  handleRemoveApplicationFromGroup = (groupId, applicationId) => confirmDeletion(
    i18n.t('applicationGroups.removeApplicationFromGroupConfirm'),
    () => this.props.removeApplicationFromGroup(groupId, applicationId),
  );

  openAddApplicationToGroupForm = (applicationId) => {
    this.props.setFormField({
      target: {
        name: 'applicationId',
        value: applicationId,
      },
    });
  };

  render() {
    const hasApplicationGroups = R.converge(
      (a, b, c) => R.any(notNilOrEmpty, [a, b, c]),
      [
        R.prop('draftApplicationGroups'),
        R.prop('sentApplicationGroups'),
        R.prop('inProcessingApplicationGroups'),
      ],
    )(this.props);

    const renderDraftApplication = groupId => (application) => {
      const addApplicationToGroup = !groupId && hasApplicationGroups ?
        this.openAddApplicationToGroupForm : null;
      const removeApplicationFromGroup = groupId &&
        R.curryN(2, this.handleRemoveApplicationFromGroup)(R.__, groupId);

      return (
        <ApplicationDraftListItem
          applicationId={application.applicationId}
          surname={application.surname}
          firstName={application.firstName}
          modifiedAt={application.modifiedAt}
          groupId={application.groupId}
          history={this.props.history}
          deleteApplication={this.handleDeleteApplication}
          addApplicationToGroup={addApplicationToGroup}
          removeApplicationFromGroup={removeApplicationFromGroup}
          isFormOpen={
            R.pathEq(['applicationId', 'value'], application.applicationId)(this.props.applicationGroupForm)
          }
        />
      );
    };

    const renderSentApplication = groupId => (application) => {
      const printApplication = application.espLocation === 'IRN' ?
        this.props.printApplicationInBrowser :
        this.props.printApplication;
      const addApplicationToGroup = !groupId && hasApplicationGroups ?
        this.openAddApplicationToGroupForm : null;
      const removeApplicationFromGroup = groupId &&
        R.curryN(2, this.handleRemoveApplicationFromGroup)(R.__, groupId);

      return (
        <ApplicationListItem
          applicationId={application.applicationId}
          surname={application.surname}
          firstName={application.firstName}
          finalizedAt={application.finalizedAt}
          printedAt={application.printedAt}
          printApplication={printApplication}
          addApplicationToGroup={addApplicationToGroup}
          removeApplicationFromGroup={removeApplicationFromGroup}
          isFormOpen={
            R.pathEq(['applicationId', 'value'], application.applicationId)(this.props.applicationGroupForm)
          }
          requestNames={[
            actions.createDownloadingApplicationPdfBlobAction(
              application.applicationId,
            )().type,
          ]}
        />
      );
    };

    const renderInProcessingApplication = () => (application) => {
      const printApplication = application.espLocation === 'IRN' ?
        this.props.printApplicationInBrowser :
        this.props.printApplication;

      return (
        <ApplicationInProcessingListItem
          applicationId={application.applicationId}
          surname={application.surname}
          firstName={application.firstName}
          finalizedAt={application.finalizedAt}
          printedAt={application.printedAt}
          printApplication={printApplication}
          requestNames={[
            actions.createDownloadingApplicationPdfBlobAction(
              application.applicationId,
            )().type,
          ]}
        />
      );
    };

    const renderApplication = (groupId = null) => application =>
      R.cond([
        [R.propEq('status', applicationStatus.DRAFT), renderDraftApplication(groupId)],
        [R.propEq('status', applicationStatus.DONE), renderSentApplication(groupId)],
        [R.propEq('status', applicationStatus.IN_PROCESSING), renderInProcessingApplication(groupId)],
      ])(application);

    return (
      <>
        <ApplicationList
          title={i18n.t('frontPage.unfinishedApplicationsTitle')}
          className="front-page-application-lists"
          applications={this.props.draftApplications}
          applicationGroups={this.props.draftApplicationGroups}
          renderApplication={renderApplication}
          createApplicationInGroup={this.props.createApplicationInGroup}
          deleteApplicationGroup={this.props.deleteApplicationGroup}
          setFormField={this.props.setApplicationCopyFormField}
          clearForm={this.props.clearAddApplicationForm}
          applicationCopyForm={this.props.applicationCopyForm}
          dataCy="front-page-applications-draft"
        />

        <Divider fullWidth />

        <ApplicationList
          title={i18n.t('frontPage.sentApplicationsTitle')}
          className="front-page-application-lists"
          applications={this.props.sentApplications}
          applicationGroups={this.props.sentApplicationGroups}
          renderApplication={renderApplication}
          createApplicationInGroup={this.props.createApplicationInGroup}
          deleteApplicationGroup={this.props.deleteApplicationGroup}
          setFormField={this.props.setApplicationCopyFormField}
          clearForm={this.props.clearAddApplicationForm}
          applicationCopyForm={this.props.applicationCopyForm}
          dataCy="front-page-applications-sent"
        />

        <Divider fullWidth />

        <ApplicationList
          title={i18n.t('frontPage.inProcessingApplicationsTitle')}
          className="front-page-application-lists"
          applications={this.props.inProcessingApplications}
          applicationGroups={this.props.inProcessingApplicationGroups}
          renderApplication={renderApplication}
          createApplicationInGroup={this.props.createApplicationInGroup}
          deleteApplicationGroup={this.props.deleteApplicationGroup}
          setFormField={this.props.setApplicationCopyFormField}
          clearForm={this.props.clearAddApplicationForm}
          applicationCopyForm={this.props.applicationCopyForm}
          dataCy="front-page-applications-in-processing"
        />
      </>
    );
  }
}

FrontPageApplicationLists.propTypes = {
  history: historyShape.isRequired,
  draftApplications: PropTypes.arrayOf(applicationListItemShape),
  sentApplications: PropTypes.arrayOf(applicationListItemShape),
  inProcessingApplications: PropTypes.arrayOf(applicationListItemShape),
  draftApplicationGroups: PropTypes.arrayOf(applicationGroupShape),
  sentApplicationGroups: PropTypes.arrayOf(applicationGroupShape),
  inProcessingApplicationGroups: PropTypes.arrayOf(applicationGroupShape),
  fetchApplications: PropTypes.func.isRequired,
  fetchApplicationGroups: PropTypes.func.isRequired,
  printApplication: PropTypes.func.isRequired,
  printApplicationInBrowser: PropTypes.func.isRequired,
  deleteApplication: PropTypes.func.isRequired,
  removeApplicationFromGroup: PropTypes.func.isRequired,
  deleteApplicationGroup: PropTypes.func.isRequired,
  setFormField: PropTypes.func.isRequired,
  setApplicationCopyFormField: PropTypes.func.isRequired,
  clearAddApplicationForm: PropTypes.func.isRequired,
  createApplicationInGroup: PropTypes.func.isRequired,
  applicationGroupForm: applicationGroupFormShape.isRequired,
  applicationCopyForm: applicationCopyFormShape.isRequired,
};

FrontPageApplicationLists.defaultProps = {
  draftApplications: [],
  sentApplications: [],
  inProcessingApplications: [],
  draftApplicationGroups: [],
  sentApplicationGroups: [],
  inProcessingApplicationGroups: [],
};

export default FrontPageApplicationLists;
