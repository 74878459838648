import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  InputGroup,
  Input,
  inputProps,
  DateInput,
  dateInputProps,
} from '../UI/Form/FormComponents';
import TwoColumnRow from '../UI/TwoColumnRow/TwoColumnRow';
import { formFieldShape } from '../../dataModel';
import i18n from '../../services/i18n';

const TravelDocuments = (props) => {
  const CodesetSelectContainer = props.codesetSelectContainer;
  const textInputProps = inputProps('text', R.__, R.__, null, false, props.handleInput);
  const dateProps = dateInputProps(R.__, R.__, false, false, props.handleInput);
  const travelDocumentTypeLabel = `${i18n.t('common:applicationForm.travelDocumentType')} [12]`;
  const travelDocumentTypeSpecifyLabel = `${i18n.t('common:applicationForm.travelDocumentTypeSpecify')} [12]`;
  const travelDocumentNumberLabel = `${i18n.t('common:applicationForm.travelDocumentNumber')} [13]`;
  const travelDocumentIssueDateLabel = `${i18n.t('common:applicationForm.travelDocumentIssueDate')} [14]`;
  const travelDocumentValidUntilLabel = `${i18n.t('common:applicationForm.travelDocumentValidUntil')} [15]`;
  const travelDocumentIssuedByLabel = i18n.t('common:applicationForm.travelDocumentIssuedBy');
  const travelDocumentCountryIssuedLabel = `${i18n.t('common:applicationForm.travelDocumentCountryIssued')} [16]`;

  return (
    <div role="form" aria-labelledby="applicationForm-travelDocumentsSubtitle">
      <TwoColumnRow oneColumn={props.oneColumn}>
        <>
          <InputGroup
            label={travelDocumentTypeLabel}
            required
            validationError={props.travelDocumentType.validationError}
            describedBy="travelDocumentsGuide-travelDocumentType"
          >
            <CodesetSelectContainer
              name="travelDocumentType"
              value={props.travelDocumentType.value}
              codesetGroup="TravelDocumentType"
              onChange={props.handleInput}
              lang={i18n.language()}
            />
          </InputGroup>

          {props.travelDocumentType.value === 'Other' && (
            <InputGroup
              label={travelDocumentTypeSpecifyLabel}
              required
              validationError={props.travelDocumentTypeSpecify.validationError}
            >
              <Input
                {...textInputProps(
                  props.travelDocumentTypeSpecify.value,
                  'travelDocumentTypeSpecify',
                  null,
                )}
                uppercase
                required
              />
            </InputGroup>
          )}
        </>
        <InputGroup
          label={travelDocumentNumberLabel}
          required
          validationError={props.travelDocumentNumber.validationError}
          describedBy="travelDocumentsGuide-travelDocumentNumber"
        >
          <Input
            {...textInputProps(
              props.travelDocumentNumber.value,
              'travelDocumentNumber',
              null,
            )}
            uppercase
            required
          />
        </InputGroup>
      </TwoColumnRow>

      <TwoColumnRow oneColumn={props.oneColumn}>
        <InputGroup
          label={travelDocumentIssueDateLabel}
          required
          withHelp={i18n.t('common:dateFormats.inputFormatLongDateDescription')}
          validationError={props.travelDocumentIssueDate.validationError}
          describedBy="travelDocumentsGuide-travelDocumentIssueDate"
        >
          <DateInput
            {...dateProps(
              props.travelDocumentIssueDate.value,
              'travelDocumentIssueDate',
            )}
            useLongDates
            required
          />
        </InputGroup>
        <InputGroup
          label={travelDocumentValidUntilLabel}
          required
          withHelp={i18n.t('common:dateFormats.inputFormatLongDateDescription')}
          validationError={props.travelDocumentValidUntil.validationError}
          describedBy="travelDocumentsGuide-travelDocumentValidUntil"
        >
          <DateInput
            {...dateProps(
              props.travelDocumentValidUntil.value,
              'travelDocumentValidUntil',
            )}
            shortYearInFuture
            useLongDates
            required
          />
        </InputGroup>
      </TwoColumnRow>

      <TwoColumnRow oneColumn={props.oneColumn}>
        { props.travelDocumentIssuedBy &&
          <InputGroup
            label={travelDocumentIssuedByLabel}
            validationError={props.travelDocumentIssuedBy.validationError}
          >
            <Input
              {...textInputProps(
                props.travelDocumentIssuedBy.value,
                'travelDocumentIssuedBy',
                null,
              )}
              uppercase
            />
          </InputGroup>}
        <InputGroup
          label={travelDocumentCountryIssuedLabel}
          required
          validationError={props.travelDocumentCountryIssued.validationError}
          describedBy="travelDocumentsGuide-travelDocumentCountryIssued"
        >
          <CodesetSelectContainer
            name="travelDocumentCountryIssued"
            value={props.travelDocumentCountryIssued.value}
            codesetGroup="CountryOfNationality"
            onChange={props.handleInput}
          />
        </InputGroup>
      </TwoColumnRow>
    </div>
  );
};

TravelDocuments.propTypes = {
  handleInput: PropTypes.func.isRequired,
  travelDocumentType: formFieldShape.isRequired,
  travelDocumentTypeSpecify: formFieldShape.isRequired,
  travelDocumentNumber: formFieldShape.isRequired,
  travelDocumentIssueDate: formFieldShape.isRequired,
  travelDocumentValidUntil: formFieldShape.isRequired,
  travelDocumentIssuedBy: formFieldShape,
  travelDocumentCountryIssued: formFieldShape.isRequired,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
};

TravelDocuments.defaultProps = {
  oneColumn: false,
  travelDocumentIssuedBy: null,
};

export default TravelDocuments;
