import moment from 'moment';
import i18n from '../services/i18n';
import { dateParseFormatJson, pastYearParser } from '../services/datetime';

export const aurnPatterns = {
  aurn: '^([A-Z]{4})/([0-9]+)/([0-9]+)/([0-9]+)$',
  aurnV2: '^([A-Z]{4})/([0-9]{6})/([0-9]{4})/([0-9]{2})$',
};

export const aurnDateFormat = 'DDMMYY';

const aurnDate = (aurn) => {
  moment.parseTwoDigitYear = pastYearParser;
  if (aurn) {
    const matches = aurn.match(aurnPatterns.aurnV2);
    if (matches) {
      const dateValue = matches[2];
      const date = moment(dateValue, aurnDateFormat, true);
      if (date.isValid()) {
        return date;
      }
    }
  }
  return null;
};

export const aurnJsonDateStr = (aurn) => {
  const date = aurnDate(aurn);
  if (date) {
    return date.format(dateParseFormatJson);
  }
  return null;
};

export const aurnDisplayDate = (aurn) => {
  const date = aurnDate(aurn);
  if (date) {
    return date.format(i18n.t('common:dateFormats.displayFormat'));
  }
  return null;
};
