import { createAction } from 'redux-actions';

const listSortChanged = createAction(
  'LIST_SORT_CHANGED',
  (listName, sortedBy, sortDescending) => ({ listName, sortedBy, sortDescending }),
);

const actions = { listSortChanged };

export default actions;
