import http from 'visa-frontend-common/src/services/http';

const baseUrl = '/api/applications';

export const fetchPreSignedS3URL = (applicationId, pendingRequestId, uid) => {
  return http.fetchAsJSON(
    `${baseUrl}/${applicationId}/documents/pre-sign`,
    { method: 'POST', body: { pendingRequestId, uid } },
  );
};

export const uploadDocument = (url, file) => {
  return http.fetchAsJSON(
    url,
    {
      method: 'PUT',
      headers: { 'Content-Type': file.type },
      body: new Blob([file]),
    },
  );
};

export const addDocument = (applicationId, pendingRequestId, key) => {
  return http.fetchAsJSON(
    `${baseUrl}/${applicationId}/documents`,
    { method: 'POST', body: { pendingRequestId, key } },
  );
};

export const checkDocument = (applicationId, pendingRequestId, key) => {
  return http.fetchAsJSON(
    `${baseUrl}/${applicationId}/documents/check`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: { pendingRequestId, key },
    },
  );
};

export const acceptDocuments = (applicationId, pendingRequestId, attachmentKeys) => {
  return http.fetchAsJSON(
    `${baseUrl}/${applicationId}/documents/accept`,
    { method: 'POST', body: { pendingRequestId, keys: attachmentKeys } },
  );
};

export const deleteDocument = (applicationId, pendingRequestId, key) => {
  return http.fetchAsJSON(
    `${baseUrl}/${applicationId}/documents`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: { pendingRequestId, key },
    },
  );
};

export const deleteAllDocuments = (applicationId, pendingRequestId) => {
  return http.fetchAsJSON(
    `${baseUrl}/${applicationId}/documents`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: { pendingRequestId },
    },
  );
};
