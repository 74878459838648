import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import DateInput from '../DateInput/DateInput';
import DatePicker from './DatePicker';

import './DateInputWithPicker.scss';

const DateInputWithPicker = (props) => {
  return (
    <div className={classnames('date-input-with-picker', props.className)}>
      <DateInput {...props} />
      <DatePicker
        className="date-input-with-picker__picker"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        tabIndex={props.tabIndex}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </div>
  );
};

const DateInputWithPickerShape = {
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  describedBy: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  shortYearInFuture: PropTypes.bool,
  invalid: PropTypes.bool,
  useLongDates: PropTypes.bool,
  placeholderDate: PropTypes.string,
  format: PropTypes.string,
  dataCy: PropTypes.string,
  tabIndex: PropTypes.number,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
};

DateInputWithPicker.propTypes = DateInputWithPickerShape;

const DateInputWithPickerDefaultProps = {
  id: null,
  value: '',
  describedBy: '',
  required: false,
  className: '',
  onChange: null,
  disabled: false,
  shortYearInFuture: false,
  invalid: false,
  useLongDates: false,
  placeholderDate: null,
  format: null,
  dataCy: null,
  tabIndex: 0,
  minDate: null,
  maxDate: null,
};

DateInputWithPicker.defaultProps = DateInputWithPickerDefaultProps;

export default DateInputWithPicker;
