import React from 'react';
import PropTypes from 'prop-types';

import './RequiredText.scss';

const RequiredText = ({
  text,
  isRequired,
}) => {
  const requiredMark = isRequired ? (<span className="required-text"> *</span>) : '';

  return (
    <>
      {text}
      {requiredMark}
    </>
  );
};

RequiredText.propTypes = {
  text: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

RequiredText.defaultProps = {
  isRequired: true,
};

export default RequiredText;
