import * as R from 'ramda';
import { generatePath, matchPath } from 'react-router-dom';
import { notNilOrEmpty } from './commonUtils';

const generateQueryParamsToBaseUrl = R.curry((basePath, queryParams) => {
  return R.ifElse(
    R.isNil,
    R.always(basePath),
    R.compose(R.concat(basePath), R.concat('?'), pairs => new URLSearchParams(pairs).toString()),
  )(queryParams);
});

const generatePathWithQueryParams = R.curry((path, pathParams, queryParams) => {
  const basePath = generatePath(path, pathParams);
  return generateQueryParamsToBaseUrl(basePath, queryParams);
});

const searchToQueryParams = search => Object.fromEntries(new URLSearchParams(search));

const urlWithQueryParam = (location, paramName, paramValue) => R.compose(
  generateQueryParamsToBaseUrl(location.pathname),
  R.assoc(paramName, paramValue),
  searchToQueryParams,
)(location.search);

const urlWithoutQueryParams = (location, paramNames) => R.compose(
  generateQueryParamsToBaseUrl(location.pathname),
  R.omit(paramNames),
  searchToQueryParams,
)(location.search);

const matchesPathList = R.curry((list, pathName) => R.any(R.complement(R.isNil), R.map(
  path => matchPath(pathName, { path, exact: true }),
  list,
)));

const getPathnameFromPath = R.compose(R.head, R.split('?'));
const getQueryParamsFromPath = R.compose(
  searchToQueryParams,
  R.ifElse(R.compose(R.lt(1), R.length), R.last, R.always('')),
  R.split('?'),
);

const matchesPathname = R.curry((location, path) => R.compose(
  notNilOrEmpty,
  pathname => matchPath(location.pathname, {
    path: pathname,
    exact: true,
  }),
  getPathnameFromPath,
)(path));

const containsQueryParams = R.curry((location, path) => R.compose(
  R.whereEq(R.__, searchToQueryParams(location.search)),
  getQueryParamsFromPath,
)(path));

export {
  generatePathWithQueryParams,
  generateQueryParamsToBaseUrl,
  matchesPathList,
  searchToQueryParams,
  urlWithQueryParam,
  urlWithoutQueryParams,
  getPathnameFromPath,
  getQueryParamsFromPath,
  matchesPathname,
  containsQueryParams,
};
