import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';

import Button from '../../Button/Button';
import Selector from '../Selector/Selector';

const EnumListSelector = (props) => {
  return (
    <Selector
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.handleChange}
      disabled={props.disabled}
      options={
        R.differenceWith((o, v) => o.value === v, props.options, props.values)
      }
      className={classnames(
        'list-field__selector',
        {
          'list-field__selector--disabled': props.disabled,
        },
      )}
      inTableRow={props.inTableRow}
      invalid={props.invalid}
      describedBy={props.describedBy}
      required={props.required}
    />
  );
};

EnumListSelector.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.exact({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.string,
  inTableRow: PropTypes.bool,
  invalid: PropTypes.bool,
  describedBy: PropTypes.string,
  required: PropTypes.bool,
};

EnumListSelector.defaultProps = {
  values: [],
  options: [],
  disabled: false,
  id: null,
  value: '',
  inTableRow: false,
  invalid: false,
  describedBy: null,
  required: false,
};

class EnumListField extends React.Component {
  state = {
    newItem: {
      value: '',
      label: '',
    },
  };

  handleAddItem = (event) => {
    const targetItem = {
      value: event.target.value,
      label: R.prop('label', this.props.options.find(o => o.value === event.target.value)),
    };

    if (!targetItem || !targetItem.value ||
        this.props.options.includes(targetItem.value) ||
        targetItem.value.trim() === '') {
      return;
    }

    const newItems = R.append(targetItem.value, this.props.values);

    this.props.handleChange({
      target: {
        name: this.props.name,
        value: newItems,
      },
    });

    this.setState({
      newItem: {
        value: '',
        label: '',
      },
    });
  };

  handleRemoveItem = (itemToBeRemoved) => {
    const newItems = this.props.values.filter((item) => {
      return item !== itemToBeRemoved.value;
    });

    this.props.handleChange({
      target: {
        name: this.props.name,
        value: newItems,
      },
    });

    this.setState({
      newItem: {
        value: '',
        label: '',
      },
    });
  };

  render() {
    const addFieldName = `${this.props.name}New`;

    return (
      <>
        { this.props.showSelectedItemsBelowDropdown && (
          <EnumListSelector
            id={this.props.id}
            name={addFieldName}
            value={this.state.newItem.value}
            handleChange={this.handleAddItem}
            disabled={this.props.disabled}
            options={this.props.options}
            values={this.props.values}
            inTableRow={this.props.inTableRow}
            invalid={this.props.invalid}
            describedBy={this.props.describedBy}
            required={this.props.required}
          />)}

        { this.props.values.length > 0 && (
          <ul>
            {
              this.props.values.map((item, index) => {
                const option = this.props.options.find(o => o.value === item);
                const key = this.props.name + index;

                return (
                  option ?
                    <li key={key} className="list-field__item">
                      <small>{option.label}</small>
                      <Button
                        type="button"
                        onClick={() => this.handleRemoveItem(option)}
                        class="invisible"
                        icon="close"
                        disabled={this.props.disabled}
                        className={classnames(
                          'list-field__item-remove-button',
                          {
                            'list-field__item-remove-button--disabled': this.props.disabled,
                          },
                        )}
                        dataCy={`${this.props.name}-list-field-remove-button-for-${item}`}
                      />
                    </li> : null
                );
              })
            }
          </ul>
        )}

        { !this.props.showSelectedItemsBelowDropdown && (
          <EnumListSelector
            id={this.props.id}
            name={addFieldName}
            value={this.state.newItem.value}
            handleChange={this.handleAddItem}
            disabled={this.props.disabled}
            options={this.props.options}
            values={this.props.values}
            inTableRow={this.props.inTableRow}
            invalid={this.props.invalid}
            describedBy={this.props.describedBy}
            required={this.props.required}
          />)}
      </>
    );
  }
}

EnumListField.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.exact({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inTableRow: PropTypes.bool,
  invalid: PropTypes.bool,
  describedBy: PropTypes.string,
  required: PropTypes.bool,
  showSelectedItemsBelowDropdown: PropTypes.bool,
};

EnumListField.defaultProps = {
  values: [],
  options: [],
  disabled: false,
  id: null,
  inTableRow: false,
  invalid: false,
  describedBy: null,
  required: false,
  showSelectedItemsBelowDropdown: false,
};

export default EnumListField;
