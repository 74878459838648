import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { formFieldShapeFor } from 'visa-frontend-common/src/dataModel';
import {
  booleanToOption,
  optionToBoolean,
  singleBooleanOption,
  transformEventValueBeforeHandle,
} from 'visa-frontend-common/src/utils/commonUtils';
import Label from 'visa-frontend-common/src/components/UI/Label/Label';
import TwoColumnRow from 'visa-frontend-common/src/components/UI/TwoColumnRow/TwoColumnRow';
import Subsection from 'visa-frontend-common/src/components/UI/Subsection/Subsection';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import {
  CheckboxGroup,
  CheckboxGroupWithOtherValueInput,
  InnerInput,
  inputProps,
  InputGroup,
} from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import ValidationError from 'visa-frontend-common/src/components/UI/ValidationError/ValidationError';
import RequiredText from 'visa-frontend-common/src/components/UI/RequiredText/RequiredText';

import {
  travelCostsApplicantValues,
  travelCostsApplicantInUseOption,
  travelCostsApplicantOptions,
  travelCostsSponsorInUseOption,
  travelCostsSponsorValues,
  travelCostsSponsorOptions,
} from './TravelCostsDataModel';
import i18n from '../../../services/i18n';

import './TravelCostsField.scss';

const TravelCostsField = ({
  travelCostsApplicantInUse,
  travelCostsApplicant,
  travelCostApplicantOther,
  travelCostsSponsorInUse,
  sponsorHost,
  sponsorHostSpecify,
  sponsorOther,
  sponsorOtherSpecify,
  travelCostsSponsor,
  travelCostSponsorOther,
  handleChange,
  clearFields,
  oneColumn,
  isRequired,
  uppercase,
}) => {
  const handleTopLevelCheckboxChangeFor = usage => (event) => {
    transformEventValueBeforeHandle(handleChange, optionToBoolean)(event);

    // Always reset the sub-fields when the "main level" checkbox is clicked
    if (usage === 'travelCostsApplicantInUse') {
      clearFields(['form.travelCostsApplicant', 'form.travelCostApplicantOther']);
      // To fix the validation error bug in UV-5096
      if (!travelCostsSponsorInUse.value) {
        clearFields(['form.travelCostsSponsorInUse']);
      }
    } else if (usage === 'travelCostsSponsorInUse') {
      clearFields(['form.sponsorHost', 'form.sponsorHostSpecify', 'form.sponsorOther', 'form.sponsorOtherSpecify', 'form.travelCostsSponsor', 'form.travelCostSponsorOther']);
      // To fix the validation error bug in UV-5096
      if (!travelCostsApplicantInUse.value) {
        clearFields(['form.travelCostsApplicantInUse']);
      }
    }
  };

  const travelCostsLabel =
    <RequiredText
      text={`${i18n.t('common:applicationForm.travelCosts.title')} [32]`}
      isRequired={isRequired}
    />;

  const textInputProps = inputProps('text', R.__, R.__, R.__, false, handleChange);

  return (
    <div role="form" aria-labelledby="applicationForm-travelCosts-title">
      <TabSubtitle id="applicationForm-travelCosts-title" type="h4">{travelCostsLabel}</TabSubtitle>
      <ValidationError
        validationError={
          travelCostsApplicantInUse.validationError || travelCostsSponsorInUse.validationError
        }
      />

      <TwoColumnRow oneColumn={oneColumn}>
        <>
          <div className="travelcosts-field__input-group">
            <CheckboxGroup
              name="travelCostsApplicantInUse"
              value={booleanToOption(travelCostsApplicantInUse.value)}
              options={travelCostsApplicantInUseOption}
              onChange={handleTopLevelCheckboxChangeFor('travelCostsApplicantInUse')}
              describedBy="contactsAndAccomodationGuide-contactInfoGuideForField32"
            />
          </div>
          <div className="travelcosts-field__chrome-fix" />

          {
            travelCostsApplicantInUse.value &&
            <Subsection withoutTopMargin>
              <Label className="input-group__label" label={i18n.t('common:applicationForm.meansOfSupport')} />
              <InputGroup
                validationError={
                  travelCostsApplicant.validationError ||
                  travelCostApplicantOther.validationError
                }
                className="travelcosts-field__input-group"
              >
                <CheckboxGroupWithOtherValueInput
                  checkboxGroupProps={{
                    name: 'travelCostsApplicant',
                    value: travelCostsApplicant.value,
                    options: travelCostsApplicantOptions,
                  }}
                  otherFieldValue={travelCostsApplicantValues.OTHER}
                  onChange={handleChange}
                  onClickOther={() => clearFields(['form.travelCostApplicantOther'])}
                  required
                >
                  <InnerInput
                    {...textInputProps(travelCostApplicantOther.value, 'travelCostApplicantOther', null)}
                    className={travelCostApplicantOther.validationError ? 'input--invalid' : null}
                    uppercase={uppercase}
                    required
                  />
                </CheckboxGroupWithOtherValueInput>
              </InputGroup>
            </Subsection>
          }

          <div className="travelcosts-field__input-group">
            <CheckboxGroup
              name="travelCostsSponsorInUse"
              value={booleanToOption(travelCostsSponsorInUse.value)}
              options={travelCostsSponsorInUseOption}
              onChange={handleTopLevelCheckboxChangeFor('travelCostsSponsorInUse')}
            />
          </div>
          <div className="travelcosts-field__chrome-fix" />

          {
            travelCostsSponsorInUse.value &&
            <Subsection withoutTopMargin>
              <InputGroup
                validationError={
                  sponsorHost.validationError ||
                  sponsorOther.validationError
                }
                className="travelcosts-field__input-group"
              >
                <InputGroup
                  validationError={sponsorHostSpecify.validationError}
                  className="travelcosts-field__input-group"
                >
                  <CheckboxGroupWithOtherValueInput
                    checkboxGroupProps={{
                      name: 'sponsorHost',
                      value: booleanToOption(sponsorHost.value),
                      options: singleBooleanOption(i18n.t('applicationForm.sponsorHost')),
                    }}
                    otherFieldValue="true"
                    onChange={transformEventValueBeforeHandle(handleChange, optionToBoolean)}
                    onClickOther={() => clearFields(['form.sponsorHostSpecify'])}
                  >
                    <InnerInput
                      {...textInputProps(sponsorHostSpecify.value, 'sponsorHostSpecify', null)}
                      className={sponsorHostSpecify.validationError ? 'input--invalid' : null}
                      uppercase={uppercase}
                    />
                  </CheckboxGroupWithOtherValueInput>
                </InputGroup>
                <InputGroup
                  validationError={sponsorOtherSpecify.validationError}
                  className="travelcosts-field__input-group"
                >
                  <CheckboxGroupWithOtherValueInput
                    checkboxGroupProps={{
                      name: 'sponsorOther',
                      value: booleanToOption(sponsorOther.value),
                      options: singleBooleanOption(i18n.t('applicationForm.sponsorOther')),
                    }}
                    otherFieldValue="true"
                    onChange={transformEventValueBeforeHandle(handleChange, optionToBoolean)}
                    onClickOther={() => clearFields(['form.sponsorOtherSpecify'])}
                  >
                    <InnerInput
                      {...textInputProps(sponsorOtherSpecify.value, 'sponsorOtherSpecify', null)}
                      className={sponsorOtherSpecify.validationError ? 'input--invalid' : null}
                      uppercase={uppercase}
                      required
                    />
                  </CheckboxGroupWithOtherValueInput>
                </InputGroup>
              </InputGroup>

              <Label className="input-group__label" label={i18n.t('common:applicationForm.meansOfSupport')} />
              <InputGroup
                validationError={
                  travelCostsSponsor.validationError ||
                  travelCostSponsorOther.validationError
                }
                className="travelcosts-field__input-group"
              >
                <CheckboxGroupWithOtherValueInput
                  checkboxGroupProps={{
                    name: 'travelCostsSponsor',
                    value: travelCostsSponsor.value,
                    options: travelCostsSponsorOptions,
                  }}
                  otherFieldValue={travelCostsSponsorValues.OTHER}
                  onChange={handleChange}
                  onClickOther={() => clearFields(['form.travelCostSponsorOther'])}
                  required
                >
                  <InnerInput
                    {...textInputProps(travelCostSponsorOther.value, 'travelCostSponsorOther', null)}
                    className={travelCostSponsorOther.validationError ? 'input--invalid' : null}
                    uppercase={uppercase}
                    required
                  />
                </CheckboxGroupWithOtherValueInput>
              </InputGroup>
            </Subsection>
          }
        </>
      </TwoColumnRow>
    </div>
  );
};

TravelCostsField.propTypes = {
  travelCostsApplicantInUse: formFieldShapeFor(PropTypes.bool).isRequired,
  travelCostsApplicant: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostApplicantOther: formFieldShapeFor(PropTypes.string).isRequired,
  travelCostsSponsorInUse: formFieldShapeFor(PropTypes.bool).isRequired,
  sponsorHost: formFieldShapeFor(PropTypes.bool).isRequired,
  sponsorHostSpecify: formFieldShapeFor(PropTypes.string).isRequired,
  sponsorOther: formFieldShapeFor(PropTypes.bool).isRequired,
  sponsorOtherSpecify: formFieldShapeFor(PropTypes.string).isRequired,
  travelCostsSponsor: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostSponsorOther: formFieldShapeFor(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  oneColumn: PropTypes.bool,
  isRequired: PropTypes.bool,
  uppercase: PropTypes.bool,
};

TravelCostsField.defaultProps = {
  oneColumn: false,
  isRequired: false,
  uppercase: false,
};

export default TravelCostsField;
