import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Label from '../../Label/Label';
import { isAsteriskInLabel } from '../../../../utils/commonUtils';

const InputGroupLabel = ({
  invalid,
  labelFor,
  required,
  label,
}) => {
  const asteriskInLabel = isAsteriskInLabel(label);
  if (asteriskInLabel) {
    console.warn(`Asterisks in required field labels are deprecated: '${label}'`); // eslint-disable-line no-console
  }
  const isRequired = required || asteriskInLabel;

  return (
    <>
      <Label
        className={classnames(
          'input-group__label',
          { 'input-group__label--invalid': invalid },
        )}
        labelFor={labelFor}
        label={asteriskInLabel ? label.slice(0, -1) : label}
      />
      {isRequired && (
        <small className="input-group__small">*</small>
      )}
    </>
  );
};

InputGroupLabel.propTypes = {
  label: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  labelFor: PropTypes.string,
  required: PropTypes.bool,
};

InputGroupLabel.defaultProps = {
  labelFor: null,
  invalid: false,
  required: false,
};

export default InputGroupLabel;
