import * as XLSX from 'xlsx';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { currentDateAsJsonString } from './datetime';

const fileType = 'xlsx';
const fileNameWithExtension = fileName => R.join('', [fileName, '.', fileType]);
const writeXlsxFile = R.curry((workBook, fileName) => XLSX.writeFile(
  workBook,
  fileNameWithExtension(fileName),
  { bookType: fileType },
));

const htmlTableDomStringToJson = (htmlTableDomString) => {
  // Use raw option to prevent formatting attempts of e.g. dates
  // as they are already formatted in htmlTableDomString
  const workBook = XLSX.read(htmlTableDomString, { type: 'string', raw: true });
  const sheetName = R.compose(R.head, R.prop('SheetNames'))(workBook);
  const workSheet = R.path(['Sheets', sheetName])(workBook);

  const stringToNumberWhenValid = (value) => {
    if (!R.is(String, value) || R.isEmpty(value) || Number.isNaN(Number(value))) return value;
    return Number(value);
  };

  return R.compose(
    // Convert strings to numbers (when valid numbers) for proper alignment in Excel
    R.map(R.map(stringToNumberWhenValid)),
    XLSX.utils.sheet_to_json,
  )(workSheet);
};

// Not used at the moment
// Returns cell range for first row based on sheetRange
export const getTitleCellRange = (sheetRange) => {
  const cellRangeSeparator = ':';

  const lastTitleCell = lastCell => R.compose(
    R.concat(R.__, '1'),
    R.take(R.__, lastCell),
    R.findIndex(R.compose(
      Number.isInteger,
      R.curry(parseInt)(R.__, 10),
    )),
    R.split(''),
  )(lastCell);

  return R.compose(
    R.converge(
      R.compose(R.join(cellRangeSeparator), R.concat),
      [
        R.dropLast(1),
        R.compose(R.append(R.__, []), lastTitleCell, R.last),
      ],
    ),
    R.split(cellRangeSeparator),
  )(sheetRange);
};

const addRowToWorkSheet = R.curry((rowValues, rowNumber, workSheet) => XLSX.utils.sheet_add_aoa(
  workSheet,
  [rowValues],
  { origin: rowNumber },
));

const addManyRowsToWorkSheet = R.curry((rows, firstRowNumber, workSheet) => {
  rows.forEach((row, index) => {
    addRowToWorkSheet([row], firstRowNumber + index, workSheet);
  });

  return workSheet;
});

const addMergedCellRangeToWorkSheet = R.curry((cellRange, workSheet) => {
  const decodedTitleRange = XLSX.utils.decode_range(cellRange);
  return R.ifElse(
    R.has('!merges'),
    R.evolve({ '!merges': R.append(decodedTitleRange) }),
    R.assoc('!merges', [decodedTitleRange]),
  )(workSheet);
});

const addRowAndMergeCellsToWorkSheet = R.curry((rowValues, rowNumber, cellRange, workSheet) => {
  return R.compose(
    addRowToWorkSheet(rowValues, rowNumber),
    addMergedCellRangeToWorkSheet(cellRange),
  )(workSheet);
});

const addCustomHeadersRowToWorkSheet = R.curry((headers, rowNumber, workSheet) =>
  addRowToWorkSheet(
    R.pluck('label', headers),
    rowNumber,
    workSheet,
  ));

const addColumnWidthsToWorkSheet = R.curry((columnWidths, workSheet) =>
  R.assoc(
    '!cols',
    R.map(width => ({ wpx: width }), columnWidths),
    workSheet,
  ));

const rangeOfTenCellsFor = rowNumber => `A${R.inc(rowNumber)}:J${R.inc(rowNumber)}`;

const addManyMergedCellRangesToWorkSheet = R.curry((firstRowNumber, rowCount, workSheet) => {
  let updatedWorkSheet = workSheet;
  R.range(firstRowNumber, firstRowNumber + rowCount).forEach((rowNumber) => {
    updatedWorkSheet =
      addMergedCellRangeToWorkSheet(rangeOfTenCellsFor(rowNumber), updatedWorkSheet);
  });

  return updatedWorkSheet;
});

const addDescriptionRowsToWorkSheet = R.curry((descriptionRows, firstRowNumber, workSheet) => {
  return R.compose(
    addManyRowsToWorkSheet(descriptionRows, firstRowNumber),
    addManyMergedCellRangesToWorkSheet(firstRowNumber, descriptionRows.length),
  )(workSheet);
});

const createWorkSheetFromJson = R.curry((json, options) => {
  const headerOrder = options.headers ? R.pluck('dataKey', options.headers) : null;
  const titleRowNumber = 0;
  const subTitleRowNumber = options.title ? 1 : 0;
  const startRowNumberForDescriptions = R.cond([
    [_options => _options.title && _options.subTitle, () => 3],
    [_options => _options.title || _options.subTitle, () => 2],
    [R.T, () => 0],
  ])(options);
  const startRowNumberForData = options.descriptionRows ?
    startRowNumberForDescriptions + options.descriptionRows.length + 1 :
    startRowNumberForDescriptions;
  const jsonToSheet = _json => XLSX.utils.json_to_sheet(_json, {
    origin: startRowNumberForData,
    header: headerOrder,
  });

  return R.compose(
    R.when(() => options.columnWidths, addColumnWidthsToWorkSheet(options.columnWidths)),
    R.when(() => options.descriptionRows, addDescriptionRowsToWorkSheet(
      options.descriptionRows,
      startRowNumberForDescriptions,
    )),
    R.when(() => options.subTitle, addRowAndMergeCellsToWorkSheet(
      [options.subTitle],
      subTitleRowNumber,
      rangeOfTenCellsFor(subTitleRowNumber),
    )),
    R.when(() => options.title, addRowAndMergeCellsToWorkSheet(
      [options.title],
      titleRowNumber,
      rangeOfTenCellsFor(titleRowNumber),
    )),
    R.when(() => options.headers, addCustomHeadersRowToWorkSheet(
      options.headers,
      startRowNumberForData,
    )),
    jsonToSheet,
  )(json);
});

const createWorkBookWithWorkSheet = (workSheet) => {
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet);
  return workBook;
};

export const exportOptionsShape = PropTypes.exact({
  // Title to be rendered as first row of xlsx-sheet
  title: PropTypes.string,
  // Subtitle to be rendered as second row (or first row if title is null) of xlsx-sheet
  subTitle: PropTypes.string,
  // Some additional description rows after title or subtitle (if present)
  descriptionRows: PropTypes.arrayOf(PropTypes.string),
  // Custom headers for columns. Defines column order.
  headers: PropTypes.arrayOf(PropTypes.exact({
    // Must match correspongin key in input data
    dataKey: PropTypes.string.isRequired,
    // Label for column header (e.g. translated text)
    label: PropTypes.string.isRequired,
  })),
  // Custom widths for columns in pixels
  columnWidths: PropTypes.arrayOf(PropTypes.number),
  // Custom filename
  fileName: PropTypes.string,
});

const defaultExportOptions = {
  title: null,
  subTitle: null,
  descriptionRows: null,
  headers: null,
  columnWidths: null,
  fileName: `export_${currentDateAsJsonString()}`,
};

const mergeExportOptionsWithDefaults = R.mergeRight(defaultExportOptions);

/**
 * htmlTableDomString - HTML Table DOMString
 * exportOptions - exportOptionsShape
 */
export const exportHtmlTableDomStringAsXlsx = (
  htmlTableDomString,
  exportOptions = {},
) => {
  const options = mergeExportOptionsWithDefaults(exportOptions);

  return R.compose(
    writeXlsxFile(R.__, options.fileName),
    createWorkBookWithWorkSheet,
    createWorkSheetFromJson(R.__, options),
    htmlTableDomStringToJson,
  )(htmlTableDomString);
};

/**
 * data - an array of JS object.
 * exportOptions - exportOptionsShape
 */
export const exportDataAsXslx = (
  data,
  exportOptions = {},
) => {
  const options = mergeExportOptionsWithDefaults(exportOptions);

  return R.compose(
    writeXlsxFile(R.__, options.fileName),
    createWorkBookWithWorkSheet,
    createWorkSheetFromJson(R.__, options),
  )(data);
};
