import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';

import actions from './ForgotPasswordActions';
import ForgotPassword from './ForgotPassword';
import {
  forgotPasswordConfirmFormSelector,
  forgotPasswordFormSelector,
} from './ForgotPasswordReducer';

const mapStateToProps = state => ({
  forgotPasswordForm: forgotPasswordFormSelector(state),
  forgotPasswordConfirmForm: forgotPasswordConfirmFormSelector(state),
});

const mapDispatchToProps = actions;

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ForgotPassword);
