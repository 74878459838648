import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import { Input, InputGroup, inputProps } from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import InlineNotification from 'visa-frontend-common/src/components/UI/InlineNotification/InlineNotification';
import TrimInput, { trimInputProps } from 'visa-frontend-common/src/components/UI/Form/Input/TrimInput';
import { eventToField } from 'visa-frontend-common/src/utils/commonUtils';
import formValidator from 'visa-frontend-common/src/services/validator/formValidator';
import Form from 'visa-frontend-common/src/components/UI/Form/Form';

import i18n from '../../services/i18n';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import actions from './ForgotPasswordActions';
import paths from '../../paths';
import { forgotPasswordConfirmFormShape } from '../../dataModel';

import './ForgotPasswordConfirmForm.scss';

const emailLabel = i18n.t('forgotPassword.email');
const confirmationCodeLabel = i18n.t('forgotPassword.confirmationCode');
const passwordLabel = i18n.t('forgotPassword.password');
const confirmPasswordLabel = i18n.t('forgotPassword.confirmPassword');
const passwordRequirements = i18n.t('forgotPassword.passwordRequirements');

class ForgotPasswordConfirmForm extends React.Component {
  state = { errorMessage: null };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const confirmationCode = params.get('code');

    if (email != null) {
      this.handleInput({
        target: {
          name: 'email',
          value: email,
        },
      });
    }
    if (confirmationCode != null) {
      this.handleInput({
        target: {
          name: 'confirmationCode',
          value: confirmationCode,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.forgotPasswordConfirmClearFields(['forgotPasswordConfirmForm']);
  }

  handleInput = (event) => {
    this.props.forgotPasswordConfirmSetFieldAndValidate(
      eventToField(event),
      this.props.forgotPasswordConfirmForm,
    );
    this.setState({ errorMessage: null });
  };

  handleSubmit = () => this.props.forgotPasswordConfirmSubmit(this.props.forgotPasswordConfirmForm)
    .then(R.ifElse(R.is(Error), (err) => {
      this.setState({ errorMessage: err.message });
    }, () => {
      this.setState({ errorMessage: null });
    }));

  render() {
    const trimProps = trimInputProps(R.__, R.__, null, false, this.handleInput);
    const passwordInputProps = inputProps('password', R.__, R.__, null, false, this.handleInput);
    const {
      email,
      confirmationCode,
      password,
      confirmPassword,
    } = this.props.forgotPasswordConfirmForm;

    return (
      <div className="forgot-password-confirm-form">
        <h2>{i18n.t('forgotPassword.confirmTitle')}</h2>

        <Form>
          <InputGroup
            label={emailLabel}
            required
            validationError={email.validationError}
            className="forgot-password-confirm-form__email"
          >
            <TrimInput {...trimProps(email.value, 'email', null)} autoFocus={Boolean(!email.value)} />
          </InputGroup>
          <InputGroup
            label={confirmationCodeLabel}
            required
            validationError={confirmationCode.validationError}
            className="forgot-password-confirm-form__code"
          >
            <TrimInput {...trimProps(confirmationCode.value, 'confirmationCode', null)} autoFocus={Boolean(email.value)} />
          </InputGroup>
          <InputGroup
            label={passwordLabel}
            required
            withHelp={passwordRequirements}
            validationError={password.validationError}
            className="forgot-password-confirm-form__password"
          >
            <Input {...passwordInputProps(password.value, 'password')} />
          </InputGroup>
          <InputGroup
            label={confirmPasswordLabel}
            required
            validationError={confirmPassword.validationError}
            className="forgot-password-confirm-form__confirmPassword"
          >
            <Input {...passwordInputProps(confirmPassword.value, 'confirmPassword')} />
          </InputGroup>

          <LoadingButton
            onClick={this.handleSubmit}
            title={i18n.t('forgotPassword.changePassword')}
            className="forgot-password-confirm-form__submit-button"
            icon="arrow_forward"
            label={i18n.t('forgotPassword.changePassword')}
            dataCy="forgot-password-confirm-form-button"
            requestNames={[actions.forgotPasswordConfirmSent().type]}
            type="submit"
          />
        </Form>
        { formValidator.isFormValid(this.props.forgotPasswordConfirmForm) &&
          !!this.state.errorMessage && (
          <InlineNotification
            type="warning"
            className="forgot-password-confirm-form__expired-code-notification"
            keyProp={i18n.t(this.state.errorMessage)}
            onClose={() => this.setState({ errorMessage: null })}
          >
            <small>
              {i18n.t(this.state.errorMessage)}
              {' '}
              <Link to={paths.forgotPassword.root}>{i18n.t('forgotPassword.retry')}</Link>
            </small>
          </InlineNotification>
        )}
      </div>
    );
  }
}

ForgotPasswordConfirmForm.propTypes = {
  forgotPasswordConfirmForm: forgotPasswordConfirmFormShape.isRequired,
  forgotPasswordConfirmSetFieldAndValidate: PropTypes.func.isRequired,
  forgotPasswordConfirmSubmit: PropTypes.func.isRequired,
  forgotPasswordConfirmClearFields: PropTypes.func.isRequired,
};

export default ForgotPasswordConfirmForm;
