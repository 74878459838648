import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import { historyShape } from 'visa-frontend-common/src/dataModel';
import i18n from '../../../../services/i18n';

import './PreviewEditLink.scss';

const PreviewEditLink = (props) => {
  const handleProceed = () => props.history.push({ pathname: props.tabPath });

  return (
    <Button
      icon="arrow_back"
      className="preview-edit-link"
      onClick={handleProceed}
      class="custom"
      label={`${i18n.t('application.previewApplication.previewEditLinkPrefix')} ${props.tabNameLabel}`}
    />);
};

PreviewEditLink.propTypes = {
  tabNameLabel: PropTypes.string.isRequired,
  tabPath: PropTypes.string.isRequired,
  history: historyShape.isRequired,
};

export default withRouter(PreviewEditLink);
