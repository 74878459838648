import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { confirmDeletion } from 'visa-frontend-common/src/services/confirmationService';
import { isNilOrEmpty, notNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';
import Accordion from 'visa-frontend-common/src/components/UI/Accordion/Accordion';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';

import i18n from '../../services/i18n';
import { applicationCopyFormShape, applicationGroupShape, applicationStatus } from '../../ovafModel';
import ApplicationCopyForm from './ApplicationCopyFormContainer';

import './ApplicationGroupListItem.scss';

const ApplicationGroupListItem = (props) => {
  const openAddApplicationForm = (groupId) => {
    props.clearForm();
    props.setFormField({
      target: {
        name: 'groupId',
        value: groupId,
      },
    });
  };

  const handleDeleteApplicationGroup = groupId => confirmDeletion(
    i18n.t('applicationGroups.confirmDeleteApplicationGroup'),
    () => props.deleteApplicationGroup(groupId),
  );

  const readOnly = R.pathSatisfies(
    R.any(R.propEq('status', applicationStatus.IN_PROCESSING)),
    ['group', 'applications'],
  )(props);
  const isFormOpen =
    R.pathEq(['groupId', 'value'], props.group.groupId)(props.applicationCopyForm);

  return (
    <div className="col-12">
      <Accordion
        className="application-group__group-accordion"
        expandButtonText={
          i18n.t('applicationGroups.applicationGroup', { count: R.length(props.group.applications) })
        }
        dataCy={`application-group-${props.group.groupId}`}
        isOpenAtStart
        bodyContent={(
          <div className="application-group__group-accordion-content">
            <div className="row">
              {isNilOrEmpty(props.group.applications) && (
                <div className="col-12">
                  <h3>{i18n.t('applicationGroups.emptyGroup')}</h3>
                  <p>{i18n.t('applicationGroups.emptyGroupDescription')}</p>
                </div>
              )}
              {notNilOrEmpty(props.group.applications) && (
                props.group.applications.map((application) => {
                  return (
                    <div key={application.applicationId} className="col-12 col-lg-6">
                      {props.renderItem(application)}
                    </div>
                  );
                })
              )}
            </div>
            { !readOnly &&
              <div className="application-group__group-accordion-footer">
                <Button
                  class="invisible"
                  label={i18n.t('frontPage.createNewApplication')}
                  icon="post_add"
                  onClick={() => props.createApplicationInGroup(props.group.groupId)}
                  dataCy={`create-application-in-group-${props.group.groupId}`}
                  disabled={isFormOpen}
                />
                <Button
                  class="invisible"
                  label={i18n.t('applicationCopy.createNewApplicationFromTemplate')}
                  icon="post_add"
                  onClick={() => openAddApplicationForm(props.group.groupId)}
                  dataCy={`create-application-in-group-${props.group.groupId}`}
                  disabled={isFormOpen || R.isEmpty(props.group.applications)}
                />
                <Button
                  class="invisible"
                  label={i18n.t('applicationGroups.deleteApplicationGroup')}
                  icon="delete_forever"
                  onClick={() => handleDeleteApplicationGroup(props.group.groupId)}
                  dataCy={`delete-application-group-${props.group.groupId}`}
                  disabled={isFormOpen}
                />
              </div>}
            { isFormOpen &&
              <ApplicationCopyForm applications={props.group.applications} /> }
          </div>
        )}
      />
    </div>
  );
};

ApplicationGroupListItem.propTypes = {
  group: applicationGroupShape.isRequired,
  applicationCopyForm: applicationCopyFormShape.isRequired,
  createApplicationInGroup: PropTypes.func.isRequired,
  setFormField: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  deleteApplicationGroup: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
};

export default ApplicationGroupListItem;
