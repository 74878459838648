import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';

import { confirmAcceptance } from 'visa-frontend-common/src/services/confirmationService';
import { notifySuccess } from 'visa-frontend-common/src/services/pushNotificationService';
import { apiThunkWithLoading, createFormArrayUpdateAction } from 'visa-frontend-common/src/utils/actionUtils';
import { validatingSetFieldThunk, validatingSetTabThunk, validatingSubmitFormThunk } from 'visa-frontend-common/src/services/validator/validatorActions';
import formValidator from 'visa-frontend-common/src/services/validator/formValidator';
import { downloadBlobAndOpenToNewWindow } from 'visa-frontend-common/src/utils/downloadUtils';

import i18n from '../../services/i18n';
import * as applicationService from '../../services/applicationService';
import validationRules from './ApplicationValidationRules';
import paths from '../../paths';
import { applicationFormFieldsPerTab, applicationStatus } from '../../ovafModel';
import * as checklistService from '../../services/checklistService';

const applicationSent = createAction('APPLICATION_SENT_COMPLETED');
const applicationFetched = createAction('APPLICATION_FETCH_COMPLETED');
const finalizedApplicationFetched = createAction('FINALIZED_APPLICATION_FETCH_COMPLETED');
const applicationStatusFetched = createAction('APPLICATION_STATUS_FETCH_COMPLETED');

const formFieldSet = createAction('APPLICATION_FORM_FIELD_SET');
const formArrayUpdate = createFormArrayUpdateAction('APPLICATION_FORM_ARRAY_UPDATE');
const formTabsSet = createAction('APPLICATION_FORM_TABS_SET');
const formCleared = createAction('APPLICATION_FORM_CLEARED');
const clearFields = createAction('APPLICATION_CLEAR_FORM_FIELDS');

const checklistsFetched = createAction('APPLICATION_FORM_CHECKLISTS_FETCH_COMPLETED');
const checklistItemsFetched = createAction('APPLICATION_FORM_CHECKLIST_ITEMS_FETCH_COMPLETED');
const checklistItemAttachmentsFetched = createAction('APPLICATION_FORM_CHECKLIST_ITEM_ATTACHMENTS_FETCH_COMPLETED');
const checklistItemTypesFetched = createAction('APPLICATION_FORM_CHECKLIST_ITEM_TYPES_FETCH_COMPLETED');
const countrySpecificInstructionsFetched = createAction('APPLICATION_FORM_COUNTRY_SPECIFIC_INSTRUCTIONS_FETCH_COMPLETED');

const createDownloadingChecklistAttachmentBlobAction = attachmentKey =>
  createAction(`APPLICATION_FORM_CHECKLIST_ATTACHMENT[${attachmentKey}]_DOWNLOAD_COMPLETED`);

const createDownloadingApplicationPdfBlobAction = applicationId =>
  createAction(`APPLICATION[${applicationId}]_PDF_DOWNLOAD_COMPLETED`);

const fetchChecklists = apiThunkWithLoading(
  checklistService.getChecklistsForEspLocation,
  checklistsFetched,
);

const fetchChecklistItems = apiThunkWithLoading(
  checklistService.getChecklistItems,
  checklistItemsFetched,
);

const fetchChecklistItemAttachments = apiThunkWithLoading(
  checklistService.getChecklistItemAttachments,
  checklistItemAttachmentsFetched,
);

const fetchChecklistItemTypes = apiThunkWithLoading(
  checklistService.getChecklistItemTypes,
  checklistItemTypesFetched,
);

const fetchChecklistCountrySpecificInstructions = apiThunkWithLoading(
  checklistService.getChecklistCountrySpecificInstructions,
  countrySpecificInstructionsFetched,
);

const setFieldAndValidate = (field, form) => (dispatch) => {
  const rules = validationRules(true);
  return validatingSetFieldThunk(formFieldSet, rules)(field, form)(dispatch);
};

const createDraftApplication = (payload, token) => (dispatch) => {
  const rules = validationRules(false);
  return validatingSubmitFormThunk(
    formFieldSet,
    applicationService.createApplication,
    applicationSent,
    rules,
  )(payload, token)(dispatch);
};

const createDraftApplicationToDone = (payload, token) => (dispatch) => {
  const rules = validationRules(true);
  return validatingSubmitFormThunk(
    formFieldSet,
    applicationService.createApplicationForDone,
    applicationSent,
    rules,
  )(payload, token)(dispatch);
};

const fetchApplicationStatus =
  apiThunkWithLoading(
    applicationService.getApplicationStatus,
    applicationStatusFetched,
  );

const redirectApplicationStatusException = applicationId => (dispatch) => {
  fetchApplicationStatus(applicationId)(dispatch)
    .then((status) => {
      const path = R.cond([
        [R.equals(applicationStatus.IN_PROCESSING),
          R.always(generatePath(paths.root))],
        [R.equals(applicationStatus.DRAFT),
          R.always(generatePath(paths.application.update, { applicationId, tab: 'finalize' }))],
        [R.equals(applicationStatus.DONE),
          R.always(generatePath(paths.application.completed, { applicationId }))],
      ])(status);
      R.compose(dispatch, push)(path);
    });
};

const fetchApplication = applicationId => dispatch =>
  apiThunkWithLoading(
    applicationService.getApplication,
    applicationFetched,
  )(applicationId)(dispatch)
    .then(R.unless(R.is(Error), (response) => {
      if (response.espLocation) {
        dispatch(fetchChecklistCountrySpecificInstructions(response.espLocation));
        dispatch(fetchChecklists(response.espLocation));
      }
      if (response.espLocation && response.checklistName) {
        dispatch(fetchChecklistItemAttachments(response.espLocation, response.checklistName));
      }
    })).then(R.when(R.is(Error), (errorResponse) => {
      if (errorResponse.message === 'applicationErrors.ApplicationStatusException') {
        redirectApplicationStatusException(applicationId)(dispatch);
      }
    }));

const fetchFinalizedApplication = applicationId => dispatch =>
  apiThunkWithLoading(
    applicationService.getFinalizedApplication,
    finalizedApplicationFetched,
  )(applicationId)(dispatch)
    .then(R.when(R.is(Error), (errorResponse) => {
      if (errorResponse.message === 'applicationErrors.ApplicationStatusException') {
        redirectApplicationStatusException(applicationId)(dispatch);
      }
    }));

const validateTab = (form, tabName) => (dispatch) => {
  const rules = validationRules(true);
  return validatingSetTabThunk(
    formFieldSet,
    formTabsSet,
    applicationFormFieldsPerTab,
    rules,
  )(form, tabName)(dispatch);
};

const updateDraftApplication = (application, applicationId) => (dispatch) => {
  const rules = validationRules(false);
  return validatingSubmitFormThunk(
    formFieldSet,
    applicationService.updateApplication,
    applicationSent,
    rules,
  )(application, applicationId)(dispatch);
};

const validateFields = fields => (dispatch, getState) => {
  const { form } = getState().application;
  const rules = validationRules(true);

  const validatedFormPart = R.pickAll(fields, form);

  const validatedFormFields =
    formValidator.validateFormPart(validatedFormPart, form, rules);

  return R.tap(R.compose(dispatch, formFieldSet))(validatedFormFields);
};

// To clear the possible validation errors of Invitations- and TravelCost-fields when
// eucitizenFamily -checkbox is checked.
const validateInvitationAndTravelCostTopFields = () =>
  validateFields([
    'contactOrAccommodation',
    'accommodations',
    'invitingPersons',
    'invitingOrganization',
    'oneDayTripWithoutAccommodation',
    'travelCostsApplicantInUse',
    'travelCostsSponsorInUse',
  ]);

const validateAllTabs = R.curry((form, rules, dispatch) => R.compose(
  R.tap(R.compose(dispatch, formTabsSet)),
  formValidator.validateTabs(R.__, applicationFormFieldsPerTab),
  R.tap(R.compose(dispatch, formFieldSet)),
  formValidator.validateForm(R.__, rules),
)(form));

const updateDraftApplicationToDone = (form, applicationId, setDirty) => (dispatch, getState) => {
  const rules = validationRules(true);
  validateAllTabs(form, rules, dispatch);

  if (formValidator.isFormValid(getState().application.form)) {
    confirmAcceptance(
      i18n.t('application.finalizeConfirmation'),
      () => {
        validatingSubmitFormThunk(
          formFieldSet,
          applicationService.updateApplicationForDone,
          applicationSent,
          rules,
        )(form, applicationId)(dispatch)
          .then(R.unless(R.is(Error), () => {
            setDirty(false);
            R.compose(dispatch, push)(
              generatePath(paths.application.completed, { applicationId }),
            );
            notifySuccess('application-draft-updated-to-done', i18n.t('common:saveSuccess'));
          }));
      },
    );
  }
};

const saveDraftApplication = (form, currentTab, setDirty) => (dispatch) => {
  if (window.mtcaptcha.getStatus('mtcaptcha-2').isVerified) {
    dispatch(createDraftApplication(form, window.mtcaptcha.getVerifiedToken('mtcaptcha-2')))
      .then(R.unless(R.is(Error), (applicationId) => {
        window.mtcaptcha.resetUI('mtcaptcha-2'); // Get new challenge to prevent token already used errors
        setDirty(false);
        R.compose(dispatch, push)(`${paths.application.root}/${applicationId}/${currentTab}`);
        dispatch(fetchApplication(applicationId));
        notifySuccess('application-draft-saved', i18n.t('common:saveSuccess'));
      }));
  } else {
    try {
      window.mtcaptcha.showMandatory();
    } catch {
      // Suppress errors
    }
  }
};

const saveDraftApplicationToDone = (form, setDirty) => (dispatch, getState) => {
  const rules = validationRules(true);
  validateAllTabs(form, rules, dispatch);

  if (formValidator.isFormValid(getState().application.form)) {
    confirmAcceptance(
      i18n.t('application.finalizeConfirmation'),
      () => {
        if (window.mtcaptcha.getStatus('mtcaptcha-2').isVerified) {
          dispatch(createDraftApplicationToDone(form, window.mtcaptcha.getVerifiedToken('mtcaptcha-2')))
            .then(R.unless(R.is(Error), (applicationId) => {
              window.mtcaptcha.resetUI('mtcaptcha-2'); // Get new challenge to prevent token already used errors
              setDirty(false);
              R.compose(dispatch, push)(
                generatePath(paths.application.completed, { applicationId }),
              );
              notifySuccess('application-draft-saved-to-done', i18n.t('common:saveSuccess'));
            }));
        } else {
          try {
            window.mtcaptcha.showMandatory();
          } catch {
            // Suppress errors
          }
        }
      },
    );
  }
};

const setEspLocation = (espLocation, form, setDirty) => (dispatch) => {
  dispatch(setFieldAndValidate(espLocation, form));
  setDirty(true);

  if (form.espLocation.value !== espLocation.espLocation.value) {
    dispatch(clearFields(['checklists', 'form.checklistName', 'form.applicationChecklistItems']));
  }
  dispatch(fetchChecklistCountrySpecificInstructions(espLocation.espLocation.value));

  return dispatch(fetchChecklists(espLocation.espLocation.value));
};

const setChecklist = (checklist, checklistId, form, setDirty) => (dispatch) => {
  dispatch(setFieldAndValidate(checklist, form));
  setDirty(true);

  if (checklistId && form.checklistName.value !== checklist.checklistName.value) {
    dispatch(fetchChecklistItems(checklistId));
    dispatch(fetchChecklistItemAttachments(form.espLocation.value, checklist.checklistName.value));
  } else if (form.checklistName.value !== checklist.checklistName.value) {
    dispatch(clearFields(['form.applicationChecklistItems']));
  }
};

const downloadAttachment = attachmentKey => apiThunkWithLoading(
  downloadBlobAndOpenToNewWindow(checklistService.downloadAttachment, true),
  createDownloadingChecklistAttachmentBlobAction(attachmentKey),
)(attachmentKey);

const actions = {
  applicationFetched,
  applicationSent,
  saveDraftApplication,
  saveDraftApplicationToDone,
  fetchApplication,
  formCleared,
  formFieldSet,
  formTabsSet,
  formArrayUpdate,
  setFieldAndValidate,
  validateFields,
  validateInvitationAndTravelCostTopFields,
  updateDraftApplication,
  updateDraftApplicationToDone,
  clearFields,
  validateTab,
  validateAllTabs,
  fetchChecklists,
  checklistsFetched,
  setEspLocation,
  setChecklist,
  fetchChecklistItemTypes,
  checklistItemsFetched,
  checklistItemAttachmentsFetched,
  checklistItemTypesFetched,
  fetchChecklistCountrySpecificInstructions,
  countrySpecificInstructionsFetched,
  downloadAttachment,
  createDownloadingChecklistAttachmentBlobAction,
  fetchFinalizedApplication,
  finalizedApplicationFetched,
  createDownloadingApplicationPdfBlobAction,
};

export default actions;
