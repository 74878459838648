import React, { memo } from 'react';

import GuideItem from '../../../components/Guide/GuideItem';
import i18n from '../../../services/i18n';

const ResidencePermitGuide = memo(() => {
  return (
    <GuideItem id="occupationAndResidenceGuide-residenceInOtherCountry" subTitle={`20. ${i18n.t('common:applicationForm.residenceInOtherCountry')}`}>
      {i18n.t('application.guides.occupationAndResidenceGuide.residenceInOtherCountry')}
    </GuideItem>
  );
});

export default ResidencePermitGuide;
