import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './HelpText.scss';

const HelpText = (props) => {
  return (
    <small
      id={props.helpId}
      className={classnames(
        'help-text',
        {
          'help-text--visible': props.helpVisible,
          'help-text--visible-with-bottom-margin': props.helpVisible && props.withBottomMargin,
          [props.helpTextVisibleClassName]: props.helpVisible,
        },
      )}
    >
      { props.helpText }
    </small>
  );
};

HelpText.propTypes = {
  helpId: PropTypes.string,
  helpText: PropTypes.node.isRequired,
  helpVisible: PropTypes.bool.isRequired,
  withBottomMargin: PropTypes.bool,
  helpTextVisibleClassName: PropTypes.string,
};

HelpText.defaultProps = {
  helpId: null,
  withBottomMargin: false,
  helpTextVisibleClassName: null,
};

export default HelpText;
