import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as R from 'ramda';

import Icon from '../../Icon/Icon';
import Selector from './Selector';
import { notNilOrEmpty } from '../../../../utils/commonUtils';

import './SelectorWithIcons.scss';

const SelectorWithIcons = ({ className, icons, ...props }) => {
  const hasClassName = notNilOrEmpty(className);
  const formatOption = ({ label, value }) => (
    <div className={classnames(
      'selector-with-icons__option',
      { [`${className}__option`]: hasClassName },
    )}
    >
      {R.not(R.isNil(icons[value])) &&
        <Icon
          className={classnames(
            'selector-with-icons__icon',
            {
              [`${className}__icon`]: hasClassName,
              [`${className}__icon--${value}`]: hasClassName,
            },
          )}
          name={icons[value]}
          title={label}
        />}
      <span>{label}</span>
    </div>
  );

  return (
    <Selector
      {...props}
      className={classnames(
        'selector-with-icons',
        className,
      )}
      formatOptionLabel={formatOption}
    />
  );
};

SelectorWithIcons.propTypes = {
  className: PropTypes.string,
  icons: PropTypes.objectOf(PropTypes.string).isRequired,
};

SelectorWithIcons.defaultProps = {
  className: null,
};

export default SelectorWithIcons;
