import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from './Icon';

import './LoadingIcon.scss';

const LoadingIcon = props => <Icon name="hourglass_empty" className={classnames('loading-icon', props.className)} />;

LoadingIcon.propTypes = {
  className: PropTypes.string,
};

LoadingIcon.defaultProps = {
  className: null,
};

export default LoadingIcon;
