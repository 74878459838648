import * as R from 'ramda';

import * as validators from './validators';

export const combineRuleFunctionsFor =
  ruleFunctionList => R.converge(R.unapply(R.mergeAll), ruleFunctionList);

const concatValidators = validatorList => (acc, value) =>
  R.over(R.lensPath(value), R.compose(R.concat(R.__, validatorList), R.defaultTo([])), acc);

/** Appends validators to given fields in validation rule object
 * validators - validator function list
 * fieldsToAddTo - list of paths e.g. [['surname'], ['firstName']]
 * allFields - validation rules object i.e. return value of validationrules function
 * TODO: prevent duplicate validators for a field
 */
export const appendValidatorsToFields = R.curry((validatorList, fieldsToAddTo, allFields) =>
  R.reduce(concatValidators(validatorList), allFields, fieldsToAddTo));

export const validatedIf = condition => rule => (fieldValue, formValues, path) => (
  condition(fieldValue, formValues, path) ? rule(fieldValue, formValues, path) : null
);

export const requiredIf = isRequired => validatedIf(() => isRequired)(validators.required);

export const requiredTrueIf = isRequired => validatedIf(() => isRequired)(validators.isTrue);
