import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as R from 'ramda';

import TextWithHelp from '../../TextWithHelp/TextWithHelp';
import ValidationError from '../../ValidationError/ValidationError';

import InputGroupLabel from './InputGroupLabel';
import { notNilOrEmpty } from '../../../../utils/commonUtils';

import './InputGroup.scss';

const InputGroup = (props) => {
  const idOrNameOfFirstChild = R.compose(
    R.ifElse(
      item => notNilOrEmpty(R.path(['props', 'id'], item)),
      R.path(['props', 'id']),
      R.path(['props', 'name']),
    ),
    R.head,
    React.Children.toArray,
  )(props.children);

  const helpId = props.withHelp ? `${idOrNameOfFirstChild}Help` : null;

  const childrenWithNewProps = React.Children.map(props.children, (child) => {
    if (!React.isValidElement(child) || !child.props.name) {
      return child;
    }
    return React.cloneElement(child, {
      describedBy: props.describedBy,
      id: child.props.id || child.props.name,
      invalid: Boolean(props.validationError),
      inTableRow: props.inTableRow,
      label: child.props.label || props.label,
      required: child.props.required || props.required,
    });
  });

  const inputGroupClassNames = classnames(
    'input-group',
    {
      'input-group--in-table-row': props.inTableRow,
      'input-group--with-top-margin': props.withTopMargin,
      'input-group--with-label-margin': props.withLabelMargin,
    },
    props.className,
  );

  return (
    <div className={inputGroupClassNames}>
      {
        props.label &&
          <InputGroupLabel
            label={props.label}
            required={props.required}
            invalid={!!props.validationError}
            labelFor={idOrNameOfFirstChild}
          />
      }

      {
        props.withHelp &&
        <TextWithHelp
          text={null}
          help={props.withHelp}
          helpId={helpId}
        />
      }

      { childrenWithNewProps }

      <ValidationError validationError={props.validationError} />
    </div>
  );
};

InputGroup.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  withHelp: PropTypes.string,
  describedBy: PropTypes.string,
  children: PropTypes.node.isRequired,
  validationError: PropTypes.string,
  className: PropTypes.string,
  inTableRow: PropTypes.bool,
  withTopMargin: PropTypes.bool,
  withLabelMargin: PropTypes.bool,
};

InputGroup.defaultProps = {
  label: null,
  required: false,
  withHelp: null,
  describedBy: null,
  validationError: null,
  className: null,
  inTableRow: false, // Adds correct styles, if component is placed inside table row (td)
  withTopMargin: false,
  withLabelMargin: false,
};

export default InputGroup;
