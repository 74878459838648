import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

/* This initializes the locale before everything else */
/* eslint-disable-next-line no-unused-vars, import/order */
import i18n from './services/i18n';

import ErrorBoundary from 'visa-frontend-common/src/components/ErrorBoundary/ErrorBoundary';
import Notification from 'visa-frontend-common/src/components/Notification/Notification';
import ScrollToTop from 'visa-frontend-common/src/components/ScrollToTop/ScrollToTop';
import MainContainer from './pages/Main/MainContainer';
import HeaderContainer from './components/Header/HeaderContainer';
import Footer from './components/Footer/Footer';
import paths from './paths';
import SingletonNavigationPromptContainer from './components/NavigationPrompt/SingletonNavigationPromptContainer';

import './App.scss';

const NotificationContainer = withRouter(Notification);

class App extends React.PureComponent {
  render() {
    return (
      <ErrorBoundary>
        <NotificationContainer />
        <SingletonNavigationPromptContainer />
        <div
          className="app container-fluid"
          lang={i18n.mainContentLanguage()}
        >
          <a className="app__jump-to-content-link" href="#main">
            {i18n.t('common.jumpToContentLink')}
          </a>
          <ErrorBoundary>
            <HeaderContainer />
          </ErrorBoundary>
          <ErrorBoundary>
            <ScrollToTop exceptedPaths={[paths.checklist]}>
              <Switch>
                <Route
                  path="/**"
                  render={routeProps => (<MainContainer {...routeProps} />)}
                />
              </Switch>
            </ScrollToTop>
          </ErrorBoundary>
          <div className="app__vertical-fill" />
          <ErrorBoundary>
            <Footer />
          </ErrorBoundary>
        </div>
      </ErrorBoundary>
    );
  }
}

export default App;
