import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { flushSync } from 'react-dom';

import withNavigationPromptFor, {
  defaultMapDispatchToPropsFor, defaultMapStateToPropsFor,
} from 'visa-frontend-common/src/components/NavigationPrompt/NavigationPrompt';

const withNavigationPrompt = (
  WrappedComponent,
  formName,
  excludedPaths,
  promptTitle,
  promptListLabel,
) => {
  const mapStateToProps = defaultMapStateToPropsFor(formName);
  const mapDispatchToProps = defaultMapDispatchToPropsFor(
    formName,
    excludedPaths,
    promptTitle,
    promptListLabel,
    flushSync,
  );
  return R.compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withNavigationPromptFor,
  )(WrappedComponent);
};

export default withNavigationPrompt;
