import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import LoadingIcon from '../Icon/LoadingIcon';

import './InlineNotificationMessage.scss';

const iconNamesByClass = {
  success: 'check_circle',
  info: 'info',
  warning: 'warning',
  wait: 'hourglass_empty',
  system: 'notifications',
  message: 'mail',
  expiration: 'key',
};

class InlineNotificationMessage extends React.Component {
  state = {
    isVisible: true,
  };

  closeInlineNotification = (event) => {
    this.setState({ isVisible: false });

    if (this.props.onClose) {
      this.props.onClose(event);
    }
  };

  render() {
    if (!this.state.isVisible) {
      return null;
    }

    return (
      <div className={classNames('inline-notification-message', `inline-notification-message--${this.props.type}`, this.props.className)} data-cy={this.props.dataCy}>
        {
          this.props.type === 'wait' ?
            <LoadingIcon className={classNames('inline-notification-message__icon', `inline-notification-message__icon--${this.props.type}`)} /> :
            <Icon
              className={classNames('inline-notification-message__icon', `inline-notification-message__icon--${this.props.type}`)}
              name={iconNamesByClass[this.props.type]}
            />
        }
        {
          R.is(String, this.props.children) ?
            <small>{this.props.children}</small> :
            this.props.children
        }
        { !this.props.disableClose &&
          <Button
            icon="close"
            onClick={this.closeInlineNotification}
            className={classNames('inline-notification-message__close-button', `inline-notification-message__close-button--${this.props.type}`)}
            class="custom"
            dataCy={`${this.props.dataCy}-close-button`}
          /> }
      </div>
    );
  }
}

InlineNotificationMessage.propTypes = {
  type: PropTypes.oneOf(['success', 'info', 'warning', 'wait', 'system', 'message', 'expiration']).isRequired,
  children: PropTypes.node.isRequired,
  dataCy: PropTypes.string,
  className: PropTypes.string,
  disableClose: PropTypes.bool,
  onClose: PropTypes.func,
};

InlineNotificationMessage.defaultProps = {
  dataCy: null,
  className: null,
  disableClose: false,
  onClose: null,
};

export default InlineNotificationMessage;
