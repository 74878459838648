import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import * as R from 'ramda';

import Icon from '../Icon/Icon';
import { formTabShape, formTabDefaultProps, locationShape } from '../../../dataModel';
import i18n from '../../../services/i18n';
import { toInt } from '../../../utils/commonUtils';

import './NavbarForTabs.scss';

export const isNavLinkUrlIncludedInCurrentPathname = url => (match, location) => R.compose(
  R.includes(R.__, location.pathname),
  R.head,
  R.split('?'),
)(url);

const NavItemForTab = ({
  path,
  displayName,
  dataCy,
  showValidation,
  tabValid,
  navLinkClassName,
  navLinkActiveClassName,
  navLinkTextType,
  location,
  amount,
}) => {
  return (
    <NavLink
      to={{
        pathname: path,
        search: location.search,
      }}
      className={classnames('navbar-for-tabs__navbar-link', navLinkClassName)}
      data-cy={dataCy}
      activeClassName={classnames('navbar-for-tabs__navbar-link--selected', navLinkActiveClassName)}
      isActive={isNavLinkUrlIncludedInCurrentPathname(path)}
    >
      { R.equals(navLinkTextType, 'small') ? <small>{ displayName }</small> : <h2>{ displayName }</h2> }
      { showValidation && tabValid.isValid && tabValid.isTouched ? <Icon name="check_circle" className="navbar-for-tabs__navbar-link-icon" ariaLabel={i18n.t('common:accessibleIconLabels.done')} /> : null }
      { showValidation && !tabValid.isValid && tabValid.isTouched ? <Icon name="warning" className="navbar-for-tabs__navbar-link-icon--invalid" ariaLabel={i18n.t('common:accessibleIconLabels.validationErrors')} /> : null }
      { R.gt(amount, 0) &&
        <div className="navbar-for-tabs__navbar-link-block">
          <div className="navbar-for-tabs__navbar-link-block-amount">{amount}</div>
        </div>}
    </NavLink>
  );
};

NavItemForTab.propTypes = {
  navLinkClassName: PropTypes.string,
  navLinkActiveClassName: PropTypes.string,
  navLinkTextType: PropTypes.oneOf(['small', 'h2']).isRequired,
  path: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  dataCy: PropTypes.string.isRequired,
  showValidation: PropTypes.bool,
  tabValid: formTabShape,
  location: locationShape.isRequired,
  amount: PropTypes.number,
};

NavItemForTab.defaultProps = {
  navLinkClassName: null,
  navLinkActiveClassName: null,
  showValidation: false,
  tabValid: formTabDefaultProps,
  amount: null,
};

const NavItemForTabWithRouter = withRouter(NavItemForTab);

const NavbarForTabs = ({
  containerClassName,
  navClassName,
  navLinkClassName,
  navLinkActiveClassName,
  navLinkTextType,
  tabs,
  CustomNavItem,
  tabValidationData,
}) => {
  return (
    <div className={classnames('navbar-for-tabs__container', containerClassName)}>
      <nav className={classnames('navbar-for-tabs__navbar', 'navbar', 'navbar-expand-sm', 'navbar-default', navClassName)} role="navigation" aria-label={i18n.t('application.title')}>
        <ul className={classnames('nav', 'navbar-nav', 'navbar-for-tabs__navbar-list')}>
          {tabs.map((tab) => {
            return (
              <li key={tab.name}>
                { CustomNavItem ?
                  React.cloneElement(
                    CustomNavItem,
                    {
                      ...tab,
                    },
                  ) :
                  <NavItemForTabWithRouter
                    navLinkClassName={navLinkClassName}
                    navLinkActiveClassName={navLinkActiveClassName}
                    navLinkTextType={navLinkTextType}
                    path={tab.path}
                    showValidation={!!tabValidationData}
                    dataCy={`navbar-tab-${tab.name}`}
                    displayName={tab.displayName}
                    amount={toInt(tab.count)}
                    tabValid={tabValidationData && tabValidationData[tab.name]}
                  />}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

NavbarForTabs.propTypes = {
  containerClassName: PropTypes.string,
  navClassName: PropTypes.string,
  navLinkClassName: PropTypes.string,
  navLinkActiveClassName: PropTypes.string,
  navLinkTextType: PropTypes.oneOf(['small', 'h2']),
  tabs: PropTypes.arrayOf(PropTypes.exact({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    count: PropTypes.string,
  })).isRequired,
  CustomNavItem: PropTypes.element,
  tabValidationData: PropTypes.objectOf(formTabShape),
};

NavbarForTabs.defaultProps = {
  containerClassName: null,
  navClassName: null,
  navLinkClassName: null,
  navLinkActiveClassName: null,
  navLinkTextType: 'small',
  tabValidationData: null,
  CustomNavItem: null,
};

export default NavbarForTabs;
