import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Subsection.scss';

const Subsection = (props) => {
  return (
    <div className={classnames(
      'subsection',
      { 'subsection--without-top-margin': props.withoutTopMargin },
      { 'subsection--without-bottom-margin': props.withoutBottomMargin },
      { 'subsection--without-border': props.withoutBorder },
      props.className,
    )}
    >
      {props.title && <h4 className="subsection__title">{props.title}</h4>}
      {props.children}
    </div>
  );
};

Subsection.propTypes = {
  children: PropTypes.node.isRequired,
  withoutTopMargin: PropTypes.bool,
  withoutBottomMargin: PropTypes.bool,
  withoutBorder: PropTypes.bool,
  title: PropTypes.node,
  className: PropTypes.string,
};

Subsection.defaultProps = {
  withoutTopMargin: false,
  withoutBottomMargin: false,
  withoutBorder: false,
  title: null,
  className: null,
};

export default Subsection;
