import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { transformEventValueBeforeHandle } from '../../../../utils/commonUtils';
import CheckboxGroup from './CheckboxGroup';

const BooleanCheckboxGroup = (props) => {
  const transformValue = (value) => {
    if (R.includes('on', value)) {
      return true;
    }
    return props.treatFalseAsNull ? null : false;
  };

  const handleChange = transformEventValueBeforeHandle(
    props.onChange,
    transformValue,
  );

  return (
    <CheckboxGroup
      name={props.name}
      value={props.value ? ['on'] : []}
      onChange={handleChange}
      options={[{
        keyProp: props.name,
        value: 'on',
        label: props.label,
        required: props.required,
      }]}
      disabled={props.disabled}
      invalid={props.invalid}
    />
  );
};

BooleanCheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  treatFalseAsNull: PropTypes.bool,
  required: PropTypes.bool,
};

BooleanCheckboxGroup.defaultProps = {
  label: null,
  disabled: false,
  invalid: false,
  treatFalseAsNull: false,
  required: false,
};

export default BooleanCheckboxGroup;
