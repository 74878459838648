import React, { memo } from 'react';

import GuideSubtitle from '../../../components/Guide/GuideSubtitle';
import i18n from '../../../services/i18n';

const FingerPrintsAndDestinationPermitGuideWithExtras = memo(() => {
  return (
    <>
      <GuideSubtitle>{`28. ${i18n.t('common:applicationForm.fingerprintsAlreadyStored')}`}</GuideSubtitle>
      <div id="journeyDetailsGuide-fingerprintsAlreadyStored" lang={i18n.language()}>
        <p>
          <small>{i18n.t('application.guides.journeyDetailsGuide.fingerprintsAlreadyStored')}</small>
        </p>
        <ul>
          <li><small>{i18n.t('guideExtra.fingerprints.no')}</small></li>
          <li><small>{i18n.t('guideExtra.fingerprints.yes')}</small></li>
          <li><small>{i18n.t('guideExtra.fingerprints.date')}</small></li>
          <li><small>{i18n.t('guideExtra.fingerprints.stickerNumber')}</small></li>
        </ul>
      </div>
      <GuideSubtitle>{`29. ${i18n.t('common:applicationForm.finalDestination')}`}</GuideSubtitle>
      <div id="journeyDetailsGuide-finalDestination" lang={i18n.language()}>
        <p>
          <small>{i18n.t('application.guides.journeyDetailsGuide.finalDestination')}</small>
        </p>
        <ul>
          <li><small>{i18n.t('guideExtra.finalDestination.issuedBy')}</small></li>
          <li><small>{i18n.t('guideExtra.finalDestination.validUntil')}</small></li>
        </ul>
      </div>
    </>
  );
});

export default FingerPrintsAndDestinationPermitGuideWithExtras;
