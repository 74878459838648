import * as R from 'ramda';
import { types } from './loadingActions';

const loadingReducer = (state = {}, action) => {
  const { type } = action;

  if (type === types.CLEAR_LOADING) {
    return {};
  }

  const matches = /(.*)_(REQUEST|COMPLETED)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;
  const newState = {
    ...state,
    [requestName]: {
      status: requestState,
      error: action.error ? action.payload : null,
    },
  };
  return R.equals(state, newState) ? state : newState;
};

export default loadingReducer;
