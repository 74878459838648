import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableBodyCell = props => (
  <td
    className={classNames(
      'table__td',
      `table__td--vertical-align-${props.verticalAlign}`,
      {
        'table__td--without-vertical-padding': props.withoutVerticalPadding,
        'table__td--without-horizontal-padding': props.withoutHorizontalPadding,
        'table__td--without-padding': props.withoutPadding,
        'table__td--photo-in-cell': props.photoInCell,
      }, props.className,
    )}
    colSpan={props.colSpan}
  >
    {props.children}
  </td>
);

export default TableBodyCell;

TableBodyCell.propTypes = {
  children: PropTypes.node,
  withoutPadding: PropTypes.bool,
  withoutVerticalPadding: PropTypes.bool,
  withoutHorizontalPadding: PropTypes.bool,
  className: PropTypes.string,
  photoInCell: PropTypes.bool,
  verticalAlign: PropTypes.oneOf(['top', 'middle']),
  colSpan: PropTypes.number,
};

TableBodyCell.defaultProps = {
  children: null,
  withoutPadding: false,
  withoutVerticalPadding: false,
  withoutHorizontalPadding: false,
  className: null,
  photoInCell: false,
  verticalAlign: 'top',
  colSpan: 1,
};
