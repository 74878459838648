import React from 'react';
import PropTypes from 'prop-types';

import { notNilOrEmpty } from '../../../utils/commonUtils';

const Form = (props) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    if (notNilOrEmpty(props.onSubmit)) {
      props.onSubmit();
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className={props.className}
      noValidate
      data-cy={props.dataCy}
      id={props.id}
      tabIndex={props.tabIndex}
      autoComplete={props.autoComplete}
    >
      {props.children}
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataCy: PropTypes.string,
  id: PropTypes.string,
  tabIndex: PropTypes.string,
  autoComplete: PropTypes.string,
};

Form.defaultProps = {
  onSubmit: null,
  className: '',
  dataCy: null,
  id: null,
  tabIndex: null,
  autoComplete: process.env.REACT_APP_AUTOCOMPLETE || 'off',
};

export default Form;
