import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { notifyInfo } from 'visa-frontend-common/src/services/pushNotificationService';
import { checklistItemAttachmentShape } from '../../ovafModel';
import i18n from '../../services/i18n';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import actions from './ApplicationActions';

import './ChecklistItemAttachment.scss';

const ChecklistItemAttachment = ({ attachment, downloadAttachment }) => {
  const handleDownload = () => {
    downloadAttachment(attachment.attachmentKey)
      .then(R.when(
        R.is(Error),
        () => {
          notifyInfo(
            'checklist-attachment-download-failed',
            i18n.t('application.applicationDocuments.checklistAttachmentDownloadFailed'),
          );
        },
      ));
  };
  return (
    <li>
      <LoadingButton
        requestNames={[
          actions.createDownloadingChecklistAttachmentBlobAction(attachment.attachmentKey)().type,
        ]}
        className="checklist-item-attachment__button"
        class="invisible"
        onClick={handleDownload}
        icon="save_alt"
        iconOnEnd
        label={attachment.name}
      />
    </li>
  );
};

ChecklistItemAttachment.propTypes = {
  attachment: checklistItemAttachmentShape.isRequired,
  downloadAttachment: PropTypes.func.isRequired,
};

export default ChecklistItemAttachment;
