import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { fullName } from 'visa-frontend-common/src/utils/dataModelUtils';
import { getDaysSinceStartDateWithText } from 'visa-frontend-common/src/services/datetime';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';

import LoadingButton from '../UI/Button/LoadingButtonContainer';
import i18n from '../../services/i18n';
import ApplicationGroupAddForm from './ApplicationGroupAddFormContainer';

import './ApplicationListItem.scss';

const ApplicationListItem = memo((props) => {
  const {
    applicationId,
    printApplication,
    addApplicationToGroup,
    removeApplicationFromGroup,
    requestNames,
    finalizedAt,
    printedAt,
    isFormOpen,
  } = props;

  const applicantName =
    props.firstName || props.surname ?
      fullName(props.surname, props.firstName) :
      i18n.t('common.missingNamePlaceholder');

  return (
    <div className="application-list-item" data-cy={`application-list-item-${applicationId}`}>
      <div className="application-list-item__body">
        <div className="application-list-item__content">
          <small className="application-list-item__header-without-link-button">{applicantName}</small>
          <small className="application-list-item__status">
            <span>
              {`${i18n.t('frontPage.finalised')}: `}
            </span>
            {getDaysSinceStartDateWithText(finalizedAt)}
          </small>
          <span className="application-list-item__status">
            <LoadingButton
              icon="print"
              onClick={() => printApplication(applicationId)}
              disabled={isFormOpen}
              label={i18n.t('frontPage.printApplication')}
              dataCy={`application-list-item-${applicationId}-print-button`}
              requestNames={requestNames}
              className="application-list-item__print-button"
            />
            {printedAt && (
              <sub className="application-list-item__printed-at">
                {`${i18n.t('frontPage.printed')}: `}
                {getDaysSinceStartDateWithText(printedAt)}
              </sub>
            )}
          </span>
        </div>
        <div className="application-list-item__actions">
          {removeApplicationFromGroup && <Button
            icon="group_remove"
            class="invisible"
            label={i18n.t('applicationGroups.removeApplicationFromGroup')}
            onClick={() => removeApplicationFromGroup(applicationId)}
            dataCy={`application-list-item-${applicationId}-ungroup-button`}
          />}
          {addApplicationToGroup && <Button
            icon="group_add"
            class="invisible"
            label={i18n.t('applicationGroups.addToGroup')}
            onClick={() => addApplicationToGroup(applicationId)}
            disabled={isFormOpen}
            dataCy={`application-list-item-${applicationId}-group-button`}
          />}
        </div>
      </div>
      { isFormOpen && <ApplicationGroupAddForm /> }
    </div>
  );
});

ApplicationListItem.propTypes = {
  applicationId: PropTypes.string.isRequired,
  surname: PropTypes.string,
  firstName: PropTypes.string,
  finalizedAt: PropTypes.string.isRequired,
  printedAt: PropTypes.string,
  isFormOpen: PropTypes.bool.isRequired,
  printApplication: PropTypes.func.isRequired,
  addApplicationToGroup: PropTypes.func,
  removeApplicationFromGroup: PropTypes.func,
  requestNames: PropTypes.arrayOf(PropTypes.string),
};

ApplicationListItem.defaultProps = {
  firstName: null,
  surname: null,
  printedAt: null,
  addApplicationToGroup: null,
  removeApplicationFromGroup: null,
  requestNames: [],
};

export default ApplicationListItem;
