import React from 'react';
import PropTypes from 'prop-types';

import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import { putNotification } from 'visa-frontend-common/src/services/pushNotificationService';

import ConfirmationModal from '../../components/UI/ConfirmationModal/ConfirmationModalContainer';
import i18n from '../../services/i18n';
import { applicationListItemShape } from '../../ovafModel';
import { userAccountDeleted } from './AccountSettingsActions';

class DeleteUserModal extends React.Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    this.props.fetchApplications();
  }

  handleConfirm = () => this.props.deleteUser().then(() => {
    putNotification('accountSettings.userAccountDelete.accountRemoved');
    window.location = '/api/logout';
  });

  toggleOpen = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  render() {
    return (
      <>
        <ConfirmationModal
          requestNames={[userAccountDeleted().type]}
          isOpen={this.state.isOpen}
          onConfirm={this.handleConfirm}
          onCancel={this.toggleOpen}
          message={i18n.t('accountSettings.userAccountDelete.confirmTitle')}
          confirmButtonProps={{
            label: i18n.t('common:delete'),
            icon: 'delete_forever',
            color: 'red',
          }}
        >
          <div>
            {
              this.props.sentApplications.length === 0 ?
                i18n.t('accountSettings.userAccountDelete.confirmText') : i18n.t('accountSettings.userAccountDelete.confirmTextHasFinalized')
            }
          </div>
        </ConfirmationModal>
        <Button
          color="red"
          onClick={this.toggleOpen}
          icon="block"
          label={i18n.t('accountSettings.userAccountDelete.openConfirmButton')}
        />
      </>
    );
  }
}

DeleteUserModal.propTypes = {
  sentApplications: PropTypes.arrayOf(applicationListItemShape),
  fetchApplications: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
};

DeleteUserModal.defaultProps = {
  sentApplications: [],
};

export default DeleteUserModal;
