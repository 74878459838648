import React from 'react';
import PropTypes from 'prop-types';

import Accordion from 'visa-frontend-common/src/components/UI/Accordion/Accordion';

import i18n from '../../services/i18n';
import { applicationListItemShape } from '../../ovafModel';

import './ApplicationGroupListItem.scss';

const IndividualApplicationGroupListItem = (props) => {
  return (
    <div className="col-12 col-lg-6 application-group__individual">
      <Accordion
        expandButtonText={
          i18n.t('applicationGroups.individualApplication')
        }
        isOpenAtStart
        bodyContent={props.renderItem(props.application)}
      />
    </div>
  );
};

IndividualApplicationGroupListItem.propTypes = {
  application: applicationListItemShape.isRequired,
  renderItem: PropTypes.func.isRequired,
};

export default IndividualApplicationGroupListItem;
