import React, { memo } from 'react';
import PropTypes from 'prop-types';

import GuideSubtitle from '../../../components/Guide/GuideSubtitle';
import GuideItem from '../../../components/Guide/GuideItem';
import i18n from '../../../services/i18n';

const JourneyDetailsGuide = memo(({ children }) => {
  return (
    <>
      <GuideSubtitle id="guide-journeyDetailsSubtitle" type="h3">{i18n.t('common:applicationForm.journeyDetailsSubtitle')}</GuideSubtitle>

      <GuideItem id="journeyDetailsGuide-mainPurposeOfJourney" subTitle={`23. ${i18n.t('common:applicationForm.mainPurposeOfJourney')}`}>
        {i18n.t('application.guides.journeyDetailsGuide.mainPurposeOfJourney')}
      </GuideItem>

      <GuideItem id="journeyDetailsGuide-journeyPurposeDetails" subTitle={`24. ${i18n.t('common:applicationForm.journeyPurposeDetails')}`}>
        {i18n.t('application.guides.journeyDetailsGuide.journeyPurposeDetails')}
      </GuideItem>

      <GuideItem id="journeyDetailsGuide-destinationStates" subTitle={`25. ${i18n.t('common:applicationForm.destinationStates')}`}>
        {i18n.t('application.guides.journeyDetailsGuide.destinationStates')}
      </GuideItem>

      <GuideItem id="journeyDetailsGuide-borderOfFirstEntryCountry" subTitle={`26. ${i18n.t('common:applicationForm.borderOfFirstEntryCountry')}`}>
        {i18n.t('application.guides.journeyDetailsGuide.borderOfFirstEntryCountry')}
      </GuideItem>

      <GuideItem id="journeyDetailsGuide-numberOfEntriesRequested" subTitle={`27. ${i18n.t('common:applicationForm.numberOfEntriesRequested')}`}>
        {i18n.t('application.guides.journeyDetailsGuide.numberOfEntriesRequested')}
      </GuideItem>

      <GuideItem id="journeyDetailsGuide-dateOfArrivalToSchengenArea" subTitle={i18n.t('common:applicationForm.dateOfArrivalToSchengenArea')}>
        {i18n.t('application.guides.journeyDetailsGuide.dateOfArrivalToSchengenArea')}
      </GuideItem>

      <GuideItem id="journeyDetailsGuide-dateOfDeparture" subTitle={i18n.t('common:applicationForm.dateOfDeparture')}>
        {i18n.t('application.guides.journeyDetailsGuide.dateOfDeparture')}
      </GuideItem>

      { children }
    </>
  );
});

JourneyDetailsGuide.propTypes = {
  children: PropTypes.node.isRequired,
};

export default JourneyDetailsGuide;
