import * as R from 'ramda';
import { createAction } from 'redux-actions';
import apiThunkWithLoading from 'visa-frontend-common/src/utils/actionUtils';
import { notifySuccess } from 'visa-frontend-common/src/services/pushNotificationService';
import {
  pickValuesDeep,
  convertToForm,
} from 'visa-frontend-common/src/utils/commonUtils';
import {
  getCurrentUserAccountData,
  setCurrentUserAccountData,
  deleteCurrentUser,
} from '../../services/utilityService';
import registerActions from '../Register/RegisterActions';
import i18n from '../../services/i18n';

export const loadingUserAccountData = createAction('USER_ACCOUNT_DATA_LOAD_COMPLETED');
export const savingUserAccountData = createAction('USER_ACCOUNT_DATA_SAVE_COMPLETED');
export const userAccountDeleted = createAction('USER_ACCOUNT_DELETE_COMPLETED');

export const loadUserAccountData = () => dispatch => (
  dispatch(apiThunkWithLoading(getCurrentUserAccountData, loadingUserAccountData)())
    .then(convertToForm)
    .then(data => dispatch(registerActions.registerFormFieldSet(data)))
);

export const saveUserAccountData = registerForm => dispatch => (
  dispatch(apiThunkWithLoading(setCurrentUserAccountData, savingUserAccountData)(
    pickValuesDeep(registerForm),
  ))
    .then(R.ifElse(
      R.is(Error),
      err => err,
      (data) => {
        dispatch(registerActions.registerFormFieldSet({
          ...convertToForm(data),
          currentPassword: { value: null },
          password: { value: null },
          confirmPassword: { value: null },
        }));
        notifySuccess('account-settings-save-success', i18n.t('accountSettings.saveSuccess'));
      },
    ))
);

export const deleteUserAccount = () => (dispatch) => {
  return dispatch(apiThunkWithLoading(
    deleteCurrentUser,
    userAccountDeleted,
  )());
};
