import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import i18n from '../../../services/i18n';
import { booleanToOption, optionToBoolean } from '../../../utils/commonUtils';
import TwoColumnRow from '../../UI/TwoColumnRow/TwoColumnRow';
import Subsection from '../../UI/Subsection/Subsection';
import { formFieldShapeFor } from '../../../dataModel';
import {
  dateInputProps,
  inputProps,
  CheckboxGroup,
  DateInput,
  Input,
  InputGroup,
} from '../../UI/Form/FormComponents';
import { formEntryPermitShape } from '../commonApplicationModel';

const EntryPermit =
({
  entryPermitForFinalDestination,
  entryPermit,
  handleChange,
  clearFields,
  oneColumn,
}) => {
  const toggleOption = [
    {
      keyProp: 'entryPermitForFinalDestination.YES',
      value: 'true',
      label: i18n.t('common:applicationForm.entryPermitForFinalDestination'),
    }];

  const shouldHaveEntryPermit = () => {
    return R.defaultTo(false)(entryPermitForFinalDestination.value);
  };

  const handleResidenceOptionChange = (event) => {
    const newValue = optionToBoolean(event.target.value);

    handleChange({
      target: {
        name: event.target.name,
        value: newValue,
      },
    });

    if (!newValue) {
      clearFields(['form.entryPermit']);
    }
  };

  const textInputProps = inputProps('text', R.__, R.__, R.__, false, handleChange);
  const dateProps = dateInputProps(R.__, R.__, false, false, handleChange);
  const finalDestinationLabel = `${i18n.t('common:applicationForm.finalDestination')} [29]`;
  const issuingAuthorityLabel = `${i18n.t('common:applicationForm.entryPermitIssuingAuthority')}`;
  const entryPermitValidFromLabel = `${i18n.t('common:applicationForm.entryPermitValidFrom')}`;
  const entryPermitValidUntilLabel = `${i18n.t('common:applicationForm.entryPermitValidUntil')}`;

  return (
    <TwoColumnRow oneColumn={oneColumn}>
      <>
        <InputGroup
          label={finalDestinationLabel}
          validationError={entryPermitForFinalDestination.validationError}
          describedBy="journeyDetailsGuide-finalDestination"
        >
          <CheckboxGroup
            name="entryPermitForFinalDestination"
            value={booleanToOption(entryPermitForFinalDestination.value)}
            options={toggleOption}
            onChange={handleResidenceOptionChange}
          />
        </InputGroup>

        { shouldHaveEntryPermit() &&
        <Subsection>
          <InputGroup
            label={issuingAuthorityLabel}
            validationError={entryPermit.value.issuingAuthority.validationError}
            describedBy="journeyDetailsGuide-finalDestination"
          >
            <Input {...textInputProps(entryPermit.value.issuingAuthority.value, 'entryPermit.issuingAuthority', null)} uppercase />
          </InputGroup>

          <InputGroup
            label={entryPermitValidFromLabel}
            validationError={entryPermit.value.validFrom.validationError}
            withHelp={i18n.t('common:dateFormats.inputFormatLongDateDescription')}
            describedBy="journeyDetailsGuide-finalDestination"
          >
            <DateInput
              {...dateProps(entryPermit.value.validFrom.value, 'entryPermit.validFrom', null)}
              shortYearInFuture
              useLongDates
            />
          </InputGroup>

          <InputGroup
            label={entryPermitValidUntilLabel}
            validationError={entryPermit.value.validUntil.validationError}
            withHelp={i18n.t('common:dateFormats.inputFormatLongDateDescription')}
            describedBy="journeyDetailsGuide-finalDestination"
          >
            <DateInput
              {...dateProps(entryPermit.value.validUntil.value, 'entryPermit.validUntil', null)}
              shortYearInFuture
              useLongDates
            />
          </InputGroup>
        </Subsection>}
      </>
    </TwoColumnRow>
  );
};

EntryPermit.propTypes = {
  entryPermitForFinalDestination: formFieldShapeFor(PropTypes.bool).isRequired,
  entryPermit: formFieldShapeFor(formEntryPermitShape).isRequired,
  handleChange: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  oneColumn: PropTypes.bool,
};

EntryPermit.defaultProps = {
  oneColumn: false,
};

export default EntryPermit;
