import React from 'react';
import i18n from '../../services/i18n';
import paths from '../../paths';

import DocumentTitle from '../../components/DocumentTitle/DocumentTitle';

import './Accessibility.scss';

const Accessibility = () => (
  <div className="accessibility container" data-cy="accessibility-statement" role="main" aria-labelledby="accessibility-title">
    <DocumentTitle>{i18n.t('accessibility:title')}</DocumentTitle>
    <h1 id="accessibility-title">{i18n.t('accessibility:title')}</h1>

    <p>
      {i18n.t(
        'accessibility:paragraph1',
        {
          link: (<a href={paths.root}>finlandvisa.fi</a>),
          postProcess: 'reactPostprocessor',
        },
      )}
    </p>
    <p>{i18n.t('accessibility:paragraph2')}</p>

    <h2>{i18n.t('accessibility:fulfillmentSubtitle')}</h2>
    <p>{i18n.t('accessibility:fulfillment')}</p>

    <h2>{i18n.t('accessibility:aboutSubtitle')}</h2>
    <p>{i18n.t('accessibility:aboutParagraph1')}</p>
    <p>{i18n.t('accessibility:aboutParagraph2')}</p>

    <h2>{i18n.t('accessibility:feedbackSubtitle')}</h2>
    <p>{i18n.t('accessibility:feedbackParagraph1')}</p>
    <p>
      {i18n.t('accessibility:feedbackParagraph2')}
      {': '}
      <a href="mailto:kirjaamo.um@gov.fi">kirjaamo.um@gov.fi</a>
    </p>

    <h2>{i18n.t('accessibility:implementationSubtitle')}</h2>
    <p>
      {i18n.t(
        'accessibility:implementationParagraph',
        {
          link: (
            <a href={i18n.t('accessibility:accessibilityLink')} target="_blank" rel="noopener noreferrer">
              {i18n.t('accessibility:accessibilityLinkLabel')}
            </a>
          ),
          postProcess: 'reactPostprocessor',
        },
      )}
    </p>

    <h2>{i18n.t('accessibility:contactSubtitle')}</h2>
    <p>
      {i18n.t('accessibility:officeName')}
      <br />
      (
      {i18n.t('accessibility:officeNameTranslated')}
      )
    </p>
    <ul>
      <li>
        {i18n.t(
          'accessibility:website',
          {
            link: (
              <a href={i18n.t('accessibility:accessibilityLink')} target="_blank" rel="noopener noreferrer">
                {i18n.t('accessibility:accessibilityLinkLabel')}
              </a>
            ),
            postProcess: 'reactPostprocessor',
          },
        )}
      </li>
      <li>
        {i18n.t('accessibility:email')}
        {': '}
        <a href="mailto:saavutettavuus@avi.fi">saavutettavuus@avi.fi</a>
      </li>
      <li>
        {i18n.t('accessibility:switchboard')}
        {': '}
        <a href="tel:+358295016000">+358 295 016 000</a>
      </li>
    </ul>
  </div>
);

export default Accessibility;
