import React from 'react';

import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import { formFieldShape } from 'visa-frontend-common/src/dataModel';

import i18n from '../../../../services/i18n';

const PreviewApplicationDetails = (props) => {
  return (
    <div className="preview-application__section">
      { props.travelAgencyName.value &&
        <LabeledValue
          labelText={i18n.t('common:applicationForm.travelAgencyName')}
          dataCy="labeled-travelAgencyName"
          value={props.travelAgencyName.value}
        />}
    </div>
  );
};

PreviewApplicationDetails.propTypes = {
  travelAgencyName: formFieldShape.isRequired,
};

export default PreviewApplicationDetails;
