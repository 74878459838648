import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import LanguageSelector from 'visa-frontend-common/src/components/LanguageSelector/LanguageSelector';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import Icon from 'visa-frontend-common/src/components/UI/Icon/Icon';
import InlineNotification from 'visa-frontend-common/src/components/UI/InlineNotification/InlineNotification';
import { historyShape } from 'visa-frontend-common/src/dataModel';
import { convertToForm, eventToField } from 'visa-frontend-common/src/utils/commonUtils';

import paths from '../../paths';
import i18n from '../../services/i18n';
import {
  applicationCopyFormShape,
  applicationGroupFormShape,
  applicationGroupShape,
  applicationListItemShape,
  languageSelectorOptions,
} from '../../ovafModel';
import Guide from '../../components/Guide/Guide';
import FrontPageGuide from './FrontPageGuide';
import Welcome from '../Welcome/Welcome';
import FrontPageApplicationLists from './FrontPageApplicationLists';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import actions from './FrontPageActions';

import './FrontPage.scss';

class FrontPage extends React.Component {
  handleCreateSingleApplicationClick = () => {
    this.props.history.push(paths.application.create);
  };

  handleCreateApplicationGroupClick = () => {
    this.props.createApplicationGroup();
  };

  handleCreateApplicationInGroup = (groupId) => {
    Promise.resolve(this.props.history.push(paths.application.create))
      .then(() => R.compose(this.props.applicationFormFieldSet, convertToForm)({ groupId }));
  };

  handleApplicationGroupFormFieldChange =
    R.compose(this.props.applicationGroupFormFieldSet, eventToField);

  handleApplicationCopyFormFieldChange = event =>
    this.props.setCopyFieldAndValidate(eventToField(event), this.props.applicationCopyForm);

  render() {
    if (!this.props.userDataFetchIsDone) {
      return null;
    }

    if (!this.props.userLoggedIn) {
      return <Welcome history={this.props.history} />;
    }

    return (
      <div className="front-page container" role="main">
        <div className="row" id="main" tabIndex="-1">
          { R.gt(this.props.pendingAmount, 0) &&
            <div className="col-12">
              <InlineNotification type="message" keyProp="openRequestsInfo" className="front-page__pending-notification">
                <Link to={paths.pending.root} className="front-page__pending-notification__content">
                  <span>
                    {i18n.t('pending.openRequestsInfo', { count: this.props.pendingAmount })}
                  </span>
                  <Icon name="arrow_forward" />
                </Link>
              </InlineNotification>
            </div> }
          <div className="col-12">
            <div className="front-page__language-wrapper">
              <LanguageSelector
                languageSelectorOptions={languageSelectorOptions}
                locale={this.props.locale}
                updateLocale={this.props.updateLocale}
                label={i18n.t('frontPage.selectLanguage')}
              />
            </div>

            <h1>{i18n.t('frontPage.title')}</h1>
            <div className="front-page__create-application-controls">
              <Button
                label={i18n.t('frontPage.createNewApplication')}
                icon="post_add"
                onClick={this.handleCreateSingleApplicationClick}
                dataCy="create-application"
              />
              <ul className="front-page__instructions">
                <li>{i18n.t('applicationGroups.createApplicationGroupInstructions1')}</li>
                <li>{i18n.t('applicationGroups.createApplicationGroupInstructions2')}</li>
                <li>{i18n.t('applicationGroups.createApplicationGroupInstructions3')}</li>
              </ul>
              <LoadingButton
                requestNames={[actions.applicationGroupsFetched().type]}
                label={i18n.t('applicationGroups.createApplicationGroup')}
                icon="group"
                onClick={this.handleCreateApplicationGroupClick}
                dataCy="create-application-group"
              />
            </div>
          </div>
        </div>
        <>
          <FrontPageApplicationLists
            fetchApplications={this.props.fetchApplications}
            fetchApplicationGroups={this.props.fetchApplicationGroups}
            printApplication={this.props.printApplication}
            printApplicationInBrowser={this.props.printApplicationInBrowser}
            deleteApplication={this.props.deleteApplication}
            addApplicationToGroup={this.props.addApplicationToGroup}
            removeApplicationFromGroup={this.props.removeApplicationFromGroup}
            deleteApplicationGroup={this.props.deleteApplicationGroup}
            createApplicationInGroup={this.handleCreateApplicationInGroup}
            setFormField={this.handleApplicationGroupFormFieldChange}
            setApplicationCopyFormField={this.handleApplicationCopyFormFieldChange}
            clearAddApplicationForm={this.props.applicationCopyFormCleared}
            draftApplications={this.props.draftApplications}
            sentApplications={this.props.sentApplications}
            inProcessingApplications={this.props.inProcessingApplications}
            draftApplicationGroups={this.props.draftApplicationGroups}
            sentApplicationGroups={this.props.sentApplicationGroups}
            inProcessingApplicationGroups={this.props.inProcessingApplicationGroups}
            applicationGroupForm={this.props.applicationGroupForm}
            applicationCopyForm={this.props.applicationCopyForm}
            history={this.props.history}
          />
        </>

        <Guide openModalAtStart={this.props.showFrontPageGuide}>
          <FrontPageGuide />
        </Guide>
      </div>
    );
  }
}

FrontPage.propTypes = {
  history: historyShape.isRequired,
  draftApplications: PropTypes.arrayOf(applicationListItemShape),
  sentApplications: PropTypes.arrayOf(applicationListItemShape),
  inProcessingApplications: PropTypes.arrayOf(applicationListItemShape),
  draftApplicationGroups: PropTypes.arrayOf(applicationGroupShape),
  sentApplicationGroups: PropTypes.arrayOf(applicationGroupShape),
  inProcessingApplicationGroups: PropTypes.arrayOf(applicationGroupShape),
  applicationGroupForm: applicationGroupFormShape.isRequired,
  applicationCopyForm: applicationCopyFormShape.isRequired,
  fetchApplications: PropTypes.func.isRequired,
  updateLocale: PropTypes.func.isRequired,
  printApplication: PropTypes.func.isRequired,
  printApplicationInBrowser: PropTypes.func.isRequired,
  deleteApplication: PropTypes.func.isRequired,
  locale: PropTypes.string,
  userLoggedIn: PropTypes.bool,
  userDataFetchIsDone: PropTypes.bool,
  showFrontPageGuide: PropTypes.bool,
  pendingAmount: PropTypes.number,
  fetchApplicationGroups: PropTypes.func.isRequired,
  createApplicationGroup: PropTypes.func.isRequired,
  deleteApplicationGroup: PropTypes.func.isRequired,
  addApplicationToGroup: PropTypes.func.isRequired,
  removeApplicationFromGroup: PropTypes.func.isRequired,
  applicationGroupFormFieldSet: PropTypes.func.isRequired,
  applicationFormFieldSet: PropTypes.func.isRequired,
  setCopyFieldAndValidate: PropTypes.func.isRequired,
  applicationCopyFormCleared: PropTypes.func.isRequired,
};

FrontPage.defaultProps = {
  draftApplications: [],
  sentApplications: [],
  inProcessingApplications: [],
  draftApplicationGroups: [],
  sentApplicationGroups: [],
  inProcessingApplicationGroups: [],
  locale: null,
  userLoggedIn: false,
  userDataFetchIsDone: false,
  showFrontPageGuide: true,
  pendingAmount: 0,
};

export default FrontPage;
