import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as R from 'ramda';

import Icon from '../Icon/Icon';
import i18n from '../../../services/i18n';
import { generateId } from '../../../services/idService';

import './Accordion.scss';

class Accordion extends React.PureComponent {
  state = {
    isExpanded: this.props.isOpenAtStart,
  };

  toggleExpand = (event) => {
    R.when(
      R.either(R.isNil, R.includes(R.__, ['Enter', ' ', 'Spacebar'])),
      () => {
        event.preventDefault();
        this.setState((prevState => ({
          isExpanded: !prevState.isExpanded,
        })));
      },
    )(event.key);
  };

  accordionBodyId = generateId();

  render() {
    return (
      <div
        className={classnames(
          'accordion',
          this.props.className,
        )}
        data-cy={this.props.dataCy}
      >
        <div
          className={classnames(
            'accordion__header',
            { 'accordion__header--reverse': this.props.expandButtonOnRight },
            this.props.headerClassName,
          )}
          tabIndex={0}
          role="button"
          onClick={this.toggleExpand}
          onKeyDown={this.toggleExpand}
          aria-expanded={this.state.isExpanded}
          aria-controls={this.accordionBodyId}
          data-cy={this.props.dataCy ? `${this.props.dataCy}-accordion-expand-button` : null}
        >
          <div className={classnames(
            'accordion__header-expand-section',
            { 'accordion__header-expand-section--margin-right': this.props.expandButtonText },
            { 'accordion__header-expand-section--reverse': this.props.expandButtonOnRight },
            this.props.expandSectionClassName,
          )}
          >
            <Icon
              className="accordion__header-expand-icon"
              name={!this.state.isExpanded ? 'expand_more' : 'expand_less'}
              ariaLabel={!this.state.isExpanded ? i18n.t('common:show') : i18n.t('common:hide')}
            />
            {this.props.expandButtonText &&
            <span>
              { (this.state.isExpanded && this.props.expandButtonShowLessText) ?
                this.props.expandButtonShowLessText :
                this.props.expandButtonText}
            </span>}
          </div>
          {this.props.headerContent}
        </div>
        {
          this.state.isExpanded &&
          <div
            id={this.accordionBodyId}
            className={classnames(
              'accordion__body',
              this.props.bodyClassName,
            )}
          >
            {this.props.bodyContent}
          </div>
        }
      </div>
    );
  }
}

Accordion.propTypes = {
  expandButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headerContent: PropTypes.node,
  bodyContent: PropTypes.node.isRequired,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  dataCy: PropTypes.string,
  isOpenAtStart: PropTypes.bool,
  headerClassName: PropTypes.string,
  expandButtonOnRight: PropTypes.bool,
  // Works only if expandButtonText has a non-nullable value
  expandButtonShowLessText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  expandSectionClassName: PropTypes.string,
};

Accordion.defaultProps = {
  expandButtonText: null,
  headerContent: null,
  className: null,
  bodyClassName: null,
  dataCy: null,
  isOpenAtStart: false,
  headerClassName: null,
  expandButtonOnRight: false,
  expandButtonShowLessText: null,
  expandSectionClassName: null,
};

export default Accordion;
