import React, { memo } from 'react';

import GuideSubtitle from '../../../components/Guide/GuideSubtitle';
import i18n from '../../../services/i18n';

const ResidencePermitGuideWithExtras = memo(() => {
  return (
    <>
      <GuideSubtitle>{`20. ${i18n.t('common:applicationForm.residenceInOtherCountry')}`}</GuideSubtitle>
      <div id="occupationAndResidenceGuide-residenceInOtherCountry" lang={i18n.language()}>
        <p>
          <small>{i18n.t('application.guides.occupationAndResidenceGuide.residenceInOtherCountry')}</small>
        </p>
        <ul>
          <li><small>{i18n.t('guideExtra.residencePermit.no')}</small></li>
          <li><small>{i18n.t('guideExtra.residencePermit.yes')}</small></li>
          <li><small>{i18n.t('guideExtra.residencePermit.number')}</small></li>
          <li><small>{i18n.t('guideExtra.residencePermit.validUntil')}</small></li>
        </ul>
      </div>
    </>
  );
});

export default ResidencePermitGuideWithExtras;
