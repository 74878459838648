import React from 'react';
import PropTypes from 'prop-types';

import LanguageSelector from 'visa-frontend-common/src/components/LanguageSelector/LanguageSelector';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import { printApplicationInIframe } from 'visa-frontend-common/src/services/htmlPrintingService';

import { languageSelectorOptions, languagesWithExtraGuides } from '../../ovafModel';
import i18n from '../../services/i18n';
import FrontPageGuide from '../FrontPage/FrontPageGuide';
import EuFamilyRelationshipGuideWithExtras from '../Application/Guides/EuFamilyRelationshipGuideWithExtras';
import EuFamilyRelationshipGuide from '../Application/Guides/EuFamilyRelationshipGuide';
import PersonalDetailsGuide from '../Application/Guides/PersonalDetailsGuide';
import TravelDocumentsGuide from '../Application/Guides/TravelDocumentsGuide';
import ResidencePermitGuideWithExtras from '../Application/Guides/ResidencePermitGuideWithExtras';
import ResidencePermitGuide from '../Application/Guides/ResidencePermitGuide';
import OccupationAndResidenceGuide from '../Application/Guides/OccupationAndResidenceGuide';
import FingerPrintsAndDestinationPermitGuideWithExtras
  from '../Application/Guides/FingeprintsAndDestinationPermitGuideWithExtras';
import FingerPrintsAndDestinationPermitGuide from '../Application/Guides/FingerprintsAndDestinationPermitGuide';
import JourneyDetailsGuide from '../Application/Guides/JourneyDetailsGuide';
import ContactsAndAccommodationGuide from '../Application/Guides/ContactsAndAccommodationGuide';
import ApplicationDocumentsGuide from '../Application/Guides/ApplicationDocumentsGuide';
import FinalizeGuide from '../Application/Guides/FinalizeGuide';

import './GuidePage.scss';

const GuidePage = (props) => {
  const currentLocale = i18n.getLocaleFromLocalStorage();

  const updateLocale = (newLocale) => {
    if (props.userLoggedIn) {
      props.updateLocale(newLocale);
    } else {
      i18n.setLocale(newLocale, window.location.reload());
    }
  };

  const isWithExtraGuides = () => languagesWithExtraGuides.includes(i18n.language());

  const printGuide = () => {
    const element = document.getElementById('guide-page-content');
    const html = element.innerHTML;
    printApplicationInIframe(html);
  };

  return (
    <div className="guide-page container" role="main">
      <div className="guide-page__toolbar">
        <div className="guide-page__language-wrapper">
          <LanguageSelector
            languageSelectorOptions={languageSelectorOptions}
            locale={currentLocale}
            updateLocale={updateLocale}
          />
        </div>
        <Button
          className="guide-page__print-guide-button"
          label={i18n.t('guidePage.printButtonLabel')}
          icon="print"
          onClick={printGuide}
          class="primary"
          dataCy="print-guide-button"
        />
      </div>
      <div className="guide-page__content" id="guide-page-content">
        <h1 id="guide-mainTitle">{i18n.t('guide.mainTitle')}</h1>
        <FrontPageGuide />
        <PersonalDetailsGuide>
          {isWithExtraGuides() ?
            <EuFamilyRelationshipGuideWithExtras /> :
            <EuFamilyRelationshipGuide />}
        </PersonalDetailsGuide>
        <TravelDocumentsGuide />
        <OccupationAndResidenceGuide>
          {isWithExtraGuides() ?
            <ResidencePermitGuideWithExtras /> :
            <ResidencePermitGuide />}
        </OccupationAndResidenceGuide>
        <JourneyDetailsGuide>
          {isWithExtraGuides() ?
            <FingerPrintsAndDestinationPermitGuideWithExtras /> :
            <FingerPrintsAndDestinationPermitGuide />}
        </JourneyDetailsGuide>
        <ContactsAndAccommodationGuide />
        <ApplicationDocumentsGuide />
        <FinalizeGuide />
      </div>
    </div>
  );
};

GuidePage.propTypes = {
  userLoggedIn: PropTypes.bool,
  updateLocale: PropTypes.func.isRequired,
};

GuidePage.defaultProps = {
  userLoggedIn: false,
};

export default GuidePage;
