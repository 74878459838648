import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableRow = props => (
  <tr className={classNames('table__tr', props.className)} data-cy={props.dataCy}>
    {props.children}
  </tr>
);

export default TableRow;

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataCy: PropTypes.string,
};

TableRow.defaultProps = {
  className: null,
  dataCy: null,
};
