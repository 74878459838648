import * as R from 'ramda';
import { matchPath } from 'react-router-dom';

import { isObjectNotArrayNorString } from './commonUtils';

// TODO: hash instead of stringified - might have memory impact with very large arrays/objects
// also does not work properly with Files UV-8829
const createSelector = (inputSelectors, resultFunction) =>
  R.converge(R.memoizeWith(R.unapply(JSON.stringify), resultFunction), inputSelectors);

const routerPathSelector = createSelector([R.path(['router', 'location', 'pathname'])], R.identity);

const queryParametersSelector = createSelector([R.path(['router', 'location', 'query'])], R.identity);

const routerLocationSelector = createSelector([R.path(['router', 'location'])], R.identity);

const locationChangeResetReducerFor = (initialState, path) => (state, action) => R.compose(
  R.ifElse(R.isNil, R.always(initialState), R.always(state)),
  R.curry(matchPath)(R.__, { path, exact: true }),
  R.path(['payload', 'location', 'pathname']),
)(action);

const dropUnwantedProperties = R.curry((validProps, updated) => {
  const wantedProperties = R.pick(R.keys(validProps), updated);
  if (R.compose(R.not, R.isEmpty, R.difference)(R.keys(updated), R.keys(wantedProperties))) {
    /* eslint no-console: ["error", { allow: ["warn"] }] */
    console.warn('Dropped properties', R.omit(R.keys(validProps), updated), 'from', updated);
  }
  return wantedProperties;
});

const recursivePurifyProps = (validProps, updated) => {
  if (isObjectNotArrayNorString(updated) && validProps) {
    return R.compose(
      R.fromPairs,
      R.map(([key, value]) => [key, recursivePurifyProps(validProps[key], value)]),
      R.toPairs,
      dropUnwantedProperties,
    )(validProps, updated);
  }
  return updated;
};

/*
Updates and validates substate of the given field.
The action.payload content must be at the given field level.
It will remove all keys (deep check) that are not in initialState.
Does not validate array contents.
*/
const fieldSetReducerFor = R.curry((initialState, field) => (state, action) => {
  if (!R.has(field, initialState)) {
    return state;
  }
  if (isObjectNotArrayNorString(action.payload)) {
    const updatedFieldState = recursivePurifyProps(
      initialState[field],
      R.mergeDeepRight(state[field], action.payload),
    );
    return { ...state, [field]: updatedFieldState };
  }
  return { ...state, [field]: action.payload };
});

const fieldSetWithErrorReducerFor = R.curry((initialState, field, errorValue) =>
  (state, action) => {
    if (action.error) {
      return fieldSetReducerFor(initialState, field)(state, { payload: errorValue });
    }
    return fieldSetReducerFor(initialState, field)(state, action);
  });

const fetchReducerFor = R.curry((initialState, field) =>
  fieldSetWithErrorReducerFor(initialState, field, R.prop(field, initialState)));

// Updates (overwrites) the array in given path
// Path should point to the array
const arraySetReducerFor = path => (state, action) => {
  const arrayLens = R.lensPath(path);
  return R.set(arrayLens, action.payload, state);
};

// Updates a field in indexed object in the given path
// Path should point to the array and payload object should have fieldName, fieldValue and index
const arrayUpdateReducerFor = path => (state, action) => {
  const arrayLens = R.lensPath(path);
  return R.over(
    arrayLens,
    R.adjust(
      action.payload.index,
      R.evolve({ [action.payload.fieldName]: R.mergeDeepLeft(action.payload.fieldValue) }),
    ),
    state,
  );
};

const resetStateBranches = (state, initialState, stateKeys) => {
  if (!stateKeys) {
    return {
      ...initialState,
    };
  }

  const resetBranch = (acc, key) => {
    const path = R.split('.', key);
    const initialValue = R.path(path, initialState);
    if (R.isNil(initialValue)) return acc;
    return R.assocPath(path, initialValue, acc);
  };
  return R.reduce(resetBranch, state, stateKeys);
};

const resetReducerFor = initialState => (state, action) => {
  return resetStateBranches(state, initialState, action.payload);
};

export {
  arraySetReducerFor,
  arrayUpdateReducerFor,
  fieldSetReducerFor,
  recursivePurifyProps,
  fieldSetWithErrorReducerFor,
  fetchReducerFor,
  resetStateBranches,
  resetReducerFor,
  createSelector,
  routerPathSelector,
  queryParametersSelector,
  routerLocationSelector,
  locationChangeResetReducerFor,
};
