import { createAction } from 'redux-actions';

const listPaginationChanged = createAction(
  'LIST_PAGINATION_CHANGED',
  (listName, rowsOnPage, currentPage) => ({ listName, rowsOnPage, currentPage }),
);

const listPaginationReset = listName => listPaginationChanged(listName);

const actions = {
  listPaginationChanged,
  listPaginationReset,
};

export default actions;
