import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as R from 'ramda';

import i18n from '../../../../services/i18n';
import compatibleEvent from '../../../../compatibilityFixes';
import ExpandableText from '../../ExpandableText/ExpandableText';
import { radioButtonOptionShape } from './radioButtonModel';

import './RadioButtonGroup.scss';

const RadioButton = (props) => {
  const id = `${props.name}-${props.keyProp}`;

  return (
    <div className={classnames(
      'mdc-form-field',
      `mdc-form-field--radio__${props.orientation}`,
      {
        'mdc-form-field--radio-requested': props.highlighted,
      },
      props.className,
    )}
    >
      <div className={classnames('mdc-radio', { 'mdc-radio--invalid': props.invalid })}>
        <input
          className={classnames('mdc-radio__native-control', { 'mdc-radio__native-control--invalid': props.invalid })}
          type="radio"
          key={props.keyProp}
          id={id}
          name={props.name}
          value={props.value}
          disabled={props.disabled}
          checked={props.checked}
          onChange={props.onChange}
          aria-describedby={props.describedBy}
          required={props.required}
        />
        <div className="mdc-radio__background">
          <div className="mdc-radio__outer-circle" />
          <div className="mdc-radio__inner-circle" />
        </div>
      </div>
      <div className="mdc-radio__label-description-wrap">
        <label
          className={classnames(
            'mdc-radio__label', {
              'mdc-radio__label--with-description': !!props.description,
            },
          )}
          htmlFor={id}
        >
          {props.label}
          {' '}
          {props.highlighted && props.requestedOptionLabelPostfix}
        </label>
        { props.description && (
          <ExpandableText lang={props.lang}>
            {props.description}
          </ExpandableText>
        )}
      </div>
    </div>
  );
};

RadioButton.propTypes = {
  className: PropTypes.string,
  keyProp: PropTypes.string.isRequired, // Props cannot be named key
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  highlighted: PropTypes.bool,
  requestedOptionLabelPostfix: PropTypes.string,
  describedBy: PropTypes.string,
  required: PropTypes.bool,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  lang: PropTypes.string,
};

RadioButton.defaultProps = {
  className: null,
  description: null,
  disabled: false,
  checked: false,
  onChange: undefined,
  invalid: false,
  highlighted: false,
  requestedOptionLabelPostfix: null,
  describedBy: null,
  required: false,
  orientation: 'vertical',
  lang: null,
};

const RadioButtonGroup = (props) => {
  const handleChange = (event) => {
    props.onChange(compatibleEvent(event));
  };

  const isOptionRequested = (requestedValue, currentValue) => {
    return R.has('requestedOptionValue', props) && requestedValue === currentValue;
  };

  return (
    <fieldset aria-required={props.required}>
      {
        props.options.map(option => (
          <RadioButton
            key={option.keyProp}
            keyProp={option.keyProp}
            className={classnames('form-radio-input', props.className)}
            label={option.label}
            description={option.description}
            name={props.name}
            value={option.value}
            disabled={option.disabled}
            checked={option.value === props.value}
            onChange={handleChange}
            invalid={props.invalid}
            highlighted={isOptionRequested(props.requestedOptionValue, option.value)}
            requestedOptionLabelPostfix={props.requestedOptionLabelPostfix}
            describedBy={props.describedBy}
            required={props.required}
            orientation={props.orientation}
            lang={props.lang}
          />
        ))
      }
    </fieldset>
  );
};

RadioButtonGroup.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.exact(radioButtonOptionShape)).isRequired,
  invalid: PropTypes.bool,
  requestedOptionValue: PropTypes.string,
  requestedOptionLabelPostfix: PropTypes.string,
  required: PropTypes.bool,
  describedBy: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  lang: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  className: null,
  value: '',
  onChange: null,
  invalid: false,
  requestedOptionValue: null,
  requestedOptionLabelPostfix: i18n.t('common:requested'),
  required: false,
  describedBy: null,
  orientation: 'vertical',
  lang: null,
};

export default RadioButtonGroup;
