import React from 'react';
import PropTypes from 'prop-types';

import { isAnyRequestLoading } from '../../../services/loading/loadingUtils';
import LoadingIcon from '../Icon/LoadingIcon';
import Button from './Button';

export const defaultMapStateToProps = (state, { requestNames = [] }) => ({
  requestsLoading: isAnyRequestLoading(requestNames, state.loading),
});

const LoadingButton = (props) => {
  return (
    <Button
      id={props.id}
      label={props.label}
      icon={props.requestsLoading ? props.loadingIcon : props.icon}
      iconOnEnd={props.iconOnEnd}
      type={props.type}
      className={props.className}
      onClick={props.onClick}
      disabled={props.disabled || props.requestsLoading}
      class={props.class}
      color={props.color}
      dataCy={props.dataCy}
      tabIndex={props.tabIndex}
      title={props.title}
      autoFocus={props.autoFocus}
    >
      {props.children}
    </Button>
  );
};

LoadingButton.propTypes = {
  requestsLoading: PropTypes.bool.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  loadingIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  iconOnEnd: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  class: PropTypes.oneOf(['primary', 'secondary', 'invisible', 'custom']),
  color: PropTypes.oneOf(['blue', 'green', 'red']),
  children: PropTypes.node,
  dataCy: PropTypes.string,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  requestNames: PropTypes.arrayOf(PropTypes.string), // Used by defaultMapStateToProps
  autoFocus: PropTypes.bool,
};

LoadingButton.defaultProps = {
  id: null,
  label: null,
  icon: null,
  loadingIcon: <LoadingIcon />,
  iconOnEnd: false,
  type: 'button',
  className: null,
  onClick: null,
  disabled: false,
  class: 'primary',
  color: 'blue',
  children: null,
  dataCy: null,
  tabIndex: 0,
  title: null,
  requestNames: [],
  autoFocus: false,
};

export default LoadingButton;
