import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

import Button from '../Button/Button';
import { historyShape, locationShape } from '../../../dataModel';
import i18n from '../../../services/i18n';

import './NavigationButtons.scss';

const NavigationButtons = (props) => {
  const handleBack = () => props.history.push({
    pathname: props.backToPath,
    search: props.location.search,
  });
  const handleProceed = () => props.history.push({
    pathname: props.proceedToPath,
    search: props.location.search,
  });
  return (
    <div className={classnames('navigation-buttons-container', props.className)}>
      {props.backToPath &&
        <Button
          class="secondary"
          className={classnames('navigation-button', 'navigation-button--back')}
          onClick={handleBack}
          label={`${i18n.t('common:backTo')} ${props.backToName}`}
          icon="arrow_back"
          dataCy="navigation-back-button"
        />}
      {props.proceedToPath &&
        <Button
          class="secondary"
          className="navigation-button"
          onClick={handleProceed}
          label={`${i18n.t('common:proceedTo')} ${props.proceedToName}`}
          icon="arrow_forward"
          dataCy="navigation-proceed-button"
        />}
    </div>
  );
};

NavigationButtons.propTypes = {
  backToPath: PropTypes.string,
  backToName: PropTypes.string,
  proceedToPath: PropTypes.string,
  proceedToName: PropTypes.string,
  history: historyShape.isRequired,
  className: PropTypes.string,
  location: locationShape.isRequired,
};

NavigationButtons.defaultProps = {
  backToPath: null,
  backToName: '',
  proceedToPath: null,
  proceedToName: '',
  className: null,
};

export default withRouter(NavigationButtons);
