import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  formAddressShape,
  formFieldShape,
  formFieldShapeFor,
} from '../../dataModel';
import Button from '../UI/Button/Button';
import TabSubtitle from '../UI/TabSubtitle/TabSubtitle';
import { pickValuesDeep, flattenObject } from '../../utils/commonUtils';
import Guardian from './Guardian';
import i18n from '../../services/i18n';

const guardianFieldList = [
  'form.guardianSurname',
  'form.guardianFirstName',
  'form.guardianNationality',
  'form.guardianAddress',
  'form.guardianAddressIsDifferentThanApplicants',
  'form.guardianEmail',
  'form.guardianPhonenumber',
  'form.secondaryGuardianSurname',
  'form.secondaryGuardianFirstName',
  'form.secondaryGuardianNationality',
  'form.secondaryGuardianAddress',
  'form.secondaryGuardianAddressIsDifferentThanApplicants',
  'form.secondaryGuardianEmail',
  'form.secondaryGuardianPhonenumber',
];

const areValuesNotEmptyByKey = (keys, object) => R.compose(
  R.complement(R.isEmpty),
  R.reject(R.isNil),
  R.values,
  flattenObject(R.__, false),
  pickValuesDeep,
  R.pick(keys),
)(object);

const secondGuardianKeys = [
  'secondaryGuardianSurname',
  'secondaryGuardianFirstName',
  'secondaryGuardianNationality',
  'secondaryGuardianAddress',
  'secondaryGuardianEmail',
  'secondaryGuardianPhonenumber',
];

class Guardians extends React.PureComponent {
  state = {
    showSecondaryGuardian: areValuesNotEmptyByKey(secondGuardianKeys, this.props),
  };

  componentDidUpdate(prevProps) {
    // Fixes the bug in UV-5181
    if (!areValuesNotEmptyByKey(secondGuardianKeys, prevProps) &&
      areValuesNotEmptyByKey(secondGuardianKeys, this.props)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        showSecondaryGuardian: areValuesNotEmptyByKey(secondGuardianKeys, this.props),
      });
    }

    if (!this.props.showFields && prevProps.showFields !== this.props.showFields) {
      this.props.clearFields(guardianFieldList);
    }
  }

  handleButtonClick = () => {
    if (!this.state.showSecondaryGuardian) {
      this.setState({ showSecondaryGuardian: true });
    } else {
      this.setState({ showSecondaryGuardian: false });
      this.props.clearFields([
        'form.secondaryGuardianSurname',
        'form.secondaryGuardianFirstName',
        'form.secondaryGuardianNationality',
        'form.secondaryGuardianAddress',
        'form.secondaryGuardianAddressIsDifferentThanApplicants',
        'form.secondaryGuardianEmail',
        'form.secondaryGuardianPhonenumber',
      ]);
    }
  };

  handleAddressChange = usage => (event) => {
    this.props.handleChange(event);

    // Always reset the sub-fields when the "main level" address-checkbox is clicked
    if (usage === 'guardian') {
      this.props.clearFields(['form.guardianAddress']);
    } else if (usage === 'secondaryGuardian') {
      this.props.clearFields(['form.secondaryGuardianAddress']);
    }
  };

  renderFields = () => {
    return (
      <div role="form" aria-labelledby="applicationForm-guardianSubtitle">
        <Guardian
          namePrefix="guardian"
          handleFieldChange={this.props.handleChange}
          surname={this.props.guardianSurname}
          firstName={this.props.guardianFirstName}
          nationality={this.props.guardianNationality}
          address={this.props.guardianAddress}
          email={this.props.guardianEmail}
          phonenumber={this.props.guardianPhonenumber}
          handleAddressChange={this.handleAddressChange('guardian')}
          addressIsDifferentThanApplicants={this.props.guardianAddressIsDifferentThanApplicants}
          emailRequired={this.props.emailRequired}
          codesetSelectContainer={this.props.codesetSelectContainer}
          oneColumn={this.props.oneColumn}
          showEmailReminder={this.props.showEmailReminder}
        />
        {
          this.state.showSecondaryGuardian &&
          <>
            <TabSubtitle>{`${i18n.t('common:applicationForm.secondaryGuardianSubtitle')} [10]`}</TabSubtitle>
            <Guardian
              namePrefix="secondaryGuardian"
              autoFocus
              handleFieldChange={this.props.handleChange}
              surname={this.props.secondaryGuardianSurname}
              firstName={this.props.secondaryGuardianFirstName}
              nationality={this.props.secondaryGuardianNationality}
              address={this.props.secondaryGuardianAddress}
              email={this.props.secondaryGuardianEmail}
              phonenumber={this.props.secondaryGuardianPhonenumber}
              handleAddressChange={this.handleAddressChange('secondaryGuardian')}
              addressIsDifferentThanApplicants={
                this.props.secondaryGuardianAddressIsDifferentThanApplicants
              }
              codesetSelectContainer={this.props.codesetSelectContainer}
              oneColumn={this.props.oneColumn}
              showEmailReminder={this.props.showEmailReminder}
            />
          </>
        }
        <Button
          label={i18n.t(`common:applicationForm.${this.state.showSecondaryGuardian ? 'remove' : 'add'}SecondaryGuardian`)}
          onClick={this.handleButtonClick}
          icon={this.state.showSecondaryGuardian ? 'remove' : 'add'}
          class="invisible"
          dataCy="secondaryGuardian-toggle-button"
        />
      </div>
    );
  };

  render() {
    return (
      <>
        <TabSubtitle id="applicationForm-guardianSubtitle">{`${i18n.t('common:applicationForm.guardianSubtitle')} [10]`}</TabSubtitle>
        { this.props.showFields && this.renderFields() }
      </>
    );
  }
}

Guardians.propTypes = {
  showFields: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  guardianSurname: formFieldShape.isRequired,
  guardianFirstName: formFieldShape.isRequired,
  guardianNationality: formFieldShape.isRequired,
  guardianAddress: formFieldShapeFor(formAddressShape).isRequired,
  guardianAddressIsDifferentThanApplicants: formFieldShapeFor(PropTypes.bool).isRequired,
  guardianEmail: formFieldShape.isRequired,
  guardianPhonenumber: formFieldShape.isRequired,

  secondaryGuardianSurname: formFieldShape.isRequired,
  secondaryGuardianFirstName: formFieldShape.isRequired,
  secondaryGuardianNationality: formFieldShape.isRequired,
  secondaryGuardianAddress: formFieldShapeFor(formAddressShape).isRequired,
  secondaryGuardianAddressIsDifferentThanApplicants: formFieldShapeFor(PropTypes.bool).isRequired,
  secondaryGuardianEmail: formFieldShape.isRequired,
  secondaryGuardianPhonenumber: formFieldShape.isRequired,
  emailRequired: PropTypes.bool,

  codesetSelectContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
  showEmailReminder: PropTypes.bool,
};

Guardians.defaultProps = {
  emailRequired: false,
  oneColumn: false,
  showEmailReminder: false,
};

export default Guardians;
