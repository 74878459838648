import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { fullName } from 'visa-frontend-common/src/utils/dataModelUtils';
import { getDaysSinceStartDateWithText } from 'visa-frontend-common/src/services/datetime';

import LoadingButton from '../UI/Button/LoadingButtonContainer';
import i18n from '../../services/i18n';

import './ApplicationListItem.scss';

const ApplicationInProcessingListItem = memo((props) => {
  const {
    applicationId,
    printApplication,
    requestNames,
    finalizedAt,
  } = props;

  const applicantName =
    props.firstName || props.surname ?
      fullName(props.surname, props.firstName) :
      i18n.t('common.missingNamePlaceholder');

  return (
    <div className="application-list-item" data-cy={`application-list-item-${applicationId}`}>
      <div className="application-list-item__body">
        <div className="application-list-item__content">
          <small className="application-list-item__header-without-link-button">{applicantName}</small>
          <small className="application-list-item__status">
            <span>
              {`${i18n.t('frontPage.submitted')}: `}
            </span>
            {getDaysSinceStartDateWithText(finalizedAt)}
          </small>
          <span className="application-list-item__status">
            <LoadingButton
              class="secondary"
              icon="description"
              onClick={() => printApplication(applicationId)}
              label={i18n.t('frontPage.downloadApplication')}
              dataCy={`application-list-item-${applicationId}-print-button`}
              requestNames={requestNames}
              className="application-list-item__print-button"
              disabled={!props.printedAt}
            />
          </span>
        </div>
      </div>
    </div>
  );
});

ApplicationInProcessingListItem.propTypes = {
  applicationId: PropTypes.string.isRequired,
  surname: PropTypes.string,
  firstName: PropTypes.string,
  finalizedAt: PropTypes.string.isRequired,
  printedAt: PropTypes.string,
  printApplication: PropTypes.func.isRequired,
  requestNames: PropTypes.arrayOf(PropTypes.string),
};

ApplicationInProcessingListItem.defaultProps = {
  firstName: null,
  surname: null,
  printedAt: null,
  requestNames: [],
};

export default ApplicationInProcessingListItem;
