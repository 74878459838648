import React, { memo } from 'react';

import i18n from '../../services/i18n';

const FrontPageGuide = memo(() => {
  const applicationCentreLink =
    <a
      href="https://visa.finland.eu"
      target="_blank"
      rel="noopener noreferrer"
    >
      {i18n.t('frontPage.guide.visaApplicationCentre')}
    </a>;

  const finnishMissionLink =
    <a
      href="https://finlandabroad.fi/frontpage"
      target="_blank"
      rel="noopener noreferrer"
    >
      {i18n.t('frontPage.guide.finnishMission')}
    </a>;

  const finnishMissionLinkWebpages =
    <a
      href="https://finlandabroad.fi/frontpage"
      target="_blank"
      rel="noopener noreferrer"
    >
      {i18n.t('frontPage.guide.finnishMissionWebPages')}
    </a>;

  const euFreeMovementDirectiveLink =
    <a
      href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX:02004L0038-20110616"
      target="_blank"
      rel="noopener noreferrer"
    >
      {i18n.t('frontPage.guide.euFreeMovementDirective')}
    </a>;

  return (
    <div lang={i18n.language()}>
      <p>
        <small>{i18n.t('frontPage.guide.desc1')}</small>
      </p>

      <ul>
        <li>
          <small>{i18n.t('frontPage.guide.listItem1_1')}</small>
        </li>
        <li>
          <small>{i18n.t('frontPage.guide.listItem1_2')}</small>
        </li>
        <li>
          <small>{i18n.t('frontPage.guide.listItem1_3')}</small>
        </li>
        <li>
          <small>{i18n.t('frontPage.guide.listItem1_4')}</small>
        </li>
      </ul>

      <p>
        <small>{i18n.t('frontPage.guide.desc2')}</small>
      </p>

      <ul>
        <li>
          <small>{i18n.t('frontPage.guide.listItem2_1')}</small>
        </li>
        <li>
          <small>{i18n.t('frontPage.guide.listItem2_2')}</small>
        </li>
        <li>
          <small>{i18n.t('frontPage.guide.listItem2_3')}</small>
        </li>
        <li>
          <small>{i18n.t('frontPage.guide.listItem2_4')}</small>
        </li>
        <li>
          <small>{i18n.t('frontPage.guide.listItem2_5')}</small>
        </li>
        <li>
          <small>
            {i18n.t(
              'frontPage.guide.listItem2_6',
              {
                VisaFinlandLink: applicationCentreLink,
                postProcess: 'reactPostprocessor',
              },
            )}
          </small>
          <ul>
            <li>
              <small>
                {i18n.t(
                  'frontPage.guide.listItem2_6_1',
                  {
                    EuFreeMovementDirectiveLink: euFreeMovementDirectiveLink,
                    postProcess: 'reactPostprocessor',
                  },
                )}
              </small>
            </li>
            <li>
              <small>
                {i18n.t(
                  'frontPage.guide.listItem2_6_2',
                  {
                    FinnishMissionLink: finnishMissionLink,
                    postProcess: 'reactPostprocessor',
                  },
                )}
              </small>
            </li>
            <li>
              <small>{i18n.t('frontPage.guide.listItem2_6_3')}</small>
            </li>
          </ul>
        </li>
      </ul>

      <p>
        <small>
          {i18n.t(
            'frontPage.guide.desc3',
            {
              VisaFinlandLink: applicationCentreLink,
              postProcess: 'reactPostprocessor',
            },
          )}
        </small>
      </p>

      <ul>
        <li>
          <small>{i18n.t('frontPage.guide.listItem3_1')}</small>
        </li>
        <li>
          <small>{i18n.t('frontPage.guide.listItem3_2')}</small>
        </li>
        <li>
          <small>{i18n.t('frontPage.guide.listItem3_3')}</small>
        </li>
        <li>
          <small>{i18n.t('frontPage.guide.listItem3_4')}</small>
        </li>
        <li>
          <small>
            {i18n.t(
              'frontPage.guide.listItem3_5',
              {
                FinnishMissionLinkWebPages: finnishMissionLinkWebpages,
                postProcess: 'reactPostprocessor',
              },
            )}
          </small>
        </li>
      </ul>

    </div>
  );
});

export default FrontPageGuide;
