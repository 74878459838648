import React from 'react';
import { generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import Icon from 'visa-frontend-common/src/components/UI/Icon/Icon';
import { historyShape } from 'visa-frontend-common/src/dataModel';
import * as R from 'ramda';
import i18n from '../../services/i18n';
import paths from '../../paths';
import { answerText, instructionsText } from './pendingUtils';
import { pendingDeliveryTargets, pendingRequestReasons } from '../../ovafModel';

const PendingAnswerView = ({
  answer, documentCount, requestReason, history, deliveryTarget,
}) => {
  const redirectToRequests = () => {
    history.push(generatePath(paths.pending.root));
  };

  return (
    <div className="pending-answer">
      <h2>{i18n.t('pending.answerConfirmed')}</h2>
      <div className="pending-answer__status">
        <Icon name="check" className="pending-answer__status-icon" />
        <span className="pending-answer__status-text">
          {answerText(requestReason, answer, { count: documentCount, location: i18n.t(`pending.answer.location.${deliveryTarget}`) })}
        </span>
      </div>
      <div className="pending-answer__instructions">
        <p>{instructionsText(requestReason, answer)}</p>
      </div>
      <div className="pending-answer__navigation">
        <Button
          class="secondary"
          label={i18n.t('pending.navigateToRequests')}
          onClick={redirectToRequests}
        />
      </div>
    </div>
  );
};

PendingAnswerView.propTypes = {
  history: historyShape.isRequired,
  answer: PropTypes.string.isRequired,
  documentCount: PropTypes.number.isRequired,
  requestReason: PropTypes.oneOf(R.values(pendingRequestReasons)).isRequired,
  deliveryTarget: PropTypes.oneOf(pendingDeliveryTargets).isRequired,
};

export default PendingAnswerView;
