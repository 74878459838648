import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Image = React.forwardRef((props, ref) =>
  (<img
    ref={ref}
    src={props.url}
    alt={props.alt}
    title={props.title}
    className={props.className}
    onLoad={props.onLoad}
    data-cy={props.dataCy}
    draggable={props.draggable}
  />));

Image.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  onLoad: PropTypes.func,
  dataCy: PropTypes.string,
  draggable: PropTypes.oneOf([true, false, 'auto']),
};

Image.defaultProps = {
  title: null,
  className: '',
  onLoad: null,
  dataCy: null,
  draggable: 'auto',
};

export const LazyImage = React.forwardRef((props, ref) =>
  (<LazyLoadImage
    ref={ref}
    src={props.url}
    alt={props.alt}
    title={props.title}
    className={props.className}
    onLoad={props.onLoad}
    data-cy={props.dataCy}
    draggable={props.draggable}
  />));

LazyImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  onLoad: PropTypes.func,
  dataCy: PropTypes.string,
  draggable: PropTypes.oneOf([true, false, 'auto']),
};

LazyImage.defaultProps = {
  title: null,
  className: '',
  onLoad: null,
  dataCy: null,
  draggable: 'auto',
};

export default Image;
