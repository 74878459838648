import * as R from 'ramda';
import { handleActions } from 'redux-actions';

import {
  createSelector,
  fieldSetReducerFor, resetReducerFor,
} from 'visa-frontend-common/src/utils/reducerUtils';
import {
  formFieldDefaultProps,
  formFieldDefaultValue,
} from 'visa-frontend-common/src/dataModel';

import actions from './RegisterActions';

const initialState = {
  registerForm: {
    email: formFieldDefaultProps,
    currentPassword: formFieldDefaultProps,
    password: formFieldDefaultProps,
    confirmPassword: formFieldDefaultProps,
    isTravelAgency: formFieldDefaultValue(false),
    travelAgencyName: formFieldDefaultProps,
  },
  registerConfirmForm: {
    email: formFieldDefaultProps,
    confirmationCode: formFieldDefaultProps,
  },
  registerCodeResendForm: {
    email: formFieldDefaultProps,
  },
  emailSent: null,
  termsAccepted: false,
  privacyPolicyAccepted: false,
};

const fieldSetReducer = fieldSetReducerFor(initialState);

export const registerFormSelector = createSelector([R.path(['register', 'registerForm'])], R.identity);
export const registerConfirmFormSelector = createSelector([R.path(['register', 'registerConfirmForm'])], R.identity);
export const registerCodeResendFormSelector = createSelector([R.path(['register', 'registerCodeResendForm'])], R.identity);
export const registerEmailSentSelector = createSelector([R.path(['register', 'emailSent'])], R.identity);
export const termsAcceptedSelector = createSelector([R.path(['register', 'termsAccepted'])], R.identity);
export const privacyPolicyAcceptedSelector = createSelector([R.path(['register', 'privacyPolicyAccepted'])], R.identity);

const reducer = handleActions({
  [actions.registerFormFieldSet]: fieldSetReducer('registerForm'),
  [actions.registerConfirmFormFieldSet]: fieldSetReducer('registerConfirmForm'),
  [actions.registerCodeResendFormFieldSet]: fieldSetReducer('registerCodeResendForm'),
  [actions.registerClearFields]: resetReducerFor(initialState),
  [actions.registerConfirmClearFields]: resetReducerFor(initialState),
  [actions.registerCodeResendClearFields]: resetReducerFor(initialState),
  [actions.registerEmailSentSet]: (state, action) => {
    return {
      ...state,
      emailSent: action.payload,
    };
  },
  [actions.termsAcceptedSet]: (state, action) => {
    return {
      ...state,
      termsAccepted: action.payload,
    };
  },
  [actions.privacyPolicyAcceptedSet]: (state, action) => {
    return {
      ...state,
      privacyPolicyAccepted: action.payload,
    };
  },
}, initialState);

export default reducer;
