import React from 'react';
import CodesetListField from 'visa-frontend-common/src/components/UI/Form/CodesetListField/CodesetListField';
import CodesetValueContainer from './CodesetValueContainer';
import CodesetSelectContainer from './CodesetSelectContainer';

const CodesetListFieldContainer = props => (
  <CodesetListField
    codesetValueContainer={CodesetValueContainer}
    codesetSelectContainer={CodesetSelectContainer}
    {...props}
  />
);

export default CodesetListFieldContainer;
