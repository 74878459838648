import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Label from 'visa-frontend-common/src/components/UI/Label/Label';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import { formFieldShape, formFieldShapeFor } from 'visa-frontend-common/src/dataModel';
import Subsection from 'visa-frontend-common/src/components/UI/Subsection/Subsection';

import i18n from '../../../../services/i18n';
import PreviewValidationError from './PreviewValidationError';
import paths from '../../../../paths';
import { travelCostsApplicantOptions, travelCostsSponsorOptions } from '../../TravelCostsField/TravelCostsDataModel';

const contactsAndAccommodationPath = paths.application.tabs.contactsAndAccommodation;

const PreviewTravelCostSubFields = (props) => {
  const hostSponsorSpecifier = (sponsor) => {
    return (
      <small>
        { i18n.t('applicationForm.travelCosts.hostSponsorSpecifier', { sponsor })}
      </small>
    );
  };

  const otherSponsorSpecifier = (sponsor) => {
    return (
      <small>
        { i18n.t('applicationForm.travelCosts.otherSponsorSpecifier', { sponsor })}
      </small>
    );
  };

  const otherCostSpecifier = (cost) => {
    return (
      <small>
        { i18n.t('applicationForm.travelCosts.otherCostSpecifier', { cost })}
      </small>
    );
  };

  const hostSponsors = (sponsorHost, sponsorHostSpecify) => {
    if (sponsorHost.value) {
      return R.compose(
        sponsorSpecify => [hostSponsorSpecifier(sponsorSpecify)],
        R.defaultTo(i18n.t('common:applicationForm.travelCosts.noSponsorSpecifier')),
      )(sponsorHostSpecify.value);
    }

    return [];
  };

  const otherSponsors = (sponsorOther, sponsorOtherSpecify) => {
    if (sponsorOther.value) {
      return R.compose(
        sponsorSpecify => [otherSponsorSpecifier(sponsorSpecify)],
        R.defaultTo(i18n.t('common:applicationForm.travelCosts.noSponsorSpecifier')),
      )(sponsorOtherSpecify.value);
    }

    return [];
  };

  const sponsors = R.concat(
    hostSponsors(props.sponsorHost, props.sponsorHostSpecify),
    otherSponsors(props.sponsorOther, props.sponsorOtherSpecify),
  );

  const applicantCosts = R.concat(
    R.compose(
      R.map(option => <small>{option.label}</small>),
      R.filter(option => R.includes(option.value, props.travelCostsApplicant.value)),
      R.filter(option => option.value !== 'Other'),
    )(travelCostsApplicantOptions),
    R.compose(
      R.defaultTo([]),
      R.unless(R.isNil, other => [otherCostSpecifier(other)]),
      R.prop('value'),
    )(props.travelCostApplicantOther),
  );

  const sponsorCosts = R.concat(
    R.compose(
      R.map(option => <small>{option.label}</small>),
      R.filter(option => R.includes(option.value, props.travelCostsSponsor.value)),
      R.filter(option => option.value !== 'Other'),
    )(travelCostsSponsorOptions),
    R.compose(
      R.defaultTo([]),
      R.unless(R.isNil, other => [otherCostSpecifier(other)]),
      R.prop('value'),
    )(props.travelCostSponsorOther),
  );

  return (
    <>
      { props.travelCostsApplicant.validationError ?
        <PreviewValidationError
          fieldNameLabel={i18n.t('application.previewApplication.previewTravelCostsByApplicantTitle')}
          validationError={i18n.t('application.previewApplication.previewTravelCostSpecifyMeans')}
          tabPath={contactsAndAccommodationPath}
        /> :
        <>
          { applicantCosts.length > 0 &&
            <>
              <p>
                <small>{i18n.t('applicationForm.travelCosts.byTheApplicantHimself')}</small>
              </p>

              <Subsection>
                <Label className="input-group__label" label={i18n.t('common:applicationForm.meansOfSupport')} />

                <ul className="preview-application__travel-cost-list">
                  { applicantCosts.map((entry, i) => {
                    const itemKey = `preview-travelcost-applicant_${i}`;
                    return (
                      <li key={itemKey}>{entry}</li>
                    );
                  })}
                </ul>
              </Subsection>
            </>}
        </>}

      { props.travelCostApplicantOther.validationError &&
        <PreviewValidationError
          fieldNameLabel={i18n.t('application.previewApplication.previewTravelCostsByApplicantTitle')}
          validationError={i18n.t('application.previewApplication.previewTravelCostSpecifyByOtherMeans')}
          tabPath={contactsAndAccommodationPath}
        />}

      { (props.sponsorHost.validationError || props.sponsorOther.validationError) ?
        <PreviewValidationError
          fieldNameLabel={i18n.t('application.previewApplication.previewTravelCostsBySponsorTitle')}
          validationError={i18n.t('application.previewApplication.previewTravelCostSelectSponsor')}
          tabPath={contactsAndAccommodationPath}
        /> :
        <>
          { sponsors.length > 0 &&
          <>
            <small>{i18n.t('applicationForm.travelCosts.byASponsor')}</small>

            <Subsection>
              <ul className="preview-application__travel-cost-list">
                { sponsors.map((entry, i) => {
                  const itemKey = `preview-travelcost-sponsors_${i}`;
                  return (
                    <li key={itemKey}>{entry}</li>
                  );
                })}
              </ul>
            </Subsection>
          </>}
        </>}

      { props.sponsorHostSpecify.validationError &&
        <PreviewValidationError
          fieldNameLabel={i18n.t('application.previewApplication.previewTravelCostsBySponsorTitle')}
          validationError={i18n.t('application.previewApplication.previewTravelCostSpecifyHostSponsor')}
          tabPath={contactsAndAccommodationPath}
        />}

      { props.sponsorOtherSpecify.validationError &&
        <PreviewValidationError
          fieldNameLabel={i18n.t('application.previewApplication.previewTravelCostsBySponsorTitle')}
          validationError={i18n.t('application.previewApplication.previewTravelCostSpecifyOtherSponsor')}
          tabPath={contactsAndAccommodationPath}
        />}

      { props.travelCostsSponsor.validationError ?
        <PreviewValidationError
          fieldNameLabel={i18n.t('application.previewApplication.previewTravelCostsBySponsorTitle')}
          validationError={i18n.t('application.previewApplication.previewTravelCostSpecifyMeans')}
          tabPath={contactsAndAccommodationPath}
        /> :
        <>
          { sponsorCosts.length > 0 &&
            <>
              <p>
                <small>
                  {
                    (props.sponsorHost.validationError ||
                      props.sponsorOther.validationError ||
                      sponsors.length === 0
                    ) &&
                      i18n.t('applicationForm.travelCosts.byASponsor')
                  }
                </small>
              </p>

              <Subsection>
                <Label className="input-group__label" label={i18n.t('common:applicationForm.meansOfSupport')} />

                <ul className="preview-application__travel-cost-list">
                  { sponsorCosts.map((entry, i) => {
                    const itemKey = `preview-travelcost-sponsorCosts_${i}`;
                    return (
                      <li key={itemKey}>{entry}</li>
                    );
                  })}
                </ul>
              </Subsection>
            </>}
        </>}

      { props.travelCostSponsorOther.validationError &&
        <PreviewValidationError
          fieldNameLabel={i18n.t('application.previewApplication.previewTravelCostsBySponsorTitle')}
          validationError={i18n.t('application.previewApplication.previewTravelCostSpecifyByOtherMeans')}
          tabPath={contactsAndAccommodationPath}
        />}
    </>
  );
};

PreviewTravelCostSubFields.propTypes = {
  travelCostsApplicant: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostApplicantOther: formFieldShape.isRequired,
  sponsorHost: formFieldShape.isRequired,
  sponsorHostSpecify: formFieldShape.isRequired,
  sponsorOther: formFieldShape.isRequired,
  sponsorOtherSpecify: formFieldShape.isRequired,
  travelCostsSponsor: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostSponsorOther: formFieldShape.isRequired,
};

const PreviewTravelCosts = (props) => {
  return (
    <>
      { (props.travelCostsApplicantInUse.validationError ||
        props.travelCostsSponsorInUse.validationError) ?
          <PreviewValidationError
            fieldNameLabel={`${i18n.t('common:applicationForm.travelCosts.title')} [32] *`}
            validationError={
              props.travelCostsApplicantInUse.validationError ||
              props.travelCostsSponsorInUse.validationError
            }
            tabPath={contactsAndAccommodationPath}
          /> :
          <>
            <TabSubtitle type="h4" className="preview-application__subtitle">
              {`${i18n.t('common:applicationForm.travelCosts.title')} [32]${props.eucitizenFamily.value ? '' : ' *'}`}
            </TabSubtitle>

            <PreviewTravelCostSubFields
              travelCostsApplicant={props.travelCostsApplicant}
              travelCostApplicantOther={props.travelCostApplicantOther}
              sponsorHost={props.sponsorHost}
              sponsorHostSpecify={props.sponsorHostSpecify}
              sponsorOther={props.sponsorOther}
              sponsorOtherSpecify={props.sponsorOtherSpecify}
              travelCostsSponsor={props.travelCostsSponsor}
              travelCostSponsorOther={props.travelCostSponsorOther}
            />
          </>}
    </>
  );
};

PreviewTravelCosts.propTypes = {
  travelCostsApplicantInUse: formFieldShapeFor(PropTypes.bool).isRequired,
  travelCostsApplicant: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostApplicantOther: formFieldShape.isRequired,
  travelCostsSponsorInUse: formFieldShapeFor(PropTypes.bool).isRequired,
  sponsorHost: formFieldShape.isRequired,
  sponsorHostSpecify: formFieldShape.isRequired,
  sponsorOther: formFieldShape.isRequired,
  sponsorOtherSpecify: formFieldShape.isRequired,
  travelCostsSponsor: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostSponsorOther: formFieldShape.isRequired,
  eucitizenFamily: formFieldShapeFor(PropTypes.bool).isRequired,
};

export default PreviewTravelCosts;
