import { connect } from 'react-redux';
import { currentUserNameSelector } from '../../pages/Main/MainReducer';

import { hasPendingRequestsSelector, openPendingRequestAmountSelector } from '../../pages/Pending/PendingReducer';
import Header from './Header';

const mapStateToProps = state => ({
  username: currentUserNameSelector(state),
  hasRequests: hasPendingRequestsSelector(state),
  requestAmount: openPendingRequestAmountSelector(state),
});

export default connect(mapStateToProps)(Header);
