import { connect } from 'react-redux';

import actions from './ApplicationActions';
import ChecklistItemAttachment from './ChecklistItemAttachment';

const mapDispatchToProps = {
  downloadAttachment: actions.downloadAttachment,
};

export default connect(null, mapDispatchToProps)(ChecklistItemAttachment);
