import * as R from 'ramda';
import PropTypes from 'prop-types';
import i18n from '../../../../services/i18n';

export const radioButtonOptionShape = {
  keyProp: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export const enumTypesToRadioOptions = R.map(value => ({
  keyProp: value.type,
  value: value.type,
  name: value.text,
  label: value.text,
}));

export const booleanRadioOptions = enumTypesToRadioOptions([
  {
    type: 'true',
    text: i18n.tBoolean(true),
  },
  {
    type: 'false',
    text: i18n.tBoolean(false),
  },
]);
