import React from 'react';
import PropTypes from 'prop-types';
import LinkifyIt from 'linkify-it';

const LinkComponent = (props) => {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.text}
    </a>
  );
};

LinkComponent.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const Linkify = (props) => {
  const parseString = (content) => {
    if (content === '') {
      return content;
    }

    const linkify = new LinkifyIt(null, { fuzzyEmail: false, fuzzyLink: false })
      .add('ftp:', null)
      .add('mailto:', null)
      .add('//', null);
    const matches = linkify.match(content);

    if (!matches) {
      return content;
    }

    const elements = [];
    let lastIndex = 0;

    matches.forEach((match) => {
      // Push preceding text if there is any
      if (match.index > lastIndex) {
        elements.push(content.substring(lastIndex, match.index));
      }

      const linkComponent = <LinkComponent href={match.url} text={match.text} key={match.index} />;
      elements.push(linkComponent);

      lastIndex = match.lastIndex;
    });

    // Push remaining text if there is any
    if (content.length > lastIndex) {
      elements.push(content.substring(lastIndex));
    }

    return (elements.length === 1) ? elements[0] : elements;
  };

  const parseChildren = (children) => {
    if (typeof children === 'string') {
      return parseString(children);
    }

    return children;
  };

  return (
    <>
      {parseChildren(props.children)}
    </>
  );
};

Linkify.propTypes = {
  children: PropTypes.node,
};

Linkify.defaultProps = {
  children: null,
};

export default Linkify;
