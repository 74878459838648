import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { createSelector } from '../../utils/reducerUtils';

import setDirty from './navigationPromptActions';
import { notNilOrEmpty } from '../../utils/commonUtils';

const initialState = {
  dirtyForms: [],
};

export const dirtyFormsSelector = createSelector([R.path(['dirtyForm', 'dirtyForms'])], R.identity);
export const hasDirtyFormSelector = createSelector([dirtyFormsSelector], notNilOrEmpty);
export const isDirtySelectorFor = formName => createSelector(
  [dirtyFormsSelector],
  R.ifElse(
    notNilOrEmpty,
    R.compose(R.defaultTo(false), R.prop('isDirty'), R.find(R.propEq('formName', formName))),
    R.F,
  ),
);

const addOrRemoveDirtyForm = (state, payload) => {
  const assocDirtyForms = R.assoc('dirtyForms', R.__, state);
  const addDirtyForm = R.ifElse(
    dirtyForm => R.none(R.propEq('formName', dirtyForm.formName), state.dirtyForms),
    R.compose(assocDirtyForms, R.append(R.__, state.dirtyForms)),
    R.always(state),
  );
  const removeDirtyForm = R.compose(
    R.ifElse(
      R.lte(0),
      R.compose(assocDirtyForms, R.remove(R.__, 1, state.dirtyForms)),
      R.always(state),
    ),
    dirtyForm => R.findIndex(R.propEq('formName', dirtyForm.formName), state.dirtyForms),
  );
  return R.ifElse(
    R.propEq('isDirty', true),
    addDirtyForm,
    removeDirtyForm,
  )(payload);
};

export default handleActions({
  [setDirty]: (state, action) => R.reduce(addOrRemoveDirtyForm, state, action.payload),
}, initialState);
