import * as validators from 'visa-frontend-common/src/services/validator/validators';
import i18n from '../../services/i18n';

const ForgotPasswordValidationRules = {
  email: [
    validators.required,
    validators.maxLength(100),
    validators.emailFormat(),
    validators.cognitoUsername,
  ],
  password: [
    validators.required,
    validators.ovafPasswordFormat(),
  ],
  confirmPassword: [
    validators.required,
    validators.ovafPasswordFormat(),
    (fieldValue, formValues) =>
      (fieldValue === formValues.password ? null : i18n.t('forgotPassword.passwordMismatch')),
  ],
  confirmationCode: [
    validators.required,
    validators.onlyDigits,
  ],
};

export default ForgotPasswordValidationRules;
