import * as validators from 'visa-frontend-common/src/services/validator/validators';
import { validatedIf } from 'visa-frontend-common/src/services/validator/validationRulesUtils';
import i18n from '../../services/i18n';

const RegisterValidationRules = {
  email: [
    validators.required,
    validators.maxLength(100),
    validators.emailFormat(),
    validators.cognitoUsername,
  ],
  currentPassword: [],
  password: [
    validators.required,
    validators.ovafPasswordFormat(),
    (fieldValue, formValues) => (formValues.currentPassword && !fieldValue ?
      i18n.t('common:validationMessages.valueMissing') :
      null),
    (fieldValue, formValues) =>
      (
        formValues.currentPassword &&
        fieldValue &&
        formValues.currentPassword === fieldValue ?
          i18n.t('register.registerForm.theNewPasswordMustBeDifferent') :
          null
      ),
  ],
  confirmPassword: [
    validators.required,
    validators.ovafPasswordFormat(),
    (fieldValue, formValues) =>
      (fieldValue === formValues.password ? null : i18n.t('register.registerForm.passwordMismatch')),
    (fieldValue, formValues) => (formValues.currentPassword && !fieldValue ?
      i18n.t('common:validationMessages.valueMissing') :
      null),
  ],
  travelAgencyName: [
    validators.maxLength(100),
    validators.generalAllowedCharacters,
    validators.noLowercaseLetters,
    validatedIf((fieldValue, formValues) => formValues.isTravelAgency)(validators.required),
  ],
  confirmationCode: [
    validators.required,
    validators.onlyDigits,
  ],
};

export default RegisterValidationRules;
