import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import './Modal.scss';

// Docs: http://reactcommunity.org/react-modal/

// https://github.com/reactjs/react-modal/issues/632
if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

const Modal = (props) => {
  const overlayClassName = classNames({
    modal__overlay: !props.unstyled,
    'modal__overlay--blur-backdrop': !props.unstyled && props.blurBackdrop,
  }, props.overlayClassName);
  const contentClassName = classNames({
    modal__content: !props.unstyled,
    'modal__content--disable-vertical-scrollbar': !props.unstyled && props.disableVerticalScrollbar,
  }, props.contentClassName);
  const bodyOpenClassName = classNames({
    'modal__body--open': props.disableBodyScroll,
  });
  return (
    <ReactModal
      data={props.dataAttributes}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      overlayClassName={overlayClassName}
      bodyOpenClassName={bodyOpenClassName}
      className={contentClassName}
      closeTimeoutMS={props.closeTimeoutMS}
      ariaHideApp={process.env.NODE_ENV !== 'test'}
      aria={{
        labelledby: `${props.labelledBy}`,
        describedby: `${props.describedBy}`,
      }}
      id={props.id}
    >
      {props.children}
    </ReactModal>
  );
};

Modal.propTypes = {
  overlayClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  closeTimeoutMS: PropTypes.number,
  dataAttributes: PropTypes.objectOf(PropTypes.string),
  labelledBy: PropTypes.string,
  describedBy: PropTypes.string,
  unstyled: PropTypes.bool,
  disableVerticalScrollbar: PropTypes.bool,
  blurBackdrop: PropTypes.bool,
  disableBodyScroll: PropTypes.bool,
  id: PropTypes.string,
};

Modal.defaultProps = {
  overlayClassName: null,
  contentClassName: null,
  closeTimeoutMS: 0,
  dataAttributes: null,
  labelledBy: null,
  describedBy: null,
  unstyled: false,
  disableVerticalScrollbar: false,
  blurBackdrop: false,
  disableBodyScroll: false,
  id: null,
};

export default Modal;
