import * as R from 'ramda';
import {
  isBeforeOrEqualDate,
  isAfterOrEqualDate,
  currentDateAsJsonString,
  subtractTimeUnitsFromDate,
  momentTimeUnit,
} from '../../../../services/datetime';

const isValidCodesetCode = codesetCode =>
  R.both(
    R.compose(
      R.either(isAfterOrEqualDate, R.isNil),
      R.prop('validFromInclusive'),
    ),
    R.compose(
      R.either(isBeforeOrEqualDate, R.isNil),
      R.prop('validToInclusive'),
    ),
  )(codesetCode);

const sixYearsBeforeToday = subtractTimeUnitsFromDate(
  currentDateAsJsonString(),
  momentTimeUnit.YEAR,
  6,
);

const isExpiredLessThanEqualSixYearsAgo = codesetCode =>
  R.both(
    R.compose(
      R.either(isAfterOrEqualDate, R.isNil),
      R.prop('validFromInclusive'),
    ),
    R.compose(
      R.either(
        validToInclusive => isAfterOrEqualDate(sixYearsBeforeToday, validToInclusive),
        R.isNil,
      ),
      R.prop('validToInclusive'),
    ),
  )(codesetCode);

const noDuplicateCodesetCodes = (codeset, index, codesets) => {
  const hasMultipleCodesetsWithSameCode = R.compose(
    R.gt(R.__, 1),
    R.length,
    R.filter(R.propEq('codesetCode', codeset.codesetCode)),
  )(codesets);

  if (hasMultipleCodesetsWithSameCode && !codeset.validToInclusive) {
    return true;
  }
  return !(hasMultipleCodesetsWithSameCode && codeset.validToInclusive);
};

const formatLabelAsTextAndCode = codesetCode => `${codesetCode.text} - ${codesetCode.codesetCode}`;
const formatValueAsVisCode = codesetCode => `${codesetCode.visCode}.${codesetCode.visCodeVersion}`;
const formatLabelAsCode = codesetCode => codesetCode.codesetCode;
const formatLabel = ({ text }) => text;
const formatValue = ({ codesetCode }) => codesetCode;

const codesetNotInList = (list, formatCodeset) => (codeset) => {
  const codesetValue = formatCodeset ? formatCodeset(codeset) : R.prop('codesetCode', codeset);
  return !R.includes(codesetValue, list);
};
const codesetNotEqual = (value, formatCodeset) => (codeset) => {
  const codesetValue = formatCodeset ? formatCodeset(codeset) : R.prop('codesetCode', codeset);
  return value !== codesetValue;
};

export {
  isValidCodesetCode,
  isExpiredLessThanEqualSixYearsAgo,
  formatLabelAsTextAndCode,
  formatValueAsVisCode,
  formatLabelAsCode,
  formatLabel,
  formatValue,
  noDuplicateCodesetCodes,
  codesetNotInList,
  codesetNotEqual,
};
