import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import i18n from '../../../../services/i18n';
import { Selector } from '../FormComponents';
import { codesetCodeShape } from './codesetDataModel';
import {
  formatLabel, formatValue, isValidCodesetCode, isExpiredLessThanEqualSixYearsAgo,
} from './codesetUtils';

const CodesetSelect = (props) => {
  const options = R.compose(
    R.unless(
      () => props.withoutSelectLabel,
      R.prepend({ value: '', label: i18n.t('common:select') }),
    ),
    R.map(codesetCode => ({
      label: props.formatLabel(codesetCode),
      value: props.formatValue(codesetCode),
    })),
    R.ifElse(() => props.showExpiredCodes,
      R.filter(isExpiredLessThanEqualSixYearsAgo),
      R.filter(isValidCodesetCode)),
  );

  const selectProps = codesetCodes => ({
    value: props.value,
    name: props.name,
    onChange: props.onChange,
    options: options(codesetCodes),
    className: props.className,
    autoFocus: props.autoFocus,
    invalid: props.invalid,
    id: props.id,
    disabled: props.disabled,
    inTableRow: props.inTableRow,
    lang: props.lang,
    required: props.required,
    requestedOptionValue: props.requestedOptionValue,
    showExpiredCodes: props.showExpiredCodes,
    describedBy: props.describedBy,
    ariaLabel: props.ariaLabel,
  });

  return <Selector {...selectProps(props.codesetCodes)} />;
};

CodesetSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  codesetCodes: PropTypes.arrayOf(codesetCodeShape).isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  withoutSelectLabel: PropTypes.bool,
  autoFocus: PropTypes.bool,
  invalid: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  inTableRow: PropTypes.bool,
  lang: PropTypes.string,
  required: PropTypes.bool,
  describedBy: PropTypes.string,
  ariaLabel: PropTypes.string,
  requestedOptionValue: PropTypes.string,
  showExpiredCodes: PropTypes.bool,
  formatLabel: PropTypes.func,
  formatValue: PropTypes.func,
};

CodesetSelect.defaultProps = {
  value: '',
  className: null,
  withoutSelectLabel: false,
  autoFocus: false,
  invalid: false,
  id: null,
  disabled: false,
  inTableRow: false,
  lang: null,
  required: false,
  describedBy: null,
  ariaLabel: null,
  requestedOptionValue: null,
  showExpiredCodes: false,
  formatLabel,
  formatValue,
};

export default CodesetSelect;
