import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { locationShape } from '../../dataModel';
import { matchesPathList } from '../../utils/pathUtils';

// https://reacttraining.com/react-router/web/guides/scroll-restoration
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const notExcepted = R.complement(matchesPathList(this.props.exceptedPaths));
    if (this.props.location.pathname !== prevProps.location.pathname &&
      notExcepted(this.props.location.pathname)) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop.propTypes = {
  location: locationShape.isRequired,
  children: PropTypes.node,
  exceptedPaths: PropTypes.arrayOf(PropTypes.string),
};

ScrollToTop.defaultProps = {
  children: null,
  exceptedPaths: [],
};

export default withRouter(ScrollToTop);
