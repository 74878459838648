import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  formFieldShape,
  formFieldDefaultProps,
  formFieldShapeFor,
  formAddressShape,
  formAddressDefaultProps,
} from '../../../dataModel';

export const invitationFormDefaultProps = {
  uid: formFieldDefaultProps,
  invitationType: formFieldDefaultProps,
  companyName: formFieldDefaultProps,
  surname: formFieldDefaultProps,
  firstNames: formFieldDefaultProps,
  address: R.assoc('value', formAddressDefaultProps, formFieldDefaultProps),
  phonenumber: formFieldDefaultProps,
  faxnumber: formFieldDefaultProps,
  email: formFieldDefaultProps,
};

export const invitationType = {
  ORGANIZATION: 'ORGANIZATION',
  ORGANIZATION_CONTACT: 'ORGANIZATION_CONTACT',
  PERSON: 'PERSON',
  ACCOMMODATION: 'ACCOMMODATION',
};

export const invitationFormShape = PropTypes.exact({
  applicationId: formFieldShape,
  orderNumber: formFieldShape,
  uid: formFieldShape, // generated key for React
  invitationType: formFieldShape.isRequired,
  companyName: formFieldShape,
  surname: formFieldShape,
  firstNames: formFieldShape,
  address: formFieldShapeFor(formAddressShape),
  phonenumber: formFieldShape,
  faxnumber: formFieldShape,
  email: formFieldShape,
});

const addressShape = PropTypes.exact({
  street: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
});

export const invitationShape = PropTypes.exact({
  orderNumber: PropTypes.number,
  uid: PropTypes.string, // generated key for React
  invitationType: PropTypes.string,
  companyName: PropTypes.string,
  surname: PropTypes.string,
  firstNames: PropTypes.string,
  address: addressShape,
  phonenumber: PropTypes.string,
  faxnumber: PropTypes.string,
  email: PropTypes.string,
});
