import { createAction } from 'redux-actions';

import apiThunkWithLoading from 'visa-frontend-common/src/utils/actionUtils';

import getCodesetCodes from '../../../../services/codesetService';

const codesetByGroupSet = createAction('CODESET_CODES_BY_GROUP_SET_COMPLETED');
const codesetByGroupInEnglishSet = createAction('CODESET_CODES_BY_GROUP_IN_ENGLISH_SET_COMPLETED');

const fetchCodesetCodes = apiThunkWithLoading(
  getCodesetCodes,
  codesetByGroupSet,
);

const fetchCodesetCodesInEnglish = codesetGroup =>
  apiThunkWithLoading(
    getCodesetCodes,
    codesetByGroupInEnglishSet,
  )(codesetGroup, true, 'en');

export {
  fetchCodesetCodes,
  codesetByGroupSet,
  fetchCodesetCodesInEnglish,
  codesetByGroupInEnglishSet,
};
