import React from 'react';
import PropTypes from 'prop-types';
import { __, includes } from 'ramda';

import { notNilOrEmpty } from '../../utils/commonUtils';

import InlineNotification from '../UI/InlineNotification/InlineNotification';
import TabSubtitle from '../UI/TabSubtitle/TabSubtitle';
import TwoColumnRow from '../UI/TwoColumnRow/TwoColumnRow';
import {
  InputGroup,
  Input,
  inputProps,
  ListField,
} from '../UI/Form/FormComponents';
import { formFieldShape, formFieldShapeFor, formAddressShape } from '../../dataModel';
import ResidencePermit from './ResidencePermit';
import i18n from '../../services/i18n';
import {
  occupationCodesWithOptionalAddress,
  occupationCodesWithOptionalPhonenumber,
} from '../../services/validator/commonValidationRules';
import ApplicantAddresses from './ApplicantAddresses';
import Address from './Address';

import './OccupationAndResidence.scss';

const OccupationAndResidence = (props) => {
  const CodesetSelectContainer = props.codesetSelectContainer;
  const textInputProps = inputProps('text', __, __, __, false, props.handleInput);

  const applicantIsStudent = props.occupationCode.value === 'Student';
  const occupationAddressFieldsRequired =
    props.occupationCode.value &&
    !includes(props.occupationCode.value, occupationCodesWithOptionalAddress);
  const occupationPhonenumberRequired =
    props.occupationCode.value &&
    !includes(props.occupationCode.value, occupationCodesWithOptionalPhonenumber);

  const emailLabel = i18n.t('common:applicationForm.email');
  const phonenumberLabel = i18n.t('common:applicationForm.phonenumber');

  const occupationCodeLabel = `${i18n.t('common:applicationForm.occupationCode')} [21]`;
  const occupationOtherLabel = `${i18n.t('common:applicationForm.occupationOther')}`;

  const employerNameLabel = `${i18n.t('common:applicationForm.employerName')}`;
  const schoolNameLabel = i18n.t('common:applicationForm.schoolName');
  const employerStreetLabel = {
    label: i18n.t('common:applicationForm.employerStreet'),
    required: occupationAddressFieldsRequired,
  };
  const employerZipLabel = {
    label: i18n.t('common:applicationForm.employerZip'),
  };
  const employerCityLabel = {
    label: i18n.t('common:applicationForm.employerCity'),
    required: occupationAddressFieldsRequired,
  };
  const employerCountryLabel = {
    label: i18n.t('common:applicationForm.employerCountry'),
    required: occupationAddressFieldsRequired,
  };
  const employerEmailLabel = i18n.t('common:applicationForm.employerEmail');
  const employerPhonenumberLabel = i18n.t('common:applicationForm.employerPhonenumber');

  const handleOccupationCodeChange = (event) => {
    props.handleChangeAndResetClarificationField('form.occupationOther')(event);

    if (event.target.value === 'Student') {
      props.clearFields(['form.employerName']);
    } else {
      props.clearFields(['form.schoolName']);
    }
  };

  return (
    <div role="form" aria-labelledby="applicationForm-occupationAndResidenceSubtitle">
      <ApplicantAddresses
        address={props.address}
        secondaryAddress={props.secondaryAddress}
        handleChange={props.handleInput}
        clearFields={props.clearFields}
        codesetSelectContainer={CodesetSelectContainer}
        oneColumn={props.oneColumn}
        describedBy="occupationAndResidenceGuide-applicantStreet"
      />

      <TwoColumnRow oneColumn={props.oneColumn}>
        {notNilOrEmpty(props.email.value) && props.emailRequired &&
          <InlineNotification
            type="info"
            disableClose
            className="occupation-and-residence__email-notification"
          >
            {i18n.t('common:applicationForm.checkEmailNotifier')}
          </InlineNotification>}
        <InputGroup
          label={emailLabel}
          required={props.emailRequired}
          withHelp={i18n.t('common:miscellaneousFormats.emailFormatDescription')}
          validationError={props.email.validationError}
          describedBy="occupationAndResidenceGuide-email"
        >
          <Input {...textInputProps(props.email.value, 'email', null)} uppercase />
        </InputGroup>

        <InputGroup
          label={phonenumberLabel}
          required
          withHelp={i18n.t('common:miscellaneousFormats.phonenumberFormatDescription')}
          validationError={props.phonenumbersInput.validationError ||
            props.phonenumbers.validationError}
          describedBy="occupationAndResidenceGuide-phonenumber"
        >
          <ListField
            name="phonenumbers"
            values={props.phonenumbers.value}
            inputName="phonenumbersInput"
            inputField={props.phonenumbersInput}
            handleInputFieldChange={props.handleInput}
            updateListField={props.updateListField}
            uppercase
            required
          />
        </InputGroup>
      </TwoColumnRow>

      <ResidencePermit
        residenceInOtherCountry={props.residenceInOtherCountry}
        residencePermitNumber={props.residencePermitNumber}
        residencePermitValidUntil={props.residencePermitValidUntil}
        handleChange={props.handleInput}
        clearFields={props.clearFields}
        oneColumn={props.oneColumn}
      />

      { props.eucitizenFamily.value &&
        <InlineNotification
          type="info"
          className="occupation-and-residence__eu-citizen-notification"
          disableClose
        >
          {i18n.t('common:applicationForm.invitations.euCitizenNotification')}
        </InlineNotification>}

      <TwoColumnRow oneColumn={props.oneColumn}>
        <>
          <InputGroup
            label={occupationCodeLabel}
            required={!props.eucitizenFamily.value}
            validationError={props.occupationCode.validationError}
            describedBy="occupationAndResidenceGuide-occupationCode"
          >
            <CodesetSelectContainer
              name="occupationCode"
              value={props.occupationCode.value}
              codesetGroup="Occupation"
              onChange={handleOccupationCodeChange}
              lang={i18n.language()}
            />
          </InputGroup>

          {props.occupationCode.value === 'Others' &&
          <InputGroup
            label={occupationOtherLabel}
            validationError={props.occupationOther.validationError}
          >
            <Input {...textInputProps(props.occupationOther.value, 'occupationOther', null)} uppercase />
          </InputGroup>}
        </>
      </TwoColumnRow>

      <TabSubtitle type="h4">{`${i18n.t('common:applicationForm.occupationAddressSubtitle')} [22]`}</TabSubtitle>

      <TwoColumnRow oneColumn={props.oneColumn}>
        { !applicantIsStudent &&
          <InputGroup
            label={employerNameLabel}
            required={!!occupationAddressFieldsRequired}
            validationError={props.employerName.validationError}
            describedBy="occupationAndResidenceGuide-employerName"
          >
            <Input {...textInputProps(props.employerName.value, 'employerName', null)} uppercase required={occupationAddressFieldsRequired} />
          </InputGroup>}

        { applicantIsStudent &&
          <InputGroup
            label={schoolNameLabel}
            required
            validationError={props.schoolName.validationError}
            describedBy="occupationAndResidenceGuide-employerName"
          >
            <Input {...textInputProps(props.schoolName.value, 'schoolName', null)} uppercase required />
          </InputGroup>}
      </TwoColumnRow>

      <Address
        address={props.employerAddress}
        addressFieldName="employerAddress"
        labels={{
          street: employerStreetLabel,
          zip: employerZipLabel,
          city: employerCityLabel,
          country: employerCountryLabel,
        }}
        handleFieldChange={props.handleInput}
        codesetSelectContainer={CodesetSelectContainer}
        oneColumn={props.oneColumn}
        describedBy="occupationAndResidenceGuide-employerName"
        uppercase
      />

      <TwoColumnRow oneColumn={props.oneColumn}>
        <InputGroup
          label={employerPhonenumberLabel}
          required={occupationPhonenumberRequired}
          withHelp={i18n.t('common:miscellaneousFormats.phonenumberFormatDescription')}
          validationError={props.employerPhonenumber.validationError}
          describedBy="occupationAndResidenceGuide-employerName"
        >
          <Input {...textInputProps(props.employerPhonenumber.value, 'employerPhonenumber', null)} uppercase required={occupationPhonenumberRequired} />
        </InputGroup>

        <InputGroup
          label={employerEmailLabel}
          withHelp={i18n.t('common:miscellaneousFormats.emailFormatDescription')}
          validationError={props.employerEmail.validationError}
        >
          <Input {...textInputProps(props.employerEmail.value, 'employerEmail', null)} uppercase />
        </InputGroup>
      </TwoColumnRow>
    </div>
  );
};

OccupationAndResidence.propTypes = {
  handleInput: PropTypes.func.isRequired,
  handleChangeAndResetClarificationField: PropTypes.func.isRequired,
  updateListField: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  address: formFieldShapeFor(formAddressShape).isRequired,
  secondaryAddress: formFieldShapeFor(formAddressShape).isRequired,
  email: formFieldShape.isRequired,
  phonenumbers: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  phonenumbersInput: formFieldShape.isRequired,
  residenceInOtherCountry: formFieldShapeFor(PropTypes.bool).isRequired,
  residencePermitNumber: formFieldShape.isRequired,
  residencePermitValidUntil: formFieldShape.isRequired,
  occupationCode: formFieldShape.isRequired,
  occupationOther: formFieldShape.isRequired,
  employerName: formFieldShape.isRequired,
  schoolName: formFieldShape.isRequired,
  employerAddress: formFieldShapeFor(formAddressShape).isRequired,
  employerPhonenumber: formFieldShape.isRequired,
  employerEmail: formFieldShape.isRequired,
  eucitizenFamily: formFieldShape.isRequired,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
  emailRequired: PropTypes.bool,
};

OccupationAndResidence.defaultProps = {
  oneColumn: false,
  emailRequired: false,
};

export default OccupationAndResidence;
