import http from 'visa-frontend-common/src/services/http';

const baseUrl = '/api/pending-requests';

export const getPendingRequests = () =>
  http.fetchAsJSON(`${baseUrl}`, { method: 'GET' });

export const getPendingRequest = pendingId =>
  http.fetchAsJSON(`${baseUrl}/${pendingId}`, { method: 'GET' });

export const readPendingRequest = pendingId =>
  http.fetchAsJSON(`${baseUrl}/${pendingId}/read`, { method: 'POST' });

export const acceptPendingRequest = pendingId =>
  http.fetchAsJSON(`${baseUrl}/${pendingId}/accept`, { method: 'POST' });

export const declinePendingRequest = pendingId =>
  http.fetchAsJSON(`${baseUrl}/${pendingId}/decline`, { method: 'POST' });

export const readyPendingRequest = pendingId =>
  http.fetchAsJSON(`${baseUrl}/${pendingId}/ready`, { method: 'POST' });

export const getPendingStatusHistory = pendingId =>
  http.fetchAsJSON(`${baseUrl}/${pendingId}/history`, { method: 'GET' });
