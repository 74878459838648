import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import CheckboxGroup from './CheckboxGroup';

const getSelectedOptions = (value, options) => {
  return R.isNil(value) ?
    [] :
    R.compose(
      R.filter(option => value.includes(option)),
      R.map(R.prop('value')),
    )(options);
};

const CheckboxGroupWithOtherValueInput = (props) => {
  const selectedOptions = getSelectedOptions(
    props.checkboxGroupProps.value,
    props.checkboxGroupProps.options,
  );

  const isOtherSelected = (options) => {
    return options.includes(props.otherFieldValue);
  };

  const handleChange = (event) => {
    const optionEvent = R.evolve({
      target: { name: R.always(props.checkboxGroupProps.name) },
    }, event);
    props.onChange(optionEvent);

    // Check if the checked-status of "Other" -checkbox was changed. If yes, fire "onClickOther"
    // -event (if given).
    if (isOtherSelected(event.target.value) !== isOtherSelected(selectedOptions)) {
      if (props.onClickOther) {
        props.onClickOther(R.evolve({
          target: { name: R.always(props.children.props.name) },
        }, event));
      }
    }
  };

  return (
    <>
      <CheckboxGroup
        {...props.checkboxGroupProps}
        value={selectedOptions}
        onChange={handleChange}
        invalid={props.invalid}
        required={props.required}
      />
      { isOtherSelected(selectedOptions) && props.children }
    </>
  );
};

CheckboxGroupWithOtherValueInput.propTypes = {
  checkboxGroupProps: PropTypes.exact(CheckboxGroup.propTypes).isRequired,
  children: PropTypes.node.isRequired,
  otherFieldValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickOther: PropTypes.func,
  invalid: PropTypes.bool,
  required: PropTypes.bool,
};

CheckboxGroupWithOtherValueInput.defaultProps = {
  onClickOther: null,
  invalid: false,
  required: false,
};

export default CheckboxGroupWithOtherValueInput;
