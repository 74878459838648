import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';

import withDebouncedValue from '../../../debounce/DebouncedValue';
import { transformEventValueBeforeHandle } from '../../../../utils/commonUtils';
import compatibleEvent from '../../../../compatibilityFixes';

import './Textarea.scss';

const Textarea = (props) => {
  const handleChange = (event) => {
    props.onChange({
      target: {
        name: event.target.name,
        files: event.target.files,
        value: event.target.value || null,
      },
    });
  };
  const handleBlur = props.onBlur && R.compose(
    transformEventValueBeforeHandle(
      props.onBlur,
      R.when(R.isEmpty, R.always(null)),
    ),
    compatibleEvent,
  );
  const handleOnKeyDown = props.onKeyDown && R.compose(
    transformEventValueBeforeHandle(
      props.onKeyDown,
      R.when(R.isEmpty, R.always(null)),
    ),
    compatibleEvent,
  );

  return (
    <textarea
      id={props.id}
      className={classnames(
        'textarea',
        props.className,
        {
          'textarea--invalid': props.invalid,
        },
      )}
      value={props.value || ''}
      name={props.name}
      aria-describedby={props.describedBy}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleOnKeyDown}
      placeholder={props.placeholder}
      disabled={props.disabled}
      data-cy={props.dataCy}
      rows={props.rows}
    />
  );
};

export const textareaProps = R.curry((
  value,
  name,
  placeholder,
  disabled,
  rows,
  onChange,
) => {
  return {
    value,
    name,
    placeholder,
    disabled,
    rows,
    onChange,
  };
});

export const TextareaShape = {
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  describedBy: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  invalid: PropTypes.bool,
  dataCy: PropTypes.string,
};

Textarea.propTypes = TextareaShape;

Textarea.defaultProps = {
  id: null,
  value: '',
  className: null,
  describedBy: '',
  onChange: null,
  onBlur: null,
  onKeyDown: null,
  placeholder: null,
  disabled: false,
  rows: 3,
  invalid: false,
  dataCy: null,
};

export default withDebouncedValue(Textarea);
