import React from 'react';
import { Switch, Route } from 'react-router-dom';
import paths from '../../paths';
import PendingRequests from './PendingRequestsContainer';
import PendingRequest from './PendingRequestContainer';

const Pending = () => {
  return (
    <div id="main" tabIndex="-1">
      <Switch>
        <Route exact path={paths.pending.view} component={PendingRequest} />
        <Route path={paths.pending.root} component={PendingRequests} />
      </Switch>
    </div>
  );
};

export default Pending;
