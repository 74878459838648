import React from 'react';
import { generatePath, Link } from 'react-router-dom';

import LanguageSelector from 'visa-frontend-common/src/components/LanguageSelector/LanguageSelector';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import { historyShape } from 'visa-frontend-common/src/dataModel';
import Icon from 'visa-frontend-common/src/components/UI/Icon/Icon';
import { InputGroup } from 'visa-frontend-common/src/components/UI/Form/FormComponents';

import i18n from '../../services/i18n';
import { languageSelectorOptions } from '../../ovafModel';
import paths from '../../paths';

import './Welcome.scss';

const Welcome = (props) => {
  const currentLocale = i18n.getLocaleFromLocalStorage();

  const updateLocale = (newLocale) => {
    i18n.setLocale(newLocale, window.location.reload());
  };

  return (
    <div className="welcome-page container" id="main" tabIndex="-1">
      <div className="row">
        <div className="welcome-page__language-wrapper col-sm-6">
          <h2 className="d-flex align-items-center">
            <Icon className="welcome-page__language-icon" name="language" title={i18n.t('frontPage.languageTitle')} />
            {i18n.t('frontPage.languageTitle')}
          </h2>
          <InputGroup label={i18n.t('frontPage.selectLanguage')}>
            <LanguageSelector
              languageSelectorOptions={languageSelectorOptions}
              locale={currentLocale}
              updateLocale={updateLocale}
            />
          </InputGroup>
        </div>
      </div>
      <div className="welcome-page__sign-in-block row row-cols-md-auto">
        <div className="col-md welcome-page__sign-in-block--left">
          <h2>{i18n.t('welcome.createTitle')}</h2>

          <Button
            label={i18n.t('welcome.createAccount')}
            icon="add"
            onClick={() => props.history.push('/register')}
          />
          <Link
            className="welcome-page__confirm-link"
            to={paths.register.codeResend}
          >
            {i18n.t('welcome.resend')}
          </Link>
        </div>
        <div className="col welcome-page__separator" />
        <div className="col-md welcome-page__sign-in-block--right">
          <h2>{i18n.t('welcome.signTitle')}</h2>
          <a href="/api/login/oauth2/authorization/cognito">
            <Button
              icon="login"
              label={i18n.t('welcome.signIn')}
            />
          </a>
          <Link
            className="welcome-page__forgot-password-link"
            to={paths.forgotPassword.root}
          >
            {i18n.t('welcome.forgotPassword')}
          </Link>
        </div>
      </div>
      <div className="welcome-page__documents row">
        <div className="col-sm">
          <h2>{i18n.t('welcome.documentsTitle')}</h2>
          <p className="d-flex align-items-center">
            <Icon className="welcome-page__documents-icon" name="description" title={i18n.t('welcome.documentsTitle')} />
            <Link to={generatePath(paths.checklist)} target="_blank">
              <strong>{i18n.t('welcome.documentsLink')}</strong>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

Welcome.propTypes = {
  history: historyShape.isRequired,
};

export default Welcome;
