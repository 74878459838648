import React from 'react';
import * as R from 'ramda';

import { SimpleInput, InputShape } from './Input';
import withDebouncedValue from '../../../debounce/DebouncedValue';
import { transformEventValueBeforeHandle } from '../../../../utils/commonUtils';

const TrimInput = (props) => {
  const handleChange = transformEventValueBeforeHandle(
    props.onChange,
    R.unless(
      R.isNil,
      R.compose(R.when(R.isEmpty, R.always(null)), R.trim),
    ),
  );

  return (
    <SimpleInput
      {...R.dissoc('onChange', props)}
      type="text"
      onChange={handleChange}
    />
  );
};

export const trimInputProps = R.curry((
  value,
  name,
  placeholder,
  disabled,
  onChange,
) => {
  return {
    value,
    name,
    placeholder,
    disabled,
    onChange,
  };
});

TrimInput.propTypes = {
  ...R.dissoc('type', InputShape),
};

export { TrimInput as SimpleTrimInput };
export default withDebouncedValue(TrimInput);
