import React, { memo } from 'react';

import GuideSubtitle from '../../../components/Guide/GuideSubtitle';
import GuideItem from '../../../components/Guide/GuideItem';
import i18n from '../../../services/i18n';

const ContactsAndAccommodationGuide = memo(() => {
  return (
    <>
      <GuideSubtitle id="guide-invitations-title" type="h3">{i18n.t('common:applicationForm.invitations.title')}</GuideSubtitle>

      <GuideItem id="contactsAndAccomodationGuide-contactInfoGuideForField30" subTitle={`30. ${i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoSubtitleForField30')}`}>
        {i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoGuideForField30')}
      </GuideItem>

      <GuideItem id="contactsAndAccomodationGuide-contactInfoGuideForField30address" subTitle={i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoSubtitleForField30address')}>
        {i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoGuideForField30address')}
      </GuideItem>

      <GuideItem id="contactsAndAccomodationGuide-contactInfoGuideForField30telephone" subTitle={i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoSubtitleForField30telephone')}>
        {i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoGuideForField30telephone')}
      </GuideItem>

      <GuideItem id="contactsAndAccomodationGuide-contactInfoGuideForField31" subTitle={`31. ${i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoSubtitleForField31')}`}>
        {i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoGuideForField31')}
      </GuideItem>

      <GuideItem id="contactsAndAccomodationGuide-contactInfoGuideForField31address" subTitle={i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoSubtitleForField31address')}>
        {i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoGuideForField31address')}
      </GuideItem>

      <GuideItem id="contactsAndAccomodationGuide-contactInfoGuideForField31telephone" subTitle={i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoSubtitleForField31telephone')}>
        {i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoGuideForField31telephone')}
      </GuideItem>

      <GuideItem id="contactsAndAccomodationGuide-contactInfoGuideForField30oneDayTrip" subTitle={i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoSubtitleForField30oneDayTrip')}>
        {i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoGuideForField30oneDayTrip')}
      </GuideItem>

      <GuideItem id="contactsAndAccomodationGuide-contactInfoGuideForField32" subTitle={`32. ${i18n.t('common:applicationForm.travelCosts.title')}`}>
        {i18n.t('application.guides.contactsAndAccomodationGuide.contactInfoGuideForField32')}
      </GuideItem>
    </>
  );
});

export default ContactsAndAccommodationGuide;
