import { connect } from 'react-redux';
import { checklistCountrySpecificInstructionsSelector } from '../Application/ApplicationReducer';

import ChecklistPage from './ChecklistPage';
import actions from './ChecklistPageActions';
import {
  checklistItemAttachmentsSelector,
  checklistPageLocaleSelector,
  staticChecklistsSelector,
} from './ChecklistPageReducer';

const mapStateToProps = state => ({
  instructions: checklistCountrySpecificInstructionsSelector(state),
  checklists: staticChecklistsSelector(state),
  checklistItemAttachments: checklistItemAttachmentsSelector(state),
  checklistPageLocale: checklistPageLocaleSelector(state),
});

const mapDispatchToProps = actions;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChecklistPage);
