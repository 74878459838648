import React from 'react';
import PropTypes from 'prop-types';

import './UserConfirmationLabelList.scss';

const UserConfirmationLabelList = props => (
  <ul className="user-confirmation-label-list">
    {props.labelList.map(label => <li key={label}>{label}</li>)}
  </ul>
);

UserConfirmationLabelList.propTypes = {
  labelList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UserConfirmationLabelList;
