import React, { memo } from 'react';

import GuideItem from '../../../components/Guide/GuideItem';
import i18n from '../../../services/i18n';

const EuFamilyRelationshipGuide = memo(() => {
  return (
    <GuideItem id="personalDetailsGuide-contactInfoGuideForField17" subTitle={`17. ${i18n.t('common:applicationForm.invitations.euCitizen.subtitle')}`}>
      {i18n.t('application.guides.personalDetailsGuide.contactInfoGuideForField17')}
    </GuideItem>
  );
});

export default EuFamilyRelationshipGuide;
