import * as R from 'ramda';
import { createSelector } from 'visa-frontend-common/src/utils/reducerUtils';
import i18n from '../../services/i18n';

export const newPasswordDisabledSelector = createSelector([
  R.path(['register', 'registerForm', 'currentPassword', 'value']),
  R.path(['register', 'registerForm', 'password', 'value']),
], (currentPassword, password) => !currentPassword && !password);

export const confirmPasswordDisabledSelector = createSelector([
  R.path(['register', 'registerForm', 'currentPassword', 'value']),
  R.path(['register', 'registerForm', 'confirmPassword', 'value']),
], (currentPassword, confirmPassword) => !currentPassword && !confirmPassword);

export const newPasswordValidationErrorSelector = createSelector([
  newPasswordDisabledSelector,
  R.path(['register', 'registerForm', 'currentPassword', 'value']),
  R.path(['register', 'registerForm', 'password', 'value']),
  R.path(['register', 'registerForm', 'password', 'validationError']),
], (newPasswordDisabled, currentPassword, password, validationError) => {
  if (!currentPassword && password) {
    return i18n.t('register.registerForm.pleaseClearField');
  } if (!newPasswordDisabled) {
    return validationError;
  }
  return null;
});

export const confirmPasswordValidationErrorSelector = createSelector([
  confirmPasswordDisabledSelector,
  R.path(['register', 'registerForm', 'currentPassword', 'value']),
  R.path(['register', 'registerForm', 'confirmPassword', 'value']),
  R.path(['register', 'registerForm', 'confirmPassword', 'validationError']),
], (
  newPasswordDisabled,
  currentPassword,
  confirmPassword,
  validationError,
) => {
  if (!currentPassword && confirmPassword) {
    return i18n.t('register.registerForm.pleaseClearField');
  } if (!newPasswordDisabled) {
    return validationError;
  }
  return null;
});

export const saveAccountSettingsDisabledSelector = createSelector([
  R.path(['register', 'registerForm', 'currentPassword', 'value']),
  R.path(['register', 'registerForm', 'password', 'value']),
  R.path(['register', 'registerForm', 'confirmPassword', 'value']),
  newPasswordValidationErrorSelector,
  confirmPasswordValidationErrorSelector,
  R.path(['register', 'registerForm', 'isTravelAgency', 'value']),
  R.path(['register', 'registerForm', 'travelAgencyName', 'value']),
  R.path(['register', 'registerForm', 'travelAgencyName', 'validationError']),
], (
  currentPassword,
  password,
  confirmPassword,
  newPasswordValidationError,
  confirmPasswordValidationError,
  isTravelAgency,
  travelAgencyName,
  travelAgencyNameValidationError,
) => {
  const currentPasswordEnteredButNotNewOne =
    !!currentPassword &&
    (!password || !confirmPassword);

  return !!newPasswordValidationError ||
    !!confirmPasswordValidationError ||
    currentPasswordEnteredButNotNewOne ||
    !!travelAgencyNameValidationError ||
    (isTravelAgency && !travelAgencyName);
});
