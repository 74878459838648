import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import i18n from '../../services/i18n';
import paths from '../../paths';

import './RegisterEmailSent.scss';

const RegisterEmailSent = (props) => {
  return (
    <div className="register-email-sent">
      <h2>{i18n.t('register.emailSent.title')}</h2>
      <div className="register-email-sent__text">
        {i18n.t('register.emailSent.text', { email: props.emailSent })}
      </div>
      <Link
        className="register-email-sent__resend-link"
        to={paths.register.codeResend}
      >
        {i18n.t('welcome.resend')}
      </Link>
    </div>
  );
};

RegisterEmailSent.defaultProps = {
  emailSent: null,
};

RegisterEmailSent.propTypes = {
  emailSent: PropTypes.string,
};

export default RegisterEmailSent;
