import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Address from './Address';
import Button from '../UI/Button/Button';
import Section from '../UI/Section/Section';
import {
  pickValuesDeep,
  flattenObject,
} from '../../utils/commonUtils';
import { formAddressShape, formFieldShapeFor } from '../../dataModel';
import i18n from '../../services/i18n';

const areValuesNotEmptyByKey = (keys, object) => R.compose(
  R.complement(R.isEmpty),
  R.reject(R.isNil),
  R.values,
  flattenObject(R.__, false),
  pickValuesDeep,
  R.pick(keys),
)(object);

class ApplicantAddresses extends React.PureComponent {
  state = {
    showSecondaryAddress: areValuesNotEmptyByKey(['secondaryAddress'], this.props),
  };

  handleButtonClick = () => {
    if (!this.state.showSecondaryAddress) {
      this.setState({ showSecondaryAddress: true });
    } else {
      this.setState({ showSecondaryAddress: false });
      this.props.clearFields([
        'form.secondaryAddress',
      ]);
    }
  };

  render() {
    return (
      <>
        <Address
          address={this.props.address}
          addressFieldName="address"
          labels={{
            street: {
              label: `${i18n.t('common:applicationForm.applicantStreet')} ${this.props.showFieldNumber ? '[19]' : ''}`,
              required: true,
            },
          }}
          handleFieldChange={this.props.handleChange}
          codesetSelectContainer={this.props.codesetSelectContainer}
          oneColumn={this.props.oneColumn}
          describedBy={this.props.describedBy}
          uppercase
        />
        {
          this.state.showSecondaryAddress &&
          <Section>
            <Address
              address={this.props.secondaryAddress}
              addressFieldName="secondaryAddress"
              labels={{
                street: {
                  label: `${i18n.t('common:applicationForm.secondaryStreet')}`,
                  required: true,
                },
              }}
              handleFieldChange={this.props.handleChange}
              codesetSelectContainer={this.props.codesetSelectContainer}
              oneColumn={this.props.oneColumn}
              describedBy={this.props.describedBy}
              uppercase
            />
          </Section>
        }
        <Button
          label={i18n.t(`common:applicationForm.secondaryAddress${this.state.showSecondaryAddress ? 'Remove' : 'Add'}`)}
          onClick={this.handleButtonClick}
          icon={this.state.showSecondaryAddress ? 'remove' : 'add'}
          class="invisible"
          dataCy="secondaryAddress-toggle-button"
        />
      </>
    );
  }
}

ApplicantAddresses.propTypes = {
  address: formFieldShapeFor(formAddressShape).isRequired,
  secondaryAddress: formFieldShapeFor(formAddressShape).isRequired,
  showFieldNumber: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
  describedBy: PropTypes.string,
};

ApplicantAddresses.defaultProps = {
  showFieldNumber: true,
  oneColumn: false,
  describedBy: null,
};

export default ApplicantAddresses;
