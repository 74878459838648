import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../services/i18n';
import LinkButton from '../Button/LinkButton';

import './ExpandableText.scss';

const lineHeight = 21;

class ExpandableText extends React.Component {
  state = {
    expanded: false,
    showExpandButton: false,
  };

  heightWrapperRef = React.createRef();

  contentRef = React.createRef();

  getHeight() {
    if (!this.state.expanded) {
      return this.props.showLines * lineHeight;
    } if (this.contentRef.current) {
      return this.contentRef.current.getBoundingClientRect().height;
    }
    return null;
  }

  componentDidMount() {
    this.setState({
      showExpandButton: (
        this.contentRef.current.getBoundingClientRect().height >
        this.heightWrapperRef.current.getBoundingClientRect().height
      ),
    });
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    const unexpandedMaxHeight = this.props.showLines * lineHeight;
    this.setState(state => ({
      showExpandButton: (
        (this.contentRef.current.getBoundingClientRect().height >
        this.heightWrapperRef.current.getBoundingClientRect().height) ||
          (state.expanded &&
            unexpandedMaxHeight < this.contentRef.current.getBoundingClientRect().height)
      ),
    }));
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const shouldShowExpandButton = this.contentRef.current.getBoundingClientRect().height >
      this.heightWrapperRef.current.getBoundingClientRect().height;
    const propsChanged = prevProps.children !== this.props.children;
    if (prevState.showExpandButton !== shouldShowExpandButton && propsChanged) {
      this.setState({ showExpandButton: shouldShowExpandButton });
    }
  }

  render() {
    const {
      children,
      noGradient,
      lang,
    } = this.props;
    const {
      expanded,
      showExpandButton,
    } = this.state;
    return (
      <div className="expandable-text">
        <div
          ref={this.heightWrapperRef}
          className="expandable-text__height-wrapper"
          style={{ maxHeight: this.getHeight() }}
        >
          <small>
            <pre
              ref={this.contentRef}
              className="expandable-text__content"
            >
              {children}
            </pre>
          </small>
          { !noGradient && showExpandButton && !expanded && (
            <div
              className="expandable-text__gradient"
              style={{ height: this.getHeight() }}
            />)}
        </div>
        { showExpandButton &&
          <LinkButton
            className="expandable-text__link-button"
            onClick={() => this.setState({ expanded: !expanded })}
            ariaExpanded={expanded}
          >
            { i18n.t(expanded ? 'common:showLess' : 'common:showMore', { lng: lang }) }
          </LinkButton>}
      </div>
    );
  }
}

ExpandableText.propTypes = {
  showLines: PropTypes.number,
  children: PropTypes.node,
  noGradient: PropTypes.bool,
  lang: PropTypes.string,
};

ExpandableText.defaultProps = {
  showLines: 4, // four lines when the line height is 21px
  children: null,
  noGradient: false,
  lang: null,
};

export default ExpandableText;
