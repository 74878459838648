import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Form from 'visa-frontend-common/src/components/UI/Form/Form';
import {
  enumTypesToRadioOptions,
  InputGroup,
  RadioButtonGroup,
} from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import { confirmAcceptance } from 'visa-frontend-common/src/services/confirmationService';
import { isNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';

import {
  answerFormShape,
  pendingAnswerType,
  pendingDeliveryTargets,
  pendingRequestReasons,
} from '../../ovafModel';
import i18n from '../../services/i18n';
import { answerText, deliveryText, pendingReasonType } from './pendingUtils';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import actions from './PendingActions';

const PendingAnswerForm = (props) => {
  const pendingAnswerTypes = deliveryTarget => [
    {
      type: pendingAnswerType.ACCEPTED,
      text: answerText(props.requestReason,
        pendingAnswerType.ACCEPTED,
        { location: i18n.t(`pending.answer.location.${deliveryTarget}`) }),
    },
    {
      type: pendingAnswerType.DECLINED,
      text: answerText(props.requestReason,
        pendingAnswerType.DECLINED,
        { location: i18n.t(`pending.answer.location.${deliveryTarget}`) }),
    },
  ];

  const handleSubmit = () => confirmAcceptance(
    i18n.t(`pending.confirmTitle.${props.answerForm.answer.value}`,
      { location: i18n.t(`pending.answer.location.${props.deliveryTarget}`) }),
    props.handleSubmit,
    (<></>),
  );

  return (
    <Form>
      <p>
        {
          deliveryText(props.requestReason,
            props.attachmentsAllowed,
            i18n.t(`pending.answer.location.${props.deliveryTarget}`))
        }
      </p>
      <InputGroup label={
        i18n.t(`pending.attachmentDeliveryLabel.${pendingReasonType(props.requestReason)}`,
          { type: R.toLower(i18n.t(`pending.reason.${props.requestReason}`)) })
      }
      >
        <RadioButtonGroup
          name="answer"
          value={props.answerForm.answer.value}
          onChange={props.handleSelect}
          options={enumTypesToRadioOptions(pendingAnswerTypes(props.deliveryTarget))}
        />
      </InputGroup>
      <LoadingButton
        requestNames={[actions.pendingRequestAnswered().type]}
        class="secondary"
        label={i18n.t('pending.answerButton')}
        onClick={handleSubmit}
        dataCy="submit-answer-button"
        disabled={isNilOrEmpty(props.answerForm.answer.value)}
      />
    </Form>
  );
};

PendingAnswerForm.propTypes = {
  answerForm: answerFormShape.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  requestReason: PropTypes.oneOf(R.values(pendingRequestReasons)).isRequired,
  deliveryTarget: PropTypes.oneOf(pendingDeliveryTargets),
  attachmentsAllowed: PropTypes.bool,
};

PendingAnswerForm.defaultProps = {
  deliveryTarget: null,
  attachmentsAllowed: false,
};

export default PendingAnswerForm;
