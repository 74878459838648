import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { codesetGroupEnum } from '../../../dataModel';
import LabeledContainer from './LabeledContainer';

import './Labeled.scss';

const LabeledCodeList = (props) => {
  const CodesetValueContainer = props.codesetValueContainer;

  return (
    <LabeledContainer
      labelText={props.labelText}
      className={props.className}
      noWrap={props.noWrap}
      indent={props.indent}
      dataCy={props.dataCy}
    >
      {props.value && props.value.length > 0 &&
        <ul className={classnames('labeled__small', { 'labeled__bordered-page-element': props.withBottomBorder })}>
          {props.value.map((item, i) => {
            const key = props.labelText + i;
            return (
              <li key={key}>
                <CodesetValueContainer codesetGroup={props.group} value={item} />
              </li>
            );
          })}
        </ul>}
    </LabeledContainer>
  );
};

LabeledCodeList.propTypes = {
  dataCy: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  className: PropTypes.string,
  group: codesetGroupEnum.isRequired,
  withBottomBorder: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  noWrap: PropTypes.bool,
  indent: PropTypes.bool,
  codesetValueContainer: PropTypes.elementType.isRequired,
};

LabeledCodeList.defaultProps = {
  dataCy: null,
  className: null,
  withBottomBorder: false,
  value: [],
  noWrap: false,
  indent: false,
};

export default LabeledCodeList;
