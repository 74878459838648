import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import i18n from '../../../services/i18n';
import LabeledContainer from './LabeledContainer';

import './Labeled.scss';

const LabeledEnum = (props) => {
  const valueKey = `${props.i18nPrefix}.${props.value}`;
  const displayValue = props.value && i18n.t(valueKey);

  return (
    <LabeledContainer
      labelText={props.labelText}
      className={props.className}
      noWrap={props.noWrap}
      indent={props.indent}
      dataCy={props.dataCy}
    >
      {props.value &&
        <small className={classnames('labeled__small', { 'labeled__bordered-page-element': props.withBottomBorder })}>
          {displayValue}
        </small>}
    </LabeledContainer>
  );
};

LabeledEnum.propTypes = {
  dataCy: PropTypes.string,
  className: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  i18nPrefix: PropTypes.string.isRequired,
  value: PropTypes.string,
  withBottomBorder: PropTypes.bool,
  noWrap: PropTypes.bool,
  indent: PropTypes.bool,
};

LabeledEnum.defaultProps = {
  dataCy: null,
  className: null,
  value: null,
  withBottomBorder: false,
  noWrap: false,
  indent: false,
};

export default LabeledEnum;
