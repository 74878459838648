import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import setDirty from '../../services/navigationprompt/navigationPromptActions';
import { isDirtySelectorFor } from '../../services/navigationprompt/navigationPromptReducer';

const withNavigationPrompt = (WrappedComponent) => {
  class NavigationPrompt extends React.Component {
    componentWillUnmount() {
      if (this.props.isDirty) {
        // eslint-disable-next-line no-restricted-syntax
        this.props.setDirtyNoFlush(false);
      }
      return null;
    }

    render() {
      const wrappedComponentProps = R.omit(['isDirty'], this.props);
      return <WrappedComponent {...wrappedComponentProps} />;
    }
  }

  NavigationPrompt.propTypes = {
    setDirty: PropTypes.func.isRequired,
    isDirty: PropTypes.bool.isRequired,
    setDirtyNoFlush: PropTypes.func.isRequired,
  };

  return NavigationPrompt;
};

export const defaultMapStateToPropsFor = formName => state => ({
  isDirty: isDirtySelectorFor(formName)(state),
});

/* flushSync is required in React 18 to render dirtystate to Prompt before navigation. They need
to be in different batches. Seems FlushSync needs to be imported from same project (react instance).
*/
export const defaultMapDispatchToPropsFor = (
  formName,
  excludedPaths,
  promptTitle,
  promptListLabel,
  flushSync,
) =>
  dispatch => ({
    setDirty: isDirty => (isDirty ?
      dispatch(setDirty([{
        formName,
        isDirty,
        excludedPaths,
        promptTitle,
        promptListLabel,
      }])) :
      flushSync(() => dispatch(setDirty([{
        formName,
        isDirty,
        excludedPaths,
        promptTitle,
        promptListLabel,
      }])))
    ),
    setDirtyNoFlush: isDirty => dispatch(setDirty([{
      formName,
      isDirty,
      excludedPaths,
      promptTitle,
      promptListLabel,
    }])),
  });

export default withNavigationPrompt;
