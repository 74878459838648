import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { formFieldShape, historyShape } from 'visa-frontend-common/src/dataModel';
import paths from '../../paths';
import { forgotPasswordConfirmFormShape } from '../../dataModel';
import ForgotPasswordConfirmForm from './ForgotPasswordConfirmForm';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = (props) => {
  return (
    <div className="container" id="main" tabIndex="-1">
      <Switch>
        <Route path={paths.forgotPassword.confirm}>
          <ForgotPasswordConfirmForm
            forgotPasswordConfirmForm={props.forgotPasswordConfirmForm}
            forgotPasswordConfirmSetFieldAndValidate={
              props.forgotPasswordConfirmSetFieldAndValidate
            }
            forgotPasswordConfirmSubmit={props.forgotPasswordConfirmSubmit}
            forgotPasswordConfirmClearFields={props.forgotPasswordConfirmClearFields}
          />
        </Route>
        <Route path={paths.forgotPassword.root}>
          <ForgotPasswordForm
            history={props.history}
            forgotPasswordForm={props.forgotPasswordForm}
            forgotPasswordSetFieldAndValidate={props.forgotPasswordSetFieldAndValidate}
            forgotPasswordSubmit={props.forgotPasswordSubmit}
            forgotPasswordClearFields={props.forgotPasswordClearFields}
          />
        </Route>
      </Switch>
    </div>
  );
};

ForgotPassword.propTypes = {
  history: historyShape.isRequired,

  forgotPasswordConfirmForm: forgotPasswordConfirmFormShape.isRequired,
  forgotPasswordConfirmSetFieldAndValidate: PropTypes.func.isRequired,
  forgotPasswordConfirmSubmit: PropTypes.func.isRequired,
  forgotPasswordConfirmClearFields: PropTypes.func.isRequired,

  forgotPasswordForm: PropTypes.exact({
    email: formFieldShape,
  }).isRequired,
  forgotPasswordSetFieldAndValidate: PropTypes.func.isRequired,
  forgotPasswordSubmit: PropTypes.func.isRequired,
  forgotPasswordClearFields: PropTypes.func.isRequired,
};

export default ForgotPassword;
