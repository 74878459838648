import { connect } from 'react-redux';

import Application from './Application';
import { userLoggedInSelector } from '../Main/MainReducer';

const mapStateToProps = state => ({
  userLoggedIn: userLoggedInSelector(state),
});

export default connect(mapStateToProps, null)(Application);
