import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import TwoColumnRow from '../UI/TwoColumnRow/TwoColumnRow';
import {
  formAddressShape,
  formFieldShapeFor,
  labelObject,
} from '../../dataModel';
import {
  InputGroup,
  Input,
  inputProps,
} from '../UI/Form/FormComponents';
import i18n from '../../services/i18n';

const Address = (props) => {
  const labels = {
    street: {
      label: `${i18n.t('common:applicationForm.street')}`,
      required: true,
    },
    zip: {
      label: `${i18n.t('common:applicationForm.zip')}`,
    },
    city: {
      label: `${i18n.t('common:applicationForm.city')}`,
      required: true,
    },
    country: {
      label: `${i18n.t('common:applicationForm.country')}`,
      required: true,
    },
    ...props.labels,
  };
  const CodesetSelectContainer = props.codesetSelectContainer;
  const textInputProps = inputProps('text', R.__, R.__, R.__, false, props.handleFieldChange);

  return (
    <TwoColumnRow oneColumn={props.oneColumn}>
      <InputGroup
        label={labels.street.label}
        required={labels.street.required}
        validationError={props.address.value.street.validationError}
        describedBy={props.describedBy}
      >
        <Input {...textInputProps(props.address.value.street.value, `${props.addressFieldName}.street`, null)} uppercase={props.uppercase} required={labels.street.required} />
      </InputGroup>
      <InputGroup
        label={labels.zip.label}
        required={labels.zip.required}
        validationError={props.address.value.zip.validationError}
        describedBy={props.describedBy}
      >
        <Input {...textInputProps(props.address.value.zip.value, `${props.addressFieldName}.zip`, null)} uppercase={props.uppercase} required={labels.zip.required} />
      </InputGroup>
      <InputGroup
        label={labels.city.label}
        required={labels.city.required}
        validationError={props.address.value.city.validationError}
        describedBy={props.describedBy}
      >
        <Input {...textInputProps(props.address.value.city.value, `${props.addressFieldName}.city`, null)} uppercase={props.uppercase} required={labels.city.required} />
      </InputGroup>
      <InputGroup
        label={labels.country.label}
        required={labels.country.required}
        validationError={props.address.value.country.validationError}
        describedBy={props.describedBy}
      >
        <CodesetSelectContainer
          name={`${props.addressFieldName}.country`}
          value={props.address.value.country.value}
          codesetGroup="Country"
          onChange={props.handleFieldChange}
        />
      </InputGroup>
    </TwoColumnRow>
  );
};

Address.propTypes = {
  addressFieldName: PropTypes.oneOf(['address', 'secondaryAddress', 'employerAddress']).isRequired,
  labels: PropTypes.exact({
    street: labelObject,
    zip: labelObject,
    city: labelObject,
    country: labelObject,
  }).isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  address: formFieldShapeFor(formAddressShape).isRequired,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
  uppercase: PropTypes.bool,
  describedBy: PropTypes.string,
};

Address.defaultProps = {
  oneColumn: false,
  uppercase: false,
  describedBy: null,
};

export default Address;
