import http from 'visa-frontend-common/src/services/http';

export const getFeatureFlags = () => {
  return http.fetchAsJSON('/api/feature-flags', { method: 'GET' });
};

export const isFeatureEnabled = (featureFlagName, enabledFeatureFlags) => {
  return enabledFeatureFlags && enabledFeatureFlags.includes(featureFlagName);
};

export const deleteCurrentUser = () => {
  return http.fetchAsJSON('/api/users/current', { method: 'DELETE' });
};

export const getCurrentUser = () => {
  return http.fetchAsJSON('/api/users/current', { method: 'GET' });
};

export const setCurrentUserData = (data) => {
  return http.fetchAsJSON('/api/users/current/settings', { method: 'PUT', body: JSON.stringify(data) });
};

export const getCurrentUserAccountData = () => (
  http.fetchAsJSON('/api/users/current/account', { method: 'GET' })
);

export const setCurrentUserAccountData = data => (
  http.fetchAsJSON('/api/users/current/account', { method: 'PUT', body: JSON.stringify(data) })
);
