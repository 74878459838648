import axios, { getAdapter } from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import { LRUCache } from 'lru-cache';
import * as R from 'ramda';

const FIFTEEN_MINUTES = 1000 * 60 * 15;

const cache = new LRUCache({ ttl: FIFTEEN_MINUTES, max: 100 });

const enhancedCacheAdapter = cacheAdapterEnhancer(
  getAdapter(axios.defaults.adapter),
  {
    enabledByDefault: false,
    defaultCache: cache,
    cacheFlag: 'useCache',
  },
);

export const axiosInstance = axios.create({ adapter: enhancedCacheAdapter });
export const cacheClear = () => cache.clear();

const toLocalizationKey = responseData =>
  (responseData.code ? `${responseData.exception}-${responseData.code}` : responseData.exception);

const errorLocalizationKey = responseData =>
  (responseData && responseData.exception ? `applicationErrors.${toLocalizationKey(responseData)}` : 'common:errors.unexpectedError');

const resetCacheOnRequest = (request) => {
  if (request.resetCache) cache.clear();
  return request;
};

axiosInstance.interceptors.request.use(resetCacheOnRequest);

const requestOptions = (url, options) => {
  return R.mergeRight({ url, ...(options.body ? { data: options.body } : {}) }, R.dissoc('body', options));
};

const request = (options) => {
  const setContentType =
    R.includes(options.method, ['POST', 'PUT']) && (!options.headers || !options.headers['Content-Type']);
  return axiosInstance.request({
    ...options,
    ...(setContentType ? { headers: { ...options.headers, 'Content-Type': 'application/json' } } : {}),
  }).then(
    // Empty string parses to null, as before axios 0.21.2
    R.compose(R.when(R.both(R.is(String), R.isEmpty), R.always(null)), R.prop('data')),
  )
    .catch((error) => {
      // If error is undefined, probable cause is no network connection
      return error ?
        Promise.reject(Error(errorLocalizationKey(error.data))) :
        Promise.reject(Error('common:errors.notNetworkConnection'));
    });
};

export default {
  fetchAsJSON: (url, options) => request(requestOptions(url, { ...options, responseType: 'json' })),
  fetchAsText: (url, options) => request(requestOptions(url, { ...options, responseType: 'text' })),
  fetchAsBlob: (url, options) => request(requestOptions(url, { ...options, responseType: 'blob' })),
};
