import * as R from 'ramda';
import * as validators from 'visa-frontend-common/src/services/validator/validators';
import i18n from '../../services/i18n';

const maxPageCount = 200;
const fileTypes = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  PDF: 'application/pdf',
};
const maxFileSizes = {
  [fileTypes.JPEG]: 10000,
  [fileTypes.PNG]: 10000,
  [fileTypes.PDF]: 100000,
};

const uploadFormValidationRules = {
  documentFiles: {
    rules: {
      file: [
        validators.fileType(R.values(fileTypes)),
        fieldValue =>
          validators.maxFileSizeInKB(R.defaultTo(0, maxFileSizes[fieldValue.type]))(fieldValue),
      ],
    },
    validators: [
      validators.required,
      validators.arrayMaxLength(maxPageCount),
      (fieldValue) => {
        const files = R.compose(
          R.map(R.path(['file', 'value'])),
        )(fieldValue);
        return validators.maxTotalFileSizeInKB(150000)(files);
      },
      (fieldValue) => {
        const pages = R.compose(
          R.sum,
          R.map(R.path(['pages', 'value'])),
        )(fieldValue);
        return (!pages || pages <= maxPageCount ?
          null :
          i18n.t('common:validationMessages.totalPageAmountTooLarge', { max: maxPageCount }));
      },
    ],
  },
};

export default uploadFormValidationRules;
