import React, { memo } from 'react';
import PropTypes from 'prop-types';

import GuideSubtitle from '../../../components/Guide/GuideSubtitle';
import GuideItem from '../../../components/Guide/GuideItem';
import i18n from '../../../services/i18n';

const PersonalDetailsGuide = memo(({ children }) => {
  return (
    <>
      <GuideSubtitle id="guide-personalDetailsSubtitle" type="h3">{i18n.t('common:applicationForm.personalDetailsSubtitle')}</GuideSubtitle>

      <GuideItem id="personalDetailsGuide-surname" subTitle={`1. ${i18n.t('common:applicationForm.surname')}`}>
        {i18n.t('application.guides.personalDetailsGuide.surname')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-surnameAtBirth" subTitle={`2. ${i18n.t('common:applicationForm.surnameAtBirth')}`}>
        {i18n.t('application.guides.personalDetailsGuide.surnameAtBirth')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-formerSurnames" subTitle={i18n.t('common:applicationForm.formerSurnames')}>
        {i18n.t('application.guides.personalDetailsGuide.formerSurnames')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-firstNames" subTitle={`3. ${i18n.t('common:applicationForm.firstNames')}`}>
        {i18n.t('application.guides.personalDetailsGuide.firstNames')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-dateOfBirth" subTitle={`4. ${i18n.t('common:applicationForm.dateOfBirth')}`}>
        {i18n.t('application.guides.personalDetailsGuide.dateOfBirth')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-placeOfBirth" subTitle={`5. ${i18n.t('common:applicationForm.placeOfBirth')}`}>
        {i18n.t('application.guides.personalDetailsGuide.placeOfBirth')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-countryOfBirth" subTitle={`6. ${i18n.t('common:applicationForm.countryOfBirth')}`}>
        {i18n.t('application.guides.personalDetailsGuide.countryOfBirth')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-currentNationality" subTitle={`7. ${i18n.t('common:applicationForm.currentNationality')}`}>
        {i18n.t('application.guides.personalDetailsGuide.currentNationality')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-nationalityAtBirth" subTitle={i18n.t('common:applicationForm.nationalityAtBirth')}>
        {i18n.t('application.guides.personalDetailsGuide.nationalityAtBirth')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-otherNationalities" subTitle={i18n.t('common:applicationForm.otherNationalities')}>
        {i18n.t('application.guides.personalDetailsGuide.otherNationalities')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-sex" subTitle={`8. ${i18n.t('common:applicationForm.sex')}`}>
        {i18n.t('application.guides.personalDetailsGuide.sex')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-maritalStatus" subTitle={`9. ${i18n.t('common:applicationForm.maritalStatus')}`}>
        {i18n.t('application.guides.personalDetailsGuide.maritalStatus')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-nationalIdentityNumber" subTitle={`11. ${i18n.t('common:applicationForm.nationalIdentityNumber')}`}>
        {i18n.t('application.guides.personalDetailsGuide.nationalIdentityNumber')}
      </GuideItem>

      <GuideItem id="personalDetailsGuide-guardianSubtitle" subTitle={`10. ${i18n.t('common:applicationForm.guardianSubtitle')}`}>
        {i18n.t('application.guides.personalDetailsGuide.guardianSubtitle')}
      </GuideItem>

      { children }

      <GuideItem id="personalDetailsGuide-euFamilyRelationshipCode" subTitle={`18. ${i18n.t('common:applicationForm.euFamilyRelationshipCode')}`}>
        {i18n.t('application.guides.personalDetailsGuide.euFamilyRelationshipCode')}
      </GuideItem>
    </>
  );
});

PersonalDetailsGuide.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PersonalDetailsGuide;
