import React, { memo } from 'react';
import PropTypes from 'prop-types';

import GuideSubtitle from '../../../components/Guide/GuideSubtitle';
import GuideItem from '../../../components/Guide/GuideItem';
import i18n from '../../../services/i18n';

const OccupationAndResidenceGuide = memo(({ children }) => {
  return (
    <>
      <GuideSubtitle id="guide-occupationAndResidenceSubtitle" type="h3">{i18n.t('common:applicationForm.occupationAndResidenceSubtitle')}</GuideSubtitle>

      <GuideItem id="occupationAndResidenceGuide-applicantStreet" subTitle={`19. ${i18n.t('common:applicationForm.applicantStreet')}`}>
        {i18n.t('application.guides.occupationAndResidenceGuide.applicantStreet')}
      </GuideItem>
      <GuideItem id="occupationAndResidenceGuide-email" subTitle={i18n.t('common:applicationForm.email')}>
        {i18n.t('application.guides.occupationAndResidenceGuide.email')}
      </GuideItem>
      <GuideItem id="occupationAndResidenceGuide-phonenumber" subTitle={i18n.t('common:applicationForm.phonenumber')}>
        {i18n.t('application.guides.occupationAndResidenceGuide.phonenumber')}
      </GuideItem>

      { children }

      <GuideSubtitle>{`21. ${i18n.t('common:applicationForm.occupationCode')}`}</GuideSubtitle>
      <div id="occupationAndResidenceGuide-occupationCode" lang={i18n.language()}>
        <p>
          <small>{i18n.t('application.guides.occupationAndResidenceGuide.occupationCode')}</small>
        </p>
        <ul>
          <li><small>{i18n.t('application.guides.occupationAndResidenceGuide.occupationExample1')}</small></li>
          <li><small>{i18n.t('application.guides.occupationAndResidenceGuide.occupationExample2')}</small></li>
          <li><small>{i18n.t('application.guides.occupationAndResidenceGuide.occupationExample3')}</small></li>
          <li><small>{i18n.t('application.guides.occupationAndResidenceGuide.occupationExample4')}</small></li>
          <li><small>{i18n.t('application.guides.occupationAndResidenceGuide.occupationExample5')}</small></li>
          <li><small>{i18n.t('application.guides.occupationAndResidenceGuide.occupationExample6')}</small></li>
        </ul>
      </div>

      <GuideItem id="occupationAndResidenceGuide-employerName" subTitle={`22. ${i18n.t('common:applicationForm.occupationAddressSubtitle')}`}>
        {i18n.t('application.guides.occupationAndResidenceGuide.employerName')}
      </GuideItem>
    </>
  );
});

OccupationAndResidenceGuide.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OccupationAndResidenceGuide;
