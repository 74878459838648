import React from 'react';
import PropTypes from 'prop-types';

const Label = (props) => {
  return (
    <label
      className={props.className}
      htmlFor={props.labelFor}
    >
      {props.label}
    </label>
  );
};

Label.propTypes = {
  label: PropTypes.string.isRequired,
  labelFor: PropTypes.string,
  className: PropTypes.string,
};

Label.defaultProps = {
  labelFor: null,
  className: null,
};

export default Label;
