import * as R from 'ramda';
import { createSelector } from '../../utils/reducerUtils';
import {
  getRequestsInLoading,
  getAllRequestsInLoading,
  isAnyRequestCompleted,
  getRequestsInError,
  getAllRequestsInError,
} from './loadingUtils';
import { notNilOrEmpty } from '../../utils/commonUtils';

const isSomeRequestLoadingSelector = createSelector(
  [R.path(['loading']), (state, requestNames) => requestNames],
  (loading, requestNames) => {
    const requestsLoading = notNilOrEmpty(requestNames) ?
      getRequestsInLoading(requestNames, loading) :
      getAllRequestsInLoading(loading);
    return notNilOrEmpty(requestsLoading);
  },
);

const isAnyRequestCompletedSelector = createSelector(
  [R.path(['loading']), (state, requestNames) => requestNames],
  R.flip(isAnyRequestCompleted),
);

const requestsInErrorSelector = createSelector(
  [R.path(['loading']), (state, requestNames) => requestNames],
  (loading, requestNames) => {
    return notNilOrEmpty(requestNames) ?
      getRequestsInError(requestNames, loading) :
      getAllRequestsInError(loading);
  },
);

export {
  isSomeRequestLoadingSelector,
  isAnyRequestCompletedSelector,
  requestsInErrorSelector,
};
