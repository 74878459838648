import React from 'react';

import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import LabeledEnum from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledEnum';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import { formFieldShape } from 'visa-frontend-common/src/dataModel';
import { displayDate } from 'visa-frontend-common/src/services/datetime';

import LabeledCode from '../../../../components/UI/labeledComponents/LabeledCode';
import PreviewValidationError from './PreviewValidationError';
import i18n from '../../../../services/i18n';
import paths from '../../../../paths';
import CodesetValueContainer from '../../../../components/UI/Form/CodesetCode/CodesetValueContainer';

const personalDetailsPath = paths.application.tabs.personalDetails;

const EuCitizenView = (props) => {
  return (
    <>
      <TabSubtitle type="h4" className="preview-application__subtitle">
        {`${i18n.t('common:applicationForm.invitations.euCitizen.subtitle')} [17]`}
      </TabSubtitle>

      { props.euSurname.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.invitations.euCitizen.surnameFamily')} *`}
          validationError={props.euSurname.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.invitations.euCitizen.surnameFamily')} *`}
          dataCy="labeled-euSurname"
          value={props.euSurname.value}
        />}

      { props.euFirstname.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.invitations.euCitizen.firstNamesFamily')} *`}
          validationError={props.euFirstname.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.invitations.euCitizen.firstNamesFamily')} *`}
          dataCy="labeled-euFirstname"
          value={props.euFirstname.value}
        />}

      { props.euDateOfBirth.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.invitations.euCitizen.dateOfBirth')} *`}
          validationError={props.euDateOfBirth.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.invitations.euCitizen.dateOfBirth')} *`}
          dataCy="labeled-euDateOfBirth"
          value={displayDate(props.euDateOfBirth.value)}
        />}

      { props.euNationality.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.invitations.euCitizen.nationality')} *`}
          validationError={props.euNationality.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.invitations.euCitizen.nationality')} *`}
          dataCy="labeled-euNationality"
          group="EuropeanUnionNationality"
          codesetValueContainer={CodesetValueContainer}
          code={props.euNationality.value}
        />}

      { props.euPassportNumber.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.invitations.euCitizen.travelDocumentNumber')} *`}
          validationError={props.euPassportNumber.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.invitations.euCitizen.travelDocumentNumber')} *`}
          dataCy="labeled-euPassportNumber"
          value={props.euPassportNumber.value}
        />}

      { props.euFamilyRelationshipCode.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.invitations.euCitizen.familyRelationship')} [18] *`}
          validationError={props.euFamilyRelationshipCode.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledEnum
          labelText={`${i18n.t('common:applicationForm.invitations.euCitizen.familyRelationship')} [18] *`}
          dataCy="labeled-euFamilyRelationshipCode"
          i18nPrefix="applicationForm.invitations.familyRelationships"
          value={props.euFamilyRelationshipCode.value}
        />}

      { props.euFamilyRelationshipCode.value === 'OTHER' &&
        <>
          { props.euFamilyRelationshipSpecify.validationError ?
            <PreviewValidationError
              fieldNameLabel={`${i18n.t('applicationForm.familyRelationshipSpecify')} *`}
              validationError={props.euFamilyRelationshipSpecify.validationError}
              tabPath={personalDetailsPath}
            /> :
            <LabeledValue
              labelText={`${i18n.t('applicationForm.familyRelationshipSpecify')} *`}
              dataCy="labeled-euFamilyRelationshipSpecify"
              value={props.euFamilyRelationshipSpecify.value}
            />}
        </>}
    </>);
};

EuCitizenView.propTypes = {
  euFirstname: formFieldShape.isRequired,
  euSurname: formFieldShape.isRequired,
  euDateOfBirth: formFieldShape.isRequired,
  euPassportNumber: formFieldShape.isRequired,
  euFamilyRelationshipCode: formFieldShape.isRequired,
  euFamilyRelationshipSpecify: formFieldShape.isRequired,
  euNationality: formFieldShape.isRequired,
};

export default EuCitizenView;
