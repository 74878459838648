import React, { memo } from 'react';
import { generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';

import { historyShape } from 'visa-frontend-common/src/dataModel';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import { fullName } from 'visa-frontend-common/src/utils/dataModelUtils';
import { getDaysSinceStartDateWithText } from 'visa-frontend-common/src/services/datetime';

import i18n from '../../services/i18n';
import paths from '../../paths';
import ApplicationGroupAddForm from './ApplicationGroupAddFormContainer';
import LoadingButton from '../UI/Button/LoadingButtonContainer';
import actions from '../../pages/FrontPage/FrontPageActions';

import './ApplicationListItem.scss';

const ApplicationDraftListItem = memo((props) => {
  const {
    applicationId,
    deleteApplication,
    addApplicationToGroup,
    removeApplicationFromGroup,
    isFormOpen,
  } = props;
  const redirectUserToApplication = () => {
    props.history.push(generatePath(paths.application.update, { applicationId }));
  };

  const applicantName =
    props.firstName || props.surname ?
      fullName(props.surname, props.firstName) :
      i18n.t('common.missingNamePlaceholder');

  return (
    <div className="application-list-item" data-cy={`application-list-item-${applicationId}`}>
      <div className="application-list-item__body">
        <div className="application-list-item__content">
          <Button
            className="application-list-item__header"
            icon="arrow_forward"
            class="invisible"
            onClick={redirectUserToApplication}
            label={applicantName}
            dataCy={`application-list-item-${applicationId}-link-button`}
          />
          <small className="application-list-item__status">
            <span>
              {`${i18n.t('frontPage.modified')}: `}
            </span>
            {getDaysSinceStartDateWithText(props.modifiedAt)}
          </small>
        </div>
        <div className="application-list-item__actions">
          <LoadingButton
            requestNames={[actions.applicationDeleted().type]}
            icon="delete_forever"
            class="invisible"
            label={i18n.t('common:delete')}
            onClick={() => deleteApplication(applicationId)}
            disabled={isFormOpen}
            dataCy={`application-list-item-${applicationId}-delete-button`}
          />
          {removeApplicationFromGroup && <Button
            icon="group_remove"
            class="invisible"
            label={i18n.t('applicationGroups.removeApplicationFromGroup')}
            onClick={() => removeApplicationFromGroup(applicationId)}
            dataCy={`application-list-item-${applicationId}-ungroup-button`}
          />}
          {addApplicationToGroup && <Button
            icon="group_add"
            class="invisible"
            label={i18n.t('applicationGroups.addToGroup')}
            onClick={() => addApplicationToGroup(applicationId)}
            disabled={isFormOpen}
            dataCy={`application-list-item-${applicationId}-group-button`}
          />}
        </div>
      </div>
      { isFormOpen && <ApplicationGroupAddForm /> }
    </div>
  );
});

ApplicationDraftListItem.propTypes = {
  history: historyShape.isRequired,
  applicationId: PropTypes.string.isRequired,
  surname: PropTypes.string,
  firstName: PropTypes.string,
  modifiedAt: PropTypes.string.isRequired,
  isFormOpen: PropTypes.bool.isRequired,
  deleteApplication: PropTypes.func.isRequired,
  addApplicationToGroup: PropTypes.func,
  removeApplicationFromGroup: PropTypes.func,
};

ApplicationDraftListItem.defaultProps = {
  firstName: null,
  surname: null,
  addApplicationToGroup: null,
  removeApplicationFromGroup: null,
};

export default ApplicationDraftListItem;
