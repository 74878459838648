export default {
  root: '/',
  logout: '/logout',
  checklist: '/checklist/:espLocation?/:checklistId?',
  application: {
    root: '/application',
    create: '/application/create/:tab?',
    update: '/application/:applicationId/:tab?',
    completed: '/application/:applicationId/completed',
    tabs: {
      personalDetails: 'personal-details',
      travelDocuments: 'travel-documents',
      occupationAndResidence: 'occupation-and-residence',
      journeyDetails: 'journey-details',
      contactsAndAccommodation: 'contacts-and-accommodation',
      applicationDocuments: 'application-documents',
      finalize: 'finalize',
    },
  },
  pending: {
    root: '/requests',
    view: '/requests/:pendingId',
  },
  register: {
    root: '/register',
    codeSent: '/register/code-sent',
    codeResend: '/register/code-resend',
    confirm: '/register/confirm',
  },
  forgotPassword: {
    root: '/forgot-password',
    confirm: '/forgot-password/confirm',
  },
  accessibility: {
    root: '/accessibility',
  },
  accountSettings: {
    root: '/account-settings',
  },
  guidePage: {
    root: '/guide',
  },
};
