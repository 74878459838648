import PropTypes from 'prop-types';
import { formFieldDefaultProps, formFieldShape } from 'visa-frontend-common/src/dataModel';

export const registerFormShape = PropTypes.exact({
  email: formFieldShape,
  password: formFieldShape,
  currentPassword: formFieldShape,
  confirmPassword: formFieldShape,
  isTravelAgency: formFieldShape,
  travelAgencyName: formFieldShape,
});

export const registerFormDefaultProps = {
  email: formFieldDefaultProps,
  password: formFieldDefaultProps,
  currentPassword: formFieldDefaultProps,
  confirmPassword: formFieldDefaultProps,
  isTravelAgency: formFieldDefaultProps,
  travelAgencyName: formFieldDefaultProps,
};

export const forgotPasswordConfirmFormShape = PropTypes.exact({
  email: formFieldShape,
  password: formFieldShape,
  confirmPassword: formFieldShape,
  confirmationCode: formFieldShape,
});

export const forgotPasswordConfirmFormDefaultProps = {
  email: formFieldDefaultProps,
  password: formFieldDefaultProps,
  confirmPassword: formFieldDefaultProps,
  confirmationCode: formFieldDefaultProps,
};
