import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';

import {
  validatingSetFieldThunk,
  validatingSubmitFormThunk,
} from 'visa-frontend-common/src/services/validator/validatorActions';
import { notifySuccess } from 'visa-frontend-common/src/services/pushNotificationService';

import i18n from '../../services/i18n';
import { forgotPassword, forgotPasswordConfirm } from '../../services/forgotPasswordService';
import paths from '../../paths';

import rules from './ForgotPasswordValidationRules';

const forgotPasswordSent = createAction('FORGOT_PASSWORD_SENT_COMPLETED');
const forgotPasswordFormFieldSet = createAction('FORGOT_PASSWORD_FORM_FIELD_SET');

const forgotPasswordConfirmSent = createAction('FORGOT_PASSWORD_CONFIRM_SENT_COMPLETED');
const forgotPasswordConfirmFormFieldSet = createAction('FORGOT_PASSWORD_CONFIRM_FORM_FIELD_SET');

const forgotPasswordSetFieldAndValidate =
  validatingSetFieldThunk(forgotPasswordFormFieldSet, rules);
const forgotPasswordConfirmSetFieldAndValidate =
  validatingSetFieldThunk(forgotPasswordConfirmFormFieldSet, rules);

const forgotPasswordClearFields = createAction('FORGOT_PASSWORD_CLEAR_FORM_FIELDS');
const forgotPasswordConfirmClearFields = createAction('FORGOT_PASSWORD_CONFIRM_CLEAR_FORM_FIELDS');

const forgotPasswordSubmit = form => (dispatch) => {
  validatingSubmitFormThunk(
    forgotPasswordFormFieldSet,
    forgotPassword,
    forgotPasswordSent,
    rules,
  )(form)(dispatch)
    .then(
      (result) => {
        if (!R.is(Error)(result)) {
          return Promise.resolve(
            dispatch(
              forgotPasswordConfirmFormFieldSet({ email: { value: form.email.value } }, form),
            ),
          ).then(() => {
            dispatch(push(`${paths.forgotPassword.confirm}`));
            notifySuccess('forgot-password-success', i18n.t('forgotPassword.successNotification'));
          });
        }
        return result;
      },
    );
};

const forgotPasswordConfirmSubmit = form => dispatch =>
  validatingSubmitFormThunk(
    forgotPasswordConfirmFormFieldSet,
    forgotPasswordConfirm,
    forgotPasswordConfirmSent,
    rules,
  )(form)(dispatch)
    .then(R.ifElse(
      R.is(Error),
      err => err,
      () => {
        R.compose(dispatch, push)(`${paths.root}`);
        notifySuccess('forgot-password-confirm-success', i18n.t('forgotPassword.confirmSuccessNotification'));
      },
    ));

const actions = {
  forgotPasswordSent,
  forgotPasswordFormFieldSet,
  forgotPasswordSetFieldAndValidate,
  forgotPasswordClearFields,
  forgotPasswordSubmit,

  forgotPasswordConfirmSent,
  forgotPasswordConfirmSetFieldAndValidate,
  forgotPasswordConfirmFormFieldSet,
  forgotPasswordConfirmClearFields,
  forgotPasswordConfirmSubmit,
};

export default actions;
