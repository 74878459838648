// eslint-disable-next-line no-restricted-imports
import i18n from 'visa-frontend-common/src/services/i18n';
import { changeLocale as changeMomentLocale } from 'visa-frontend-common/src/services/datetime';

import enGB from '../languages/en-GB.json';
import ruRU from '../languages/ru-RU.json';
import thTH from '../languages/th-TH.json';
import idID from '../languages/id-ID.json';
import faIR from '../languages/fa-IR.json';
import frFR from '../languages/fr-FR.json';
import sqAL from '../languages/sq-AL.json';
import arAR from '../languages/ar-AR.json';
import trTR from '../languages/tr-TR.json';
import neNP from '../languages/ne-NP.json';
import viVN from '../languages/vi-VN.json';
import ukUA from '../languages/uk-UA.json';
import zhCN from '../languages/zh-CN.json';
import esES from '../languages/es-ES.json';
import ptPT from '../languages/pt-PT.json';
import grGR from '../languages/gr-GR.json';
import fiFI from '../languages/fi-FI.json';
import svSE from '../languages/sv-SE.json';

import accessibilityEnGb from '../languages/accessibility-en-GB.json';
import accessibilityFiFI from '../languages/accessibility-fi-FI.json';
import accessibilitySvSe from '../languages/accessibility-sv-SE.json';

i18n.localI18next.addResourceBundle('en-GB', 'ovaf', enGB.ovaf, true, true);
i18n.localI18next.addResourceBundle('ru-RU', 'ovaf', ruRU.ovaf, true, true);
i18n.localI18next.addResourceBundle('th-TH', 'ovaf', thTH.ovaf, true, true);
i18n.localI18next.addResourceBundle('id-ID', 'ovaf', idID.ovaf, true, true);
i18n.localI18next.addResourceBundle('fa-IR', 'ovaf', faIR.ovaf, true, true);
i18n.localI18next.addResourceBundle('fr-FR', 'ovaf', frFR.ovaf, true, true);
i18n.localI18next.addResourceBundle('sq-AL', 'ovaf', sqAL.ovaf, true, true);
i18n.localI18next.addResourceBundle('ar-AR', 'ovaf', arAR.ovaf, true, true);
i18n.localI18next.addResourceBundle('tr-TR', 'ovaf', trTR.ovaf, true, true);
i18n.localI18next.addResourceBundle('ne-NP', 'ovaf', neNP.ovaf, true, true);
i18n.localI18next.addResourceBundle('vi-VN', 'ovaf', viVN.ovaf, true, true);
i18n.localI18next.addResourceBundle('uk-UA', 'ovaf', ukUA.ovaf, true, true);
i18n.localI18next.addResourceBundle('zh-CN', 'ovaf', zhCN.ovaf, true, true);
i18n.localI18next.addResourceBundle('es-ES', 'ovaf', esES.ovaf, true, true);
i18n.localI18next.addResourceBundle('pt-PT', 'ovaf', ptPT.ovaf, true, true);
i18n.localI18next.addResourceBundle('gr-GR', 'ovaf', grGR.ovaf, true, true);
i18n.localI18next.addResourceBundle('fi-FI', 'ovaf', fiFI.ovaf, true, true);
i18n.localI18next.addResourceBundle('sv-SE', 'ovaf', svSE.ovaf, true, true);

// Accessibility statement only in english, finnish and swedish
i18n.localI18next.addResourceBundle('en-GB', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('ru-RU', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('th-TH', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('id-ID', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('fa-IR', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('fr-FR', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('sq-AL', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('ar-AR', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('tr-TR', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('ne-NP', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('vi-VN', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('uk-UA', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('zh-CN', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('es-ES', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('pt-PT', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('gr-GR', 'accessibility', accessibilityEnGb.accessibility, true, true);
i18n.localI18next.addResourceBundle('fi-FI', 'accessibility', accessibilityFiFI.accessibility, true, true);
i18n.localI18next.addResourceBundle('sv-SE', 'accessibility', accessibilitySvSe.accessibility, true, true);

i18n.localI18next.loadNamespaces(['ovaf', 'accessibility']);
i18n.localI18next.setDefaultNamespace('ovaf');

const {
  t,
  tBoolean,
  language,
  changeLanguage,
} = i18n;

const lang = localStorage.getItem('i18nextLng');

if (!lang || lang === 'dev') {
  localStorage.setItem('i18nextLng', 'en-GB');
  i18n.localI18next.changeLanguage();
}

const getLocaleFromLocalStorage = () => {
  return localStorage.getItem('i18nextLng').replace('-', '_');
};

const setLocale = (locale, callback = null) => {
  changeMomentLocale(locale);
  return i18n.changeLanguage(locale, callback);
};

const mainContentLanguage = () => {
  // In russian both Guide + page are translated, otherwise main page is in english
  return i18n.language() === 'ru' ? 'ru' : 'en';
};

export default
{
  language,
  mainContentLanguage,
  changeLanguage,
  t,
  tBoolean,
  getLocaleFromLocalStorage,
  setLocale,
};
