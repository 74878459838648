import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';

import Button from '../Button/Button';
import sortingActions from '../../../services/sorting/sortingActions';
import { sortingSelectorFor } from '../../../services/sorting/sortingReducer';
import { numberOfPagesForList, rowsForCurrentPage } from '../../../services/pagination/pagination';
import paginationActions from '../../../services/pagination/paginationActions';
import { paginationSelectorFor } from '../../../services/pagination/paginationReducer';
import { paginationPropType } from './tableUtils';
import i18n from '../../../services/i18n';
import { exportHtmlTableDomStringAsXlsx, exportOptionsShape } from '../../../services/exportService';
import ExportButton from '../Button/ExportButton';
import PaginationControl from '../PaginationControl/PaginationControl';

import './Table.scss';

const defaultRowsOnPageOptions = [10, 25, 50, 100, 1000];

const makeUniqueSortingSelectorInstance = listName => sortingSelectorFor(listName);
const makeUniquePaginationSelectorInstance = listName => paginationSelectorFor(listName);
export const defaultMapStateToProps = (_, ownProps) => {
  const sortingSelector = makeUniqueSortingSelectorInstance(ownProps.listName);
  const paginationSelector = makeUniquePaginationSelectorInstance(ownProps.listName);
  return function mapStateToProps(state) {
    return { ...sortingSelector(state), ...paginationSelector(state) };
  };
};
export const defaultMapDispatchToProps = (dispatch, ownProps) => {
  if (R.isNil(ownProps.listName)) return {};
  return {
    listSortChanged: (sortedBy, sortDescending) =>
      dispatch(sortingActions.listSortChanged(ownProps.listName, sortedBy, sortDescending)),
    listPaginationChanged: (rowsOnPage, currentPage) =>
      dispatch(paginationActions.listPaginationChanged(ownProps.listName, rowsOnPage, currentPage)),
  };
};

export class Table extends React.Component {
  state = {
    showStickyHeader: false,
    stickyInitialised: false,
    headerWidth: 0,
    headerHeight: 0,
  };

  tableRef = createRef();

  tableWrapperRef = createRef();

  headerRef = createRef();

  stickyTableRef = createRef();

  toggleSticky = () => {
    if (!this.tableRef.current) {
      return;
    }

    const { offsetTop } = this.tableRef.current;
    const { width } = this.tableWrapperRef.current.getBoundingClientRect();
    const { height } = this.tableRef.current.getBoundingClientRect();
    const { height: headerHeight } = this.headerRef.current.getBoundingClientRect();
    const showStickyHeader = (
      window.pageYOffset > offsetTop &&
      window.pageYOffset <= offsetTop + height - headerHeight
    );
    this.setState({
      showStickyHeader,
      headerWidth: width,
      headerHeight: showStickyHeader ? headerHeight : 0,
      stickyInitialised: true,
    });
  };

  initSticky = () => {
    if (this.props.isSticky && this.headerRef.current && !this.state.stickyInitialised) {
      window.addEventListener('scroll', this.toggleSticky);
      this.toggleSticky();
    }
  };

  componentDidUpdate = () => this.initSticky();

  componentWillUnmount = () => (
    this.props.isSticky && window.removeEventListener('scroll', this.toggleSticky)
  );

  render = () => {
    const {
      props,
      state,
    } = this;

    if (R.isEmpty(props.bodyRows)) {
      return <p className={props.notFoundClassName}>{props.emptyText}</p>;
    }

    const isTableSorted = R.has('sortedBy', props);

    const handleSortChangeFor = (sortKey) => {
      const sortDescendingUpdated = props.sortedBy === sortKey ? !props.sortDescending : false;
      return props.listSortChanged(sortKey, sortDescendingUpdated);
    };

    const handleExport = (ref, exportOptions) => {
      if (!ref || !ref.current) return null;
      const htmlTableDomStringWithoutSortIcons = R.replace(
        />(sort|arrow_downward|arrow_upward)</g,
        '><',
        ref.current.outerHTML,
      );
      return exportHtmlTableDomStringAsXlsx(htmlTableDomStringWithoutSortIcons, exportOptions);
    };

    const getSortIcon = (sortKey) => {
      if (props.sortedBy !== sortKey || !isTableSorted) {
        return 'sort';
      }

      return props.sortDescending ? 'arrow_downward' : 'arrow_upward';
    };

    const header = (cell, index) => {
      if (R.has('sortKey', cell)) {
        return (
          <th
            key={cell.sortKey}
            className={
              classNames(
                'table__heading',
                props.thClassName,
                { 'table__heading--sticky': state.showStickyHeader },
              )
            }
          >
            <div className="table__heading-content--sortable">
              {cell.label}
              <Button
                class="invisible"
                color="blue"
                className="table__heading-sort-button"
                icon={getSortIcon(cell.sortKey)}
                onClick={() => handleSortChangeFor(cell.sortKey)}
                dataCy={`sort-button-${cell.label}`}
              />
            </div>
          </th>
        );
      }

      return (
        <th className={classNames('table__heading', props.thClassName)} key={`${cell.label}-${index}`}>
          {cell.label}
        </th>
      );
    };

    const tableRows = R.when(
      () => props.pagination,
      rowsForCurrentPage(props.rowsOnPage, props.currentPage),
    )(props.bodyRows);

    const renderTable = renderStickyTable => (
      <table
        ref={renderStickyTable ? this.stickyTableRef : this.tableRef}
        className={classNames(
          'table',
          { 'table--alternating-rows': props.alternatingRows },
          props.className,
          { 'table--sticky': renderStickyTable },
        )}
        data-cy={props.dataCy}
      >
        <thead ref={!renderStickyTable && this.headerRef}>
          <tr>
            {props.headerTexts.map(header)}
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </table>
    );

    return (
      <>
        {
          (props.title || props.exportable) &&
          <div className="table__title-container">
            { props.title && <h3 className="table__title">{props.title}</h3> }
            {
              props.exportable &&
              <ExportButton
                className="table__export-button"
                onClick={() => handleExport(this.tableRef, props.exportOptions)}
                disabled={
                  props.pagination &&
                  numberOfPagesForList(props.rowsOnPage, props.bodyRows.length) > 1
                }
              />
            }
          </div>
        }
        <div
          ref={this.tableWrapperRef}
          className="table-wrapper"
        >
          {renderTable()}
          {props.isSticky && (
            <div
              className={classNames(
                'table__sticky-container',
                { 'table__sticky-container--hide': !state.showStickyHeader },
              )}
              style={{
                width: this.state.headerWidth,
                height: this.state.headerHeight,
              }}
            >
              {renderTable(true)}
            </div>
          )}
        </div>
        {
          props.pagination &&
          <PaginationControl
            listPaginationChanged={props.listPaginationChanged}
            rowsOnPageOptions={props.rowsOnPageOptions}
            rowsOnPage={props.rowsOnPage}
            currentPage={props.currentPage}
            listLength={props.bodyRows.length}
          />
        }
      </>
    );
  };
}

Table.propTypes = {
  title: PropTypes.string,
  headerTexts: PropTypes.arrayOf(PropTypes.exact({
    label: PropTypes.string,
    sortKey: PropTypes.string,
  })).isRequired,
  bodyRows: PropTypes.arrayOf(PropTypes.node).isRequired,
  emptyText: PropTypes.string,
  className: PropTypes.string,
  thClassName: PropTypes.string,
  dataCy: PropTypes.string,
  alternatingRows: PropTypes.bool,
  exportable: PropTypes.bool,
  exportOptions: exportOptionsShape,
  // eslint-disable-next-line react/no-unused-prop-types
  listName: PropTypes.string,
  listSortChanged: PropTypes.func,
  sortedBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  notFoundClassName: PropTypes.string,
  pagination: paginationPropType,
  listPaginationChanged: PropTypes.func,
  rowsOnPageOptions: PropTypes.arrayOf(PropTypes.number),
  rowsOnPage: PropTypes.number,
  currentPage: PropTypes.number,
  isSticky: PropTypes.bool,
};

Table.defaultProps = {
  title: null,
  emptyText: i18n.t('common:emptyList'),
  className: null,
  thClassName: null,
  dataCy: null,
  alternatingRows: false,
  exportable: false,
  exportOptions: null,
  listName: null,
  listSortChanged: null,
  sortedBy: null,
  sortDescending: null,
  notFoundClassName: null,
  pagination: false,
  listPaginationChanged: null,
  rowsOnPageOptions: defaultRowsOnPageOptions,
  rowsOnPage: defaultRowsOnPageOptions[0],
  currentPage: 1,
  isSticky: false,
};
