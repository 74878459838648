import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Modal from '../Modal/Modal';
import i18n from '../../../services/i18n';
import LoadingButton from '../Button/LoadingButton';
import { isAnyRequestLoading } from '../../../services/loading/loadingUtils';

import './ConfirmationModal.scss';

export const defaultMapStateToProps = (state, { requestNames = [] }) => ({
  requestsLoading: isAnyRequestLoading(requestNames, state.loading),
});

const confirmButtonDefaultProps = {
  label: i18n.t('common:true'),
  icon: 'check',
  color: 'green',
  disabled: false,
  autoFocus: true,
};

export const id = 'confirmation-modal';

class ConfirmationModal extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('popstate', this.handlePopStateAndBeforeUnload);
    window.addEventListener('beforeunload', this.handlePopStateAndBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopStateAndBeforeUnload);
    window.removeEventListener('beforeunload', this.handlePopStateAndBeforeUnload);
  }

  handlePopStateAndBeforeUnload = () => {
    // Cancel confirmation when a navigation occurs e.g. due triggering the browser's back button
    if (this.props.isOpen) this.props.onCancel();
  };

  render() {
    const confirmButtonProps =
      R.mergeRight(confirmButtonDefaultProps, this.props.confirmButtonProps);
    return (
      <Modal
        id={id}
        overlayClassName="confirm-modal__overlay"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onCancel}
        dataAttributes={{ cy: 'confirmation-modal' }}
        disableVerticalScrollbar={this.props.disableVerticalScrollbar}
        blurBackdrop
        disableBodyScroll
      >
        <h2 className="confirm-modal__title">{this.props.message}</h2>

        { this.props.children && <div className="confirm-modal__body">{this.props.children}</div> }

        <div className="confirm-modal__button-container">
          <LoadingButton
            className="confirm-modal__confirm-button"
            dataCy="confirm-modal-confirm-button"
            onClick={this.props.onConfirm}
            label={confirmButtonProps.label}
            icon={confirmButtonProps.icon}
            color={confirmButtonProps.color}
            disabled={confirmButtonProps.disabled}
            autoFocus={confirmButtonProps.autoFocus}
            requestsLoading={this.props.requestsLoading}
          />
          <LoadingButton
            className="confirm-modal__cancel-button"
            dataCy="confirm-modal-cancel-button"
            label={i18n.t('common:cancel')}
            onClick={this.props.onCancel}
            icon="clear"
            color="blue"
            class="secondary"
            requestsLoading={this.props.requestsLoading}
            loadingIcon="clear"
          />
        </div>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.node,
  confirmButtonProps: PropTypes.exact({
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    color: PropTypes.oneOf(['blue', 'green', 'red']),
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
  }),
  disableVerticalScrollbar: PropTypes.bool,
  requestsLoading: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  requestNames: PropTypes.arrayOf(PropTypes.string), // Used by defaultMapStateToProps
};

ConfirmationModal.defaultProps = {
  isOpen: true,
  children: null,
  confirmButtonProps: confirmButtonDefaultProps,
  disableVerticalScrollbar: false,
  requestsLoading: false,
  requestNames: [],
};

export default ConfirmationModal;
