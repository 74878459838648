import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';

import { notifyError } from 'visa-frontend-common/src/services/pushNotificationService';
import { getCurrentUser, getFeatureFlags } from '../../services/utilityService';
import i18n from '../../services/i18n';
import paths from '../../paths';
import loadCaptchaLibrary from '../../services/captchaService';

const userFetched = createAction('MAIN_USER_FETCHED_COMPLETED');
const featureFlagsFetched = createAction('FEATURE_FLAGS_FETCHED_COMPLETED');
const featureFlagsError = createAction('FEATURE_FLAGS_ERROR');
const logoutDone = createAction('LOGOUT');

const fetchCurrentUser = () => (dispatch) => {
  return getCurrentUser()
    .then(response => i18n.setLocale(response.locale)
      .then(() => dispatch(userFetched(response))))
    .catch((error) => {
      dispatch(userFetched(error));
      return error;
    });
};

const fetchFeatureFlags = () => (dispatch) => {
  return getFeatureFlags()
    .then((response) => {
      return dispatch(featureFlagsFetched(response));
    })
    // TODO: remove when featureflag 401 fixed
    .catch(() => dispatch(featureFlagsError()));
};

const loadCaptcha = () => () => loadCaptchaLibrary(
  () => notifyError('captchaLoadingFailed', i18n.t('applicationErrors.captchaLoadingFailed')),
);

const doLogout = () => (dispatch) => {
  dispatch(logoutDone());
  dispatch(push(paths.root));
};

const actions = {
  fetchCurrentUser,
  userFetched,
  fetchFeatureFlags,
  featureFlagsError,
  featureFlagsFetched,
  doLogout,
  logoutDone,
  loadCaptcha,
};

export default actions;
