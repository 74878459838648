import * as R from 'ramda';

import { isUnderage } from '../services/datetime';
import { isNilOrEmpty } from './commonUtils';
import { minorChecklistItemTypes } from '../components/ApplicationForm/commonApplicationModel';

const applicantIsMinorOrUnknown = referenceDate =>
  R.anyPass([
    R.propSatisfies(R.complement(isNilOrEmpty), 'validationError'),
    R.propSatisfies(isNilOrEmpty, 'value'),
    R.propSatisfies(dateOfBirth => isUnderage(dateOfBirth, referenceDate), 'value'),
  ]);

const documentForMinor = R.includes(R.__, minorChecklistItemTypes);
const applicantIsAdult = R.complement(applicantIsMinorOrUnknown());
const hideDocumentForMinor = (dateOfBirthField, checklistItemType, referenceDate) => R.and(
  applicantIsAdult(dateOfBirthField, referenceDate),
  documentForMinor(checklistItemType),
);

export {
  applicantIsMinorOrUnknown,
  applicantIsAdult,
  documentForMinor,
  hideDocumentForMinor,
};
