import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import { formFieldShape, formFieldShapeFor } from 'visa-frontend-common/src/dataModel';
import { invitationFormShape } from 'visa-frontend-common/src/components/ApplicationForm/Invitations/InvitationsDataModel';
import { notNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';

import i18n from '../../../../services/i18n';
import PreviewValidationError from './PreviewValidationError';
import paths from '../../../../paths';
import PreviewInvitation from './PreviewInvitation';

const PreviewInvitations = (props) => {
  const contactsAndAccommodationPath = paths.application.tabs.contactsAndAccommodation;

  const invitationValidationError =
    R.compose(
      R.last,
      R.filter(R.compose(R.not, R.isNil)),
    )([
      props.contactOrAccommodation.validationError,
      props.invitingOrganization.validationError,
      props.invitingPersons.validationError,
      props.accommodations.validationError,
      props.oneDayTripWithoutAccommodation.validationError,
    ]);

  return (
    <>
      { invitationValidationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.invitations.title')} *`}
          validationError={invitationValidationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <TabSubtitle type="h4" className="preview-application__subtitle">
          {`${i18n.t('common:applicationForm.invitations.title')}${props.eucitizenFamily.value ? '' : ' *'}`}
        </TabSubtitle>}

      <>
        { notNilOrEmpty(props.accommodations.value) &&
          <>
            <TabSubtitle type="h4" className="preview-application__subtitle">
              {`${i18n.t('common:applicationForm.invitations.ACCOMMODATION.title')} [30]`}
            </TabSubtitle>
            {
              props.accommodations.value.map((invitation, i) => {
                const key = `Accommodation${i}`;
                return (
                  <React.Fragment key={key}>
                    {i === 0 &&
                      <PreviewInvitation invitation={invitation} />}
                    {i !== 0 &&
                      <>
                        <TabSubtitle type="h4" className="preview-application__subtitle">
                          {i18n.t('common:applicationForm.invitations.ACCOMMODATION.subtitle', { order: i + 1 })}
                        </TabSubtitle>
                        <PreviewInvitation invitation={invitation} />
                      </>}
                  </React.Fragment>
                );
              })
            }
          </>}
      </>

      { notNilOrEmpty(props.invitingPersons.value) &&
      <>
        <TabSubtitle type="h4" className="preview-application__subtitle">
          {`${i18n.t('common:applicationForm.invitations.PERSON.title')} [30]`}
        </TabSubtitle>
        {
          props.invitingPersons.value.map((invitation, i) => {
            const key = `InvitingPerson${i}`;
            return (
              <React.Fragment key={key}>
                { i === 0 &&
                <PreviewInvitation invitation={invitation} />}
                { i !== 0 &&
                <>
                  <TabSubtitle type="h4" className="preview-application__subtitle">
                    {i18n.t('common:applicationForm.invitations.PERSON.subtitle', { order: i + 1 })}
                  </TabSubtitle>
                  <PreviewInvitation invitation={invitation} />
                </>}
              </React.Fragment>
            );
          })
        }
      </>}

      { notNilOrEmpty(props.invitingOrganization.value) &&
      <>
        <TabSubtitle type="h4" className="preview-application__subtitle">
          {`${i18n.t('common:applicationForm.invitations.ORGANIZATION.title')} [31]`}
        </TabSubtitle>
        <PreviewInvitation invitation={props.invitingOrganization.value} />
      </>}

      { notNilOrEmpty(props.invitingOrganizationContact.value) &&
      <>
        <TabSubtitle type="h4" className="preview-application__subtitle">
          {i18n.t('common:applicationForm.invitations.ORGANIZATION_CONTACT.title')}
        </TabSubtitle>
        <PreviewInvitation invitation={props.invitingOrganizationContact.value} />
      </>}

      { props.oneDayTripWithoutAccommodation.value &&
      <TabSubtitle type="h4" className="preview-application__subtitle">
        {i18n.t('common:applicationForm.invitations.oneDayTripWithoutAccommodation')}
      </TabSubtitle>}
    </>
  );
};

PreviewInvitations.propTypes = {
  contactOrAccommodation: formFieldShape.isRequired,
  invitingOrganization: formFieldShapeFor(invitationFormShape).isRequired,
  invitingOrganizationContact: formFieldShapeFor(invitationFormShape).isRequired,
  invitingPersons: formFieldShapeFor(PropTypes.arrayOf(invitationFormShape)).isRequired,
  accommodations: formFieldShapeFor(PropTypes.arrayOf(invitationFormShape)).isRequired,
  oneDayTripWithoutAccommodation: formFieldShape.isRequired,
  eucitizenFamily: formFieldShapeFor(PropTypes.bool).isRequired,
};

export default PreviewInvitations;
