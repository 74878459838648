import { createAction } from 'redux-actions';

import apiThunkWithLoading from 'visa-frontend-common/src/utils/actionUtils';
import { setCurrentUserData } from '../../services/utilityService';
import i18n from '../../services/i18n';

const updatingCurrentUserData = createAction('CURRENT_USER_DATA_UPDATE_COMPLETED');

const updateLocale = locale => (dispatch) => {
  return dispatch(apiThunkWithLoading(setCurrentUserData, updatingCurrentUserData)({ locale }))
    .then(() => {
      i18n.setLocale(locale, () => {
        window.location.reload();
      });
    });
};

const actions = {
  updateLocale,
};

export default actions;
