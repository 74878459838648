import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as R from 'ramda';

import InputGroup from 'visa-frontend-common/src/components/UI/Form/InputGroup/InputGroup';
import InlineNotification from 'visa-frontend-common/src/components/UI/InlineNotification/InlineNotification';
import TrimInput, { trimInputProps } from 'visa-frontend-common/src/components/UI/Form/Input/TrimInput';
import { eventToField } from 'visa-frontend-common/src/utils/commonUtils';
import { formFieldShape } from 'visa-frontend-common/src/dataModel';

import Form from 'visa-frontend-common/src/components/UI/Form/Form';
import i18n from '../../services/i18n';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import actions from './RegisterActions';
import paths from '../../paths';

import './RegisterConfirmForm.scss';

const emailLabel = i18n.t('register.confirmRegisterForm.email');
const confirmationCodeLabel = i18n.t('register.confirmRegisterForm.confirmationCode');

class RegisterConfirmForm extends React.Component {
  state = { errorMessage: null };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const confirmationCode = params.get('code');

    if (email != null) {
      this.handleInput({
        target: {
          name: 'email',
          value: email,
        },
      });
    }
    if (confirmationCode != null) {
      this.handleInput({
        target: {
          name: 'confirmationCode',
          value: confirmationCode,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.registerConfirmClearFields(['registerConfirmForm']);
  }

  handleInput = event => this.props.registerConfirmSetFieldAndValidate(
    eventToField(event),
    this.props.registerConfirmForm,
  );

  handleSubmit = () => this.props.registerConfirmSubmit(this.props.registerConfirmForm)
    .then(R.ifElse(R.is(Error), (err) => {
      this.setState({ errorMessage: err.message });
    }, () => {
      this.setState({ errorMessage: null });
    }));

  render() {
    const trimProps = trimInputProps(R.__, R.__, null, false, this.handleInput);
    const { email, confirmationCode } = this.props.registerConfirmForm;

    return (
      <div className="register-confirm-form">
        <h2>{i18n.t('register.confirmRegisterForm.title')}</h2>
        <Form>
          <InputGroup
            label={emailLabel}
            required
            validationError={email.validationError}
            className="register-confirm-form__email"
          >
            <TrimInput {...trimProps(email.value, 'email', null)} autoFocus={Boolean(!email.value)} />
          </InputGroup>
          <InputGroup
            label={confirmationCodeLabel}
            required
            validationError={confirmationCode.validationError}
            className="register-confirm-form__code"
          >
            <TrimInput {...trimProps(confirmationCode.value, 'confirmationCode', null)} autoFocus={Boolean(email.value)} />
          </InputGroup>

          <LoadingButton
            onClick={this.handleSubmit}
            title={i18n.t('register.confirmRegisterForm.submit')}
            className="register-confirm-form__submit-button"
            icon="arrow_forward"
            label={i18n.t('register.confirmRegisterForm.submit')}
            dataCy="register-confirm-form-button"
            requestNames={[actions.registerConfirmSent().type]}
          />
          {!!this.state.errorMessage && (
          <InlineNotification
            type="warning"
            className="register-confirm-form__expired-code-notification"
            keyProp={i18n.t(this.state.errorMessage)}
            onClose={() => this.setState({ errorMessage: null })}
          >
            <small>
              {i18n.t(this.state.errorMessage)}
              {' '}
              <Link to={paths.register.codeResend}>{i18n.t('register.codeResendForm.submit')}</Link>
            </small>
          </InlineNotification>
          )}
        </Form>
      </div>
    );
  }
}

export default RegisterConfirmForm;

RegisterConfirmForm.propTypes = {
  registerConfirmForm: PropTypes.exact({
    email: formFieldShape,
    confirmationCode: formFieldShape,
  }).isRequired,
  registerConfirmSetFieldAndValidate: PropTypes.func.isRequired,
  registerConfirmSubmit: PropTypes.func.isRequired,
  registerConfirmClearFields: PropTypes.func.isRequired,
};
