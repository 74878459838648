import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import { createSelector } from 'visa-frontend-common/src/utils/reducerUtils';
import actions from './MainActions';

const initialState = {
  currentUser: null,
  enabledFeatureFlags: [],
  userDataFetchIsDone: false,
  featureFlagsFetchIsDone: false,
};

export const userLoggedInSelector = createSelector([R.path(['main', 'currentUser'])], R.complement(R.isNil));
export const userDataFetchIsDoneSelector = createSelector([R.path(['main', 'userDataFetchIsDone'])], R.identity);
export const featureFlagsFetchIsDoneSelector = createSelector([R.path(['main', 'featureFlagsFetchIsDone'])], R.identity);
export const currentUserNameSelector = createSelector([R.path(['main', 'currentUser', 'username'])], R.identity);
export const currentUserLocaleSelector = createSelector([R.path(['main', 'currentUser', 'locale'])], R.identity);
export const enabledFeatureFlagsSelector = createSelector([R.path(['main', 'enabledFeatureFlags'])], R.identity);

const reducer = handleActions({
  [actions.userFetched]: (state, action) => {
    if (action.error) {
      return {
        ...state,
        userDataFetchIsDone: true,
        currentUser: null,
      };
    }

    return {
      ...state,
      userDataFetchIsDone: true,
      currentUser: action.payload,
    };
  },
  [actions.featureFlagsFetched]: (state, action) => R.compose(
    R.assoc('featureFlagsFetchIsDone', true),
    R.assoc('enabledFeatureFlags', action.payload),
  )(state),
  // TODO: remove when featureflag 401 fixed
  [actions.featureFlagsError]: R.assoc('featureFlagsFetchIsDone', true),
}, initialState);

export default reducer;
