import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import InputGroup from 'visa-frontend-common/src/components/UI/Form/InputGroup/InputGroup';
import { BooleanCheckboxGroup } from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';

import i18n from '../../services/i18n';

import './TermsAndConditions.scss';

class TermsAndConditions extends React.PureComponent {
  state = {
    termsShown: false,
  };

  acceptSelected = (event) => {
    this.props.termsAcceptedSet(event.target.value);
  };

  toggleTerms = () => {
    this.setState(prevState => ({ termsShown: !prevState.termsShown }));
  };

  termsText = () => {
    const paragraphs = i18n.t('register.terms.text').split('\n');

    // eslint-disable-next-line react/no-array-index-key
    return paragraphs.map((paragraph, index) => <p key={`terms${index}`}>{paragraph}</p>);
  };

  render() {
    return (
      <div className="terms-and-conditions">
        <InputGroup>
          <BooleanCheckboxGroup
            label={i18n.t('register.terms.readAndAgree')}
            name="termsAccepted"
            value={this.props.termsAccepted}
            onChange={this.acceptSelected}
            required
          />
        </InputGroup>
        <Button
          ariaControls="terms"
          ariaExpanded={this.state.termsShown}
          onClick={this.toggleTerms}
          class="invisible"
          className="terms-and-conditions__show-button"
          icon={this.state.termsShown ? 'expand_less' : 'expand_more'}
          label={this.state.termsShown ? i18n.t('register.terms.hide') : i18n.t('register.terms.show')}
        />
        <div
          id="terms"
          role="region"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          aria-label={i18n.t('register.terms.title')}
          className={classNames('terms-and-conditions__terms-area', { 'terms-and-conditions__terms-area--show': this.state.termsShown })}
        >
          {this.termsText()}
        </div>
      </div>
    );
  }
}

TermsAndConditions.propTypes = {
  termsAccepted: PropTypes.bool.isRequired,
  termsAcceptedSet: PropTypes.func.isRequired,
};

export default TermsAndConditions;
