import React from 'react';
import PropTypes from 'prop-types';

import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import { formAddressShape, formFieldShape, formFieldShapeFor } from 'visa-frontend-common/src/dataModel';
import { areValuesNotEmptyByKey } from 'visa-frontend-common/src/utils/commonUtils';

import i18n from '../../../../services/i18n';
import LabeledCode from '../../../../components/UI/labeledComponents/LabeledCode';
import PreviewValidationError from './PreviewValidationError';
import paths from '../../../../paths';

const personalDetailsPath = paths.application.tabs.personalDetails;

const secondGuardianKeys = [
  'secondaryGuardianSurname',
  'secondaryGuardianFirstName',
  'secondaryGuardianNationality',
  'secondaryGuardianAddress',
  'secondaryGuardianEmail',
  'secondaryGuardianPhonenumber',
];

const PreviewGuardianAddress = (props) => {
  // Just the first guardian has required (address) fields
  const guardianAddressLabel = (labelText, isFirstGuardian) => {
    return isFirstGuardian ? `${labelText} *` : labelText;
  };

  return (
    <>
      <p>{i18n.t('common:applicationForm.guardians.differentAddressThanApplicants')}</p>

      { props.address.value.street.validationError ?
        <PreviewValidationError
          fieldNameLabel={guardianAddressLabel(i18n.t('common:applicationForm.guardians.guardianStreet'), props.isFirstGuardian)}
          validationError={props.address.value.street.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={guardianAddressLabel(i18n.t('common:applicationForm.guardians.guardianStreet'), props.isFirstGuardian)}
          value={props.address.value.street.value}
        />}

      { props.address.value.zip.validationError ?
        <PreviewValidationError
          fieldNameLabel={i18n.t('common:applicationForm.guardians.guardianZip')}
          validationError={props.address.value.zip.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={i18n.t('common:applicationForm.guardians.guardianZip')}
          value={props.address.value.zip.value}
        />}

      { props.address.value.city.validationError ?
        <PreviewValidationError
          fieldNameLabel={guardianAddressLabel(i18n.t('common:applicationForm.guardians.guardianCity'), props.isFirstGuardian)}
          validationError={props.address.value.city.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={guardianAddressLabel(i18n.t('common:applicationForm.guardians.guardianCity'), props.isFirstGuardian)}
          value={props.address.value.city.value}
        />}

      { props.address.value.country.validationError ?
        <PreviewValidationError
          fieldNameLabel={guardianAddressLabel(i18n.t('common:applicationForm.guardians.guardianCountry'), props.isFirstGuardian)}
          validationError={props.address.value.country.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledCode
          labelText={guardianAddressLabel(i18n.t('common:applicationForm.guardians.guardianCountry'), props.isFirstGuardian)}
          group="CountryOfNationality"
          code={props.address.value.country.value}
        />}
    </>
  );
};

PreviewGuardianAddress.propTypes = {
  address: formFieldShapeFor(formAddressShape),
  isFirstGuardian: PropTypes.bool,
};

PreviewGuardianAddress.defaultProps = {
  address: null,
  isFirstGuardian: false,
};

const PreviewGuardians = (props) => {
  const showSecondaryGuardian = areValuesNotEmptyByKey(secondGuardianKeys, props);

  return (
    <>
      <TabSubtitle type="h4" className="preview-application__subtitle">
        {`${i18n.t('common:applicationForm.guardians.guardianSubtitle')} [10]`}
      </TabSubtitle>

      { props.guardianSurname.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.surname')} *`}
          validationError={props.guardianSurname.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.surname')} *`}
          dataCy="labeled-guardianSurname"
          value={props.guardianSurname.value}
        />}

      { props.guardianFirstName.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.firstName')} *`}
          validationError={props.guardianFirstName.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.firstName')} *`}
          dataCy="labeled-guardianFirstName"
          value={props.guardianFirstName.value}
        />}

      { props.guardianNationality.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.nationality')} *`}
          validationError={props.guardianNationality.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.nationality')} *`}
          dataCy="labeled-guardianNationality"
          group="CountryOfNationality"
          code={props.guardianNationality.value}
        />}

      { props.guardianEmail.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.email')} *`}
          validationError={props.guardianEmail.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.email')} *`}
          dataCy="labeled-guardianEmail"
          value={props.guardianEmail.value}
        />}

      { props.guardianPhonenumber.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.phonenumber')} *`}
          validationError={props.guardianPhonenumber.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.phonenumber')} *`}
          dataCy="labeled-guardianPhonenumber"
          value={props.guardianPhonenumber.value}
        />}

      { props.guardianAddressIsDifferentThanApplicants.value &&
        <PreviewGuardianAddress address={props.guardianAddress} isFirstGuardian />}

      { showSecondaryGuardian &&
        <>
          <TabSubtitle type="h4" className="preview-application__subtitle">
            {`${i18n.t('common:applicationForm.guardians.secondaryGuardianSubtitle')} [10]`}
          </TabSubtitle>

          { props.secondaryGuardianSurname.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.surname')}
              validationError={props.secondaryGuardianSurname.validationError}
              tabPath={personalDetailsPath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.surname')}
              dataCy="labeled-secondaryGuardianSurname"
              value={props.secondaryGuardianSurname.value}
            />}

          { props.secondaryGuardianFirstName.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.firstName')}
              validationError={props.secondaryGuardianFirstName.validationError}
              tabPath={personalDetailsPath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.firstName')}
              dataCy="labeled-secondaryGuardianFirstName"
              value={props.secondaryGuardianFirstName.value}
            />}

          { props.secondaryGuardianNationality.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.nationality')}
              validationError={props.secondaryGuardianNationality.validationError}
              tabPath={personalDetailsPath}
            /> :
            <LabeledCode
              labelText={i18n.t('common:applicationForm.nationality')}
              dataCy="labeled-secondaryGuardianNationality"
              group="CountryOfNationality"
              code={props.secondaryGuardianNationality.value}
            />}

          { props.secondaryGuardianEmail.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.email')}
              validationError={props.secondaryGuardianEmail.validationError}
              tabPath={personalDetailsPath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.email')}
              dataCy="labeled-secondaryGuardianEmail"
              value={props.secondaryGuardianEmail.value}
            />}

          { props.secondaryGuardianPhonenumber.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.phonenumber')}
              validationError={props.secondaryGuardianPhonenumber.validationError}
              tabPath={personalDetailsPath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.phonenumber')}
              dataCy="labeled-secondaryGuardianPhonenumber"
              value={props.secondaryGuardianPhonenumber.value}
            />}

          { props.secondaryGuardianAddressIsDifferentThanApplicants.value &&
            <PreviewGuardianAddress address={props.secondaryGuardianAddress} />}
        </>}
    </>
  );
};

PreviewGuardians.propTypes = {
  guardianSurname: formFieldShape.isRequired,
  guardianFirstName: formFieldShape.isRequired,
  guardianNationality: formFieldShape.isRequired,
  guardianAddress: formFieldShapeFor(formAddressShape).isRequired,
  guardianAddressIsDifferentThanApplicants: formFieldShapeFor(PropTypes.bool).isRequired,
  guardianEmail: formFieldShape.isRequired,
  guardianPhonenumber: formFieldShape.isRequired,

  secondaryGuardianSurname: formFieldShape.isRequired,
  secondaryGuardianFirstName: formFieldShape.isRequired,
  secondaryGuardianNationality: formFieldShape.isRequired,
  secondaryGuardianAddress: formFieldShapeFor(formAddressShape).isRequired,
  secondaryGuardianAddressIsDifferentThanApplicants: formFieldShapeFor(PropTypes.bool).isRequired,
  secondaryGuardianEmail: formFieldShape.isRequired,
  secondaryGuardianPhonenumber: formFieldShape.isRequired,
};

export default PreviewGuardians;
