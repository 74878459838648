import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../UI/Button/Button';
import TwoColumnRow from '../../UI/TwoColumnRow/TwoColumnRow';
import { invitationFormShape, invitationType as types } from './InvitationsDataModel';
import { Input, InputGroup } from '../../UI/Form/FormComponents';
import i18n from '../../../services/i18n';
import { createCountryCodeFilterPredicate } from '../../../utils/dataModelUtils';
import { schengenCountryCodes } from '../../../dataModel';

import './Invitations.scss';

const isCompanyType = invitation =>
  invitation.invitationType &&
  R.includes(invitation.invitationType.value, [types.ORGANIZATION, types.ACCOMMODATION]);
const isPersonType = invitation => !isCompanyType(invitation);

class Invitation extends React.Component {
  state = { isRemoveHovered: false };

  render() {
    const {
      codesetSelectContainer,
      invitation,
      handleChange,
      handleRemove,
      oneColumn,
      showFaxnumber,
      uppercase,
      inputIdPrefix,
    } = this.props;

    const CodesetSelectContainer = codesetSelectContainer;
    const invitationType = invitation.invitationType && invitation.invitationType.value;
    const isRequired = ![types.ACCOMMODATION, types.ORGANIZATION_CONTACT].includes(invitationType);

    const companyNameLabel = i18n.t(`common:applicationForm.invitations.${invitationType}.companyName`);
    const surnameLabel = i18n.t(`common:applicationForm.invitations.${invitationType}.surname`);
    const firstNamesLabel = i18n.t(`common:applicationForm.invitations.${invitationType}.firstNames`);
    const streetLabel = i18n.t(`common:applicationForm.invitations.${invitationType}.street`);
    const zipLabel = i18n.t(`common:applicationForm.invitations.${invitationType}.zip`);
    const cityLabel = i18n.t(`common:applicationForm.invitations.${invitationType}.city`);
    const countryLabel = i18n.t(`common:applicationForm.invitations.${invitationType}.country`);
    const phonenumberLabel = i18n.t(`common:applicationForm.invitations.${invitationType}.phonenumber`);
    const faxnumberLabel = i18n.t(`common:applicationForm.invitations.${invitationType}.fax`);
    const emailLabel = i18n.t(`common:applicationForm.invitations.${invitationType}.email`);

    const removeButtonLabel = i18n.t(`common:applicationForm.invitations.${invitationType}.remove`);

    const fieldNumber = [types.ACCOMMODATION, types.PERSON].includes(invitationType) ? '30' : '31';

    const guideNameField = `contactsAndAccomodationGuide-contactInfoGuideForField${fieldNumber}`;
    const guideAddressField = `contactsAndAccomodationGuide-contactInfoGuideForField${fieldNumber}address`;
    const guideTelephoneField = `contactsAndAccomodationGuide-contactInfoGuideForField${fieldNumber}telephone`;

    return (
      <div className="invitation">
        <div
          className={classNames(
            'container',
            'invitation__container',
            { 'invitation__container--hovered': this.state.isRemoveHovered },
          )}
        >
          <TwoColumnRow oneColumn={oneColumn}>
            {
              isCompanyType(invitation) &&
              <InputGroup
                label={companyNameLabel}
                required
                validationError={invitation.companyName.validationError}
                describedBy={guideNameField}
              >
                <Input
                  name="companyName"
                  value={invitation && invitation.companyName.value}
                  onChange={handleChange}
                  type="text"
                  dataCy={`${inputIdPrefix}-companyName`}
                  uppercase={uppercase}
                  id={`${inputIdPrefix}-companyName`}
                  required
                />
              </InputGroup>
            }

            {
              isPersonType(invitation) &&
              <InputGroup
                label={surnameLabel}
                required
                validationError={invitation.surname.validationError}
                describedBy={guideNameField}
              >
                <Input
                  name="surname"
                  value={invitation && invitation.surname.value}
                  onChange={handleChange}
                  type="text"
                  dataCy={`${inputIdPrefix}-surname`}
                  uppercase={uppercase}
                  id={`${inputIdPrefix}-surname`}
                  required
                />
              </InputGroup>
            }
            {
              isPersonType(invitation) &&
              <InputGroup
                label={firstNamesLabel}
                required
                validationError={invitation.firstNames.validationError}
                describedBy={guideNameField}
              >
                <Input
                  name="firstNames"
                  value={invitation && invitation.firstNames.value}
                  onChange={handleChange}
                  type="text"
                  dataCy={`${inputIdPrefix}-firstNames`}
                  uppercase={uppercase}
                  id={`${inputIdPrefix}-firstNames`}
                  required
                />
              </InputGroup>
            }

            <InputGroup
              label={streetLabel}
              required={isRequired}
              validationError={invitation.address.value.street.validationError}
              describedBy={guideAddressField}
            >
              <Input
                name="address.street"
                value={invitation.address.value && invitation.address.value.street.value}
                onChange={handleChange}
                type="text"
                dataCy={`${inputIdPrefix}-street`}
                uppercase={uppercase}
                id={`${inputIdPrefix}-street`}
                required={isRequired}
              />
            </InputGroup>

            <InputGroup
              label={zipLabel}
              validationError={invitation.address.value.zip.validationError}
              describedBy={guideAddressField}
            >
              <Input
                name="address.zip"
                value={invitation.address.value && invitation.address.value.zip.value}
                onChange={handleChange}
                type="text"
                dataCy={`${inputIdPrefix}-zip`}
                uppercase={uppercase}
                id={`${inputIdPrefix}-zip`}
              />
            </InputGroup>

            <InputGroup
              label={cityLabel}
              required={isRequired}
              validationError={invitation.address.value.city.validationError}
              describedBy={guideAddressField}
            >
              <Input
                name="address.city"
                value={invitation.address.value && invitation.address.value.city.value}
                onChange={handleChange}
                type="text"
                dataCy={`${inputIdPrefix}-city`}
                uppercase={uppercase}
                id={`${inputIdPrefix}-city`}
                required={isRequired}
              />
            </InputGroup>

            <InputGroup
              label={countryLabel}
              required={isRequired}
              validationError={invitation.address.value.country.validationError}
              describedBy={guideAddressField}
            >
              <CodesetSelectContainer
                name="address.country"
                value={invitation.address.value && invitation.address.value.country.value}
                codesetGroup="Country"
                onChange={handleChange}
                dataCy={`${inputIdPrefix}-country`}
                codesetPredicate={createCountryCodeFilterPredicate('codesetCode', schengenCountryCodes)}
                id={`${inputIdPrefix}-country`}
              />
            </InputGroup>

            <InputGroup
              label={emailLabel}
              withHelp={i18n.t('common:miscellaneousFormats.emailFormatDescription')}
              validationError={invitation.email.validationError}
              describedBy={guideAddressField}
            >
              <Input
                name="email"
                value={invitation && invitation.email.value}
                onChange={handleChange}
                type="text"
                dataCy={`${inputIdPrefix}-email`}
                uppercase={uppercase}
                id={`${inputIdPrefix}-email`}
              />
            </InputGroup>
            <InputGroup
              label={phonenumberLabel}
              required={isRequired}
              withHelp={i18n.t('common:miscellaneousFormats.phonenumberFormatDescription')}
              validationError={invitation.phonenumber.validationError}
              describedBy={guideTelephoneField}
            >
              <Input
                name="phonenumber"
                value={invitation && invitation.phonenumber.value}
                onChange={handleChange}
                type="text"
                dataCy={`${inputIdPrefix}-phonenumber`}
                uppercase={uppercase}
                id={`${inputIdPrefix}-phonenumber`}
                required={isRequired}
              />
            </InputGroup>

            {
              showFaxnumber &&
              <InputGroup
                label={faxnumberLabel}
                validationError={invitation.faxnumber.validationError}
              >
                <Input
                  name="faxnumber"
                  value={invitation && invitation.faxnumber.value}
                  onChange={handleChange}
                  type="text"
                  dataCy={`${inputIdPrefix}-faxnumber`}
                  uppercase={uppercase}
                  id={`${inputIdPrefix}-faxnumber`}
                />
              </InputGroup>
            }
          </TwoColumnRow>
        </div>

        { handleRemove &&
          <Button
            type="button"
            primary
            label={removeButtonLabel}
            onClick={handleRemove}
            icon="remove"
            class="invisible"
            className="invitation__remove-button"
            onMouseEnter={() => this.setState({ isRemoveHovered: true })}
            onMouseLeave={() => this.setState({ isRemoveHovered: false })}
          />}
      </div>
    );
  }
}

Invitation.propTypes = {
  invitation: invitationFormShape.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
  showFaxnumber: PropTypes.bool,
  uppercase: PropTypes.bool,
  inputIdPrefix: PropTypes.string.isRequired,
};

Invitation.defaultProps = {
  handleRemove: null,
  oneColumn: false,
  showFaxnumber: true,
  uppercase: false,
};

export default Invitation;
