import * as R from 'ramda';

const mtcaptchaConfig = {
  sitekey: 'MTPublic-LU9Zoru4L', loadAnimation: 'false', render: 'explicit', renderQueue: [],
};

export const mtCaptchaUrls = [
  'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js',
  'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js',
];

const loadCaptchaLibrary = (onError) => {
  window.mtcaptchaConfig = mtcaptchaConfig;
  const mtService = document.createElement('script');
  mtService.src = R.head(mtCaptchaUrls);
  mtService.async = true;
  mtService.onerror = () => {
    const mtService2 = document.createElement('script');
    mtService2.src = R.last(mtCaptchaUrls);
    mtService2.async = true;
    mtService2.onerror = onError;
    document.body.appendChild(mtService2);
  };
  document.body.appendChild(mtService);
};

export default loadCaptchaLibrary;
