import * as R from 'ramda';
import PropTypes from 'prop-types';
import { flattenObject, notNilOrEmpty, pickValuesDeep } from './commonUtils';

export const notEuropolOrSis =
  R.compose(R.not, R.includes(R.__, ['SIS', 'EPO']), R.prop('codesetCode'));

export const notEuropolOrSisOrFinland =
  R.compose(R.not, R.includes(R.__, ['SIS', 'EPO', 'FIN']), R.prop('codesetCode'));

export const fullName = (surname, firstName, patronymicName = null) => {
  return [surname, firstName, patronymicName].join(' ').trim();
};

export const createCountryCodeFilterPredicate = (propName, countryCodes) => (
  country => countryCodes[country[propName]]
);

export const isGuardianNotEmpty = formFields => R.any(
  notNilOrEmpty,
  R.compose(
    R.values,
    flattenObject(R.__, false),
    pickValuesDeep,
    R.pick([
      'guardianFirstName',
      'guardianSurname',
      'guardianNationality',
      'guardianAddress',
      'guardianEmail',
      'guardianPhonenumber',
      'secondaryGuardianFirstName',
      'secondaryGuardianSurname',
      'secondaryGuardianNationality',
      'secondaryGuardianAddress',
      'secondaryGuardianEmail',
      'secondaryGuardianPhonenumber',
    ]),
  )(formFields),
);
/**
 * Custom PropType for a pair type (list of two)
 * @param {*} typeCheckers exactly two typeCheckers, one for each half
 * @example someProperty: tupleOf([PropTypes.string.isRequired, PropTypes.number])
 *
 * Valid values for someProperty would be ['foo', null] and ['foo', 234].
 * Invalid values would be [null, 123], ['foo'] or ["foo", "bar"]
 */
export const tupleOf = typeCheckers => PropTypes.arrayOf((value, index, ...rest) => {
  if (value.length !== 2) {
    return new Error(`Invalid length prop supplied to ${rest[0]}. A tuple should have a length of 2.`);
  }
  return typeCheckers[index].call(PropTypes, value, index, ...rest);
});
