import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import LanguageSelector from 'visa-frontend-common/src/components/LanguageSelector/LanguageSelector';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import {
  BooleanCheckboxGroup,
  InputGroup,
} from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import Accordion from 'visa-frontend-common/src/components/UI/Accordion/Accordion';
import { eventToField } from 'visa-frontend-common/src/utils/commonUtils';
import Input from 'visa-frontend-common/src/components/UI/Form/Input/Input';
import InlineNotification from 'visa-frontend-common/src/components/UI/InlineNotification/InlineNotification';
import Form from 'visa-frontend-common/src/components/UI/Form/Form';

import Loading from '../../components/UI/Loading/LoadingContainer';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import i18n from '../../services/i18n';
import { applicationListItemShape, languageSelectorOptions } from '../../ovafModel';
import { registerFormDefaultProps, registerFormShape } from '../../dataModel';
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle';

import DeleteUserModal from './DeleteUserModal';

import './AccountSettings.scss';

class AccountSettings extends Component {
  state = {
    showSaveNotification: false,
  };

  saveInitialState = () => this.setState({
    initial: this.props.registerForm,
  });

  componentDidMount = () => {
    this.props.loadUserAccountData()
      .then(this.saveInitialState);
  };

  handleInput = event => this.props.registerSetFieldAndValidate(
    eventToField(event),
    this.props.registerForm,
  );

  hasChanges = () => (
    this.state.initial &&
      Object.keys(this.state.initial).find(
        key => this.state.initial[key].value !== this.props.registerForm[key].value,
      )
  );

  haveTravelAgencyDetailsChanged = () => {
    const { initial } = this.state;
    if (!initial) {
      return false;
    }
    const current = this.props.registerForm;
    const isTravelAgency = current.isTravelAgency.value;
    const isTravelAgencyChanged = initial.isTravelAgency.value !== current.isTravelAgency.value;
    const travelAgencyNameChanged = (
      initial.travelAgencyName.value !== current.travelAgencyName.value
    );

    return (
      isTravelAgencyChanged || (isTravelAgency && travelAgencyNameChanged)
    );
  };

  clearChanges = () => {
    this.props.registerSetFieldAndValidate(
      this.state.initial,
      this.props.registerForm,
    );
  };

  save = (form) => {
    this.props.saveUserAccountData(form)
      .then(R.unless(R.is(Error), () => {
        if (this.haveTravelAgencyDetailsChanged()) {
          this.setState({ showSaveNotification: true });
        }
        this.saveInitialState();
      }));
  };

  termsText = () => i18n.t('register.terms.text')
    .split('\n')
    .map((paragraph, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <p key={`terms${index}`} className="account-settings__ts-and-cs-content">
        {paragraph}
      </p>
    ));

  render() {
    const {
      locale,
      updateLocale,
      email,
      registerForm,
      userAccountSaveActionType,
      userAccountLoadActionType,
      newPasswordDisabled,
      confirmPasswordDisabled,
      saveAccountSettingsDisabled,
      newPasswordValidationError,
      confirmPasswordValidationError,
    } = this.props;

    const {
      currentPassword,
      password,
      confirmPassword,
      isTravelAgency,
      travelAgencyName,
    } = registerForm;

    return (
      <div className="account-settings container" role="main">
        <DocumentTitle>{i18n.t('accountSettings.title')}</DocumentTitle>
        <div className="row" id="main" tabIndex="-1">
          <div className="col-12 col-lg-6">
            <h1>{i18n.t('accountSettings.title')}</h1>
            <Form>
              <LanguageSelector
                languageSelectorOptions={languageSelectorOptions}
                locale={locale}
                updateLocale={updateLocale}
                label={i18n.t('frontPage.selectLanguage')}
              />
              <InputGroup
                label={i18n.t('accountSettings.emailLabel')}
              >
                <div>{email}</div>
              </InputGroup>
              <InputGroup
                className="account-settings__current-password"
                label={i18n.t('register.registerForm.currentPassword')}
              >
                <Input
                  type="password"
                  name="currentPassword"
                  value={currentPassword.value}
                  onChange={this.handleInput}
                />
              </InputGroup>
              <InputGroup
                className="account-settings__new-password"
                label={i18n.t('register.registerForm.newPassword')}
                required={!newPasswordDisabled}
                withHelp={`${i18n.t('register.registerForm.passwordRequirements')}`}
                validationError={newPasswordValidationError}
              >
                <Input
                  type="password"
                  name="password"
                  value={password.value}
                  onChange={this.handleInput}
                  disabled={newPasswordDisabled}
                />
              </InputGroup>
              <InputGroup
                className="account-settings__confirm-password"
                label={i18n.t('register.registerForm.confirmPassword')}
                required={!confirmPasswordDisabled}
                validationError={confirmPasswordValidationError}
              >
                <Input
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword.value}
                  onChange={this.handleInput}
                  disabled={confirmPasswordDisabled}
                />
              </InputGroup>
              <Loading
                requestNames={[
                  userAccountLoadActionType,
                ]}
              >
                <InputGroup
                  validationError={isTravelAgency.validationError}
                >
                  <BooleanCheckboxGroup
                    name="isTravelAgency"
                    label={i18n.t('register.registerForm.travelAgency')}
                    value={isTravelAgency.value}
                    onChange={this.handleInput}
                  />
                </InputGroup>
                {!!isTravelAgency.value && (
                  <InputGroup
                    label={i18n.t('register.registerForm.travelAgencyName')}
                    required
                    validationError={travelAgencyName.validationError}
                  >
                    <Input
                      type="text"
                      name="travelAgencyName"
                      className="account-settings__travel-agency-name"
                      value={travelAgencyName.value}
                      uppercase
                      onChange={this.handleInput}
                    />
                  </InputGroup>
                )}
              </Loading>
              <div className="account-settings__save-buttons">
                <LoadingButton
                  className="account-settings__save-button"
                  icon="save"
                  label={i18n.t('application.save')}
                  onClick={() => this.save(registerForm)}
                  requestNames={[userAccountSaveActionType]}
                  disabled={!this.hasChanges() || saveAccountSettingsDisabled}
                />
                <Button
                  className="account-settings__cancel-button"
                  icon="close"
                  label={i18n.t('accountSettings.cancelChanges')}
                  class="secondary"
                  onClick={this.clearChanges}
                  disabled={!this.hasChanges()}
                />
                {this.state.showSaveNotification && (
                  <InlineNotification
                    type="success"
                    className="account-settings__save-notification"
                    keyProp={i18n.t('accountSettings.saveSuccess')}
                    onClose={() => this.setState({ showSaveNotification: false })}
                  >
                    <small>
                      {i18n.t('accountSettings.saveSuccess')}
                      {' '}
                      <a
                        href="/api/logout"
                        className="account-settings__logout-link"
                      >
                        {i18n.t('accountSettings.loginAgainNow')}
                      </a>
                    </small>
                  </InlineNotification>
                )}
              </div>
              <h3 className="account-settings__delete-title">
                {i18n.t('accountSettings.userAccountDelete.title')}
              </h3>
              <DeleteUserModal
                deleteUser={this.props.deleteUserAccount}
                fetchApplications={this.props.fetchApplications}
                sentApplications={this.props.sentApplications}
              />
              <Accordion
                className="account-settings__ts-and-cs"
                expandButtonText={i18n.t('accountSettings.tsAndCs')}
                bodyContent={this.termsText()}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

AccountSettings.propTypes = {
  locale: PropTypes.string,
  updateLocale: PropTypes.func.isRequired,
  registerForm: registerFormShape,
  email: PropTypes.string,
  fetchApplications: PropTypes.func.isRequired,
  deleteUserAccount: PropTypes.func.isRequired,
  loadUserAccountData: PropTypes.func.isRequired,
  saveUserAccountData: PropTypes.func.isRequired,
  registerSetFieldAndValidate: PropTypes.func.isRequired,
  userAccountLoadActionType: PropTypes.string.isRequired,
  userAccountSaveActionType: PropTypes.string.isRequired,
  sentApplications: PropTypes.arrayOf(applicationListItemShape),
  newPasswordDisabled: PropTypes.bool.isRequired,
  confirmPasswordDisabled: PropTypes.bool.isRequired,
  saveAccountSettingsDisabled: PropTypes.bool.isRequired,
  newPasswordValidationError: PropTypes.string,
  confirmPasswordValidationError: PropTypes.string,
};

AccountSettings.defaultProps = {
  locale: '',
  email: 'example@domain.com',
  registerForm: registerFormDefaultProps,
  sentApplications: [],
  newPasswordValidationError: null,
  confirmPasswordValidationError: null,
};

export default AccountSettings;
