import { connect } from 'react-redux';
import ApplicationFinalizedPage from './ApplicationFinalizedPage';
import { finalizedApplicationSelector, idFromPathSelector } from './ApplicationReducer';
import actions from './ApplicationActions';
import printActions from '../FrontPage/FrontPageActions';

const mapStateToProps = state => ({
  finalizedApplication: finalizedApplicationSelector(state),
  pathId: idFromPathSelector(state),
});

const mapDispatchToProps = {
  ...actions,
  printApplication: printActions.printApplication,
  printApplicationInBrowser: printActions.printApplicationInBrowser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationFinalizedPage);
