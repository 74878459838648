import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import InputGroup from 'visa-frontend-common/src/components/UI/Form/InputGroup/InputGroup';
import Input, { inputProps } from 'visa-frontend-common/src/components/UI/Form/Input/Input';
import { eventToField } from 'visa-frontend-common/src/utils/commonUtils';
import { formFieldShape, historyShape } from 'visa-frontend-common/src/dataModel';
import Form from 'visa-frontend-common/src/components/UI/Form/Form';

import i18n from '../../services/i18n';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import actions from './ForgotPasswordActions';

import './ForgotPasswordForm.scss';

const emailLabel = i18n.t('forgotPassword.email');

class ForgotPasswordForm extends React.Component {
  componentWillUnmount() {
    this.props.forgotPasswordClearFields(['forgotPasswordForm']);
  }

  handleInput = event => this.props.forgotPasswordSetFieldAndValidate(
    eventToField(event),
    this.props.forgotPasswordForm,
  );

  handleSubmit = () => this.props.forgotPasswordSubmit(this.props.forgotPasswordForm);

  render() {
    const textInputProps = inputProps('text', R.__, R.__, null, false, this.handleInput);

    return (
      <div className="forgot-password-form">
        <h2>{i18n.t('forgotPassword.title')}</h2>

        <p className="forgot-password-form__info-text">
          <small>{i18n.t('forgotPassword.infoText')}</small>
        </p>
        <Form>
          <InputGroup
            label={emailLabel}
            required
            validationError={this.props.forgotPasswordForm.email.validationError}
            className="forgot-password-form__email"
          >
            <Input {...textInputProps(this.props.forgotPasswordForm.email.value, 'email')} autoFocus />
          </InputGroup>

          <LoadingButton
            onClick={this.handleSubmit}
            title={i18n.t('forgotPassword.submit')}
            className="forgot-password-form__submit-button"
            icon="arrow_forward"
            label={i18n.t('forgotPassword.submit')}
            dataCy="forgot-password-form-button"
            requestNames={[actions.forgotPasswordSent().type]}
            type="submit"
          />
        </Form>
      </div>
    );
  }
}

ForgotPasswordForm.propTypes = {
  history: historyShape.isRequired,
  forgotPasswordForm: PropTypes.exact({
    email: formFieldShape,
  }).isRequired,
  forgotPasswordSetFieldAndValidate: PropTypes.func.isRequired,
  forgotPasswordSubmit: PropTypes.func.isRequired,
  forgotPasswordClearFields: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
