import { createAction } from 'redux-actions';
import * as R from 'ramda';
import i18n from '../i18n';

const defaultPromptMessage = i18n.t('common:leavePrompt');

const addDefaultProps = payload => R.evolve({
  excludedPaths: R.defaultTo([]),
  promptTitle: R.defaultTo(defaultPromptMessage),
  promptListLabel: R.defaultTo(payload.promptTitle || defaultPromptMessage),
}, payload);

const setDirty = createAction('FORM_DIRTY_SET', R.map(addDefaultProps));

export default setDirty;
