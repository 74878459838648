import React from 'react';
import { createRoot } from 'react-dom/client';

import ConfirmationModal from '../components/UI/ConfirmationModal/ConfirmationModal';
import i18n from './i18n';

const confirmTypes = {
  ACCEPTANCE: 'ACCEPTANCE',
  REJECTION: 'REJECTION',
  RETURN: 'RETURN',
  DELETION: 'DELETION',
};

const confirmButtonPropsByType = {
  [confirmTypes.ACCEPTANCE]: {
    label: i18n.t('common:true'),
    icon: 'check',
    color: 'green',
  },
  [confirmTypes.REJECTION]: {
    label: i18n.t('common:true'),
    icon: 'block',
    color: 'red',
  },
  [confirmTypes.RETURN]: {
    label: i18n.t('common:true'),
    icon: 'undo',
    color: 'red',
  },
  [confirmTypes.DELETION]: {
    label: i18n.t('common:true'),
    icon: 'delete_forever',
    color: 'red',
  },
};

const confirmRoot = document.createElement('div');
const body = document.querySelector('body');

const confirm = type => (message, onConfirm, modalChildren) => {
  const root = createRoot(confirmRoot);
  const renderConfirmModal = () => new Promise((resolve, reject) => {
    const ConfirmModal = React.createElement(
      ConfirmationModal,
      {
        onConfirm: resolve,
        onCancel: () => reject(Error('Cancelled')),
        message,
        confirmButtonProps: confirmButtonPropsByType[type],
      },
      modalChildren,
    );
    body.appendChild(confirmRoot);
    root.render(ConfirmModal);
  });

  const removeConfirmModal = () => {
    root.unmount();
    body.removeChild(confirmRoot);
  };

  const handleConfirm = () => {
    removeConfirmModal();
    return onConfirm();
  };

  const handleCancel = (error) => {
    removeConfirmModal();
    return error;
  };

  return renderConfirmModal()
    .then(handleConfirm)
    .catch(handleCancel);
};

const confirmAcceptance = confirm(confirmTypes.ACCEPTANCE);
const confirmRejection = confirm(confirmTypes.REJECTION);
const confirmReturn = confirm(confirmTypes.RETURN);
const confirmDeletion = confirm(confirmTypes.DELETION);

export {
  confirm,
  confirmAcceptance,
  confirmRejection,
  confirmReturn,
  confirmDeletion,
};
