import * as R from 'ramda';
import React from 'react';
import { generatePath } from 'react-router-dom';

import { historyShape } from 'visa-frontend-common/src/dataModel';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';

import PropTypes from 'prop-types';
import paths from '../../paths';
import i18n from '../../services/i18n';
import { answerText, isClosedOutsideOvaf } from './pendingUtils';
import { pendingRequestShape } from '../../ovafModel';

const PendingClosedView = ({
  history, pendingRequest, answerStatus, documentCount,
}) => {
  const redirectToRequests = () => {
    history.push(generatePath(paths.pending.root));
  };

  const statusText = R.ifElse(
    isClosedOutsideOvaf,
    R.always(i18n.t('pending.status.readyInVisa')),
    R.always(i18n.t('pending.status.EXPIRED')),
  );

  return (
    <div className="pending-answer">
      <h2>{statusText(pendingRequest)}</h2>
      {answerStatus &&
        <div className="pending-answer__instructions">
          <h3>{i18n.t('pending.closed.answerTitle')}</h3>
          <p>{answerText(pendingRequest.reason, answerStatus, { count: documentCount })}</p>
        </div>}
      <div className="pending-answer__navigation">
        <Button
          class="secondary"
          label={i18n.t('pending.navigateToRequests')}
          onClick={redirectToRequests}
        />
      </div>
    </div>
  );
};

PendingClosedView.propTypes = {
  history: historyShape.isRequired,
  pendingRequest: pendingRequestShape.isRequired,
  answerStatus: PropTypes.string,
  documentCount: PropTypes.number,
};

PendingClosedView.defaultProps = {
  answerStatus: '',
  documentCount: 0,
};

export default PendingClosedView;
