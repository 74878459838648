import React from 'react';

import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';

import { locationShape } from '../../../dataModel';
import LoadingNotification from '../LoadingNotification/LoadingNotification';
import i18n from '../../../services/i18n';

import './Loading.scss';

const DEFAULT_LOADING_HEIGHT = 'auto';
const MIN_LOADING_HEIGHT = 60; // Height of loading indicator

class Loading extends React.Component {
  state = { loadingHeight: null };

  loadingContainerRef = React.createRef();

  componentDidMount() {
    this.setLoadingHeight();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.clearLoading();
    }
    if (prevProps.requestLoading !== this.props.requestLoading) {
      this.setLoadingHeight();
    }
  }

  setLoadingHeight = () => {
    const childrenHeight = R.compose(
      R.unless(R.isNil, current => current.getBoundingClientRect().height),
      R.prop('current'),
    )(this.loadingContainerRef);

    const loadingHeight = R.cond([
      [R.isNil, R.always(DEFAULT_LOADING_HEIGHT)],
      [R.lt(this.props.maxLoadingHeight), R.always(`${this.props.maxLoadingHeight}px`)],
      [R.lt(MIN_LOADING_HEIGHT), height => `${height}px`],
      [R.T, R.always(DEFAULT_LOADING_HEIGHT)],
    ])(childrenHeight);

    this.setState({ loadingHeight });
  };

  renderLoadingNotification() {
    return (
      <div
        data-cy="loading-info-container"
        style={{ height: this.state.loadingHeight }}
      >
        <LoadingNotification loadingText={this.props.loadingText} />
      </div>
    );
  }

  render() {
    if (!this.props.requestLoading && !this.props.children) return null;

    return (
      <div
        ref={this.loadingContainerRef}
        className={classnames(
          'loading',
          {
            'loading--request': this.props.requestLoading,
            'loading--completed': !this.props.requestLoading,
          },
          this.props.className,
        )}
      >
        {
          this.props.requestLoading ?
            this.renderLoadingNotification() :
            this.props.children
        }
      </div>
    );
  }
}

Loading.propTypes = {
  clearLoading: PropTypes.func.isRequired,
  location: locationShape.isRequired,
  requestLoading: PropTypes.bool.isRequired,
  maxLoadingHeight: PropTypes.number,
  loadingText: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

Loading.defaultProps = {
  loadingText: i18n.t('common:loading'),
  className: null,
  children: null,
  maxLoadingHeight: 500,
};

export default Loading;
