import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

// import index.scss before any component
// eslint-disable-next-line import/order
import './index.scss';

import App from './App';
import { history, store } from './setup';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}><App /></ConnectedRouter>
  </Provider>,
);
