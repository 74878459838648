import { matchPath } from 'react-router-dom';
import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import {
  formArrayFieldDefaultProps,
  formFieldDefaultProps,
  formFieldDefaultValue,
} from 'visa-frontend-common/src/dataModel';
import { notNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';
import {
  createSelector,
  fetchReducerFor,
  fieldSetReducerFor,
  resetReducerFor,
  routerPathSelector,
} from 'visa-frontend-common/src/utils/reducerUtils';
import { isPastDate } from 'visa-frontend-common/src/services/datetime';

import actions from './PendingActions';
import { pendingAnswerType, pendingRequestStatuses } from '../../ovafModel';
import paths from '../../paths';
import { isClosedOutsideOvaf } from './pendingUtils';

const initialState = {
  pendingRequests: [],
  documentStatistics: [],
  checklistItemTypes: [],
  requestInView: null,
  answerForm: {
    answer: formFieldDefaultProps,
  },
  uploadForm: {
    documentFiles: formArrayFieldDefaultProps,
    uploadConfirmed: formFieldDefaultValue(false),
  },
  pendingStatusHistory: {
    pendingRequestId: null,
    statusHistory: [],
  },
};

const parsePath = path => matchPath(path, {
  path: [paths.pending.view],
  exact: true,
});

export const pendingIdFromPathSelector = createSelector(
  [routerPathSelector],
  R.compose(
    R.unless(
      R.isNil,
      R.path(['params', 'pendingId']),
    ),
    parsePath,
  ),
);

const pendingRequestsSelector = createSelector(
  [R.path(['pending', 'pendingRequests'])],
  R.identity,
);

export const pendingRequestSelector = createSelector(
  [R.path(['pending', 'requestInView'])],
  R.identity,
);

export const answerFormSelector = createSelector(
  [R.path(['pending', 'answerForm'])],
  R.identity,
);

export const answerStatusSelector = createSelector(
  [R.path(['pending', 'pendingStatusHistory'])],
  R.compose(
    R.last,
    R.filter(R.includes(R.__, R.keys(pendingAnswerType))),
    R.prop('statusHistory'),
  ),
);

export const uploadFormSelector = R.path(['pending', 'uploadForm']);

export const openPendingRequestsSelector = createSelector(
  [pendingRequestsSelector],
  R.compose(
    R.filter(R.propEq('status', pendingRequestStatuses.OPEN)),
    R.reject(R.compose(isPastDate, R.prop('dueDate'))),
  ),
);

export const answeredPendingRequestsSelector = createSelector(
  [pendingRequestsSelector],
  R.compose(
    R.reject(R.propEq('status', pendingRequestStatuses.OPEN)),
    R.reject(isClosedOutsideOvaf),
  ),
);

export const closedPendingRequestsSelector = createSelector(
  [pendingRequestsSelector],
  R.filter(
    R.either(
      isClosedOutsideOvaf,
      R.both(
        R.propEq('status', pendingRequestStatuses.OPEN),
        R.compose(isPastDate, R.prop('dueDate')),
      ),
    ),
  ),
);

export const openPendingRequestAmountSelector = createSelector(
  [openPendingRequestsSelector],
  R.length,
);

export const hasPendingRequestsSelector = createSelector(
  [pendingRequestsSelector],
  notNilOrEmpty,
);

export const documentStatisticsSelector = createSelector(
  [R.path(['pending', 'documentStatistics'])],
  R.identity,
);

export const checklistItemTypesSelector = createSelector(
  [R.path(['pending', 'checklistItemTypes'])],
  R.identity,
);

const fetchReducer = fetchReducerFor(initialState);
const fieldSetReducer = fieldSetReducerFor(initialState);

const reducer = handleActions({
  [actions.pendingRequestHistoryFetched]: fetchReducer('pendingStatusHistory'),
  [actions.pendingRequestsFetched]: fetchReducer('pendingRequests'),
  [actions.pendingRequestFetched]: fetchReducer('requestInView'),
  [actions.documentStatisticsFetched]: fetchReducer('documentStatistics'),
  [actions.documentCheckCompleted]:
    (state, action) => (action.error ? state : R.over(
      R.lensPath(['uploadForm', 'documentFiles', 'value']),
      R.map(R.when(
        R.pathEq(['key', 'value'], action.payload.key),
        R.compose(
          R.assocPath(['status', 'value'], action.payload.status),
          R.assocPath(['pages', 'value'], action.payload.pages),
        ),
      )),
    )(state)),
  [actions.answerFormFieldSet]: fieldSetReducer('answerForm'),
  [actions.uploadFormFieldSet]: fieldSetReducer('uploadForm'),
  [actions.documentUploadSet]: (state, action) => (action.error ? state : R.over(
    R.lensPath(['uploadForm', 'documentFiles', 'value']),
    R.converge(
      (index, items) => R.update(
        index,
        R.compose(
          R.assocPath(['status', 'value'], action.payload.status),
          R.assocPath(['key', 'value'], action.payload.key),
        )(items[index]),
        items,
      ),
      [R.findIndex(R.pathEq(['uid', 'value'], action.payload.uid)), R.identity],
    ),
    state,
  )),
  [actions.clearFields]: resetReducerFor(initialState),
  [actions.checklistItemTypesFetched]: fetchReducer('checklistItemTypes'),
}, initialState);

export default reducer;
