import React, { memo } from 'react';

import GuideSubtitle from '../../../components/Guide/GuideSubtitle';
import GuideItem from '../../../components/Guide/GuideItem';
import i18n from '../../../services/i18n';

const TravelDocumentsGuide = memo(() => {
  return (
    <>
      <GuideSubtitle id="guide-travelDocumentsSubtitle" type="h3">{i18n.t('common:applicationForm.travelDocumentsSubtitle')}</GuideSubtitle>

      <GuideItem id="travelDocumentsGuide-travelDocumentType" subTitle={`12. ${i18n.t('common:applicationForm.travelDocumentType')}`}>
        {i18n.t('application.guides.travelDocumentsGuide.travelDocumentType')}
      </GuideItem>
      <GuideItem id="travelDocumentsGuide-travelDocumentNumber" subTitle={`13. ${i18n.t('common:applicationForm.travelDocumentNumber')}`}>
        {i18n.t('application.guides.travelDocumentsGuide.travelDocumentNumber')}
      </GuideItem>
      <GuideItem id="travelDocumentsGuide-travelDocumentIssueDate" subTitle={`14. ${i18n.t('common:applicationForm.travelDocumentIssueDate')}`}>
        {i18n.t('application.guides.travelDocumentsGuide.travelDocumentIssueDate')}
      </GuideItem>
      <GuideItem id="travelDocumentsGuide-travelDocumentValidUntil" subTitle={`15. ${i18n.t('common:applicationForm.travelDocumentValidUntil')}`}>
        {i18n.t('application.guides.travelDocumentsGuide.travelDocumentValidUntil')}
      </GuideItem>
      <GuideItem id="travelDocumentsGuide-travelDocumentCountryIssued" subTitle={`16. ${i18n.t('common:applicationForm.travelDocumentCountryIssued')}`}>
        {i18n.t('application.guides.travelDocumentsGuide.travelDocumentCountryIssued')}
      </GuideItem>
    </>
  );
});

export default TravelDocumentsGuide;
