import { connect } from 'react-redux';

import { featureFlagsFetchIsDoneSelector } from './MainReducer';
import actions from './MainActions';
import pendingActions from '../Pending/PendingActions';
import Main from './Main';

const mapStateToProps = state => ({
  featureFlagsFetchIsDone: featureFlagsFetchIsDoneSelector(state),
});

const mapDispatchToProps = {
  ...actions,
  fetchPendingRequests: pendingActions.fetchPendingRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
