import React, { memo } from 'react';

import GuideSubtitle from '../../../components/Guide/GuideSubtitle';
import i18n from '../../../services/i18n';

const EuFamilyRelationshipGuideWithExtras = memo(() => {
  return (
    <>
      <GuideSubtitle>{`17. ${i18n.t('common:applicationForm.invitations.euCitizen.subtitle')}`}</GuideSubtitle>
      <div id="personalDetailsGuide-contactInfoGuideForField17" lang={i18n.language()}>
        <p>
          <small>{i18n.t('application.guides.personalDetailsGuide.contactInfoGuideForField17')}</small>
        </p>

        <ul>
          <li><small>{i18n.t('guideExtra.euFamilyMember.surname')}</small></li>
          <li><small>{i18n.t('guideExtra.euFamilyMember.firstNames')}</small></li>
          <li><small>{i18n.t('guideExtra.euFamilyMember.dateOfBirth')}</small></li>
          <li><small>{i18n.t('guideExtra.euFamilyMember.nationality')}</small></li>
          <li><small>{i18n.t('guideExtra.euFamilyMember.travelDocument')}</small></li>
        </ul>
      </div>
    </>
  );
});

export default EuFamilyRelationshipGuideWithExtras;
