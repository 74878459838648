import * as R from 'ramda';

import {
  pickFirstKey,
  isObjectArray,
  pickValuesDeep,
  isNestedForm,
  isNilOrEmpty,
  notNilOrEmpty,
} from '../../utils/commonUtils';

const validationFields = validationError => ({
  isValid: R.isNil(validationError),
  validationError,
});

// Validates the fieldValue based on the fieldValidators
// Return the first possible validation error (if invalid) or null (if valid)
const validate = R.curry((fieldValidators, form, fieldValue, path) => {
  if (!fieldValidators) return null;

  const formValues = pickValuesDeep(form);
  const pathValues = R.reject(R.equals('value'), path);
  return fieldValidators
    .map(validator => validator(fieldValue, formValues, pathValues))
    .find(result => result) || null;
});

const validateInnerForm = (field, form, validationRules, path) => {
  const name = pickFirstKey(field);
  const baseValidation = validationFields(validate(
    validationRules.validators,
    form,
    field[name].value,
    R.concat(R.defaultTo([], path), [name, 'value']),
  ));

  const mergeInnerForm = value => ({ [name]: R.mergeDeepRight(baseValidation, { value }) });
  /* eslint-disable no-use-before-define */
  if (R.is(Array, field[name].value)) {
    const validatedArray = validateArray(field[name].value, form, validationRules.rules, R.concat(R.defaultTo([], path), [name, 'value']));
    return mergeInnerForm(validatedArray);
  }
  const validatedFormPart = validateFormPart(field[name].value, form, validationRules.rules, R.concat(R.defaultTo([], path), [name, 'value']));
  return mergeInnerForm(R.when(isNilOrEmpty, R.always(null))(validatedFormPart));
  /* eslint-enable no-use-before-define */
};

// Validate single field in form
const validateField = (field, form, validationRules, path) => {
  const name = pickFirstKey(field);

  const hasInnerRules = R.both(R.is(Object), R.has('rules'));
  if (hasInnerRules(validationRules)) {
    return R.mergeDeepRight(field, validateInnerForm(field, form, validationRules, path));
  }
  const validationErrors = validate(
    validationRules,
    form,
    field[name].value,
    R.concat(R.defaultTo([], path), [name, 'value']),
  );
  return R.mergeDeepRight(field, { [name]: validationFields(validationErrors) });
};

// Validate pair (an array of key, value: ['fieldName', fieldProperties])
const validatePair = (form, validationRules) => (pair, path) => [
  R.head(pair),
  R.prop(
    R.head(pair),
    validateField(
      R.fromPairs([pair]),
      form,
      validationRules[R.head(pair)],
      path,
    ),
  ),
];

// Validate some fields in form
const validateFormPart = (part, form, validationRules, path) => {
  return R.compose(
    R.fromPairs,
    R.map(pair => validatePair(form, validationRules)(pair, path)),
    R.toPairs,
  )(part);
};

// Validate array of objects
const validateArray = (fields, form, validationRules, path) => {
  return R.addIndex(R.map)((part, index) =>
    validateFormPart(part, form, validationRules, R.append(index, path)), fields);
};

// Validate all fields in form
const validateForm = R.curry((form, validationRules) => {
  return R.compose(
    R.fromPairs,
    R.map(validatePair(form, validationRules)),
    R.toPairs,
  )(form);
});

const filterInvalidValues = R.filter(R.cond([
  [({ value }) => isObjectArray(value),
    field => R.not(field.isValid &&
      R.isEmpty(R.reject(R.compose(R.isEmpty, filterInvalidValues), field.value)))],
  [({ value }) => isNestedForm(value),
    field => R.not(field.isValid && R.isEmpty(filterInvalidValues(field.value)))],
  [R.T, field => R.not(field.isValid)],
]));

const filterValidationErrors = R.filter(R.cond([
  [({ value }) => isObjectArray(value),
    field => R.not(!field.validationError &&
      R.isEmpty(R.reject(R.compose(R.isEmpty, filterValidationErrors), field.value)))],
  [({ value }) => isNestedForm(value),
    field => R.not(!field.validationError && R.isEmpty(filterValidationErrors(field.value)))],
  [R.T, field => notNilOrEmpty(field.validationError)],
]));

const isFormValid = R.compose(
  R.isEmpty,
  filterInvalidValues,
);

const formErrors = R.compose(
  R.toPairs,
  filterValidationErrors,
);

const validateTab = R.curry((validatedForm, tabFields) => {
  const part = R.pick(tabFields, validatedForm);
  return { isTouched: true, isValid: isFormValid(part) };
});

const validateTabs = R.curry((validatedForm, fieldsPerTab) =>
  R.map(validateTab(validatedForm), fieldsPerTab));

export default {
  pickFirstKey,
  formErrors,
  validate,
  validateField,
  validatePair,
  validateForm,
  validateFormPart,
  validateTab,
  validateTabs,
  isFormValid,
};
