import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import InlineNotificationMessage from './InlineNotificationMessage';
import { isNilOrEmpty } from '../../../utils/commonUtils';

// NOTE: New inline notification message must be generated (and be visible)
// every time when either type or keyProp changes, when compared to previous values.
// That's the reason why the key-attribute below is based on them.
const InlineNotification = (props) => {
  if (!props.children) {
    return null;
  }

  return (
    <InlineNotificationMessage
      key={`inline-notification-key-${props.type}-${props.keyProp}`}
      type={props.type}
      dataCy={props.dataCy}
      className={props.className}
      disableClose={props.disableClose}
      onClose={props.onClose}
    >
      {props.children}
    </InlineNotificationMessage>
  );
};

const eitherNilOrNotString = R.either(isNilOrEmpty, R.complement(R.is(String)));

InlineNotification.propTypes = {
  keyProp: (props, propName, componentName) => {
    if (eitherNilOrNotString(props[propName]) && R.equals(props.disableClose, false)) {
      const errorMessage =
        `Invalid prop '${propName}' supplied to '${componentName}'. ` +
        'Expected string when prop \'disableClose\' is false. ' +
        `'${propName}' should change when ever children content changes.`;
      return new Error(errorMessage);
    }
    return null;
  },
  type: PropTypes.oneOf(['success', 'info', 'warning', 'wait', 'system', 'message', 'expiration']).isRequired,
  children: PropTypes.node,
  dataCy: PropTypes.string,
  className: PropTypes.string,
  disableClose: PropTypes.bool,
  onClose: PropTypes.func,
};

InlineNotification.defaultProps = {
  keyProp: null,
  children: null,
  dataCy: null,
  className: null,
  disableClose: false,
  onClose: null,
};

export default InlineNotification;
