import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  generatePath,
} from 'react-router-dom';

import MFALogo from 'visa-frontend-common/src/static/images/um-logo-gray-fi-sv-en.svg';
import Image from 'visa-frontend-common/src/components/UI/Image/Image';
import CoFundedByEULogo from '../Logo/CoFundedByEULogo';
import i18n from '../../services/i18n';
import paths from '../../paths';

import './Footer.scss';

const FooterLink = ({ href, label }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {label}
  </a>
);

FooterLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const Footer = memo(() => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 footer__column--logo">
              <Image
                url={MFALogo}
                alt={i18n.t('loginLogoAltText')}
              />
            </div>
            <div className="col-lg-4 footer__column--logo">
              <CoFundedByEULogo />
            </div>
            <div className="col-lg-4 footer__column">
              <Link
                to={generatePath(paths.checklist)}
                data-cy="checklist-link"
                target="_blank"
              >
                {i18n.t('checklist.requiredVisaApplicationDocuments')}
              </Link>
              <FooterLink
                href={i18n.t('footer.userSupport.link')}
                label={i18n.t('footer.userSupport.label')}
              />
              <FooterLink
                href={i18n.t('footer.ministry.link')}
                label={i18n.t('footer.ministry.label')}
              />
              <Link
                to={paths.guidePage.root}
                data-cy="guide-link"
                target="_blank"
              >
                {i18n.t('guide.mainTitle')}
              </Link>
              <FooterLink
                href={i18n.t('footer.dataProtection.link')}
                label={i18n.t('footer.dataProtection.label')}
              />
              <Link
                to={paths.accessibility.root}
                data-cy="accessibility-link"
                target="_blank"
              >
                {i18n.t('footer.accessibilityStatement.label')}
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
});

export default Footer;
