import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './ValidationError.scss';

const ValidationError = ({ validationError, className }) => (
  <div
    className={classnames('validation-error', {
      'validation-error--hidden': !validationError,
      [className]: !!className,
    })}
  >
    <div className="validation-error__text">{ validationError }</div>
  </div>
);

ValidationError.propTypes = {
  validationError: PropTypes.string,
  className: PropTypes.string,
};

ValidationError.defaultProps = {
  className: null,
  validationError: null,
};

export default ValidationError;
