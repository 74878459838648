import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const codesetCodeShape = PropTypes.exact({
  codesetId: PropTypes.number,
  codesetGroup: PropTypes.string.isRequired,
  codesetCode: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  visCode: PropTypes.string,
  visCodeVersion: PropTypes.string,
  orderNumber: PropTypes.number,
  validFromInclusive: PropTypes.string,
  validToInclusive: PropTypes.string,
});
