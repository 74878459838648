import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';

import actions from './FrontPageActions';
import applicationActions from '../Application/ApplicationActions';
import FrontPage from './FrontPage';
import {
  draftApplicationGroupsSelector,
  draftApplicationsSelector,
  inProcessingApplicationsSelector,
  inProcessingApplicationGroupsSelector,
  sentApplicationGroupsSelector,
  sentApplicationsSelector,
  showFrontPageGuideSelector,
  applicationGroupFormSelector,
  applicationCopyFormSelector,
} from './FrontPageReducer';
import {
  currentUserLocaleSelector,
  userLoggedInSelector,
  userDataFetchIsDoneSelector,
} from '../Main/MainReducer';
import { openPendingRequestAmountSelector } from '../Pending/PendingReducer';

const mapStateToProps = state => ({
  draftApplications: draftApplicationsSelector(state),
  sentApplications: sentApplicationsSelector(state),
  inProcessingApplications: inProcessingApplicationsSelector(state),
  draftApplicationGroups: draftApplicationGroupsSelector(state),
  sentApplicationGroups: sentApplicationGroupsSelector(state),
  inProcessingApplicationGroups: inProcessingApplicationGroupsSelector(state),
  applicationGroupForm: applicationGroupFormSelector(state),
  applicationCopyForm: applicationCopyFormSelector(state),
  locale: currentUserLocaleSelector(state),
  userLoggedIn: userLoggedInSelector(state),
  userDataFetchIsDone: userDataFetchIsDoneSelector(state),
  showFrontPageGuide: showFrontPageGuideSelector(state),
  pendingAmount: openPendingRequestAmountSelector(state),
});

const mapDispatchToProps = {
  ...actions,
  applicationFormFieldSet: applicationActions.formFieldSet,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(FrontPage);
