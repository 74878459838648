import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';
import Select, { components, createFilter } from 'react-select';

import Icon from '../../Icon/Icon';
import i18n from '../../../../services/i18n';

import './Selector.scss';

export { components };

export const stringsToOptions = R.compose(
  R.prepend({ value: '', label: i18n.t('common:select') }),
  R.map(value => ({
    value,
    label: value,
  })),
);

export const runPredicateOnEachWord = predicate => (candidate, input) => {
  const { data, label, value } = candidate;
  return (
    // eslint-disable-next-line no-underscore-dangle
    data.__isNew__ || predicate(candidate, input) ||
      `${R.isNil(label) ? '' : label}`
        .split(/\s+/)
        .some(word => predicate({
          data: {
            ...data,
            label: word,
          },
          label: word,
          value,
        }, input))
  );
};

export const enumTypesToSelectorOptionsWithoutSelectText = R.map(({ type, text }) => ({
  value: type,
  label: text,
}));

export const enumTypesToSelectorOptions = R.compose(
  R.prepend({ value: '', label: i18n.t('common:select') }),
  enumTypesToSelectorOptionsWithoutSelectText,
);

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon
          className="selector__dropdown-icon"
          name={props.selectProps.menuIsOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
        />
      </components.DropdownIndicator>
    )
  );
};

const NoOptionsMessage = (props) => {
  return (
    components.NoOptionsMessage && (
      <components.NoOptionsMessage {...props}>
        <span>{ i18n.t('common:noResults') }</span>
      </components.NoOptionsMessage>
    )
  );
};

DropdownIndicator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object.isRequired, // https://react-select.com/props#select-props
};

const Selector = (props) => {
  const handleChange = (selectedOption) => {
    // Store empty string as null for backend storage
    const value = selectedOption.value || null;

    props.onChange({
      target: {
        name: props.name,
        value,
      },
    });
  };

  const valueSuitableForReactSelect = props.value ? {
    value: props.value,
    label: R.compose(
      R.prop('label'),
      R.find(option => option.value === props.value),
    )(props.options),
  } : '';

  const formatOptionLabel = (option) => {
    if (props.requestedOptionValue === option.value) {
      return R.join(' ', [option.label, props.requestedOptionLabelPostfix]);
    }
    return option.label;
  };

  return (
    <div lang={props.lang}>
      <Select
        className={classnames(
          'selector',
          props.className,
          {
            'selector--invalid': props.invalid,
            'selector--in-table-row': props.inTableRow,
          },
        )}
        classNamePrefix="selector"
        unstyled
        placeholder={props.placeholder || i18n.t('common:select')}
        components={{ DropdownIndicator, NoOptionsMessage }}
        aria-label={props.ariaLabel}
        aria-describedby={props.describedBy} // Does not work yet: https://github.com/JedWatson/react-select/issues/1570
        aria-invalid={props.invalid}
        inputId={props.id}
        name={props.name}
        isSearchable={!props.disableSearch}
        value={valueSuitableForReactSelect}
        onChange={handleChange}
        isDisabled={props.disabled}
        options={props.options}
        autoFocus={props.autoFocus}
        formatOptionLabel={R.defaultTo(formatOptionLabel, props.formatOptionLabel)}
        filterOption={runPredicateOnEachWord(createFilter({
          matchFrom: 'start',
          ignoreCase: true,
          ignoreAccents: true,
          trim: true,
        }))}
        tabIndex={props.tabIndex}
        required={props.required}
        // Allows menu to overflow scrollable table by portaling
        menuPortalTarget={props.inTableRow ? document.body : null}
      />
    </div>
  );
};

export const selectProps = R.curry((
  value,
  name,
  options,
  disabled,
  onChange,
) => {
  return {
    value,
    name,
    options,
    disabled,
    onChange,
  };
});

export const selectorOptionShape = PropTypes.exact({
  keyProp: PropTypes.string, // Not used, for consistency with other input components
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

export const selectorPropTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  describedBy: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disableSearch: PropTypes.bool,
  options: PropTypes.arrayOf(selectorOptionShape).isRequired,
  autoFocus: PropTypes.bool,
  invalid: PropTypes.bool,
  requestedOptionValue: PropTypes.string,
  requestedOptionLabelPostfix: PropTypes.string,
  id: PropTypes.string,
  inTableRow: PropTypes.bool,
  lang: PropTypes.string,
  required: PropTypes.bool,
  // For formatting Options and selected value
  formatOptionLabel: PropTypes.func,
  tabIndex: PropTypes.number,
};

Selector.propTypes = selectorPropTypes;

export const selectorDefaultProps = {
  value: null,
  placeholder: null,
  describedBy: null,
  ariaLabel: null,
  className: null,
  onChange: null,
  disabled: false,
  disableSearch: false,
  autoFocus: false,
  invalid: false,
  requestedOptionValue: null,
  requestedOptionLabelPostfix: i18n.t('common:requested'),
  id: null,
  inTableRow: false,
  lang: null,
  required: false,
  formatOptionLabel: null,
  tabIndex: 0,
};

Selector.defaultProps = selectorDefaultProps;

export default Selector;
