import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as R from 'ramda';

import actions from './PendingActions';
import frontPageActions from '../FrontPage/FrontPageActions';
import {
  answerFormSelector, answerStatusSelector,
  checklistItemTypesSelector,
  documentStatisticsSelector,
  pendingIdFromPathSelector,
  pendingRequestSelector,
  uploadFormSelector,
} from './PendingReducer';
import { enabledFeatureFlagsSelector } from '../Main/MainReducer';
import withNavigationPrompt from '../../components/NavigationPrompt/NavigationPrompt';
import PendingRequest from './PendingRequest';

const mapStateToProps = state => ({
  pendingRequestId: pendingIdFromPathSelector(state),
  pendingRequest: pendingRequestSelector(state),
  answerForm: answerFormSelector(state),
  uploadForm: uploadFormSelector(state),
  documentCounts: documentStatisticsSelector(state),
  checklistItemTypes: checklistItemTypesSelector(state),
  enabledFeatureFlags: enabledFeatureFlagsSelector(state),
  answerStatus: answerStatusSelector(state),
});

const mapDispatchToProps = {
  ...actions,
  printApplication: frontPageActions.printApplication,
  printApplicationInBrowser: frontPageActions.printApplicationInBrowser,
};

const formName = 'answerForm';

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(
  withNavigationPrompt(
    PendingRequest,
    formName,
    [],
  ),
);
