import React from 'react';
import PropTypes from 'prop-types';
import {
  generatePath,
  withRouter,
} from 'react-router-dom';

import { historyShape } from 'visa-frontend-common/src/dataModel';
import { RadioButtonGroup } from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import Linkify from 'visa-frontend-common/src/components/UI/Linkify/Linkify';

import i18n from '../../services/i18n';
import paths from '../../paths';
import { staticChecklistShape } from '../../ovafModel';

import './CountryChecklistChooser.scss';

const CountryChecklistChooser = ({
  history,
  espLocation,
  checklistId,
  checklists,
  instructions,
  lang,
}) => {
  return (
    <div className="country-checklist-chooser">
      <small>
        <pre className="country-checklist-chooser__checklist-instructions">
          <Linkify>{instructions}</Linkify>
        </pre>
      </small>
      <h2 className="country-checklist-chooser__title">
        {i18n.t('checklist.selectChecklistTitle', { lng: lang })}
      </h2>
      <RadioButtonGroup
        className="country-checklist-chooser__radio-button"
        name="checklistId"
        value={checklistId}
        onChange={({ target }) => history.push(
          generatePath(
            paths.checklist, {
              espLocation,
              checklistId: target.value,
            },
          ),
        )}
        options={checklists.map(checklist => ({
          keyProp: `${checklist.checklistId}`,
          name: checklist.name,
          value: `${checklist.checklistId}`,
          label: checklist.name,
          description: (<Linkify>{checklist.description}</Linkify>),
        }))}
        lang={lang}
      />
    </div>
  );
};

CountryChecklistChooser.propTypes = {
  history: historyShape.isRequired,
  espLocation: PropTypes.string.isRequired,
  checklistId: PropTypes.string,
  checklists: PropTypes.arrayOf(staticChecklistShape),
  instructions: PropTypes.string,
  lang: PropTypes.string,
};

CountryChecklistChooser.defaultProps = {
  checklists: [],
  checklistId: null,
  instructions: null,
  lang: null,
};

export default withRouter(CountryChecklistChooser);
