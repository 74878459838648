import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import TwoColumnRow from 'visa-frontend-common/src/components/UI/TwoColumnRow/TwoColumnRow';
import { InputGroup, Selector } from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import { formFieldShape } from 'visa-frontend-common/src/dataModel';
import { stringToBoolean } from 'visa-frontend-common/src/utils/commonUtils';
import Linkify from 'visa-frontend-common/src/components/UI/Linkify/Linkify';
import { hideDocumentForMinor } from 'visa-frontend-common/src/utils/applicationDocumentUtils';

import CodesetSelectContainer from '../../components/UI/Form/CodesetCode/CodesetSelectContainer';
import i18n from '../../services/i18n';
import { checklistShape, applicationChecklistItemFormWithMetadata, checklistItemAttachmentShape } from '../../ovafModel';
import ChecklistCountrySpecificInstructions from './ChecklistCountrySpecificInstructions';
import Loading from '../../components/UI/Loading/LoadingContainer';
import actions from './ApplicationActions';
import ApplicationChecklistItem from './ApplicationChecklistItem';

import './ApplicationDocuments.scss';

const ApplicationDocuments = (props) => {
  const handleEspLocationChange = (event) => {
    props.handleSetEspLocation(event);
  };

  const handleChecklistChange = (event) => {
    const currentChecklistId = R.compose(
      R.prop('checklistId'),
      R.find(R.propEq('name', event.target.value)),
    )(props.checklists);
    props.handleSetChecklist(event, currentChecklistId);
  };

  const handleApplicantCheckedChangeFor = index => (event) => {
    const newValue = stringToBoolean(event.target.value);
    const fieldObject = {
      applicantChecked: {
        value: newValue,
      },
    };

    return R.curryN(3, props.formArrayUpdate)('applicationChecklistItems', index)(fieldObject);
  };

  const checklistOptions = R.prepend({ value: '', label: i18n.t('common:select') }, R.map(
    checklist => ({ value: checklist.name, label: checklist.name }), props.checklists,
  ));

  const checklist = R.find(
    cl => cl.name === props.checklistName.value, props.checklists,
  );

  const espLocationLabel = i18n.t('application.applicationDocuments.espLocationLabel');
  const checklistNameLabel = i18n.t('application.applicationDocuments.checklistNameLabel');

  return (
    <div role="form" aria-labelledby="applicationForm-applicationDocumentsSubtitle">
      <TabSubtitle type="h3">
        {i18n.t('application.tabs.applicationDocuments')}
      </TabSubtitle>

      <TabSubtitle type="h3">
        {`1) ${i18n.t('application.applicationDocuments.espLocationTitle')}`}
      </TabSubtitle>

      <TwoColumnRow oneColumn>
        <InputGroup
          label={espLocationLabel}
          required
          validationError={props.espLocation.validationError}
        >
          <CodesetSelectContainer
            name="espLocation"
            value={props.espLocation.value}
            codesetGroup="CountryOfSubmission"
            onChange={handleEspLocationChange}
            required
          />
        </InputGroup>
      </TwoColumnRow>

      <Loading requestNames={[actions.countrySpecificInstructionsFetched().type]}>
        <ChecklistCountrySpecificInstructions
          instructions={props.checklistCountrySpecificInstructions}
        />
      </Loading>

      <TabSubtitle type="h3">
        {`2) ${i18n.t('application.applicationDocuments.checklistNameTitle')}`}
      </TabSubtitle>

      <TwoColumnRow oneColumn>
        <InputGroup
          label={checklistNameLabel}
          required
          validationError={props.checklistName.validationError}
        >
          <Selector
            name="checklistName"
            value={props.checklistName.value}
            options={checklistOptions}
            onChange={handleChecklistChange}
            required
          />
        </InputGroup>
      </TwoColumnRow>

      { checklist && (
        <p className="application-documents__checklist-description">
          <Linkify>{checklist.description}</Linkify>
        </p>
      )}

      <TabSubtitle type="h3">
        {`3) ${i18n.t('application.applicationDocuments.checklistItemsTitle')}`}
      </TabSubtitle>

      { props.applicationChecklistItems.map((item, index) => {
        return hideDocumentForMinor(props.dateOfBirth, item.checklistItemType.value) ? null :
        <ApplicationChecklistItem
          key={item.checklistItemType.value}
          handleApplicantCheckedChangeFor={handleApplicantCheckedChangeFor}
          index={index}
          item={item}
          applicationChecklistItems={props.applicationChecklistItems}
          attachedFiles={
            R.filter(R.propEq('checklistItemType', item.checklistItemType.value))(props.checklistItemAttachments)
          }
        />;
      })}
    </div>
  );
};

ApplicationDocuments.propTypes = {
  espLocation: formFieldShape.isRequired,
  checklistName: formFieldShape.isRequired,
  checklists: PropTypes.arrayOf(checklistShape),
  handleSetChecklist: PropTypes.func.isRequired,
  handleSetEspLocation: PropTypes.func.isRequired,
  applicationChecklistItems:
    PropTypes.arrayOf(PropTypes.exact(applicationChecklistItemFormWithMetadata)),
  checklistCountrySpecificInstructions: PropTypes.string,
  checklistItemAttachments: PropTypes.arrayOf(checklistItemAttachmentShape),
  formArrayUpdate: PropTypes.func.isRequired,
  dateOfBirth: formFieldShape.isRequired,
};

ApplicationDocuments.defaultProps = {
  checklists: [],
  checklistCountrySpecificInstructions: null,
  checklistItemAttachments: [],
  applicationChecklistItems: [],
};

export default ApplicationDocuments;
