import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { notNilOrEmpty } from '../../../../utils/commonUtils';
import { SimpleInput as Input } from '../Input/Input';
import {
  localLongDateStringToJsonString,
  localDateStringToJsonString,
  jsonDateStringToLocalString,
  futureYearParser,
  pastYearPlusOneParser,
} from '../../../../services/datetime';
import i18n from '../../../../services/i18n';
import { isValidPseudoDate } from './dateInputUtils';

const DateInput = React.forwardRef((props, ref) => {
  const handleChange = (event) => {
    const stringToJsonFunc = props.useLongDates ?
      localLongDateStringToJsonString :
      localDateStringToJsonString;

    const dateJson = props.shortYearInFuture ?
      stringToJsonFunc(event.target.value, futureYearParser) :
      stringToJsonFunc(event.target.value, pastYearPlusOneParser);

    if (dateJson != null) {
      props.onChange({
        target: {
          name: props.name,
          value: dateJson,
        },
      });
    } else {
      props.onChange(event);
    }
  };

  const placeholderText = R.cond([
    [R.both(notNilOrEmpty, isValidPseudoDate(props.format)), jsonDateStringToLocalString],
    [() => props.useLongDates, R.always(i18n.t('common:dateFormats.inputFormatLongDateDescription'))],
    [R.T, R.always(i18n.t('common:dateFormats.inputFormatDescription'))],
  ])(props.placeholderDate);

  return (
    <Input
      ref={ref}
      id={props.id}
      type="text"
      className={props.className}
      value={jsonDateStringToLocalString(props.value)}
      name={props.name}
      describedBy={props.describedBy}
      required={props.required}
      onChange={handleChange}
      placeholder={placeholderText}
      disabled={props.disabled}
      invalid={props.invalid}
      dataCy={props.dataCy}
      tabIndex={props.tabIndex}
    />
  );
});

export const dateInputProps = R.curry((
  value,
  name,
  required,
  disabled,
  onChange,
) => {
  return {
    value,
    name,
    required,
    disabled,
    onChange,
  };
});

const DateInputShape = {
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  describedBy: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  shortYearInFuture: PropTypes.bool,
  invalid: PropTypes.bool,
  useLongDates: PropTypes.bool,
  placeholderDate: PropTypes.string,
  format: PropTypes.string,
  dataCy: PropTypes.string,
  tabIndex: PropTypes.number,
};

DateInput.propTypes = DateInputShape;

const DateInputDefaultProps = {
  id: null,
  value: '',
  describedBy: '',
  required: false,
  className: '',
  onChange: null,
  disabled: false,
  shortYearInFuture: false,
  invalid: false,
  useLongDates: false,
  placeholderDate: null,
  format: null,
  dataCy: null,
  tabIndex: 0,
};

DateInput.defaultProps = DateInputDefaultProps;

export default DateInput;
