import React from 'react';
import PropTypes from 'prop-types';

import NavigationButtons from '../../NavigationButtons/NavigationButtons';

import Divider from '../../Divider/Divider';

class FormTab extends React.Component {
  componentWillUnmount() {
    return this.props.validateTab && this.props.validateTab();
  }

  render() {
    return (
      <>
        { this.props.children }
        { !this.props.hideDividerLine && <Divider fullWidth withTopMargin withBottomMargin /> }
        <NavigationButtons
          className={this.props.className}
          backToName={this.props.backToName}
          backToPath={this.props.backToPath}
          proceedToName={this.props.proceedToName}
          proceedToPath={this.props.proceedToPath}
        />
      </>
    );
  }
}

FormTab.propTypes = {
  validateTab: PropTypes.func,
  backToPath: PropTypes.string,
  backToName: PropTypes.string,
  proceedToPath: PropTypes.string,
  proceedToName: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideDividerLine: PropTypes.bool,
};

FormTab.defaultProps = {
  validateTab: null,
  backToPath: null,
  backToName: '',
  proceedToPath: null,
  proceedToName: '',
  className: null,
  hideDividerLine: false,
};

export default FormTab;
