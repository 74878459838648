import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import { formFieldShape } from 'visa-frontend-common/src/dataModel';
import { hideDocumentForMinor } from 'visa-frontend-common/src/utils/applicationDocumentUtils';

import i18n from '../../../../services/i18n';
import LabeledCode from '../../../../components/UI/labeledComponents/LabeledCode';
import PreviewValidationError from './PreviewValidationError';
import paths from '../../../../paths';
import PreviewEditLink from './PreviewEditLink';
import { applicationChecklistItemFormWithMetadata } from '../../../../ovafModel';

const PreviewApplicationDocuments = (props) => {
  const applicationDocumentsPath = paths.application.tabs.applicationDocuments;
  const itemTypeName = itemType => R.compose(
    R.prop('attachmentName'),
    R.find(R.pathEq(['checklistItemType', 'value'], itemType)),
  )(props.applicationChecklistItems);

  return (
    <div className="preview-application__section">
      <TabSubtitle type="h3" className="preview-application__subtitle">
        {i18n.t('application.tabs.applicationDocuments')}
      </TabSubtitle>

      { props.espLocation.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('application.applicationDocuments.espLocationLabel')} *`}
          validationError={props.espLocation.validationError}
          tabPath={applicationDocumentsPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('application.applicationDocuments.espLocationLabel')} *`}
          dataCy="labeled-espLocation"
          group="CountryOfSubmission"
          code={props.espLocation.value}
        />}

      { props.checklistName.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('application.applicationDocuments.checklistNameLabel')} *`}
          validationError={props.checklistName.validationError}
          tabPath={applicationDocumentsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('application.applicationDocuments.checklistNameLabel')} *`}
          dataCy="labeled-checklistName"
          value={props.checklistName.value}
        />}
      { R.map(item => (
        item.applicantChecked.validationError ?
          <PreviewValidationError
            key={item.checklistItemType.value}
            fieldNameLabel={itemTypeName(item.checklistItemType.value)}
            validationError={item.applicantChecked.validationError}
            tabPath={applicationDocumentsPath}
          /> :
          !hideDocumentForMinor(props.dateOfBirth, item.checklistItemType.value) &&
          <LabeledValue
            key={item.checklistItemType.value}
            labelText={itemTypeName(item.checklistItemType.value)}
            dataCy="labeled-checklistItemType"
            value={i18n.tBoolean(item.applicantChecked.value)}
          />), props.applicationChecklistForm.value)}
      <PreviewEditLink
        tabNameLabel={i18n.t('application.tabs.applicationDocuments')}
        tabPath={applicationDocumentsPath}
      />
    </div>
  );
};

PreviewApplicationDocuments.propTypes = {
  espLocation: formFieldShape.isRequired,
  checklistName: formFieldShape.isRequired,
  applicationChecklistForm: formFieldShape.isRequired,
  applicationChecklistItems:
    PropTypes.arrayOf(PropTypes.exact(applicationChecklistItemFormWithMetadata)).isRequired,
  dateOfBirth: formFieldShape.isRequired,
};

export default PreviewApplicationDocuments;
