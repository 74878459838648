import * as R from 'ramda';

const jsonPseudoDay = /00$/;
const jsonPseudoMonth = /00-00$/;

export const hideJsonPseudo = R.compose(
  R.when(R.test(jsonPseudoDay), R.replace(jsonPseudoDay, '01')),
  R.when(R.test(jsonPseudoMonth), R.replace(jsonPseudoMonth, '01-01')),
);

export const revealJsonToLocalPseudo = (originalJsonStr, format) => R.compose(
  R.when(() => R.test(jsonPseudoDay, originalJsonStr), R.compose(
    R.replace(/^[0-9]{1,2}\./, '00.'),
    R.when(() => format === 'YYMMDD', R.take(4)), // for sticker mrz
  )),
  R.when(() => R.test(jsonPseudoMonth, originalJsonStr), R.compose(
    R.replace(/^[0-9]{1,2}\.[0-9]{1,2}\./, '00.00.'),
    R.when(() => format === 'YYMMDD', R.take(2)),
  )),
);

const pseudoDay = /^00/;
const pseudoMonth = /^0000/; // day must also be pseudo
const pseudoMonthDotted = /^00\.(00)/;

export const hideLocalPseudo = R.compose(
  R.when(R.test(pseudoDay), R.replace(pseudoDay, '01')),
  R.when(R.test(pseudoMonth), R.replace(pseudoMonth, '0101')),
  R.when(R.test(pseudoMonthDotted), R.replace(pseudoMonthDotted, '01.01')),
);

export const revealLocalToJsonPseudo = originalLocalStr => R.compose(
  R.when(() => R.test(pseudoDay, originalLocalStr), R.replace(/[0-9]{2}$/, '00')),
  R.when(
    () => R.either(R.test(pseudoMonth), R.test(pseudoMonthDotted))(originalLocalStr),
    R.replace(/[0-9]{2}-[0-9]{2}$/, '00-00'),
  ),
);
