import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from './Button';
import i18n from '../../../services/i18n';

const ExportButton = (props) => {
  return (
    <Button
      id={props.id}
      className={classnames('export-button', props.className)}
      label={i18n.t('xlsx')}
      icon={props.icon}
      class="invisible"
      disabled={props.disabled}
      onClick={props.onClick}
      dataCy={props.dataCy}
    />
  );
};

ExportButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  dataCy: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

ExportButton.defaultProps = {
  className: null,
  disabled: false,
  dataCy: null,
  icon: 'save_alt',
  id: null,
};

export default ExportButton;
