import * as R from 'ramda';
import { limitLengthWithEllipsisAndSuffix } from './commonUtils';

const fileNameHasSuffix = R.converge(R.lte, [R.length, R.compose(R.add(5), R.lastIndexOf('.'))]);

const fitFilename = R.curry((textLength, text) => R.when(
  R.compose(R.lt(textLength), R.length),
  R.compose(
    limitLengthWithEllipsisAndSuffix(textLength),
    R.ifElse(
      fileNameHasSuffix,
      R.converge(R.splitAt, [R.lastIndexOf('.'), R.identity]),
      R.pair(R.__, ''),
    ),
  ),
)(text));

const downloadBlobAndOpenToNewWindow = (apiMethod, fallbackToCurrentWindow = false) =>
  (parameter1, ...rest) =>
    apiMethod(parameter1, ...rest)
      .then((blob) => {
        const popup = window.open();
        if (R.isNil(popup) && fallbackToCurrentWindow) {
          window.location = URL.createObjectURL(blob);
        } else {
          popup.location = URL.createObjectURL(blob);
        }
      })
      .catch(() => {
        return Error('download failed');
      });

const saveBlobAs = (blob, fileName = '') => {
  const blobUrl = URL.createObjectURL(blob);

  const tempLink = document.createElement('a');
  tempLink.setAttribute('style', 'display:none;');
  tempLink.setAttribute('href', blobUrl);
  tempLink.setAttribute('download', fileName);
  document.body.appendChild(tempLink);

  tempLink.click();

  setTimeout(() => {
    document.body.removeChild(tempLink);
    URL.revokeObjectURL(blobUrl);
  }, 0);
};

export {
  downloadBlobAndOpenToNewWindow,
  fileNameHasSuffix,
  fitFilename,
  saveBlobAs,
};
