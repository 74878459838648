import i18n from '../../../services/i18n';

export const travelCostsApplicantValues = {
  CASH: 'Cash',
  CREDITCARD: 'CreditCard',
  PREPAID_ACCOMMODATION: 'PrepaidAccommodation',
  PREPAID_TRANSPORT: 'PrepaidTransport',
  TRAVELLERS_CHEQUES: 'TravellersCheques',
  OTHER: 'Other',
};

export const travelCostsApplicantInUseOption = [
  {
    keyProp: 'travelCostsApplicantInUse',
    value: 'true',
    label: i18n.t('applicationForm.travelCosts.byTheApplicantHimself'),
  },
];

export const travelCostsApplicantOptions = [
  {
    keyProp: 'travelCostsApplicantCash',
    value: travelCostsApplicantValues.CASH,
    label: i18n.t('applicationForm.travelCosts.travelCostsApplicantOptions.CASH'),
  },
  {
    keyProp: 'travelCostsApplicantTravellersCheques',
    value: travelCostsApplicantValues.TRAVELLERS_CHEQUES,
    label: i18n.t('applicationForm.travelCosts.travelCostsApplicantOptions.TRAVELLERS_CHEQUES'),
  },
  {
    keyProp: 'travelCostsApplicantCreditCard',
    value: travelCostsApplicantValues.CREDITCARD,
    label: i18n.t('applicationForm.travelCosts.travelCostsApplicantOptions.CREDITCARD'),
  },
  {
    keyProp: 'travelCostsApplicantPrepaidAccommodation',
    value: travelCostsApplicantValues.PREPAID_ACCOMMODATION,
    label: i18n.t('applicationForm.travelCosts.travelCostsApplicantOptions.PREPAID_ACCOMMODATION'),
  },
  {
    keyProp: 'travelCostsApplicantPrepaidTransport',
    value: travelCostsApplicantValues.PREPAID_TRANSPORT,
    label: i18n.t('applicationForm.travelCosts.travelCostsApplicantOptions.PREPAID_TRANSPORT'),
  },
  {
    keyProp: 'travelCostsApplicantOther',
    value: travelCostsApplicantValues.OTHER,
    label: i18n.t('applicationForm.travelCosts.travelCostsApplicantOptions.OTHER'),
  },
];

export const travelCostsSponsorInUseOption = [
  {
    keyProp: 'travelCostsSponsorInUse',
    value: 'true',
    label: i18n.t('applicationForm.travelCosts.byASponsor'),
  },
];

export const travelCostsSponsorValues = {
  CASH: 'Cash',
  PROVIDED_ACCOMMODATION: 'AccommodationProvided',
  ALL_EXPENSES_COVERED: 'AllExpencesCovered',
  PREPAID_TRANSPORT: 'PrepaidTransport',
  OTHER: 'Other',
};

export const travelCostsSponsorOptions = [
  {
    keyProp: 'travelCostsSponsorCash',
    value: travelCostsSponsorValues.CASH,
    label: i18n.t('applicationForm.travelCosts.travelCostsSponsorOptions.CASH'),
  },
  {
    keyProp: 'travelCostsSponsorAccommodationProvided',
    value: travelCostsSponsorValues.PROVIDED_ACCOMMODATION,
    label: i18n.t('applicationForm.travelCosts.travelCostsSponsorOptions.PROVIDED_ACCOMMODATION'),
  },
  {
    keyProp: 'travelCostsSponsorAllExpencesCovered',
    value: travelCostsSponsorValues.ALL_EXPENSES_COVERED,
    label: i18n.t('applicationForm.travelCosts.travelCostsSponsorOptions.ALL_EXPENSES_COVERED'),
  },
  {
    keyProp: 'travelCostsSponsorPrepaidTransport',
    value: travelCostsSponsorValues.PREPAID_TRANSPORT,
    label: i18n.t('applicationForm.travelCosts.travelCostsSponsorOptions.PREPAID_TRANSPORT'),
  },
  {
    keyProp: 'travelCostsSponsorOther',
    value: travelCostsSponsorValues.OTHER,
    label: i18n.t('applicationForm.travelCosts.travelCostsSponsorOptions.OTHER'),
  },
];
