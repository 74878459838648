import React from 'react';

import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import { displayDate } from 'visa-frontend-common/src/services/datetime';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import { formFieldShape } from 'visa-frontend-common/src/dataModel';

import i18n from '../../../../services/i18n';
import LabeledCode from '../../../../components/UI/labeledComponents/LabeledCode';
import PreviewValidationError from './PreviewValidationError';
import paths from '../../../../paths';
import PreviewEditLink from './PreviewEditLink';

const PreviewTravelDocuments = (props) => {
  const travelDocumentsPath = paths.application.tabs.travelDocuments;

  return (
    <div className="preview-application__section">
      <TabSubtitle type="h3" className="preview-application__subtitle">
        {i18n.t('common:applicationForm.travelDocumentsSubtitle')}
      </TabSubtitle>

      { props.travelDocumentType.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.travelDocumentType')} [12] *`}
          validationError={props.travelDocumentType.validationError}
          tabPath={travelDocumentsPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.travelDocumentType')} [12] *`}
          dataCy="labeled-travelDocumentType"
          group="TravelDocumentType"
          code={props.travelDocumentType.value}
        />}

      { props.travelDocumentType.value === 'Other' &&
        <>
          { props.travelDocumentTypeSpecify.validationError ?
            <PreviewValidationError
              fieldNameLabel={`${i18n.t('common:applicationForm.travelDocumentTypeSpecify')} *`}
              validationError={props.travelDocumentTypeSpecify.validationError}
              tabPath={travelDocumentsPath}
            /> :
            <LabeledValue
              labelText={`${i18n.t('common:applicationForm.travelDocumentTypeSpecify')} *`}
              dataCy="labeled-travelDocumentTypeSpecify"
              value={props.travelDocumentTypeSpecify.value}
            />}
        </>}

      { props.travelDocumentNumber.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.travelDocumentNumber')} [13] *`}
          validationError={props.travelDocumentNumber.validationError}
          tabPath={travelDocumentsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.travelDocumentNumber')} [13] *`}
          dataCy="labeled-travelDocumentNumber"
          value={props.travelDocumentNumber.value}
        />}

      { props.travelDocumentIssueDate.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.travelDocumentIssueDate')} [14] *`}
          validationError={props.travelDocumentIssueDate.validationError}
          tabPath={travelDocumentsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.travelDocumentIssueDate')} [14] *`}
          dataCy="labeled-travelDocumentIssueDate"
          value={displayDate(props.travelDocumentIssueDate.value)}
        />}

      { props.travelDocumentValidUntil.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.travelDocumentValidUntil')} [15] *`}
          validationError={props.travelDocumentValidUntil.validationError}
          tabPath={travelDocumentsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.travelDocumentValidUntil')} [15] *`}
          dataCy="labeled-travelDocumentValidUntil"
          value={displayDate(props.travelDocumentValidUntil.value)}
        />}

      { props.travelDocumentCountryIssued.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.travelDocumentCountryIssued')} [16] *`}
          validationError={props.travelDocumentCountryIssued.validationError}
          tabPath={travelDocumentsPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.travelDocumentCountryIssued')} [16] *`}
          dataCy="labeled-travelDocumentCountryIssued"
          group="CountryOfNationality"
          code={props.travelDocumentCountryIssued.value}
        />}

      <PreviewEditLink
        tabNameLabel={i18n.t('application.tabs.travelDocuments')}
        tabPath={travelDocumentsPath}
      />
    </div>
  );
};

PreviewTravelDocuments.propTypes = {
  travelDocumentType: formFieldShape.isRequired,
  travelDocumentTypeSpecify: formFieldShape.isRequired,
  travelDocumentNumber: formFieldShape.isRequired,
  travelDocumentIssueDate: formFieldShape.isRequired,
  travelDocumentValidUntil: formFieldShape.isRequired,
  travelDocumentCountryIssued: formFieldShape.isRequired,
};

export default PreviewTravelDocuments;
