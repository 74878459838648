import React, { memo } from 'react';

import GuideSubtitle from '../../../components/Guide/GuideSubtitle';
import i18n from '../../../services/i18n';

const FinalizeGuide = memo(() => {
  const applicationCentreLink =
    <a
      href="https://visa.finland.eu"
      target="_blank"
      rel="noopener noreferrer"
    >
      visa.finland.eu
    </a>;

  return (
    <>
      <GuideSubtitle id="guide-finalize" type="h3">{i18n.t('application.tabs.finalize')}</GuideSubtitle>
      <div lang={i18n.language()}>
        <p>
          <small>{i18n.t('application.guides.finalizeGuide.desc1')}</small>
        </p>

        <p>
          <small>
            {i18n.t(
              'application.guides.finalizeGuide.desc2',
              {
                VisaFinlandLink: applicationCentreLink,
                postProcess: 'reactPostprocessor',
              },
            )}
          </small>
        </p>

        <p>
          <small>{i18n.t('application.guides.finalizeGuide.desc3')}</small>
        </p>
      </div>
    </>
  );
});

export default FinalizeGuide;
