import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';

import Icon from '../Icon/Icon';

import './Button.scss';

const Button = React.forwardRef((props, ref) => {
  const renderIcon = () => {
    return R.is(String, props.icon) ? <Icon name={props.icon} /> : props.icon;
  };

  return (
    <button
      id={props.id}
      ref={ref}
      // https://github.com/jsx-eslint/eslint-plugin-react/issues/1555
      // eslint-disable-next-line react/button-has-type
      type={props.type}
      className={classNames(
        `button-${props.class}`,
        `button-${props.class}--${props.color}`,
        props.className,
      )}
      disabled={props.disabled}
      data-cy={props.dataCy}
      onClick={props.onClick}
      onKeyUp={props.onKeyUp}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      tabIndex={props.tabIndex}
      title={props.title}
      autoFocus={props.autoFocus}
      aria-controls={props.ariaControls}
      aria-expanded={props.ariaExpanded}
    >
      <div className="button__content">
        { props.icon && !props.iconOnEnd && renderIcon() }
        { props.label && <span className="button__label">{props.label}</span> }
        { props.icon && props.iconOnEnd && renderIcon() }
        { props.children }
      </div>
    </button>
  );
});

Button.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  iconOnEnd: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  disabled: PropTypes.bool,
  class: PropTypes.oneOf(['primary', 'secondary', 'invisible', 'custom']),
  color: PropTypes.oneOf(['blue', 'green', 'red']),
  children: PropTypes.node,
  dataCy: PropTypes.string,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  autoFocus: PropTypes.bool,
  ariaControls: PropTypes.string,
  ariaExpanded: PropTypes.bool,
};

Button.defaultProps = {
  id: null,
  label: null,
  icon: null,
  iconOnEnd: false,
  type: 'button',
  className: null,
  onClick: null,
  onKeyUp: null,
  onMouseEnter: null,
  onMouseLeave: null,
  disabled: false,
  class: 'primary',
  color: 'blue',
  children: null,
  dataCy: null,
  tabIndex: 0,
  title: null,
  autoFocus: false,
  ariaControls: null,
  ariaExpanded: null,
};

export default Button;
