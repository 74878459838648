import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';

import Button from '../../Button/Button';
import { codesetNotInList, formatValue } from '../CodesetCode/codesetUtils';

const CodesetListField = (props) => {
  const handleChange = (event) => {
    if (!event.target.value) {
      return;
    }

    const newItems = R.append(event.target.value, props.values);

    props.handleChange({
      target: {
        name: props.name,
        value: newItems,
      },
    });
  };

  const handleRemoveItem = (itemToBeRemoved) => {
    const newItems = props.values.filter((item) => {
      return item !== itemToBeRemoved;
    });

    props.handleChange({
      target: {
        name: props.name,
        value: newItems,
      },
    });
  };

  const addFieldName = `${props.name}New`;
  const CodesetValueContainer = props.codesetValueContainer;
  const CodesetSelectContainer = props.codesetSelectContainer;
  return (
    <>
      { props.values.length > 0 &&
        <ul>
          {
            props.values.map((item, index) => {
              const key = props.name + index;

              return (
                <li key={key} className="list-field__item">
                  <CodesetValueContainer
                    codesetGroup={props.codesetGroup}
                    value={item}
                  />
                  <Button
                    type="button"
                    onClick={() => handleRemoveItem(item)}
                    class="invisible"
                    icon="close"
                    disabled={props.disabled}
                    className={classnames(
                      'list-field__item-remove-button',
                      {
                        'list-field__item-remove-button--disabled': props.disabled,
                      },
                    )}
                    dataCy={`${props.name}-list-field-remove-button-for-${item}`}
                  />
                </li>
              );
            })
          }
        </ul>}

      <CodesetSelectContainer
        id={props.id || addFieldName}
        name={addFieldName}
        value={null}
        codesetGroup={props.codesetGroup}
        codesetPredicate={
          props.codesetPredicate ?
            R.allPass([
              props.codesetPredicate,
              codesetNotInList(props.values, props.formatValue),
            ]) : codesetNotInList(props.values, props.formatValue)
        }
        onChange={handleChange}
        invalid={props.invalid}
        disabled={props.disabled}
        lang={props.lang}
        required={props.required && !props.values.length}
        describedBy={props.describedBy}
        ariaLabel={props.ariaLabel}
        className={classnames(
          'list-field__selector',
          {
            'list-field__selector--disabled': props.disabled,
          },
        )}
        showExpiredCodes={props.showExpiredCodes}
        formatValue={props.formatValue}
      />
    </>
  );
};

CodesetListField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  codesetGroup: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  codesetPredicate: PropTypes.func,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  codesetValueContainer: PropTypes.elementType.isRequired,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  lang: PropTypes.string,
  required: PropTypes.bool,
  describedBy: PropTypes.string,
  ariaLabel: PropTypes.string,
  showExpiredCodes: PropTypes.bool,
  formatValue: PropTypes.func,
};

CodesetListField.defaultProps = {
  id: null,
  values: [],
  codesetPredicate: null,
  invalid: false,
  disabled: false,
  lang: null,
  required: false,
  describedBy: null,
  ariaLabel: null,
  showExpiredCodes: false,
  formatValue,
};

export default CodesetListField;
