import * as R from 'ramda';
import i18n from '../../../../services/i18n';
import { isValidPseudoDate as checkValidPseudoDate } from '../../../../services/validator/validators';

const isValidPseudoDate = (
  visibleDateFormat = i18n.t('common:dateFormats.inputFormatLongDateDescription'),
) => R.compose(
  R.isNil,
  checkValidPseudoDate(visibleDateFormat),
);

// eslint-disable-next-line import/prefer-default-export
export { isValidPseudoDate };
