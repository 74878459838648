import React, { memo } from 'react';

import GuideItem from '../../../components/Guide/GuideItem';
import i18n from '../../../services/i18n';

const FingerPrintsAndDestinationPermitGuide = memo(() => {
  return (
    <>
      <GuideItem id="journeyDetailsGuide-fingerprintsAlreadyStored" subTitle={`28. ${i18n.t('common:applicationForm.fingerprintsAlreadyStored')}`}>
        {i18n.t('application.guides.journeyDetailsGuide.fingerprintsAlreadyStored')}
      </GuideItem>

      <GuideItem id="journeyDetailsGuide-finalDestination" subTitle={`29. ${i18n.t('common:applicationForm.finalDestination')}`}>
        {i18n.t('application.guides.journeyDetailsGuide.finalDestination')}
      </GuideItem>
    </>
  );
});

export default FingerPrintsAndDestinationPermitGuide;
