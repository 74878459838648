import * as R from 'ramda';
import { notNilOrEmpty } from '../../utils/commonUtils';

// Request names that are given as props include all postfix "_COMPLETED". We have to remove them,
// as they are not included in the corresponding key names in the Redux (loading) store.
const removeCompletedPostfix = R.replace('_COMPLETED', '');
const removeCompletedPostfixes = (requestNames) => {
  return R.map(removeCompletedPostfix, requestNames);
};

// Check which of the given requests are in loading-state
const getRequestsInLoading = (requestNames, loadingState) => {
  const requestNamesWithoutCompletedPostfix = removeCompletedPostfixes(requestNames);

  const targetRequestsInLoading = Object.keys(loadingState).filter(key =>
    loadingState[key].status !== 'COMPLETED' && requestNamesWithoutCompletedPostfix.includes(key));

  return targetRequestsInLoading;
};

// Return true if one of the given requests are still in progress
const isAnyRequestLoading = (requestNames, loadingState) => {
  const requestNamesWithoutCompletedPostfix = removeCompletedPostfixes(requestNames);

  return !R.isEmpty(R.keys(loadingState)
    .filter(key => R.and(
      R.equals(loadingState[key].status, 'REQUEST'),
      R.any(R.equals(key))(requestNamesWithoutCompletedPostfix),
    )));
};

const isAnyRequestCompleted = (requestNames, loadingState) => {
  const requestNamesWithoutCompletedPostfix = removeCompletedPostfixes(requestNames);

  return !R.isEmpty(R.keys(loadingState)
    .filter(key => R.and(
      R.equals(loadingState[key].status, 'COMPLETED'),
      R.any(R.equals(key))(requestNamesWithoutCompletedPostfix),
    )));
};

const getAllRequestsInLoading = (loadingState) => {
  return R.compose(
    R.filter(key => loadingState[key].status !== 'COMPLETED'),
    R.keys,
  )(loadingState);
};

const getAllRequestsInError = (loadingState) => {
  return R.compose(
    R.map(([action, { error }]) => ({ action, error })),
    R.toPairs,
    R.filter(R.propSatisfies(notNilOrEmpty, 'error')),
  )(loadingState);
};

const getRequestsInError = (requestNames, loadingState) => {
  const requestNamesWithoutCompletedPostfix = removeCompletedPostfixes(requestNames);

  return R.compose(
    getAllRequestsInError,
    R.pickBy((value, key) => R.includes(key, requestNamesWithoutCompletedPostfix)),
  )(loadingState);
};

export {
  removeCompletedPostfix,
  getRequestsInLoading,
  isAnyRequestLoading,
  isAnyRequestCompleted,
  getAllRequestsInLoading,
  getAllRequestsInError,
  getRequestsInError,
};
