import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as R from 'ramda';

import loading from 'visa-frontend-common/src/services/loading/loadingReducer';
import sorting from 'visa-frontend-common/src/services/sorting/sortingReducer';
import pagination from 'visa-frontend-common/src/services/pagination/paginationReducer';
import dirtyForm from 'visa-frontend-common/src/services/navigationprompt/navigationPromptReducer';

import register from './pages/Register/RegisterReducer';
import forgotPassword from './pages/ForgotPassword/ForgotPasswordReducer';
import main from './pages/Main/MainReducer';
import frontpage from './pages/FrontPage/FrontPageReducer';
import application from './pages/Application/ApplicationReducer';
import checklistPage from './pages/ChecklistPage/ChecklistPageReducer';
import pending from './pages/Pending/PendingReducer';
import codeset from './components/UI/Form/CodesetCode/CodesetReducer';

const appReducer = history => combineReducers({
  router: connectRouter(history),
  register,
  forgotPassword,
  application,
  pending,
  checklistPage,
  codeset,
  loading,
  main,
  frontpage,
  sorting,
  pagination,
  dirtyForm,
});

// todo: temp
const types = { LOGIN_COMPLETED: 'LOGIN_COMPLETED', LOGOUT: 'LOGOUT' };
/**
 * Calls appReducer with undefined when logged in or out.
 * Reducers return initial state when called with undefined.
 */
const rootReducer = history => (state, action) => R.ifElse(
  R.compose(R.anyPass([R.equals(types.LOGIN_COMPLETED), R.equals(types.LOGOUT)]), R.prop('type')),
  () => appReducer(history)(undefined, action),
  () => appReducer(history)(state, action),
)(action);

export default rootReducer;
