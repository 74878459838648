import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';

import { isSomeRequestLoadingSelector } from 'visa-frontend-common/src/services/loading/loadingSelectors';
import withNavigationPrompt from '../../components/NavigationPrompt/NavigationPrompt';
import actions from './PendingActions';
import { pendingIdFromPathSelector, pendingRequestSelector, uploadFormSelector } from './PendingReducer';
import DocumentUploadForm from './S3DocumentUploadForm';

const mapStateToProps = state => ({
  applicationId: R.prop('applicationId', pendingRequestSelector(state)),
  pendingRequestId: pendingIdFromPathSelector(state),
  uploadForm: uploadFormSelector(state),
  isUploadInProgress:
    isSomeRequestLoadingSelector(state, [
      actions.documentUploadCompleted().type,
      actions.documentUploadSet().type,
      actions.documentDeleteCompleted().type,
    ]),
});

const mapDispatchToProps = actions;

const formName = 'uploadForm';

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(
  withNavigationPrompt(
    DocumentUploadForm,
    formName,
    [],
  ),
);
