import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import { createSelector, fetchReducerFor, fieldSetReducerFor } from 'visa-frontend-common/src/utils/reducerUtils';
import actions from './ChecklistPageActions';

const initialState = {
  staticChecklists: [],
  checklistItemAttachments: [],
  checklistPageLocale: null,
};

export const staticChecklistsSelector = createSelector(
  [R.path(['checklistPage', 'staticChecklists'])],
  R.identity,
);

export const checklistItemAttachmentsSelector = createSelector(
  [R.path(['checklistPage', 'checklistItemAttachments'])],
  R.identity,
);

export const checklistPageLocaleSelector = createSelector(
  [R.path(['checklistPage', 'checklistPageLocale'])],
  R.identity,
);

const fieldSetReducer = fieldSetReducerFor(initialState);
const fetchReducer = fetchReducerFor(initialState);

const reducer = handleActions({
  [actions.staticChecklistsFetched]: fetchReducer('staticChecklists'),
  [actions.checklistItemAttachmentsFetched]: fetchReducer('checklistItemAttachments'),
  [actions.setChecklistPageLocale]: fieldSetReducer('checklistPageLocale'),
}, initialState);

export default reducer;
