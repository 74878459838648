import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { flushSync } from 'react-dom';

import { notifyError, notifySuccess } from 'visa-frontend-common/src/services/pushNotificationService';
import { apiThunkWithLoading } from 'visa-frontend-common/src/utils/actionUtils';
import { downloadBlobAndOpenToNewWindow } from 'visa-frontend-common/src/utils/downloadUtils';
import { printApplicationInIframe } from 'visa-frontend-common/src/services/htmlPrintingService';
import {
  validatingSetFieldThunk,
  validatingSubmitFormThunk,
} from 'visa-frontend-common/src/services/validator/validatorActions';
import * as applicationService from '../../services/applicationService';
import { setCurrentUserData } from '../../services/utilityService';
import i18n from '../../services/i18n';
import applicationGroupingValidationRules from './applicationGroupingValidationRules';
import applicationCopyValidationRules from '../../components/ApplicationGroupListItem/applicationCopyValidationRules';
import applicationActions from '../Application/ApplicationActions';
import paths from '../../paths';

const applicationsFetched = createAction('APPLICATIONS_FETCHED_COMPLETED');
const applicationGroupsFetched = createAction('APPLICATION_GROUPS_FETCHED_COMPLETED');
const updatingCurrentUserData = createAction('CURRENT_USER_DATA_UPDATE_COMPLETED');
const applicationDeleted = createAction('APPLICATIONS_DELETE_COMPLETED');
const showFrontPageGuideSet = createAction('FRONTPAGE_SHOW_GUIDE_SET');
const applicationPrinted = createAction('APPLICATION_PRINT_COMPLETED');
const applicationGroupFormFieldSet = createAction('APPLICATION_GROUP_FORM_FIELD_SET');
const applicationGroupFormCleared = createAction('APPLICATION_GROUP_FORM_CLEARED');
const applicationCopyFormFieldSet = createAction('APPLICATION_COPY_FORM_FIELD_SET');
const applicationCopyFormCleared = createAction('APPLICATION_COPY_FORM_CLEARED');
const applicationCopyFetched = createAction('APPLICATION_COPY_FETCH_COMPLETED');

const createDownloadingApplicationPdfBlobAction = applicationId =>
  createAction(`APPLICATION[${applicationId}]_PDF_DOWNLOAD_COMPLETED`);

const fetchApplications = () => (dispatch) => {
  return dispatch(apiThunkWithLoading(
    applicationService.getApplications,
    applicationsFetched,
  )()).then(R.unless(R.is(Error), () => {
    return R.compose(dispatch, showFrontPageGuideSet)();
  }));
};

const fetchApplicationGroups = () => (dispatch) => {
  return dispatch(apiThunkWithLoading(
    applicationService.getApplicationGroups,
    applicationGroupsFetched,
  )());
};

const printApplication = applicationId => dispatch => (
  dispatch(
    apiThunkWithLoading(
      downloadBlobAndOpenToNewWindow(applicationService.printApplication, true),
      createDownloadingApplicationPdfBlobAction(applicationId),
    )(applicationId),
  ).then(R.ifElse(
    R.is(Error),
    err => err,
    () => dispatch(fetchApplications()),
  )).then(R.ifElse(
    R.is(Error),
    () => notifyError('application-download-failed', i18n.t('print.failure')),
    () => notifySuccess('application-download-succeeded', i18n.t('print.success')),
  ))
);

const printApplicationInBrowser = apiThunkWithLoading(
  applicationId => applicationService.printApplicationInBrowser(applicationId)
    .then(applicationBody => printApplicationInIframe(applicationBody)),
  applicationPrinted,
);

const updateLocale = locale => (dispatch) => {
  return dispatch(apiThunkWithLoading(setCurrentUserData, updatingCurrentUserData)({ locale }))
    .then(() => {
      i18n.setLocale(locale, () => {
        window.location.reload();
      });
    });
};

const createApplicationGroup = () => (dispatch) => {
  return dispatch(apiThunkWithLoading(
    applicationService.createApplicationGroup,
    applicationGroupsFetched,
  )()).then(R.unless(R.is(Error), () => {
    notifySuccess('application-group-created', i18n.t('applicationGroups.applicationGroupCreated'));
  }));
};

const addApplicationToGroup = form => (dispatch) => {
  return dispatch(validatingSubmitFormThunk(
    applicationGroupFormFieldSet,
    applicationService.addApplicationToGroup,
    applicationGroupsFetched,
    applicationGroupingValidationRules,
  )(form))
    .then(R.unless(R.is(Error), () => {
      notifySuccess('application-linked', i18n.t('applicationGroups.applicationLinked'));
      dispatch(fetchApplications());
      dispatch(applicationGroupFormCleared());
    }));
};

const removeApplicationFromGroup = (applicationId, groupId) => (dispatch) => {
  return apiThunkWithLoading(
    applicationService.removeApplicationFromGroup,
    applicationGroupsFetched,
  )(applicationId, groupId)(dispatch)
    .then(R.unless(R.is(Error), () => {
      notifySuccess('application-unlinked', i18n.t('applicationGroups.applicationUnlinked'));
      dispatch(fetchApplications());
    }));
};

const deleteApplication = application => (dispatch) => {
  return dispatch(apiThunkWithLoading(
    applicationService.deleteApplication,
    applicationDeleted,
  )(application)).then(R.unless(R.is(Error), () => {
    notifySuccess('application-delete', i18n.t('frontPage.deleteSuccess'));
    dispatch(fetchApplications());
    dispatch(fetchApplicationGroups());
  }));
};

const deleteApplicationGroup = groupId => (dispatch) => {
  return dispatch(apiThunkWithLoading(
    applicationService.deleteApplicationGroup,
    applicationGroupsFetched,
  )(groupId)).then(R.unless(R.is(Error), () => {
    notifySuccess('application-group-delete', i18n.t('applicationGroups.applicationGroupDeleted'));
    dispatch(fetchApplications());
  }));
};

const setCopyFieldAndValidate = (field, form) => dispatch =>
  validatingSetFieldThunk(
    applicationCopyFormFieldSet,
    applicationCopyValidationRules,
  )(field, form)(dispatch);

const startCopyOfApplication = form => dispatch =>
  validatingSubmitFormThunk(
    applicationCopyFormFieldSet,
    applicationService.getCopyOfApplication,
    applicationCopyFetched,
    applicationCopyValidationRules,
  )(form)(dispatch)
    .then(R.unless(R.is(Error), (response) => {
      /* TODO: there probably shouldnt be react dependencies (flushsync) in action level.
        Maybe you could save the template information and populate in componentdidmount
      */
      flushSync(() => dispatch(push(`${paths.application.create}`)));
      dispatch(applicationActions.applicationFetched(response));
      dispatch(applicationCopyFormCleared());
    }));

const actions = {
  fetchApplications,
  fetchApplicationGroups,
  applicationsFetched,
  applicationGroupsFetched,
  applicationCopyFetched,
  applicationDeleted,
  updateLocale,
  printApplication,
  printApplicationInBrowser,
  createDownloadingApplicationPdfBlobAction,
  deleteApplication,
  deleteApplicationGroup,
  showFrontPageGuideSet,
  createApplicationGroup,
  addApplicationToGroup,
  removeApplicationFromGroup,
  applicationGroupFormFieldSet,
  applicationGroupFormCleared,
  applicationCopyFormFieldSet,
  applicationCopyFormCleared,
  setCopyFieldAndValidate,
  startCopyOfApplication,
};

export default actions;
