import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';

import SingletonNavigationPrompt, {
  defaultMapStateToProps,
} from 'visa-frontend-common/src/components/NavigationPrompt/SingletonNavigationPrompt';

export default R.compose(
  withRouter,
  connect(defaultMapStateToProps, null),
)(SingletonNavigationPrompt);
