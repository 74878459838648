import React from 'react';
import * as R from 'ramda';

import {
  invitationFormShape,
  invitationType as types,
} from 'visa-frontend-common/src/components/ApplicationForm/Invitations/InvitationsDataModel';
import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';

import i18n from '../../../../services/i18n';
import PreviewValidationError from './PreviewValidationError';
import paths from '../../../../paths';
import LabeledCode from '../../../../components/UI/labeledComponents/LabeledCode';

const PreviewInvitation = ({ invitation }) => {
  if (!invitation) {
    return null;
  }

  const isCompanyType = invitationType =>
    R.includes(invitationType, [types.ORGANIZATION, types.ACCOMMODATION]);
  const isPersonType = invitationType => !isCompanyType(invitationType);
  const invitationType = invitation.invitationType && invitation.invitationType.value;
  const requiredIf = label => (
    [types.ACCOMMODATION, types.ORGANIZATION_CONTACT].includes(invitationType) ? label : `${label} *`
  );

  const companyNameLabel = `${i18n.t(`common:applicationForm.invitations.${invitationType}.companyName`)} *`;
  const surnameLabel = `${i18n.t(`common:applicationForm.invitations.${invitationType}.surname`)} *`;
  const firstNamesLabel = `${i18n.t(`common:applicationForm.invitations.${invitationType}.firstNames`)} *`;
  const streetLabel = requiredIf(`${i18n.t(`common:applicationForm.invitations.${invitationType}.street`)}`);
  const zipLabel = `${i18n.t(`common:applicationForm.invitations.${invitationType}.zip`)}`;
  const cityLabel = requiredIf(`${i18n.t(`common:applicationForm.invitations.${invitationType}.city`)}`);
  const countryLabel = requiredIf(`${i18n.t(`common:applicationForm.invitations.${invitationType}.country`)}`);
  const phonenumberLabel = requiredIf(`${i18n.t(`common:applicationForm.invitations.${invitationType}.phonenumber`)}`);
  const emailLabel = `${i18n.t(`common:applicationForm.invitations.${invitationType}.email`)}`;

  const contactsAndAccommodationPath = paths.application.tabs.contactsAndAccommodation;

  return (
    <>
      { isCompanyType(invitationType) &&
        <>
          { invitation.companyName.validationError ?
            <PreviewValidationError
              fieldNameLabel={companyNameLabel}
              validationError={invitation.companyName.validationError}
              tabPath={contactsAndAccommodationPath}
            /> :
            <LabeledValue
              labelText={companyNameLabel}
              dataCy="labeled-name"
              value={invitation.companyName.value}
            />}
        </>}

      { isPersonType(invitationType) &&
        <>
          { invitation.surname.validationError ?
            <PreviewValidationError
              fieldNameLabel={surnameLabel}
              validationError={invitation.surname.validationError}
              tabPath={contactsAndAccommodationPath}
            /> :
            <LabeledValue
              labelText={surnameLabel}
              dataCy="labeled-invitationSurname"
              value={invitation.surname.value}
            />}

          { invitation.firstNames.validationError ?
            <PreviewValidationError
              fieldNameLabel={firstNamesLabel}
              validationError={invitation.firstNames.validationError}
              tabPath={contactsAndAccommodationPath}
            /> :
            <LabeledValue
              labelText={firstNamesLabel}
              dataCy="labeled-invitationFirstNames"
              value={invitation.firstNames.value}
            />}
        </>}

      { invitation.address.value.street.validationError ?
        <PreviewValidationError
          fieldNameLabel={streetLabel}
          validationError={invitation.address.value.street.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={streetLabel}
          value={invitation.address.value.street.value}
        />}

      { invitation.address.value.zip.validationError ?
        <PreviewValidationError
          fieldNameLabel={zipLabel}
          validationError={invitation.address.value.zip.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={zipLabel}
          value={invitation.address.value.zip.value}
        />}

      { invitation.address.value.city.validationError ?
        <PreviewValidationError
          fieldNameLabel={cityLabel}
          validationError={invitation.address.value.city.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={cityLabel}
          value={invitation.address.value.city.value}
        />}

      { invitation.address.value.country.validationError ?
        <PreviewValidationError
          fieldNameLabel={countryLabel}
          validationError={invitation.address.value.country.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledCode
          labelText={countryLabel}
          group="CountryOfNationality"
          code={invitation.address.value.country.value}
        />}

      { invitation.email.validationError ?
        <PreviewValidationError
          fieldNameLabel={emailLabel}
          validationError={invitation.email.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={emailLabel}
          dataCy="labeled-email"
          value={invitation.email.value}
        />}

      { invitation.phonenumber.validationError ?
        <PreviewValidationError
          fieldNameLabel={phonenumberLabel}
          validationError={invitation.phonenumber.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={phonenumberLabel}
          dataCy="labeled-phonenumber"
          value={invitation.phonenumber.value}
        />}
    </>
  );
};

PreviewInvitation.propTypes = {
  invitation: invitationFormShape.isRequired,
};

export default PreviewInvitation;
