import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as R from 'ramda';
import compatibleEvent from '../../../../compatibilityFixes';

import './CheckboxGroup.scss';

const Checkbox = (props) => {
  const id = `${props.name}-${props.keyProp}`;
  return (
    <div className="mdc-form-field mdc-form-field--checkbox">
      <div className={classnames('mdc-checkbox', { 'mdc-checkbox--disabled': props.disabled })}>
        <input
          type="checkbox"
          className={classnames(
            'mdc-checkbox__native-control',
            { 'mdc-checkbox__native-control--invalid': props.invalid },
          )}
          key={props.keyProp}
          id={id}
          name={props.name}
          value={props.value}
          disabled={props.disabled}
          checked={props.checked}
          onChange={props.onChange}
          autoFocus={props.autoFocus}
          aria-describedby={props.describedBy}
          required={props.required}
        />
        <div className="mdc-checkbox__background">
          <svg
            className="mdc-checkbox__checkmark"
            viewBox="0 0 24 24"
          >
            <path
              className="mdc-checkbox__checkmark-path"
              fill="none"
              d="M1.73,12.91 8.1,19.28 22.79,4.59"
            />
          </svg>
          <div className="mdc-checkbox__mixedmark" />
        </div>
      </div>
      <label className={`mdc-checkbox__${props.labelType}`} htmlFor={id}>
        {props.label}
        {props.required && (<small className="mdc-checkbox__small">*</small>)}
      </label>
    </div>
  );
};

const CheckboxShape = {
  keyProp: PropTypes.string.isRequired, // Props cannot be named key
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelType: PropTypes.oneOf(['label', 'h3']),
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  invalid: PropTypes.bool,
  required: PropTypes.bool,
  describedBy: PropTypes.string,
};

Checkbox.propTypes = CheckboxShape;

const CheckboxShapeDefaultProps = {
  disabled: false,
  label: null,
  labelType: 'label',
  checked: false,
  onChange: null,
  autoFocus: false,
  invalid: false,
  required: false,
  describedBy: null,
};

Checkbox.defaultProps = CheckboxShapeDefaultProps;

const CheckboxGroup = (props) => {
  const handleChange = (event) => {
    const newEvent = compatibleEvent(event);
    const newValue = R.ifElse(
      R.compose(R.equals(true), R.prop('checked')),
      R.compose(R.append(R.__, props.value), R.prop('value')),
      R.compose(R.without(R.__, props.value), R.prop('value')),
    )(newEvent.target);
    const eventWithNewValue = R.assocPath(['target', 'value'], newValue, newEvent);
    props.onChange(eventWithNewValue);
  };

  return (
    <fieldset aria-required={props.required}>
      {
        props.options.map(option => (
          <Checkbox
            key={option.keyProp}
            keyProp={option.keyProp}
            label={option.label}
            labelType={props.labelType}
            name={props.name}
            value={option.value}
            disabled={props.disabled || option.disabled}
            checked={R.includes(option.value, props.value)}
            onChange={handleChange}
            autoFocus={option.autoFocus}
            invalid={props.invalid}
            required={option.required}
            describedBy={props.describedBy}
          />
        ))
      }
    </fieldset>
  );
};

const CheckboxGroupShape = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  labelType: PropTypes.oneOf(['label', 'h3']),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.exact(R.omit(['name'], CheckboxShape))).isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  required: PropTypes.bool,
  describedBy: PropTypes.string,
};

CheckboxGroup.propTypes = CheckboxGroupShape;

const CheckboxGroupDefaultProps = {
  value: [],
  labelType: 'label',
  onChange: null,
  disabled: false,
  invalid: false,
  required: false,
  describedBy: null,
};

CheckboxGroup.defaultProps = CheckboxGroupDefaultProps;

export default CheckboxGroup;
