import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { matchPath } from 'react-router-dom';

import { removeCompletedPostfix } from 'visa-frontend-common/src/services/loading/loadingUtils';
import { assocBooleanFieldByPaths, convertToForm } from 'visa-frontend-common/src/utils/commonUtils';
import {
  fieldSetReducerFor,
  createSelector,
  routerPathSelector,
  resetReducerFor,
  fetchReducerFor,
} from 'visa-frontend-common/src/utils/reducerUtils';
import formValidator from 'visa-frontend-common/src/services/validator/formValidator';
import { validatingSetArrayField } from 'visa-frontend-common/src/services/validator/validatorReducerUtils';
import { formFieldDefaultValue } from 'visa-frontend-common/src/dataModel';

import paths from '../../paths';
import rules from './ApplicationValidationRules';
import actions from './ApplicationActions';
import {
  ovafApplicationFormTabsDefaultProps,
  ovafFormDefaultProps,
} from '../../ovafModel';

const initialState = {
  form: ovafFormDefaultProps,
  formTabs: ovafApplicationFormTabsDefaultProps,
  finalizedApplication: null,

  checklists: [],
  checklistItemTypes: [],
  checklistItemAttachments: [],
  checklistCountrySpecificInstructions: null,
  savedChecklistName: null,
  savedApplicationChecklistItems: [],
};

export const formSelector = createSelector([R.path(['application', 'form'])], R.identity);
export const formTabsSelector = createSelector([R.path(['application', 'formTabs'])], R.identity);
export const finalizedApplicationSelector = createSelector([R.path(['application', 'finalizedApplication'])], R.identity);

const parsePath = path => matchPath(path, {
  path: paths.application.update,
  exact: true,
});
export const idFromPathSelector = createSelector(
  [routerPathSelector],
  R.compose(
    R.cond([
      [R.pathSatisfies(R.equals('create'), ['params', 'applicationId']), R.always(null)],
      [R.T, R.path(['params', 'applicationId'])],
    ]),
    parsePath,
  ),
);
export const applicationFetchWasSuccessfulSelector = createSelector(
  [R.path(['loading', removeCompletedPostfix(actions.applicationFetched().type), 'error'])],
  R.isNil,
);
export const validationErrorNumberSelector = createSelector(
  [R.path(['application', 'form'])],
  (form) => { return R.length(formValidator.formErrors(form)); },
);

export const checklistsSelector = createSelector(
  [R.path(['application', 'checklists'])],
  R.identity,
);

export const checklistItemTypesSelector = createSelector(
  [R.path(['application', 'checklistItemTypes'])],
  R.identity,
);

export const checklistItemAttachmentsSelector = createSelector(
  [R.path(['application', 'checklistItemAttachments'])],
  R.identity,
);

export const applicationChecklistItemsSelector = createSelector(
  [R.path(['application', 'form', 'applicationChecklistItems', 'value']), checklistItemTypesSelector],
  (applicationChecklistItems, checklistItemTypes) => {
    if (checklistItemTypes.length === 0) {
      return [];
    }

    return R.map((item) => {
      const itemMetaDataByType = R.find(R.propEq('checklistItemType', item.checklistItemType.value))(checklistItemTypes);
      return {
        ...item,
        attachmentTypeDescription: itemMetaDataByType.attachmentTypeDescription,
        attachmentName: itemMetaDataByType.attachmentName,
      };
    }, applicationChecklistItems);
  },
);

export const checklistCountrySpecificInstructionsSelector = createSelector(
  [R.path(['application', 'checklistCountrySpecificInstructions'])],
  R.identity,
);

const fieldSetReducer = fieldSetReducerFor(initialState);
const fetchReducer = fetchReducerFor(initialState);

const reducer = handleActions({
  [actions.applicationFetched]: (state, action) => {
    return action.error ? state : R.compose(
      R.assoc('form', R.__, state),
      R.pick(R.keys(initialState.form)),
      convertToForm,
      R.assoc('formerSurnamesInput', null),
      R.assoc('phonenumbersInput', null),
      assocBooleanFieldByPaths(
        'entryPermitForFinalDestination',
        [
          R.path(['entryPermit', 'number']),
          R.path(['entryPermit', 'issuingAuthority']),
          R.path(['entryPermit', 'validFrom']),
          R.path(['entryPermit', 'validUntil']),
        ],
      ),
      assocBooleanFieldByPaths(
        'travelCostsApplicantInUse',
        [
          R.path(['travelCostApplicantOther']),
          R.path(['travelCostsApplicant']),
        ],
      ),
      assocBooleanFieldByPaths(
        'travelCostsSponsorInUse',
        [
          R.path(['sponsorHost']),
          R.path(['sponsorHostSpecify']),
          R.path(['sponsorOther']),
          R.path(['sponsorOtherSpecify']),
          R.path(['travelCostSponsorOther']),
          R.path(['travelCostsSponsor']),
        ],
      ),
      assocBooleanFieldByPaths(
        'guardianAddressIsDifferentThanApplicants',
        [
          R.path(['guardianAddress', 'street']),
          R.path(['guardianAddress', 'zip']),
          R.path(['guardianAddress', 'city']),
          R.path(['guardianAddress', 'country']),
        ],
      ),
      assocBooleanFieldByPaths(
        'secondaryGuardianAddressIsDifferentThanApplicants',
        [
          R.path(['secondaryGuardianAddress', 'street']),
          R.path(['secondaryGuardianAddress', 'zip']),
          R.path(['secondaryGuardianAddress', 'city']),
          R.path(['secondaryGuardianAddress', 'country']),
        ],
      ),
      assocBooleanFieldByPaths(
        'contactOrAccommodation',
        [
          R.path(['accommodations']),
          R.path(['invitingPersons']),
          R.path(['invitingOrganization']),
          R.path(['oneDayTripWithoutAccommodation']),
        ],
      ),
    )(action.payload);
  },
  [actions.formFieldSet]: fieldSetReducer('form'),
  [actions.formTabsSet]: fieldSetReducer('formTabs'),
  [actions.formArrayUpdate]: (state, action) => R.cond([
    [R.propEq('arrayName', 'applicationChecklistItems'), () => validatingSetArrayField(['applicationChecklistItems'], ['form'], rules(true))(state, action)],
    [R.propEq('arrayName', 'invitingPersons'), () => validatingSetArrayField(['invitingPersons'], ['form'], rules(true))(state, action)],
    [R.propEq('arrayName', 'accommodations'), () => validatingSetArrayField(['accommodations'], ['form'], rules(true))(state, action)],
    [R.T, () => state],
  ])(action.payload),
  [actions.formCleared]: (state) => {
    return {
      ...state,
      form: initialState.form,
      formTabs: initialState.formTabs,
    };
  },
  [actions.clearFields]: resetReducerFor(initialState),
  [actions.checklistsFetched]: fetchReducer('checklists'),
  [actions.checklistItemsFetched]: (state, action) => {
    if (action.error) {
      return state;
    }

    const otherChecklistItemTypes = R.compose(
      R.pluck('checklistItemType'),
      R.filter(R.propEq('attachmentTypeDescription', 'Other documents')),
      R.prop('checklistItemTypes'),
    );
    return R.compose(
      R.assocPath(['form', 'applicationChecklistItems'], R.__, state),
      formFieldDefaultValue,
      R.map(convertToForm),
      R.when(
        () => state.savedChecklistName === state.form.checklistName.value,
        () => state.savedApplicationChecklistItems,
      ),
      R.map(
        R.ifElse(
          R.compose(
            R.includes(R.__, otherChecklistItemTypes(state)),
            R.prop('checklistItemType'),
          ),
          R.assoc('applicantChecked', false),
          R.assoc('applicantChecked', null),
        ),
      ),
    )(action.payload);
  },
  [actions.checklistItemAttachmentsFetched]: fetchReducer('checklistItemAttachments'),
  [actions.checklistItemTypesFetched]: fetchReducer('checklistItemTypes'),
  [actions.countrySpecificInstructionsFetched]: fetchReducer('checklistCountrySpecificInstructions'),
  [actions.finalizedApplicationFetched]: fetchReducer('finalizedApplication'),
}, initialState);

export default reducer;
