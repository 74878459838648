import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { codesetNotEqual, codesetNotInList } from '../../UI/Form/CodesetCode/codesetUtils';
import {
  codesetNotOtherSpecificPurpose,
  codesetNotOtherWhenOtherSelected,
} from './journeyPurposeUtils';
import TwoColumnRow from '../../UI/TwoColumnRow/TwoColumnRow';
import {
  BooleanCheckboxGroup,
  Input,
  InputGroup,
  inputProps,
} from '../../UI/Form/FormComponents';
import i18n from '../../../services/i18n';
import { formFieldShape } from '../../../dataModel';
import Label from '../../UI/Label/Label';

const PurposesOfJourney = (props) => {
  const { handleChange, clearFields } = props;

  const CodesetSelectContainer = props.codesetSelectContainer;
  const CodesetListField = props.codesetListFieldContainer;

  const handleTravelPurposeChange = (event) => {
    handleChange(event);

    const isMainPurposeOfJourney = R.propSatisfies(R.equals('mainPurposeOfJourney'), 'name');
    const isOtherPurposeOfJourney = R.propSatisfies(R.equals('otherPurposesOfJourney'), 'name');
    const newAllPurposesOfJourney = R.cond([
      [isMainPurposeOfJourney, R.compose(R.append(R.__, R.defaultTo([], props.otherPurposesOfJourney.value)), R.prop('value'))],
      [isOtherPurposeOfJourney, R.compose(R.append(props.mainPurposeOfJourney.value), R.prop('value'))],
    ])(event.target);

    R.unless(
      R.includes('Other'),
      () => clearFields(['form.journeyPurposeOther']),
    )(newAllPurposesOfJourney);
  };

  const purposesOfJourneyLabel = `${i18n.t('common:applicationForm.mainPurposeOfJourney')} [23]`;
  const mainPurposeOfJourneyLabel = i18n.t('common:applicationForm.mainPurposesOfJourney');
  const otherPurposesOfJourneyLabel = `${i18n.t('common:applicationForm.otherPurposesOfJourney')}`;
  const journeyPurposeOtherLabel = i18n.t('common:applicationForm.journeyPurposeOther');
  const journeyPurposeDetailsLabel =
    `${i18n.t('common:applicationForm.journeyPurposeDetails')} [24]`;

  const adsLabel = `${i18n.t('common:applicationForm.ads')}`;

  const textInputProps = inputProps('text', R.__, R.__, R.__, false, handleChange);

  return (
    <TwoColumnRow oneColumn={props.oneColumn}>
      <>
        <Label className="input-group__label" label={purposesOfJourneyLabel} />
        <InputGroup
          label={mainPurposeOfJourneyLabel}
          required
          validationError={props.mainPurposeOfJourney.validationError}
          describedBy="journeyDetailsGuide-mainPurposeOfJourney"
        >
          <CodesetSelectContainer
            name="mainPurposeOfJourney"
            value={props.mainPurposeOfJourney.value}
            codesetGroup="MainPurposesOfJourney"
            codesetPredicate={R.allPass([
              codesetNotInList(props.otherPurposesOfJourney.value),
              codesetNotOtherWhenOtherSelected(props.otherPurposesOfJourney.value),
            ])}
            onChange={handleTravelPurposeChange}
            autoFocus={props.autoFocus}
            lang={i18n.language()}
          />
        </InputGroup>
        {
          props.mainPurposeOfJourney.value === 'Other' &&
          <InputGroup
            label={journeyPurposeOtherLabel}
            required
            validationError={props.journeyPurposeOther.validationError}
          >
            <Input
              {...textInputProps(
                props.journeyPurposeOther.value,
                'journeyPurposeOther',
                null,
              )}
              uppercase
              required
            />
          </InputGroup>
        }
      </>

      <>
        <InputGroup
          label={otherPurposesOfJourneyLabel}
          validationError={props.otherPurposesOfJourney.validationError}
          describedBy="journeyDetailsGuide-mainPurposeOfJourney"
        >
          <CodesetListField
            name="otherPurposesOfJourney"
            values={props.otherPurposesOfJourney.value}
            codesetGroup="MainPurposesOfJourney"
            codesetPredicate={R.allPass([
              codesetNotEqual(props.mainPurposeOfJourney.value),
              codesetNotOtherWhenOtherSelected(props.mainPurposeOfJourney.value),
              codesetNotOtherSpecificPurpose,
            ])}
            handleChange={handleTravelPurposeChange}
            lang={i18n.language()}
          />
        </InputGroup>
        { R.includes('Other', props.otherPurposesOfJourney.value) &&
          <InputGroup
            label={journeyPurposeOtherLabel}
            validationError={props.journeyPurposeOther.validationError}
          >
            <Input
              {...textInputProps(
                props.journeyPurposeOther.value,
                'journeyPurposeOther',
                null,
              )}
              uppercase
              required
            />
          </InputGroup>}
      </>
      <InputGroup
        label={journeyPurposeDetailsLabel}
        required={props.oneDayTripWithoutAccommodation.value}
        validationError={props.journeyPurposeDetails.validationError}
        describedBy="journeyDetailsGuide-journeyPurposeDetails"
      >
        <Input
          {...textInputProps(
            props.journeyPurposeDetails.value,
            'journeyPurposeDetails',
            null,
          )}
          uppercase
          required={!!props.oneDayTripWithoutAccommodation.value}
        />
      </InputGroup>
      { props.isAdsCapable &&
        <InputGroup>
          <BooleanCheckboxGroup
            name="ads"
            label={adsLabel}
            value={props.ads.value}
            onChange={props.handleChange}
          />
        </InputGroup> }
    </TwoColumnRow>
  );
};

PurposesOfJourney.propTypes = {
  handleChange: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  mainPurposeOfJourney: formFieldShape.isRequired,
  otherPurposesOfJourney: formFieldShape.isRequired,
  journeyPurposeOther: formFieldShape.isRequired,
  journeyPurposeDetails: formFieldShape.isRequired,
  oneDayTripWithoutAccommodation: formFieldShape.isRequired,
  isAdsCapable: PropTypes.bool.isRequired,
  ads: formFieldShape,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  codesetListFieldContainer: PropTypes.elementType.isRequired,
  autoFocus: PropTypes.bool,
  oneColumn: PropTypes.bool,
};

PurposesOfJourney.defaultProps = {
  ads: null,
  autoFocus: false,
  oneColumn: false,
};

export default PurposesOfJourney;
