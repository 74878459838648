import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Label from '../Label/Label';

const LabeledContainer = (props) => {
  const labeledClassNames = classnames(
    'labeled',
    {
      'labeled--nowrap': props.noWrap,
      'labeled--indent': props.indent,
    },
    props.className,
  );

  const valueMissing = !props.children;

  const labelContainerClassNames = classnames(
    'labeled__label-container',
    { 'labeled__label-container--with-icon': props.labelIcon },
  );
  const labelClassNames = classnames(
    'labeled__label',
    {
      'labeled__label--line-through': valueMissing,
      'labeled__label--with-icon': props.labelIcon,
      'labeled__label--with-icon-first': props.iconFirst,
    },
  );

  return (
    <div className={labeledClassNames} data-cy={props.dataCy}>
      {
        (props.labelText || props.labelIcon) &&
        <div className={labelContainerClassNames}>
          { props.iconFirst && props.labelIcon }
          { props.labelText && <Label className={labelClassNames} label={props.labelText} /> }
          { !props.iconFirst && props.labelIcon }
        </div>
      }
      {props.children}
    </div>
  );
};

LabeledContainer.propTypes = {
  dataCy: PropTypes.string,
  labelText: PropTypes.string,
  labelIcon: PropTypes.element,
  iconFirst: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  noWrap: PropTypes.bool,
  indent: PropTypes.bool,
};

LabeledContainer.defaultProps = {
  dataCy: null,
  labelText: null,
  labelIcon: null,
  iconFirst: false,
  className: null,
  children: null,
  noWrap: false,
  indent: false,
};

export default LabeledContainer;
