import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import InputGroup from 'visa-frontend-common/src/components/UI/Form/InputGroup/InputGroup';
import TrimInput, { trimInputProps } from 'visa-frontend-common/src/components/UI/Form/Input/TrimInput';
import { eventToField } from 'visa-frontend-common/src/utils/commonUtils';
import { formFieldShape } from 'visa-frontend-common/src/dataModel';
import Form from 'visa-frontend-common/src/components/UI/Form/Form';

import i18n from '../../services/i18n';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import actions from './RegisterActions';

import './RegisterCodeResendForm.scss';

const emailLabel = i18n.t('register.codeResendForm.email');

class RegisterCodeResendForm extends React.Component {
  componentWillUnmount() {
    this.props.registerCodeResendClearFields(['registerCodeResendForm']);
  }

  handleInput = event => this.props.registerCodeResendSetFieldAndValidate(
    eventToField(event),
    this.props.registerCodeResendForm,
  );

  handleSubmit = () => this.props.registerCodeResendSubmit(this.props.registerCodeResendForm);

  render() {
    const trimProps = trimInputProps(R.__, R.__, null, false, this.handleInput);
    const { email } = this.props.registerCodeResendForm;

    return (
      <div className="register-code-resend-form">
        <h2>{i18n.t('register.codeResendForm.title')}</h2>
        <Form>
          <InputGroup
            label={emailLabel}
            required
            validationError={email.validationError}
            className="register-code-resend-form__email"
          >
            <TrimInput {...trimProps(email.value, 'email', null)} autoFocus={Boolean(!email.value)} />
          </InputGroup>

          <LoadingButton
            onClick={this.handleSubmit}
            title={i18n.t('register.codeResendForm.submit')}
            className="register-code-resend-form__submit-button"
            icon="arrow_forward"
            label={i18n.t('register.codeResendForm.submit')}
            dataCy="register-code-resend-form-button"
            requestNames={[actions.registerCodeResendSent().type]}
          />
        </Form>
      </div>
    );
  }
}

export default RegisterCodeResendForm;

RegisterCodeResendForm.propTypes = {
  registerCodeResendForm: PropTypes.exact({
    email: formFieldShape,
  }).isRequired,
  registerCodeResendSetFieldAndValidate: PropTypes.func.isRequired,
  registerCodeResendSubmit: PropTypes.func.isRequired,
  registerCodeResendClearFields: PropTypes.func.isRequired,
};
