import React from 'react';
import PropTypes from 'prop-types';

import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import Linkify from 'visa-frontend-common/src/components/UI/Linkify/Linkify';

import i18n from '../../services/i18n';

import './ChecklistCountrySpecificInstructions.scss';

const ChecklistCountrySpecificInstructions = (props) => {
  return (
    <>
      { props.instructions &&
        <div className="checklist-country-specific-instructions">
          <TabSubtitle type="h4" className="checklist-country-specific-instructions__subtitle">
            {i18n.t('application.applicationDocuments.instructionsTitle')}
          </TabSubtitle>
          <p>
            <Linkify>{props.instructions}</Linkify>
          </p>
        </div>}
    </>);
};

ChecklistCountrySpecificInstructions.propTypes = {
  instructions: PropTypes.string,
};

ChecklistCountrySpecificInstructions.defaultProps = {
  instructions: null,
};

export default ChecklistCountrySpecificInstructions;
