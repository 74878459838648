import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import { historyShape } from 'visa-frontend-common/src/dataModel';
import Icon from 'visa-frontend-common/src/components/UI/Icon/Icon';
import i18n from '../../../../services/i18n';

import './PreviewValidationError.scss';

const PreviewValidationError = (props) => {
  const handleProceed = () => props.history.push({ pathname: props.tabPath });

  return (
    <Button
      icon={<Icon name="arrow_back" ariaLabel={i18n.t('common:accessibleIconLabels.returnTo')} />}
      className="preview-validation-error"
      onClick={handleProceed}
      class="custom"
    >
      <div className="preview-validation-error__text-wrapper">
        { props.fieldNameLabel &&
          <p className="preview-validation-error__field-name">{props.fieldNameLabel}</p>}
        <p className="preview-validation-error__error-text">{props.validationError}</p>
      </div>
    </Button>
  );
};

PreviewValidationError.propTypes = {
  fieldNameLabel: PropTypes.string,
  validationError: PropTypes.string.isRequired,
  tabPath: PropTypes.string.isRequired,
  history: historyShape.isRequired,
};

PreviewValidationError.defaultProps = {
  fieldNameLabel: null,
};

export default withRouter(PreviewValidationError);
