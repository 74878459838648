import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { codesetCodeShape } from './codesetDataModel';

const CodesetValue = (props) => {
  const codesetCodeEquals = value => R.propEq('codesetCode', value);
  const visCodeAndVersionEquals = value => R.both(
    R.propSatisfies(R.compose(R.equals, R.head, R.split('.'))(value), 'visCode'),
    R.propSatisfies(R.compose(R.equals, R.last, R.split('.'))(value), 'visCodeVersion'),
  );

  const code = props.value && R.find(R.either(
    codesetCodeEquals(props.value),
    visCodeAndVersionEquals(props.value),
  ))(props.codesetCodes);
  const displayText = R.ifElse(
    () => props.displayText !== null,
    () => props.displayText(code),
    () => (code && code.text) || '-',
  )(code);

  if (props.disableSmallTextWrapper) {
    return displayText;
  }

  return <small>{displayText}</small>;
};

CodesetValue.propTypes = {
  codesetCodes: PropTypes.arrayOf(codesetCodeShape).isRequired,
  value: PropTypes.string,
  disableSmallTextWrapper: PropTypes.bool,
  displayText: PropTypes.func,
};

CodesetValue.defaultProps = {
  value: '',
  disableSmallTextWrapper: false,
  displayText: null,
};

export default CodesetValue;
