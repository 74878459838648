import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';

import i18n from '../../../services/i18n';
import LabeledContainer from './LabeledContainer';
import LinkButton from '../Button/LinkButton';

import './Labeled.scss';

const List = (props) => {
  return (
    <ul className="labeled__list">
      {props.values.map((item, i) => {
        const key = props.labelText + i;
        return (
          <li key={key}>
            {R.is(String, item) ? <small className="labeled__small">{item}</small> : item}
          </li>
        );
      })}
    </ul>
  );
};

List.propTypes = {
  labelText: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.node).isRequired,
};

List.defaultProps = {
  labelText: null,
};

class ToggleableList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClick = () => this.setState(prevState => ({ open: !prevState.open }));

  renderToggleButton() {
    const length = R.length(this.props.values);
    const text = `(${this.state.open ? i18n.t('common:hide') : i18n.t('common:show')})`;
    return (
      <LinkButton
        className="labeled__button"
        onClick={this.handleClick}
      >
        {`${length} ${text}`}
      </LinkButton>
    );
  }

  render() {
    return (
      <>
        {this.renderToggleButton()}
        {
          this.state.open &&
          <List
            labelText={this.props.labelText}
            values={this.props.values}
          />
        }
      </>
    );
  }
}

ToggleableList.propTypes = {
  labelText: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.node).isRequired,
};

const LabeledList = ({
  labelText,
  values,
  hideTextIfValueIsMissing,
  className,
  withBottomBorder,
  toggleable,
  noWrap,
  indent,
  dataCy,
}) => {
  const listContainerClassNames = classnames({
    'labeled__bordered-page-element': withBottomBorder,
  });
  const moreThanTwoValues = R.compose(R.gt(R.__, 2), R.length);

  // Check if the given "values" -array is empty, or if it consists just of null-values
  // and/or empty dropdown lists. Empty dropdown is CodesetValueContainer that has
  // a property value that is null.
  const allValuesAndChildrenValuesNil = R.all(R.either(
    R.isNil,
    R.ifElse(
      R.hasPath(['props', 'value']),
      R.pathSatisfies(R.isNil, ['props', 'value']),
      R.F,
    ),
  ));

  const renderValues = R.cond([
    [allValuesAndChildrenValuesNil,
      () => <small className="labeled__small">{i18n.t('common:none')}</small>],
    [R.allPass([moreThanTwoValues, () => toggleable]),
      () =>
        <ToggleableList labelText={labelText} values={values} />],
    [R.T,
      () => <List labelText={labelText} values={values} />],
  ]);

  return (
    <LabeledContainer
      labelText={labelText}
      className={className}
      noWrap={noWrap}
      indent={indent}
      dataCy={dataCy}
    >
      {(!hideTextIfValueIsMissing ||
        (hideTextIfValueIsMissing && !allValuesAndChildrenValuesNil(values))) &&
        <div className={listContainerClassNames}>
          {renderValues(values)}
        </div>}
    </LabeledContainer>
  );
};

LabeledList.propTypes = {
  dataCy: PropTypes.string,
  labelText: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.node).isRequired,
  hideTextIfValueIsMissing: PropTypes.bool,
  className: PropTypes.string,
  withBottomBorder: PropTypes.bool,
  toggleable: PropTypes.bool,
  noWrap: PropTypes.bool,
  indent: PropTypes.bool,
};

LabeledList.defaultProps = {
  dataCy: null,
  labelText: null,
  hideTextIfValueIsMissing: false,
  className: null,
  withBottomBorder: false,
  toggleable: false,
  noWrap: false,
  indent: false,
};

export default LabeledList;
