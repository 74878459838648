import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, fi, ru } from 'date-fns/locale';
import * as R from 'ramda';

import Button from '../../Button/Button';
import { date } from '../../../../services/validator/validators';
import { localLongDateStringToJsonString } from '../../../../services/datetime';
import i18n from '../../../../services/i18n';
import { isNilOrEmpty } from '../../../../utils/commonUtils';

import './DatePicker.scss';

const DatePicker = React.forwardRef((props, ref) => {
  const handleSelect = value => props.onChange({
    target: {
      name: props.name,
      value: localLongDateStringToJsonString(value),
    },
  });

  const currentLocale =
    R.cond([
      [R.equals('fi'), R.always(fi)],
      [R.equals('ru'), R.always(ru)],
      [R.T, R.always(enGB)],
    ]);

  const parseSelectedDate = R.cond([
    [isNilOrEmpty, R.identity],
    [R.compose(R.isNil, date()), value => new Date(value)],
    [R.T, R.always(null)],
  ]);

  return (
    <ReactDatePicker
      ref={ref}
      id={props.id}
      name={props.name}
      disabled={props.disabled}
      tabIndex={props.tabIndex}
      selected={parseSelectedDate(props.value)}
      locale={currentLocale(i18n.language())}
      onChange={handleSelect}
      showYearDropdown
      showMonthDropdown
      scrollableYearDropdown
      yearDropdownItemNumber={100}
      className={props.className}
      customInput={<Button icon="calendar_month" class="invisible" />}
      minDate={Date.parse(props.minDate)}
      maxDate={Date.parse(props.maxDate)}
    />
  );
});

const DatePickerShape = {
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
};

DatePicker.propTypes = DatePickerShape;

const DatePickerDefaultProps = {
  id: null,
  value: '',
  onChange: null,
  disabled: false,
  className: null,
  tabIndex: 0,
  minDate: null,
  maxDate: null,
};

DatePicker.defaultProps = DatePickerDefaultProps;

export default DatePicker;
