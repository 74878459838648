import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Icon from '../UI/Icon/Icon';

const NotificationIcon = ({ type }) => {
  const iconNamesByType = {
    [toast.TYPE.SUCCESS]: 'check_circle',
    [toast.TYPE.INFO]: 'info',
    [toast.TYPE.ERROR]: 'warning',
  };
  return <Icon name={iconNamesByType[type]} />;
};

NotificationIcon.propTypes = {
  type: PropTypes.oneOf([toast.TYPE.SUCCESS, toast.TYPE.INFO, toast.TYPE.ERROR]).isRequired,
};

export default NotificationIcon;
