import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { currentPageOrFirstPage, numberOfPagesForList } from '../../../services/pagination/pagination';
import i18n from '../../../services/i18n';
import { Selector } from '../Form/FormComponents';
import Button from '../Button/Button';

import './PaginationControl.scss';

export const mapToStringSelectorOptions = R.compose(
  R.map(value => ({ value, label: value })),
  R.map(R.toString),
);

export const currentPageSelectorOptions = lastPage => R.compose(
  mapToStringSelectorOptions,
  R.range(1, R.__),
  R.inc,
)(lastPage);

const PaginationControl = (props) => {
  const numberOfPages = numberOfPagesForList(props.rowsOnPage, props.listLength);
  const currentPage = currentPageOrFirstPage(props.currentPage, numberOfPages);
  const previousButtonDisabled = R.equals(currentPage, 1);
  const nextButtonDisable = R.equals(currentPage, numberOfPages);

  const handleRowsOnPageChange = (event) => {
    const rowsOnPageUpdated = Number(event.target.value);
    if (rowsOnPageUpdated === props.rowsOnPage) return null;
    return props.listPaginationChanged(rowsOnPageUpdated, 1);
  };

  const handleCurrentPageChangeByButton = (inc) => {
    const currentPageUpdated = (inc ? R.inc : R.dec)(currentPage);
    return props.listPaginationChanged(props.rowsOnPage, currentPageUpdated);
  };

  const handleCurrentPageChangeBySelector = (event) => {
    const currentPageUpdated = Number(event.target.value);
    if (currentPageUpdated === props.currentPage) return null;
    return props.listPaginationChanged(props.rowsOnPage, currentPageUpdated);
  };

  return (
    <div className="pagination-control">
      <div className="pagination-control__rows-on-page-section">
        <Selector
          className="pagination-control__selector"
          name="rowsOnPage"
          value={R.toString(props.rowsOnPage)}
          options={mapToStringSelectorOptions(props.rowsOnPageOptions)}
          onChange={handleRowsOnPageChange}
        />
        <span className="pagination-control__span">{i18n.t('common:rowsOnPage')}</span>
      </div>
      <div className="pagination-control__current-page-section">
        <Button
          class="custom"
          className="pagination-control__button pagination-control__button--previous"
          icon="keyboard_arrow_left"
          disabled={previousButtonDisabled}
          onClick={() => handleCurrentPageChangeByButton(false)}
        />
        <Selector
          className="pagination-control__selector"
          name="currentPage"
          value={R.toString(currentPage)}
          options={currentPageSelectorOptions(numberOfPages)}
          onChange={handleCurrentPageChangeBySelector}
        />
        <span className="pagination-control__span pagination-control__span--last-page">
          {` / ${numberOfPages}`}
        </span>
        <Button
          class="custom"
          className="pagination-control__button pagination-control__button--next"
          icon="keyboard_arrow_right"
          disabled={nextButtonDisable}
          onClick={() => handleCurrentPageChangeByButton(true)}
        />
      </div>
    </div>
  );
};

PaginationControl.propTypes = {
  listPaginationChanged: PropTypes.func.isRequired,
  rowsOnPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  rowsOnPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  listLength: PropTypes.number.isRequired,
};

export default PaginationControl;
