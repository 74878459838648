import { connect } from 'react-redux';

import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import actions from './RegisterActions';
import Register from './Register';
import {
  privacyPolicyAcceptedSelector,
  registerCodeResendFormSelector,
  registerConfirmFormSelector,
  registerEmailSentSelector,
  registerFormSelector,
  termsAcceptedSelector,
} from './RegisterReducer';

const mapStateToProps = state => ({
  registerForm: registerFormSelector(state),
  registerConfirmForm: registerConfirmFormSelector(state),
  registerCodeResendForm: registerCodeResendFormSelector(state),
  emailSent: registerEmailSentSelector(state),
  termsAccepted: termsAcceptedSelector(state),
  privacyPolicyAccepted: privacyPolicyAcceptedSelector(state),
});

const mapDispatchToProps = actions;

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Register);
