import * as R from 'ramda';
import { safeToUpper } from '../../utils/commonUtils';

export const ascendWithNil = R.curry((propertyGetter, a, b) => {
  const aIsNil = R.compose(R.isNil, propertyGetter)(a);
  const bIsNil = R.compose(R.isNil, propertyGetter)(b);
  if (aIsNil && bIsNil) return 0;
  if (aIsNil) return -1;
  if (bIsNil) return 1;
  return R.ascend(propertyGetter, a, b);
});

export const descendWithNil = R.curry((propertyGetter, a, b) => {
  const aIsNil = R.compose(R.isNil, propertyGetter)(a);
  const bIsNil = R.compose(R.isNil, propertyGetter)(b);
  if (aIsNil && bIsNil) return 0;
  if (aIsNil) return 1;
  if (bIsNil) return -1;
  return R.descend(propertyGetter, a, b);
});

const sortDirectionFor = (propertyGetter, descending = false) => R.ifElse(
  () => descending,
  descendWithNil(R.compose(safeToUpper, propertyGetter)),
  ascendWithNil(R.compose(safeToUpper, propertyGetter)),
);

export const sortByFunction = R.curry((func, isDescending, list) =>
  R.sort(sortDirectionFor(func, isDescending), list));

export const sortByColumn = R.curry((column, isDescending, list) =>
  sortByFunction(R.prop(column), isDescending, list));

export const sortByFunctionOrColumn = R.curry((columnSortFunctions, column, isDescending, list) =>
  R.ifElse(
    R.compose(R.isNil, R.prop(column)),
    R.always(sortByColumn(column, isDescending, list)),
    R.compose(sortByFunction(R.__, isDescending, list), R.prop(column)),
  )(columnSortFunctions));
