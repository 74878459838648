import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'ramda';

import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import LabeledList from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledList';
import { formAddressShape, formFieldShape, formFieldShapeFor } from 'visa-frontend-common/src/dataModel';
import { areValuesNotEmptyByKey, toStringOrNull } from 'visa-frontend-common/src/utils/commonUtils';
import { displayDate } from 'visa-frontend-common/src/services/datetime';
import LabeledEnum from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledEnum';
import { occupationCodesWithOptionalAddress } from 'visa-frontend-common/src/services/validator/commonValidationRules';

import i18n from '../../../../services/i18n';
import LabeledCode from '../../../../components/UI/labeledComponents/LabeledCode';
import PreviewValidationError from './PreviewValidationError';
import paths from '../../../../paths';
import PreviewEditLink from './PreviewEditLink';

const PreviewOccupationAndResidence = (props) => {
  const occupationAndResidencePath = paths.application.tabs.occupationAndResidence;
  const showSecondaryAddress = areValuesNotEmptyByKey(['secondaryAddress'], props);

  const applicantIsStudent = props.occupationCode.value === 'Student';
  const occupationAddressFieldsRequired =
    props.occupationCode.value &&
    !includes(props.occupationCode.value, occupationCodesWithOptionalAddress);

  const employerNameLabel = `${i18n.t('common:applicationForm.employerName')} ${occupationAddressFieldsRequired && !applicantIsStudent ? '*' : ''}`;
  const schoolNameLabel = `${i18n.t('common:applicationForm.schoolName')} ${applicantIsStudent ? '*' : ''}`;
  const employerStreetLabel = `${i18n.t('common:applicationForm.employerStreet')} ${occupationAddressFieldsRequired ? '*' : ''}`;
  const employerZipLabel = `${i18n.t('common:applicationForm.employerZip')} ${occupationAddressFieldsRequired ? '*' : ''}`;
  const employerCityLabel = `${i18n.t('common:applicationForm.employerCity')} ${occupationAddressFieldsRequired ? '*' : ''}`;
  const employerCountryLabel = `${i18n.t('common:applicationForm.employerCountry')} ${occupationAddressFieldsRequired ? '*' : ''}`;
  const employerEmailLabel = `${i18n.t('common:applicationForm.employerEmail')}`;
  const employerPhonenumberLabel = `${i18n.t('common:applicationForm.employerPhonenumber')} ${occupationAddressFieldsRequired ? '*' : ''}`;

  return (
    <div className="preview-application__section">
      <TabSubtitle type="h3" className="preview-application__subtitle">
        {i18n.t('application.previewApplication.previewResidenceSubTitle')}
      </TabSubtitle>

      { props.address.value.street.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.street')} [19] *`}
          validationError={props.address.value.street.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.street')} [19] *`}
          dataCy="labeled-address-street"
          value={props.address.value.street.value}
        />}

      { props.address.value.zip.validationError ?
        <PreviewValidationError
          fieldNameLabel={i18n.t('common:applicationForm.zip')}
          validationError={props.address.value.zip.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledValue
          labelText={i18n.t('common:applicationForm.zip')}
          dataCy="labeled-address-zip"
          value={props.address.value.zip.value}
        />}

      { props.address.value.city.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.city')} *`}
          validationError={props.address.value.city.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.city')} *`}
          dataCy="labeled-address-city"
          value={props.address.value.city.value}
        />}

      { props.address.value.country.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.country')} *`}
          validationError={props.address.value.country.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.country')} *`}
          dataCy="labeled-address-country"
          group="CountryOfNationality"
          code={props.address.value.country.value}
        />}

      { showSecondaryAddress &&
        <>
          { props.secondaryAddress.value.street.validationError ?
            <PreviewValidationError
              fieldNameLabel={`${i18n.t('common:applicationForm.secondaryStreet')} *`}
              validationError={props.secondaryAddress.value.street.validationError}
              tabPath={occupationAndResidencePath}
            /> :
            <LabeledValue
              labelText={`${i18n.t('common:applicationForm.secondaryStreet')} *`}
              dataCy="labeled-secondaryAddress-street"
              value={props.secondaryAddress.value.street.value}
            />}

          { props.secondaryAddress.value.zip.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.zip')}
              validationError={props.secondaryAddress.value.zip.validationError}
              tabPath={occupationAndResidencePath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.zip')}
              dataCy="labeled-secondaryAddress-zip"
              value={props.secondaryAddress.value.zip.value}
            />}

          { props.secondaryAddress.value.city.validationError ?
            <PreviewValidationError
              fieldNameLabel={`${i18n.t('common:applicationForm.city')} *`}
              validationError={props.secondaryAddress.value.city.validationError}
              tabPath={occupationAndResidencePath}
            /> :
            <LabeledValue
              labelText={`${i18n.t('common:applicationForm.city')} *`}
              dataCy="labeled-secondaryAddress-city"
              value={props.secondaryAddress.value.city.value}
            />}

          { props.secondaryAddress.value.country.validationError ?
            <PreviewValidationError
              fieldNameLabel={`${i18n.t('common:applicationForm.country')} *`}
              validationError={props.secondaryAddress.value.country.validationError}
              tabPath={occupationAndResidencePath}
            /> :
            <LabeledCode
              labelText={`${i18n.t('common:applicationForm.country')} *`}
              dataCy="labeled-secondaryAddress-country"
              group="CountryOfNationality"
              code={props.secondaryAddress.value.country.value}
            />}
        </>}

      { props.email.validationError ?
        <PreviewValidationError
          fieldNameLabel={i18n.t('common:applicationForm.email')}
          validationError={props.email.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledValue
          labelText={i18n.t('common:applicationForm.email')}
          dataCy="labeled-email"
          value={props.email.value}
        />}

      { props.phonenumbers.validationError &&
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.phonenumber')} *`}
          validationError={props.phonenumbers.validationError}
          tabPath={occupationAndResidencePath}
        />}
      { props.phonenumbers.value.length > 0 &&
        <LabeledList
          labelText={`${i18n.t('common:applicationForm.phonenumber')} *`}
          dataCy="labeled-phonenumber"
          values={props.phonenumbers.value}
          hideTextIfValueIsMissing
        />}

      { props.residenceInOtherCountry.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.residenceInOtherCountryField.title')} [20] *`}
          validationError={props.residenceInOtherCountry.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledEnum
          labelText={`${i18n.t('common:applicationForm.residenceInOtherCountryField.title')} [20] *`}
          dataCy="labeled-residenceInOtherCountry"
          i18nPrefix="common:applicationForm.residenceInOtherCountryField.options"
          value={toStringOrNull(props.residenceInOtherCountry.value)}
        />}

      { props.residenceInOtherCountry.value &&
        <>
          { props.residencePermitNumber.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.residencePermitNumber')}
              validationError={props.residencePermitNumber.validationError}
              tabPath={occupationAndResidencePath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.residencePermitNumber')}
              dataCy="labeled-residencePermitNumber"
              value={props.residencePermitNumber.value}
            />}

          { props.residencePermitValidUntil.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.residencePermitValidUntil')}
              validationError={props.residencePermitValidUntil.validationError}
              tabPath={occupationAndResidencePath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.residencePermitValidUntil')}
              dataCy="labeled-residencePermitValidUntil"
              value={displayDate(props.residencePermitValidUntil.value)}
            />}
        </>}

      <PreviewEditLink
        tabNameLabel={i18n.t('application.previewApplication.previewResidenceSubTitle')}
        tabPath={occupationAndResidencePath}
      />

      <TabSubtitle type="h3" className="preview-application__subtitle">
        {i18n.t('application.previewApplication.previewOccupationSubTitle')}
      </TabSubtitle>

      { props.occupationCode.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.occupationCode')} [21] *`}
          validationError={props.occupationCode.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.occupationCode')} [21] *`}
          dataCy="labeled-occupationCode"
          group="Occupation"
          code={props.occupationCode.value}
        />}

      { props.occupationCode === 'Others' &&
        <>
          { props.occupationOther.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.occupationOther')}
              validationError={props.occupationOther.validationError}
              tabPath={occupationAndResidencePath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.occupationOther')}
              dataCy="labeled-occupationOther"
              value={props.occupationOther.value}
            />}
        </>}

      <TabSubtitle type="h4" className="preview-application__subtitle">
        {`${i18n.t('common:applicationForm.occupationAddressSubtitle')} [22]`}
      </TabSubtitle>

      { !applicantIsStudent &&
        <>
          { props.employerName.validationError ?
            <PreviewValidationError
              fieldNameLabel={employerNameLabel}
              validationError={props.employerName.validationError}
              tabPath={occupationAndResidencePath}
            /> :
            <LabeledValue labelText={employerNameLabel} dataCy="labeled-employerName" value={props.employerName.value} />}
        </>}

      { applicantIsStudent &&
        <>
          { props.schoolName.validationError ?
            <PreviewValidationError
              fieldNameLabel={schoolNameLabel}
              validationError={props.schoolName.validationError}
              tabPath={occupationAndResidencePath}
            /> :
            <LabeledValue labelText={schoolNameLabel} dataCy="labeled-schoolName" value={props.schoolName.value} />}
        </>}

      { props.employerAddress.value.street.validationError ?
        <PreviewValidationError
          fieldNameLabel={employerStreetLabel}
          validationError={props.employerAddress.value.street.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledValue
          labelText={employerStreetLabel}
          value={props.employerAddress.value.street.value}
        />}

      { props.employerAddress.value.zip.validationError ?
        <PreviewValidationError
          fieldNameLabel={employerZipLabel}
          validationError={props.employerAddress.value.zip.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledValue labelText={employerZipLabel} value={props.employerAddress.value.zip.value} />}

      { props.employerAddress.value.city.validationError ?
        <PreviewValidationError
          fieldNameLabel={employerCityLabel}
          validationError={props.employerAddress.value.city.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledValue
          labelText={employerCityLabel}
          value={props.employerAddress.value.city.value}
        />}

      { props.employerAddress.value.country.validationError ?
        <PreviewValidationError
          fieldNameLabel={employerCountryLabel}
          validationError={props.employerAddress.value.country.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledCode
          labelText={employerCountryLabel}
          group="CountryOfNationality"
          code={props.employerAddress.value.country.value}
        />}

      { props.employerPhonenumber.validationError ?
        <PreviewValidationError
          fieldNameLabel={employerPhonenumberLabel}
          validationError={props.employerPhonenumber.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledValue
          labelText={employerPhonenumberLabel}
          dataCy="labeled-employerPhonenumber"
          value={props.employerPhonenumber.value}
        />}

      { props.employerEmail.validationError ?
        <PreviewValidationError
          fieldNameLabel={employerEmailLabel}
          validationError={props.employerEmail.validationError}
          tabPath={occupationAndResidencePath}
        /> :
        <LabeledValue
          labelText={employerEmailLabel}
          dataCy="labeled-employerEmail"
          value={props.employerEmail.value}
        />}

      <PreviewEditLink
        tabNameLabel={i18n.t('application.previewApplication.previewOccupationSubTitle')}
        tabPath={occupationAndResidencePath}
      />
    </div>
  );
};

PreviewOccupationAndResidence.propTypes = {
  address: formFieldShapeFor(formAddressShape).isRequired,
  secondaryAddress: formFieldShapeFor(formAddressShape).isRequired,
  email: formFieldShape.isRequired,
  phonenumbers: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  residenceInOtherCountry: formFieldShapeFor(PropTypes.bool).isRequired,
  residencePermitNumber: formFieldShape.isRequired,
  residencePermitValidUntil: formFieldShape.isRequired,
  occupationCode: formFieldShape.isRequired,
  occupationOther: formFieldShape.isRequired,
  employerName: formFieldShape.isRequired,
  schoolName: formFieldShape.isRequired,
  employerAddress: formFieldShapeFor(formAddressShape).isRequired,
  employerPhonenumber: formFieldShape.isRequired,
  employerEmail: formFieldShape.isRequired,
};

export default PreviewOccupationAndResidence;
