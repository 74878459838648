import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import TwoColumnRow from 'visa-frontend-common/src/components/UI/TwoColumnRow/TwoColumnRow';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import Subsection from 'visa-frontend-common/src/components/UI/Subsection/Subsection';
import { formFieldShape, formFieldShapeFor } from 'visa-frontend-common/src/dataModel';
import {
  DateInput,
  Input,
  InputGroup,
  RadioButtonGroup,
  enumTypesToRadioOptions, inputProps, CheckboxGroup,
} from 'visa-frontend-common/src/components/UI/Form/FormComponents';
import {
  booleanToOption,
  optionToBoolean,
  transformEventValueBeforeHandle,
} from 'visa-frontend-common/src/utils/commonUtils';

import i18n from '../../../services/i18n';

const familyRelationships = [
  {
    type: 'SPOUSE',
    text: i18n.t('applicationForm.invitations.familyRelationships.SPOUSE'),
  },
  {
    type: 'CHILD',
    text: i18n.t('applicationForm.invitations.familyRelationships.CHILD'),
  },
  {
    type: 'GRANDCHILD',
    text: i18n.t('applicationForm.invitations.familyRelationships.GRANDCHILD'),
  },
  {
    type: 'ASCENDANT',
    text: i18n.t('applicationForm.invitations.familyRelationships.ASCENDANT'),
  },
  {
    type: 'REGISTERED_PARTNERSHIP',
    text: i18n.t('applicationForm.invitations.familyRelationships.REGISTERED_PARTNERSHIP'),
  },
  {
    type: 'OTHER',
    text: i18n.t('applicationForm.invitations.familyRelationships.OTHER'),
  },
];

const EuCitizen = (props) => {
  const textInputProps = inputProps('text', R.__, R.__, R.__, false, props.handleChange);
  const CodesetSelectContainer = props.codesetSelectContainer;

  const toggleOption = label => [
    {
      keyProp: 'YES',
      value: 'true',
      label,
    }];

  const handleEUCitizenOptionChange = (event) => {
    transformEventValueBeforeHandle(props.handleChange, optionToBoolean)(event);

    if (!optionToBoolean(event.target.value)) {
      props.clearFields([
        'form.euSurname',
        'form.euFirstname',
        'form.euDateOfBirth',
        'form.euNationality',
        'form.euPassportNumber',
        'form.euFamilyRelationshipCode',
        'form.euFamilyRelationshipSpecify',
      ]);
    } else {
      // To clear the possible validation error messages in Contacts & Accommodation -page,
      // when eucitizenFamily is checked on.
      props.validateTopFieldsInContactsSection();
    }
  };

  return (
    <>
      <TabSubtitle id="applicationForm-invitations-euCitizen-subtitle" type="h3">{`${i18n.t('common:applicationForm.invitations.euCitizen.subtitle')} [17]`}</TabSubtitle>
      <div role="form" aria-labelledby="applicationForm-invitations-euCitizen-subtitle">
        <CheckboxGroup
          name="eucitizenFamily"
          value={booleanToOption(props.eucitizenFamily.value)}
          options={toggleOption(i18n.t('common:applicationForm.invitations.options.EUCITIZEN'))}
          onChange={handleEUCitizenOptionChange}
        />

        <div className="mt-4" />

        { props.eucitizenFamily.value &&
        <>
          <TwoColumnRow oneColumn={props.oneColumn}>
            <InputGroup
              label={i18n.t('common:applicationForm.invitations.euCitizen.surnameFamily')}
              required
              validationError={props.euSurname.validationError}
              describedBy="personalDetailsGuide-contactInfoGuideForField17"
            >
              <Input
                name="euSurname"
                value={props.euSurname.value}
                onChange={props.handleChange}
                type="text"
                uppercase={props.uppercase}
                required
              />
            </InputGroup>

            <InputGroup
              label={i18n.t('common:applicationForm.invitations.euCitizen.firstNamesFamily')}
              required
              validationError={props.euFirstname.validationError}
              describedBy="personalDetailsGuide-contactInfoGuideForField17"
            >
              <Input
                name="euFirstname"
                value={props.euFirstname.value}
                onChange={props.handleChange}
                type="text"
                uppercase={props.uppercase}
                required
              />
            </InputGroup>
          </TwoColumnRow>

          <TwoColumnRow oneColumn={props.oneColumn}>
            <InputGroup
              label={i18n.t('common:applicationForm.invitations.euCitizen.dateOfBirth')}
              required
              withHelp={props.useLongDates ?
                i18n.t('common:dateFormats.inputFormatLongDateDescription') :
                i18n.t('common:dateFormats.inputFormatDescription')}
              validationError={props.euDateOfBirth.validationError}
              describedBy="personalDetailsGuide-contactInfoGuideForField17"
            >
              <DateInput
                name="euDateOfBirth"
                value={props.euDateOfBirth.value}
                onChange={props.handleChange}
                useLongDates={props.useLongDates}
                required
              />
            </InputGroup>

            <InputGroup
              label={i18n.t('common:applicationForm.invitations.euCitizen.nationality')}
              required
              validationError={props.euNationality.validationError}
              describedBy="personalDetailsGuide-contactInfoGuideForField17"
            >
              <CodesetSelectContainer
                name="euNationality"
                value={props.euNationality.value}
                codesetGroup="EuropeanUnionNationality"
                onChange={props.handleChange}
              />
            </InputGroup>
          </TwoColumnRow>

          <TwoColumnRow oneColumn={props.oneColumn}>
            <InputGroup
              label={i18n.t('common:applicationForm.invitations.euCitizen.travelDocumentNumber')}
              required
              validationError={props.euPassportNumber.validationError}
              describedBy="personalDetailsGuide-contactInfoGuideForField17"
            >
              <Input
                name="euPassportNumber"
                value={props.euPassportNumber.value}
                onChange={props.handleChange}
                type="text"
                uppercase={props.uppercase}
                required
              />
            </InputGroup>
          </TwoColumnRow>

          <TwoColumnRow oneColumn={props.oneColumn}>
            <>
              <InputGroup
                label={`${i18n.t('common:applicationForm.invitations.euCitizen.familyRelationship')} [18]`}
                required
                validationError={props.euFamilyRelationshipCode.validationError}
                className="mt-4"
                describedBy="personalDetailsGuide-euFamilyRelationshipCode"
              >
                <RadioButtonGroup
                  name="euFamilyRelationshipCode"
                  value={props.euFamilyRelationshipCode.value}
                  onChange={props.handleChangeAndResetClarificationField('form.euFamilyRelationshipSpecify')}
                  options={enumTypesToRadioOptions(familyRelationships)}
                  required
                />
              </InputGroup>
              { props.euFamilyRelationshipCode.value === 'OTHER' &&
              <Subsection withoutTopMargin>
                <InputGroup
                  label={i18n.t('applicationForm.invitations.familyRelationshipSpecify')}
                  required
                  validationError={props.euFamilyRelationshipSpecify.validationError}
                >
                  <Input
                    {...textInputProps(props.euFamilyRelationshipSpecify.value, 'euFamilyRelationshipSpecify', null)}
                    uppercase={props.uppercase}
                    required
                  />
                </InputGroup>
              </Subsection>}
            </>
          </TwoColumnRow>
        </>}
      </div>
    </>
  );
};

EuCitizen.propTypes = {
  handleChange: PropTypes.func.isRequired,
  validateTopFieldsInContactsSection: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  eucitizenFamily: formFieldShapeFor(PropTypes.bool).isRequired,
  euFirstname: formFieldShape.isRequired,
  euSurname: formFieldShape.isRequired,
  euDateOfBirth: formFieldShape.isRequired,
  euPassportNumber: formFieldShape.isRequired,
  euFamilyRelationshipCode: formFieldShape.isRequired,
  euFamilyRelationshipSpecify: formFieldShape.isRequired,
  euNationality: formFieldShape.isRequired,
  handleChangeAndResetClarificationField: PropTypes.func.isRequired,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
  useLongDates: PropTypes.bool,
  uppercase: PropTypes.bool,
};

EuCitizen.defaultProps = {
  oneColumn: false,
  useLongDates: false,
  uppercase: false,
};

export default EuCitizen;
