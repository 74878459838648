import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  InputGroup,
  Input,
  inputProps,
  DateInput,
  dateInputProps,
  ListField,
} from '../UI/Form/FormComponents';
import {
  formAddressShape,
  formFieldShape,
  formFieldShapeFor,
} from '../../dataModel';
import { codesetNotEqual } from '../UI/Form/CodesetCode/codesetUtils';
import {
  isNilOrEmpty,
  shouldGuardianFieldsBeVisible,
} from '../../utils/commonUtils';
import { isGuardianNotEmpty } from '../../utils/dataModelUtils';
import Divider from '../UI/Divider/Divider';
import TwoColumnRow from '../UI/TwoColumnRow/TwoColumnRow';
import i18n from '../../services/i18n';
import Guardians from './Guardians';

const PersonalDetails = (props) => {
  const CodesetSelectContainer = props.codesetSelectContainer;
  const CodesetListField = props.codesetListFieldContainer;

  const textInputProps = inputProps('text', R.__, R.__, null, false, props.handleInput);
  const dateProps = dateInputProps(R.__, R.__, false, false, props.handleInput);
  const surnameLabel = `${i18n.t('common:applicationForm.surname')} [1]`;
  const surnameAtBirthLabel = `${i18n.t('common:applicationForm.surnameAtBirth')} [2]`;
  const firstNameLabel = `${i18n.t('common:applicationForm.firstName')} [3]`;
  const formerSurnamesLabel = i18n.t('common:applicationForm.formerSurnames');
  const dateOfBirthLabel = `${i18n.t('common:applicationForm.dateOfBirth')} [4]`;
  const placeOfBirthLabel = `${i18n.t('common:applicationForm.placeOfBirth')} [5]`;
  const countryOfBirthLabel = `${i18n.t('common:applicationForm.countryOfBirth')} [6]`;
  const currentNationalityLabel = `${i18n.t('common:applicationForm.currentNationality')} [7]`;
  const nationalityAtBirthLabel = i18n.t('common:applicationForm.nationalityAtBirth');
  const otherNationalitiesLabel = i18n.t('common:applicationForm.otherNationalities');
  const patronymicNameLabel = i18n.t('common:applicationForm.patronymicName');
  const sexLabel = `${i18n.t('common:applicationForm.sex')} [8]`;
  const maritalStatusLabel = `${i18n.t('common:applicationForm.maritalStatus')} [9]`;
  const maritalStatusSpecifyLabel = `${i18n.t('common:applicationForm.maritalStatusSpecify')} [9]`;
  const nationalIdentityNumberLabel = `${i18n.t('common:applicationForm.nationalIdentityNumber')} [11]`;

  const showGuardianFields =
    isNilOrEmpty(props.dateOfBirth.validationError) ?
      shouldGuardianFieldsBeVisible(props.dateOfBirth.value) :
      isGuardianNotEmpty(props);

  return (
    <>
      <div role="form" aria-labelledby="applicationForm-personalDetailsSubtitle">
        <TwoColumnRow oneColumn={props.oneColumn}>
          <InputGroup
            label={surnameLabel}
            required
            validationError={props.surname.validationError}
            describedBy="personalDetailsGuide-surname"
          >
            <Input {...textInputProps(props.surname.value, 'surname')} uppercase required />
          </InputGroup>

          <InputGroup
            label={surnameAtBirthLabel}
            required
            validationError={props.surnameAtBirth.validationError}
            describedBy="personalDetailsGuide-surnameAtBirth"
          >
            <Input {...textInputProps(props.surnameAtBirth.value, 'surnameAtBirth')} uppercase required />
          </InputGroup>
        </TwoColumnRow>

        <TwoColumnRow oneColumn={props.oneColumn}>
          <InputGroup
            label={formerSurnamesLabel}
            validationError={props.formerSurnamesInput.validationError ||
              props.formerSurnames.validationError}
            describedBy="personalDetailsGuide-formerSurnames"
          >
            <ListField
              name="formerSurnames"
              values={props.formerSurnames.value}
              inputName="formerSurnamesInput"
              inputField={props.formerSurnamesInput}
              handleInputFieldChange={props.handleInput}
              updateListField={props.updateListField}
              uppercase
            />
          </InputGroup>

          <InputGroup
            label={firstNameLabel}
            required
            validationError={props.firstName.validationError}
            describedBy="personalDetailsGuide-firstNames"
          >
            <Input {...textInputProps(props.firstName.value, 'firstName', null)} uppercase required />
          </InputGroup>
        </TwoColumnRow>

        <TwoColumnRow oneColumn={props.oneColumn}>
          { props.patronymicName &&
          <InputGroup
            label={patronymicNameLabel}
            validationError={props.patronymicName.validationError}
          >
            <Input {...textInputProps(props.patronymicName.value, 'patronymicName', null)} uppercase />
          </InputGroup>}

          <InputGroup
            label={dateOfBirthLabel}
            required
            withHelp={i18n.t('common:dateFormats.inputFormatLongDateDescription')}
            validationError={props.dateOfBirth.validationError}
            describedBy="personalDetailsGuide-dateOfBirth"
          >
            <DateInput
              {...dateProps(props.dateOfBirth.value, 'dateOfBirth')}
              useLongDates
              required
            />
          </InputGroup>
        </TwoColumnRow>

        <TwoColumnRow oneColumn={props.oneColumn}>
          <InputGroup
            label={placeOfBirthLabel}
            required
            validationError={props.placeOfBirth.validationError}
            describedBy="personalDetailsGuide-placeOfBirth"
          >
            <Input {...textInputProps(props.placeOfBirth.value, 'placeOfBirth', null)} uppercase required />
          </InputGroup>

          <InputGroup
            label={countryOfBirthLabel}
            required
            validationError={props.countryOfBirth.validationError}
            describedBy="personalDetailsGuide-countryOfBirth"
          >
            <CodesetSelectContainer
              name="countryOfBirth"
              value={props.countryOfBirth.value}
              codesetGroup="CountryOfNationality"
              onChange={props.handleInput}
            />
          </InputGroup>
        </TwoColumnRow>

        <TwoColumnRow oneColumn={props.oneColumn}>
          <InputGroup
            label={currentNationalityLabel}
            required
            validationError={props.currentNationality.validationError}
            describedBy="personalDetailsGuide-currentNationality"
          >
            <CodesetSelectContainer
              name="currentNationality"
              value={props.currentNationality.value}
              codesetGroup="CountryOfNationality"
              onChange={props.handleInput}
            />
          </InputGroup>

          <InputGroup
            label={nationalityAtBirthLabel}
            validationError={props.nationalityAtBirth.validationError}
            describedBy="personalDetailsGuide-nationalityAtBirth"
          >
            <CodesetSelectContainer
              name="nationalityAtBirth"
              value={props.nationalityAtBirth.value}
              codesetGroup="CountryOfNationality"
              onChange={props.handleInput}
            />
          </InputGroup>
        </TwoColumnRow>

        <TwoColumnRow oneColumn={props.oneColumn}>
          <InputGroup
            label={otherNationalitiesLabel}
            validationError={props.otherNationalities.validationError}
            describedBy="personalDetailsGuide-otherNationalities"
          >
            <CodesetListField
              name="otherNationalities"
              values={props.otherNationalities.value}
              codesetGroup="CountryOfNationality"
              codesetPredicate={codesetNotEqual(props.currentNationality.value)}
              handleChange={props.handleInput}
            />
          </InputGroup>
        </TwoColumnRow>

        <TwoColumnRow oneColumn={props.oneColumn}>
          <InputGroup
            label={sexLabel}
            required
            validationError={props.sex.validationError}
            describedBy="personalDetailsGuide-sex"
          >
            <CodesetSelectContainer
              name="sex"
              value={props.sex.value}
              codesetGroup="Gender"
              onChange={props.handleInput}
              lang={i18n.language()}
            />
          </InputGroup>

          <>
            <InputGroup
              label={maritalStatusLabel}
              required
              validationError={props.maritalStatus.validationError}
              describedBy="personalDetailsGuide-maritalStatus"
            >
              <CodesetSelectContainer
                name="maritalStatus"
                value={props.maritalStatus.value}
                codesetGroup="MaritalStatus"
                onChange={props.handleChangeAndResetClarificationField('maritalStatusSpecify')}
                lang={i18n.language()}
              />
            </InputGroup>

            {props.maritalStatus.value === 'Other' &&
            <InputGroup
              label={maritalStatusSpecifyLabel}
              required
              validationError={props.maritalStatusSpecify.validationError}
            >
              <Input {...textInputProps(props.maritalStatusSpecify.value, 'maritalStatusSpecify', null)} uppercase required />
            </InputGroup>}
          </>
        </TwoColumnRow>

        <TwoColumnRow oneColumn={props.oneColumn}>
          <InputGroup
            label={nationalIdentityNumberLabel}
            validationError={props.nationalIdentityNumber.validationError}
            describedBy="personalDetailsGuide-nationalIdentityNumber"
          >
            <Input {...textInputProps(props.nationalIdentityNumber.value, 'nationalIdentityNumber', null)} uppercase />
          </InputGroup>
        </TwoColumnRow>
      </div>

      <Divider fullWidth withTopMargin withBottomMargin />

      <Guardians
        showFields={showGuardianFields}
        clearFields={props.clearFields}
        handleChange={props.handleInput}
        guardianSurname={props.guardianSurname}
        guardianFirstName={props.guardianFirstName}
        guardianNationality={props.guardianNationality}
        guardianAddress={props.guardianAddress}
        guardianAddressIsDifferentThanApplicants={props.guardianAddressIsDifferentThanApplicants}
        guardianEmail={props.guardianEmail}
        guardianPhonenumber={props.guardianPhonenumber}
        secondaryGuardianSurname={props.secondaryGuardianSurname}
        secondaryGuardianFirstName={props.secondaryGuardianFirstName}
        secondaryGuardianNationality={props.secondaryGuardianNationality}
        secondaryGuardianAddress={props.secondaryGuardianAddress}
        secondaryGuardianAddressIsDifferentThanApplicants={
          props.secondaryGuardianAddressIsDifferentThanApplicants
        }
        secondaryGuardianEmail={props.secondaryGuardianEmail}
        secondaryGuardianPhonenumber={props.secondaryGuardianPhonenumber}
        emailRequired={props.emailRequired}
        codesetSelectContainer={props.codesetSelectContainer}
        oneColumn={props.oneColumn}
        showEmailReminder={props.showEmailReminder}
      />

    </>
  );
};

PersonalDetails.propTypes = {
  handleInput: PropTypes.func.isRequired,
  handleChangeAndResetClarificationField: PropTypes.func.isRequired,
  updateListField: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  firstName: formFieldShape.isRequired,
  surname: formFieldShape.isRequired,
  surnameAtBirth: formFieldShape.isRequired,
  formerSurnames: formFieldShape.isRequired,
  formerSurnamesInput: formFieldShape.isRequired,
  patronymicName: formFieldShape,
  dateOfBirth: formFieldShape.isRequired,
  placeOfBirth: formFieldShape.isRequired,
  countryOfBirth: formFieldShape.isRequired,
  currentNationality: formFieldShape.isRequired,
  nationalityAtBirth: formFieldShape.isRequired,
  otherNationalities: formFieldShape.isRequired,
  sex: formFieldShape.isRequired,
  maritalStatus: formFieldShape.isRequired,
  maritalStatusSpecify: formFieldShape.isRequired,
  nationalIdentityNumber: formFieldShape.isRequired,

  guardianSurname: formFieldShape.isRequired,
  guardianFirstName: formFieldShape.isRequired,
  guardianNationality: formFieldShape.isRequired,
  guardianAddress: formFieldShapeFor(formAddressShape).isRequired,
  guardianAddressIsDifferentThanApplicants: formFieldShapeFor(PropTypes.bool).isRequired,
  guardianEmail: formFieldShape.isRequired,
  guardianPhonenumber: formFieldShape.isRequired,

  secondaryGuardianSurname: formFieldShape.isRequired,
  secondaryGuardianFirstName: formFieldShape.isRequired,
  secondaryGuardianNationality: formFieldShape.isRequired,
  secondaryGuardianAddress: formFieldShapeFor(formAddressShape).isRequired,
  secondaryGuardianAddressIsDifferentThanApplicants: formFieldShapeFor(PropTypes.bool).isRequired,
  secondaryGuardianEmail: formFieldShape.isRequired,
  secondaryGuardianPhonenumber: formFieldShape.isRequired,

  emailRequired: PropTypes.bool,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  codesetListFieldContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
  showEmailReminder: PropTypes.bool,
};

PersonalDetails.defaultProps = {
  oneColumn: false,
  patronymicName: null,
  emailRequired: false,
  showEmailReminder: false,
};

export default PersonalDetails;
