import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';
import { transformEventNameBeforeHandle } from '../../../../utils/commonUtils';
import Selector, { selectorDefaultProps, selectorPropTypes } from './Selector';

import './SelectorWithOtherValueInput.scss';

export const OTHER = 'OTHER';

const isOther = R.equals(OTHER);

const SelectorWithOtherValueInput = (props) => {
  const handleChange = (event) => {
    transformEventNameBeforeHandle(props.onChange, R.always(props.name))(event);

    const isChangeToOther = isOther(event.target.value) && !isOther(props.value);
    if (isChangeToOther && props.onChangeToOther) {
      transformEventNameBeforeHandle(props.onChangeToOther, R.always(props.name))(event);
    }
  };
  const { children, requestedOptionLabelOtherPostfix, ...selectorPassThroughProps } = props;
  const OtherInput = React.Children.only(children);

  const isRequestedValueOther = props.requestedOptionValue && isOther(props.requestedOptionValue);

  const requestedOptionLabelPostfix = isRequestedValueOther && requestedOptionLabelOtherPostfix ?
    requestedOptionLabelOtherPostfix : props.requestedOptionLabelPostfix;

  const selectorClassName = classnames(
    'selector-with-other-value-input',
    { 'selector-with-other-value-input--other-visible': isOther(props.value) },
    props.className,
  );

  return (
    <>
      <Selector
        {...selectorPassThroughProps}
        name={`${props.name}Option`}
        className={selectorClassName}
        requestedOptionValue={isRequestedValueOther ? OTHER : props.requestedOptionValue}
        requestedOptionLabelPostfix={requestedOptionLabelPostfix}
        onChange={handleChange}
      />
      {
        isOther(props.value) &&
        React.cloneElement(OtherInput, {
          name: `${children.props.name}Other`,
          onChange: transformEventNameBeforeHandle(
            children.props.onChange,
            R.always(children.props.name),
          ),
          invalid: props.invalid,
          required: props.required,
          describedBy: props.describedBy,
        })
      }
    </>
  );
};

SelectorWithOtherValueInput.propTypes = {
  ...selectorPropTypes,
  children: PropTypes.element.isRequired,
  onChangeToOther: PropTypes.func,
};

SelectorWithOtherValueInput.defaultProps = {
  ...selectorDefaultProps,
  onChangeToOther: null,
};

export default SelectorWithOtherValueInput;
