import React from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';

import { historyShape } from 'visa-frontend-common/src/dataModel';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import Icon from 'visa-frontend-common/src/components/UI/Icon/Icon';
import { fullName } from 'visa-frontend-common/src/utils/dataModelUtils';

import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';
import Loading from '../../components/UI/Loading/LoadingContainer';
import i18n from '../../services/i18n';
import paths from '../../paths';
import { completedApplicationShape } from '../../ovafModel';
import actions from './ApplicationActions';

import './ApplicationFinalizedPage.scss';

class ApplicationFinalizedPage extends React.Component {
  componentDidMount() {
    this.props.fetchFinalizedApplication(this.props.pathId);
  }

  redirectUserToFrontPage = () => {
    this.props.history.push(generatePath(paths.root));
  };

  printApplication = (applicationId) => {
    if (this.props.finalizedApplication.espLocation === 'IRN') {
      this.props.printApplicationInBrowser(applicationId);
    } else {
      this.props.printApplication(applicationId);
    }
  };

  render() {
    return (
      <Loading requestNames={[actions.finalizedApplicationFetched().type]}>
        {this.props.finalizedApplication &&
          <div className="container application-finalized-page">
            <h1 className="application-finalized-page__title">
              <Icon className="application-finalized-page__icon" name="check_circle" />
              {i18n.t('application.finalized.title', {
                name: fullName(this.props.finalizedApplication.surname,
                  this.props.finalizedApplication.firstName),
              })}
            </h1>
            <p className="application-finalized-page__reminder">{i18n.t('application.finalized.modifyReminder')}</p>
            <h2 className="application-finalized-page__title">{i18n.t('application.finalized.stepsTitle')}</h2>
            <ol className="application-finalized-page__steps">
              <li>{i18n.t('application.finalized.step1')}</li>
              <li>{i18n.t('application.finalized.step2')}</li>
              <li>{i18n.t('application.finalized.step3')}</li>
            </ol>
            <LoadingButton
              icon="print"
              onClick={() => this.printApplication(this.props.pathId)}
              label={i18n.t('frontPage.printApplication')}
              dataCy={`application-list-item-${this.props.pathId}-print-button`}
              requestNames={[
                actions.createDownloadingApplicationPdfBlobAction(
                  this.props.pathId,
                )().type,
              ]}
              className="application-list-item__print-button"
            />
            <Button
              class="secondary"
              className="navigation-button"
              onClick={this.redirectUserToFrontPage}
              label={i18n.t('application.finalized.return')}
              dataCy="navigation-homepage-button"
            />
            <p className="application-finalized-page__reminder">{i18n.t('application.finalized.printReminder')}</p>
          </div>}
      </Loading>
    );
  }
}

ApplicationFinalizedPage.propTypes = {
  history: historyShape.isRequired,
  pathId: PropTypes.string,
  finalizedApplication: completedApplicationShape,
  printApplicationInBrowser: PropTypes.func.isRequired,
  printApplication: PropTypes.func.isRequired,
  fetchFinalizedApplication: PropTypes.func.isRequired,
};

ApplicationFinalizedPage.defaultProps = {
  pathId: null,
  finalizedApplication: null,
};

export default ApplicationFinalizedPage;
