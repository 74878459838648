import { formTabDefaultProps, formTabShape } from '../../dataModel';

export const applicationFormTabs = {
  personalDetails: formTabShape.isRequired,
  travelDocuments: formTabShape.isRequired,
  occupationAndResidence: formTabShape.isRequired,
  journeyDetails: formTabShape.isRequired,
  contactsAndAccommodation: formTabShape.isRequired,
};

export const applicationFormTabsDefaultProps = {
  personalDetails: formTabDefaultProps,
  travelDocuments: formTabDefaultProps,
  occupationAndResidence: formTabDefaultProps,
  journeyDetails: formTabDefaultProps,
  contactsAndAccommodation: formTabDefaultProps,
};
