import React from 'react';
import PropTypes from 'prop-types';

import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import LabeledList from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledList';
import { displayDate } from 'visa-frontend-common/src/services/datetime';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import { formAddressShape, formFieldShape, formFieldShapeFor } from 'visa-frontend-common/src/dataModel';
import { shouldGuardianFieldsBeVisible } from 'visa-frontend-common/src/utils/commonUtils';

import i18n from '../../../../services/i18n';
import LabeledCode from '../../../../components/UI/labeledComponents/LabeledCode';
import LabeledCodeList from '../../../../components/UI/labeledComponents/LabeledCodeList';
import PreviewValidationError from './PreviewValidationError';
import paths from '../../../../paths';
import PreviewGuardians from './PreviewGuardians';
import PreviewEuCitizenView from './PreviewEuCitizenView';
import PreviewEditLink from './PreviewEditLink';

const PreviewPersonalDetails = (props) => {
  const personalDetailsPath = paths.application.tabs.personalDetails;
  const showGuardianFields = shouldGuardianFieldsBeVisible(props.dateOfBirth.value);

  return (
    <div className="preview-application__section">
      <TabSubtitle type="h3" className="preview-application__subtitle">
        {i18n.t('common:applicationForm.personalDetailsSubtitle')}
      </TabSubtitle>

      { props.surname.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.surname')} [1] *`}
          validationError={props.surname.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.surname')} [1] *`}
          dataCy="labeled-surname"
          value={props.surname.value}
        />}

      { props.surnameAtBirth.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.surnameAtBirth')} [2] *`}
          validationError={props.surnameAtBirth.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.surnameAtBirth')} [2] *`}
          dataCy="labeled-surnameAtBirth"
          value={props.surnameAtBirth.value}
        />}

      { props.formerSurnames.validationError ?
        <PreviewValidationError
          fieldNameLabel={i18n.t('common:applicationForm.formerSurnames')}
          validationError={props.formerSurnames.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledList
          labelText={i18n.t('common:applicationForm.formerSurnames')}
          dataCy="labeled-formerSurnames"
          values={props.formerSurnames.value}
          hideTextIfValueIsMissing
          className="preview-application__labeled-list"
        />}

      { props.firstName.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.firstName')} [3] *`}
          validationError={props.firstName.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.firstName')} [3] *`}
          dataCy="labeled-firstName"
          value={props.firstName.value}
        />}

      { props.dateOfBirth.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.dateOfBirth')} [4] *`}
          validationError={props.dateOfBirth.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.dateOfBirth')} [4] *`}
          dataCy="labeled-dateOfBirth"
          value={displayDate(props.dateOfBirth.value)}
        />}

      { props.placeOfBirth.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.placeOfBirth')} [5] *`}
          validationError={props.placeOfBirth.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.placeOfBirth')} [5] *`}
          dataCy="labeled-placeOfBirth"
          value={props.placeOfBirth.value}
        />}

      { props.countryOfBirth.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.countryOfBirth')} [6] *`}
          validationError={props.countryOfBirth.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.countryOfBirth')} [6] *`}
          dataCy="labeled-countryOfBirth"
          group="CountryOfNationality"
          code={props.countryOfBirth.value}
        />}

      { props.currentNationality.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.currentNationality')} [7] *`}
          validationError={props.currentNationality.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.currentNationality')} [7] *`}
          dataCy="labeled-currentNationality"
          group="CountryOfNationality"
          code={props.currentNationality.value}
        />}

      <LabeledCode
        labelText={`${i18n.t('common:applicationForm.nationalityAtBirth')}`}
        dataCy="labeled-nationalityAtBirth"
        group="CountryOfNationality"
        code={props.nationalityAtBirth.value}
      />

      <LabeledCodeList
        labelText={`${i18n.t('common:applicationForm.otherNationalities')}`}
        group="CountryOfNationality"
        value={props.otherNationalities.value}
      />

      { props.sex.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.sex')} [8] *`}
          validationError={props.sex.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.sex')} [8] *`}
          dataCy="labeled-sex"
          group="Gender"
          code={props.sex.value}
        />}

      <>
        { props.maritalStatus.validationError ?
          <PreviewValidationError
            fieldNameLabel={`${i18n.t('common:applicationForm.maritalStatus')} [9] *`}
            validationError={props.maritalStatus.validationError}
            tabPath={personalDetailsPath}
          /> :
          <LabeledCode
            labelText={`${i18n.t('common:applicationForm.maritalStatus')} [9] *`}
            dataCy="labeled-maritalStatus"
            group="MaritalStatus"
            code={props.maritalStatus.value}
          />}
        { props.maritalStatus.value === 'Other' &&
          <>
            { props.maritalStatusSpecify.validationError ?
              <PreviewValidationError
                fieldNameLabel={`${i18n.t('common:applicationForm.placeOfBirth')} [5] *`}
                validationError={props.maritalStatusSpecify.validationError}
                tabPath={personalDetailsPath}
              /> :
              <LabeledValue
                labelText={i18n.t('common:applicationForm.maritalStatusSpecify')}
                dataCy="labeled-maritalStatusSpecify"
                value={props.maritalStatusSpecify.value}
              />}
          </>}
      </>

      { props.nationalIdentityNumber.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.nationalIdentityNumber')} [11]`}
          validationError={props.nationalIdentityNumber.validationError}
          tabPath={personalDetailsPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.nationalIdentityNumber')} [11]`}
          dataCy="labeled-nationalIdentityNumber"
          value={props.nationalIdentityNumber.value}
        />}

      { showGuardianFields &&
        <PreviewGuardians
          guardianSurname={props.guardianSurname}
          guardianFirstName={props.guardianFirstName}
          guardianNationality={props.guardianNationality}
          guardianAddress={props.guardianAddress}
          guardianAddressIsDifferentThanApplicants={
            props.guardianAddressIsDifferentThanApplicants
          }
          guardianEmail={props.guardianEmail}
          guardianPhonenumber={props.guardianPhonenumber}
          secondaryGuardianSurname={props.secondaryGuardianSurname}
          secondaryGuardianFirstName={props.secondaryGuardianFirstName}
          secondaryGuardianNationality={props.secondaryGuardianNationality}
          secondaryGuardianAddress={props.secondaryGuardianAddress}
          secondaryGuardianAddressIsDifferentThanApplicants={
            props.secondaryGuardianAddressIsDifferentThanApplicants
          }
          secondaryGuardianEmail={props.secondaryGuardianEmail}
          secondaryGuardianPhonenumber={props.secondaryGuardianPhonenumber}
        />}

      { props.eucitizenFamily.value &&
        <PreviewEuCitizenView
          euSurname={props.euSurname}
          euFirstname={props.euFirstname}
          euDateOfBirth={props.euDateOfBirth}
          euPassportNumber={props.euPassportNumber}
          euFamilyRelationshipCode={props.euFamilyRelationshipCode}
          euFamilyRelationshipSpecify={props.euFamilyRelationshipSpecify}
          euNationality={props.euNationality}
        />}

      <PreviewEditLink
        tabNameLabel={i18n.t('application.tabs.personalDetails')}
        tabPath={personalDetailsPath}
      />
    </div>
  );
};

PreviewPersonalDetails.propTypes = {
  firstName: formFieldShape.isRequired,
  surname: formFieldShape.isRequired,
  surnameAtBirth: formFieldShape.isRequired,
  formerSurnames: formFieldShape.isRequired,
  dateOfBirth: formFieldShape.isRequired,
  placeOfBirth: formFieldShape.isRequired,
  countryOfBirth: formFieldShape.isRequired,
  currentNationality: formFieldShape.isRequired,
  nationalityAtBirth: formFieldShape.isRequired,
  otherNationalities: formFieldShape.isRequired,
  sex: formFieldShape.isRequired,
  maritalStatus: formFieldShape.isRequired,
  maritalStatusSpecify: formFieldShape.isRequired,
  nationalIdentityNumber: formFieldShape.isRequired,

  guardianSurname: formFieldShape.isRequired,
  guardianFirstName: formFieldShape.isRequired,
  guardianNationality: formFieldShape.isRequired,
  guardianAddress: formFieldShapeFor(formAddressShape).isRequired,
  guardianAddressIsDifferentThanApplicants: formFieldShapeFor(PropTypes.bool).isRequired,
  guardianEmail: formFieldShape.isRequired,
  guardianPhonenumber: formFieldShape.isRequired,

  secondaryGuardianSurname: formFieldShape.isRequired,
  secondaryGuardianFirstName: formFieldShape.isRequired,
  secondaryGuardianNationality: formFieldShape.isRequired,
  secondaryGuardianAddress: formFieldShapeFor(formAddressShape).isRequired,
  secondaryGuardianAddressIsDifferentThanApplicants: formFieldShapeFor(PropTypes.bool).isRequired,
  secondaryGuardianEmail: formFieldShape.isRequired,
  secondaryGuardianPhonenumber: formFieldShape.isRequired,

  eucitizenFamily: formFieldShape.isRequired,
  euFirstname: formFieldShape.isRequired,
  euSurname: formFieldShape.isRequired,
  euDateOfBirth: formFieldShape.isRequired,
  euPassportNumber: formFieldShape.isRequired,
  euFamilyRelationshipCode: formFieldShape.isRequired,
  euFamilyRelationshipSpecify: formFieldShape.isRequired,
  euNationality: formFieldShape.isRequired,
};

export default PreviewPersonalDetails;
