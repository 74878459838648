import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Section.scss';

const Section = (props) => {
  return (
    <div className={classnames('section', props.className)}>
      {props.children}
    </div>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Section.defaultProps = {
  className: null,
};

export default Section;
