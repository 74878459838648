import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';

import {
  validatingSetFieldThunk,
  validatingSubmitFormThunk,
} from 'visa-frontend-common/src/services/validator/validatorActions';
import { notifySuccess } from 'visa-frontend-common/src/services/pushNotificationService';

import rules from './RegisterValidationRules';

import i18n from '../../services/i18n';
import { register, registerConfirm, resendConfirmationCode } from '../../services/registerService';
import paths from '../../paths';

const registerSent = createAction('REGISTER_SENT_COMPLETED');
const registerFormFieldSet = createAction('REGISTER_FORM_FIELD_SET');

const registerConfirmSent = createAction('REGISTER_CONFIRM_SENT_COMPLETED');
const registerConfirmFormFieldSet = createAction('REGISTER_CONFIRM_FORM_FIELD_SET');

const registerCodeResendSent = createAction('REGISTER_CODE_RESEND_SENT_COMPLETED');
const registerCodeResendFormFieldSet = createAction('REGISTER_CODE_RESEND_FORM_FIELD_SET');

const registerSetFieldAndValidate = validatingSetFieldThunk(registerFormFieldSet, rules);
const registerConfirmSetFieldAndValidate =
  validatingSetFieldThunk(registerConfirmFormFieldSet, rules);
const registerCodeResendSetFieldAndValidate =
  validatingSetFieldThunk(registerCodeResendFormFieldSet, rules);

const registerClearFields = createAction('REGISTER_CLEAR_FORM_FIELDS');
const registerConfirmClearFields = createAction('REGISTER_CONFIRM_CLEAR_FORM_FIELDS');
const registerCodeResendClearFields = createAction('REGISTER_CODE_RESEND_CLEAR_FORM_FIELDS');

const registerEmailSentSet = createAction('REGISTER_EMAIL_SENT_SET');

const termsAcceptedSet = createAction('REGISTER_TERMS_ACCEPTED_SET');
const privacyPolicyAcceptedSet = createAction('REGISTER_PRIVACY_POLICY_ACCEPTED_SET');

const registerSubmit = form => (dispatch) => {
  if (window.mtcaptcha.getStatus('mtcaptcha-1').isVerified) {
    validatingSubmitFormThunk(
      registerFormFieldSet,
      register,
      registerSent,
      rules,
    )(form, window.mtcaptcha.getVerifiedToken('mtcaptcha-1'))(dispatch)
      .then(
        (result) => {
          if (!R.is(Error)(result)) {
            return Promise.resolve(
              dispatch(
                registerConfirmFormFieldSet({ email: { value: form.email.value } }, form),
              ),
            ).then(() => {
              dispatch(registerEmailSentSet(form.email.value));
              dispatch(push(`${paths.register.codeSent}`));
              notifySuccess('register-success', i18n.t('register.registerForm.successNotification'));
            });
          }
          return result;
        },
      );
  } else {
    try {
      window.mtcaptcha.showMandatory();
    } catch {
      // Suppress errors
    }
  }
};

const registerConfirmSubmit = form => dispatch =>
  validatingSubmitFormThunk(
    registerConfirmFormFieldSet,
    registerConfirm,
    registerConfirmSent,
    rules,
  )(form)(dispatch)
    .then(R.ifElse(
      R.is(Error),
      err => err,
      () => {
        R.compose(dispatch, push)(`${paths.root}`);
        notifySuccess('register-confirm-success', i18n.t('register.confirmRegisterForm.successNotification'));
      },
    ));

const registerCodeResendSubmit = form => (dispatch) => {
  validatingSubmitFormThunk(
    registerCodeResendFormFieldSet,
    resendConfirmationCode,
    registerCodeResendSent,
    rules,
  )(form)(dispatch)
    .then(R.unless(R.is(Error), () => {
      dispatch(registerEmailSentSet(form.email.value));
      R.compose(dispatch, push)(`${paths.register.codeSent}`);
      notifySuccess('resend-confirmation-code-success', i18n.t('register.registerForm.successNotification'));
    }));
};

const actions = {
  registerSent,
  registerFormFieldSet,
  registerSetFieldAndValidate,
  registerClearFields,
  registerSubmit,

  registerConfirmSent,
  registerConfirmSetFieldAndValidate,
  registerConfirmFormFieldSet,
  registerConfirmClearFields,
  registerConfirmSubmit,

  registerCodeResendSent,
  registerCodeResendSetFieldAndValidate,
  registerCodeResendFormFieldSet,
  registerCodeResendClearFields,
  registerCodeResendSubmit,

  registerEmailSentSet,
  termsAcceptedSet,
  privacyPolicyAcceptedSet,
};

export default actions;
