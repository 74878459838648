import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './LinkButton.scss';

const LinkButton = React.forwardRef((props, ref) => (
  <button
    id={props.id}
    ref={ref}
    type="button"
    className={classNames(
      'link-button',
      props.className,
    )}
    disabled={props.disabled}
    data-cy={props.dataCy}
    aria-expanded={props.ariaExpanded}
    onClick={props.onClick}
    onKeyUp={props.onKeyUp}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    tabIndex={props.tabIndex}
    title={props.title}
    autoFocus={props.autoFocus}
  >
    {props.children}
  </button>
));

LinkButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  dataCy: PropTypes.string,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  autoFocus: PropTypes.bool,
  ariaExpanded: PropTypes.bool,
};

LinkButton.defaultProps = {
  id: null,
  className: null,
  onClick: null,
  onKeyUp: null,
  onMouseEnter: null,
  onMouseLeave: null,
  disabled: false,
  children: null,
  dataCy: null,
  tabIndex: 0,
  title: null,
  autoFocus: false,
  ariaExpanded: null,
};

export default LinkButton;
