import * as R from 'ramda';
import { formFieldDefaultProps } from '../dataModel';
import { isUnderage } from '../services/datetime';

export const toInt = R.curry(parseInt)(R.__, 10);
export const notAnArray = R.compose(R.not, R.is(Array));
export const notAnObject = R.compose(R.not, R.is(Object));
export const notNilOrEmpty = R.compose(R.not, R.either(R.isNil, R.isEmpty));
export const notAFile = R.compose(R.not, R.equals('File'), R.type);
export const isNilOrEmpty = R.either(R.isNil, R.isEmpty);
export const isNumeric = value => !Number.isNaN(parseFloat(value));
export const isFormField = R.has('isValid');
export const isFormObject = R.compose(R.all(isFormField), R.values);
export const isObjectArray = R.both(R.is(Array), R.all(R.is(Object)));
export const isObjectNotArrayNorString = R.allPass([
  R.is(Object),
  notAnArray,
  R.compose(R.not, R.is(String)),
]);
export const isNestedForm =
  R.allPass([isObjectNotArrayNorString, notNilOrEmpty, notAFile, isFormObject]);

export const toStringOrNull = R.unless(R.is(String), R.unless(R.isNil, R.toString));
// Doesn't throw on null or non-strings
export const safeToUpper = R.when(R.is(String), R.toUpper);
export const safeCapitalize = R.when(R.is(String), R.replace(/^./, R.toUpper));

export const replaceCommaWithDot = R.when(R.is(String), R.replace(',', '.'));

export const pickValues = R.map(R.prop('value'));
export const pickValuesDeep = R.map(R.compose(
  R.when(
    isNestedForm,
    value => pickValuesDeep(value), // js requires the additional lamda in recursion
  ),
  R.when(
    isObjectArray, // nested form array
    values => R.map(pickValuesDeep, values), // js requires the additional lamda in recursion
  ),
  R.prop('value'),
));

export const pickIsValidsDeep = R.map(R.compose(
  R.cond([
    [R.compose(isNestedForm, R.prop('value')), value => R.compose(pickIsValidsDeep, R.prop('value'))(value)],
    [R.compose(isObjectArray, R.prop('value')), values => R.compose(R.map(pickIsValidsDeep), R.prop('value'))(values)],
    [R.T, R.prop('isValid')],
  ]),
));

const conversionExceptions = ['fingerprints'];
export const convertToForm = R.mapObjIndexed(R.cond([
  [(value, key) => R.includes(key, conversionExceptions), value => R.assoc('value', value, formFieldDefaultProps)],
  [isObjectArray, values => R.assoc('value', R.map(convertToForm, values), formFieldDefaultProps)],
  [R.allPass([isObjectNotArrayNorString, notNilOrEmpty, notAFile]), value => R.assoc('value', convertToForm(value), formFieldDefaultProps)],
  [R.T, value => R.assoc('value', value, formFieldDefaultProps)],
]));

export const assocBooleanFieldByPaths = (field, selectors) => {
  const allValuesNull = R.converge(
    R.unapply(R.compose(R.isEmpty, R.filter(e => e), R.flatten)),
    selectors,
  );
  return R.ifElse(allValuesNull, R.assoc(field, false), R.assoc(field, true));
};

export const pickFirstKey = R.compose(R.head, R.keys);
export const nameToFormPath = R.compose(R.intersperse('value'), R.split('.'));

// Converts event to simple {name: value} object derived from target
export const eventToObject = R.converge(R.objOf, [R.path(['target', 'name']), R.path(['target', 'value'])]);
// Converts event to object with form structure
export const eventToField = R.compose(
  R.converge(
    R.assocPath(R.__, R.__, {}),
    [R.compose(R.append('value'), nameToFormPath, R.prop('name')), R.prop('value')],
  ),
  R.prop('target'),
);

const transformEventPathBeforeHandle = path => R.curry((handleChange, transformFunction) =>
  R.compose(
    handleChange,
    R.over(R.lensPath(path), transformFunction),
  ));
export const transformEventValueBeforeHandle = transformEventPathBeforeHandle(['target', 'value']);
export const transformEventNameBeforeHandle = transformEventPathBeforeHandle(['target', 'name']);

// Moves data inside object from one location to the other, removes the old location
export const movePath = R.curry((oldPath, newPath, obj) => R.compose(
  R.dissocPath(oldPath),
  R.over(R.lens(R.path(oldPath), R.assocPath(newPath)), R.identity),
)(obj));

export const limitLengthWithEllipsis = R.curry((maxLength, text) => R.when(
  R.compose(R.both(R.always(R.gt(maxLength, 0)), R.lte(maxLength)), R.length),
  R.compose(R.append('\u2026'), R.trim, R.take(maxLength)),
)(text));

export const limitLengthWithEllipsisAndSuffix = textLength => R.converge(
  R.concat,
  [
    R.compose(
      R.unless(R.is(String), R.join('')),
      R.converge(
        limitLengthWithEllipsis,
        [R.compose(R.subtract(textLength), R.length, R.last), R.head],
      ),
    ),
    R.last,
  ],
);
/**
 * Converts boolean value to string.
 * @param {*} boolValue - true, false, or null.
 */
export const booleanToString = (boolValue) => {
  switch (boolValue) {
    case true:
      return 'true';
    case false:
      return 'false';
    case null:
      return 'null';
    default:
      throw new Error(`unknown boolean value: ${boolValue}`);
  }
};

/**
 * Converts string value to a boolean.
 * @param {*} stringValue - "true", "false", or "null"
 */
export const stringToBoolean = (stringValue) => {
  if (typeof stringValue !== 'string') {
    throw new Error(`expected a string, but got: ${stringValue}`);
  }

  switch (stringValue) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'null':
      return null;
    default:
      throw new Error(`unknown string representation of boolean value: ${stringValue}`);
  }
};

export const booleanToOption = (value) => {
  return value ? [booleanToString(value)] : [];
};

export const optionToBoolean = (options) => {
  return R.isEmpty(options) ? false : stringToBoolean(options[0]);
};

export const singleBooleanOption = label => [
  {
    keyProp: 'YES',
    value: 'true',
    label,
  }];

export const countNumbers = (beginNumber, endNumber) => {
  // 'BitInt' is not defined  no-undef, even with newest Chrome...

  if (!beginNumber || !endNumber) {
    return '';
  }

  if (beginNumber.length > 15 ||
      endNumber.length > 15) {
    return '?';
  }

  const end = Number(endNumber);
  const begin = Number(beginNumber);

  if (end < begin) {
    return '-';
  }

  return (end - begin) + 1;
};

export const toBase64 = file => new Promise((resolve, reject) => {
  if (file == null) {
    resolve(null);
  } else {
    const removeDeclaration = R.compose(R.last, R.split('base64,'));
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => R.compose(resolve, removeDeclaration, R.prop('result'))(reader);
    reader.onerror = error => reject(error);
  }
});

// https://stackoverflow.com/a/16245768
export const b64toFile = (b64Data, name = '', contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new File(byteArrays, name, { type: contentType });
};

export const flattenObject = R.curry((object, preserveArrays) => {
  const flatten = object_ => R.chain(([key, value]) => {
    const allowedType = R.ifElse(
      R.always(preserveArrays),
      R.equals('Object'),
      R.either(R.equals('Object'), R.equals('Array')),
    )(R.type(value));
    if (allowedType) {
      return R.map(([key_, value_]) => [`${key}.${key_}`, value_], flatten(value));
    }
    return [[key, value]];
  }, R.toPairs(object_));
  return R.compose(
    R.fromPairs,
    flatten,
  )(object);
});

export const areValuesNotEmptyByKey = (keys, object) => R.compose(
  R.complement(R.isEmpty),
  R.reject(R.isNil),
  R.values,
  flattenObject(R.__, false),
  pickValuesDeep,
  R.pick(keys),
)(object);

// Returns true if string is a substring of any of the objects (nested) values, case insensitive
// Doesn't filter out booleans/nulls/etc
export const stringIncludedInObject = string => R.compose(
  R.includes(R.toLower(R.defaultTo('', string))),
  R.toLower,
  R.toString,
  R.values,
  flattenObject(R.__, false),
);

export const areFormFieldsEmpty = R.compose(
  R.complement(R.isEmpty),
  R.filter(R.isNil),
  flattenObject(R.__, false),
  pickValuesDeep,
);

export const getQueryParam = R.curry((location, name) => {
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get(name);
});

export const getDecodedQueryParam = (location, name) => R.compose(
  R.unless(R.isNil, decodeURIComponent),
  getQueryParam(location),
)(name);

export const arrayIncludesProperty = R.curry((array, property) => R.compose(
  R.includes(property),
  R.defaultTo([]),
)(array));

export const getQueryParams = R.curry((location, names) => {
  const queryParamsEntries = new URLSearchParams(location.search).entries();
  return R.compose(
    R.when(R.isEmpty, R.always(null)),
    R.pick(names),
    R.fromPairs,
    Array.from,
  )(queryParamsEntries);
});

export const handleChangeAndResetClarificationField =
  (handleChange, clearFields) => clarificationField => (event) => {
    handleChange(event);
    R.unless(
      R.either(R.equals('Other'), R.equals('Others')),
      () => clearFields([clarificationField]),
    )(event.target.value);
  };

export const previousAndNextTab = (
  currentTab,
  tabList,
) => {
  const currentTabIndex = R.findIndex(R.equals(currentTab), tabList);
  const defaultToNull = R.defaultTo(null);
  const previousTab = R.compose(
    defaultToNull,
    R.head,
    R.slice(R.dec(currentTabIndex), currentTabIndex),
  )(tabList);
  const nextTab = R.compose(
    defaultToNull,
    R.head,
    R.slice(R.inc(currentTabIndex), R.add(currentTabIndex, 2)),
  )(tabList);
  return { previousTab, nextTab };
};

export const shouldGuardianFieldsBeVisible = (dateOfBirth, dateOfRequest = null) => {
  return Boolean(dateOfBirth && isUnderage(dateOfBirth, dateOfRequest));
};

export const getFileNameExtension = (fileName) => {
  const lastDot = fileName.lastIndexOf('.');
  return fileName.substring(lastDot + 1);
};

/**
 * This is the modulo function.
 * Javascripts % operator doesn't calculate the modulo correctly for negative values.
 * Hence the need for a custom method
 * https://en.wikipedia.org/wiki/Modulo_operation
 *
 * @param {the value to be divided} value
 * @param {the modulus (divider)} modulus
 * @returns the remainder of the Euclidian division (non-negative remainder)
 */
export const mod = (value, modulus) => ((value % modulus) + modulus) % modulus;

export const isAsteriskInLabel = R.ifElse(
  R.isNil,
  R.F,
  R.compose(
    R.equals('*'),
    label => label.slice(-1),
  ),
);

export const mapPropByProp = (prop, byProp, allType) => R.compose(
  R.map(R.compose(
    R.when(R.compose(R.lt(1), R.length), R.prepend(allType)),
    R.uniq,
  )),
  (object) => {
    const allValues = R.compose(R.uniq, R.flatten, R.values)(object);
    return R.assoc(allType, allValues, object);
  },
  R.reduceBy(
    (acc, element) => R.append(R.prop(prop, element))(acc),
    [],
    R.prop(byProp),
  ),
);
