import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import { displayDate } from 'visa-frontend-common/src/services/datetime';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import { formFieldShape, formFieldShapeFor } from 'visa-frontend-common/src/dataModel';

import i18n from '../../../../services/i18n';
import LabeledCode from '../../../../components/UI/labeledComponents/LabeledCode';
import LabeledCodeList from '../../../../components/UI/labeledComponents/LabeledCodeList';
import PreviewValidationError from './PreviewValidationError';
import paths from '../../../../paths';
import PreviewEditLink from './PreviewEditLink';
import PreviewEntryPermitView from './PreviewEntryPermitView';

const PreviewJourneyDetails = (props) => {
  const journeyDetailsPath = paths.application.tabs.journeyDetails;

  const journeyPurposeDetailsLabel =
    `${i18n.t('common:applicationForm.journeyPurposeDetails')} [24]${props.oneDayTripWithoutAccommodation.value ? ' *' : ''}`;

  const dateOfArrivalLabel = `${i18n.t('common:applicationForm.dateOfArrivalToSchengenArea')} *`;
  const dateOfDepartureLabel = `${i18n.t('common:applicationForm.dateOfDeparture')} *`;
  const fingerprintsAlreadyStoredLabel = `${i18n.t('common:applicationForm.fingerprintsAlreadyStored')} [28] *`;

  return (
    <div className="preview-application__section">
      <TabSubtitle type="h3" className="preview-application__subtitle">
        {i18n.t('common:applicationForm.journeyDetailsSubtitle')}
      </TabSubtitle>

      { props.mainPurposeOfJourney.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.mainPurposesOfJourney')} [23] *`}
          validationError={props.mainPurposeOfJourney.validationError}
          tabPath={journeyDetailsPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.mainPurposesOfJourney')} [23] *`}
          dataCy="labeled-MainPurposesOfJourney"
          group="MainPurposesOfJourney"
          code={props.mainPurposeOfJourney.value}
        />}

      { props.mainPurposeOfJourney.value === 'Other' &&
        <>
          { props.journeyPurposeOther.validationError ?
            <PreviewValidationError
              fieldNameLabel={`${i18n.t('common:applicationForm.journeyPurposeOther')} *`}
              validationError={props.journeyPurposeOther.validationError}
              tabPath={journeyDetailsPath}
            /> :
            <LabeledValue
              labelText={`${i18n.t('common:applicationForm.journeyPurposeOther')} *`}
              dataCy="labeled-journeyPurposeOther"
              value={props.journeyPurposeOther.value}
            />}
        </>}

      { props.otherPurposesOfJourney.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.otherPurposesOfJourney')}`}
          validationError={props.otherPurposesOfJourney.validationError}
          tabPath={journeyDetailsPath}
        /> :
        <LabeledCodeList
          labelText={`${i18n.t('common:applicationForm.otherPurposesOfJourney')}`}
          dataCy="labeled-otherPurposesOfJourney"
          group="MainPurposesOfJourney"
          value={props.otherPurposesOfJourney.value}
        />}

      { R.includes('Other', props.otherPurposesOfJourney.value) &&
        <>
          { props.journeyPurposeOther.validationError ?
            <PreviewValidationError
              fieldNameLabel={`${i18n.t('common:applicationForm.journeyPurposeOther')} *`}
              validationError={props.journeyPurposeOther.validationError}
              tabPath={journeyDetailsPath}
            /> :
            <LabeledValue
              labelText={`${i18n.t('common:applicationForm.journeyPurposeOther')} *`}
              dataCy="labeled-journeyPurposeOther"
              value={props.journeyPurposeOther.value}
            />}
        </>}

      { props.journeyPurposeDetails.validationError ?
        <PreviewValidationError
          fieldNameLabel={journeyPurposeDetailsLabel}
          validationError={props.journeyPurposeDetails.validationError}
          tabPath={journeyDetailsPath}
        /> :
        <LabeledValue
          labelText={journeyPurposeDetailsLabel}
          dataCy="labeled-journeyPurposeDetails"
          value={props.journeyPurposeDetails.value}
        />}

      { props.destinationStates.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.destinationStates')} [25] *`}
          validationError={props.destinationStates.validationError}
          tabPath={journeyDetailsPath}
        /> :
        <LabeledCodeList
          labelText={`${i18n.t('common:applicationForm.destinationStates')} [25] *`}
          dataCy="labeled-destinationStates"
          group="VisUserCountry"
          value={props.destinationStates.value}
        />}

      { props.borderOfFirstEntryCountry.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.borderOfFirstEntryCountry')} [26] *`}
          validationError={props.borderOfFirstEntryCountry.validationError}
          tabPath={journeyDetailsPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.borderOfFirstEntryCountry')} [26] *`}
          dataCy="labeled-borderOfFirstEntryCountry"
          group="VisUserCountry"
          code={props.borderOfFirstEntryCountry.value}
        />}

      { props.numberOfEntriesRequested.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.numberOfEntriesRequested')} [27] *`}
          validationError={props.numberOfEntriesRequested.validationError}
          tabPath={journeyDetailsPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.numberOfEntriesRequested')} [27] *`}
          dataCy="labeled-numberOfEntriesRequested"
          group="NumberOfEntries"
          code={props.numberOfEntriesRequested.value}
        />}

      { props.dateOfArrival.validationError ?
        <PreviewValidationError
          fieldNameLabel={dateOfArrivalLabel}
          validationError={props.dateOfArrival.validationError}
          tabPath={journeyDetailsPath}
        /> :
        <LabeledValue
          labelText={dateOfArrivalLabel}
          dataCy="labeled-dateOfArrival"
          value={displayDate(props.dateOfArrival.value)}
        />}

      { props.dateOfDeparture.validationError ?
        <PreviewValidationError
          fieldNameLabel={dateOfDepartureLabel}
          validationError={props.dateOfDeparture.validationError}
          tabPath={journeyDetailsPath}
        /> :
        <LabeledValue
          labelText={dateOfDepartureLabel}
          dataCy="labeled-dateOfDeparture"
          value={displayDate(props.dateOfDeparture.value)}
        />}

      { props.fingerprintsAlreadyStored.validationError ?
        <PreviewValidationError
          fieldNameLabel={fingerprintsAlreadyStoredLabel}
          validationError={props.fingerprintsAlreadyStored.validationError}
          tabPath={journeyDetailsPath}
        /> :
        <LabeledValue
          labelText={fingerprintsAlreadyStoredLabel}
          dataCy="labeled-fingerprintsAlreadyStored"
          value={i18n.tBoolean(props.fingerprintsAlreadyStored.value)}
        />}

      { props.fingerprintsAlreadyStored.value &&
        <>
          { props.fingerprintsStoredAt.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.fingerprintsStoredAt')}
              validationError={props.fingerprintsStoredAt.validationError}
              tabPath={journeyDetailsPath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.fingerprintsStoredAt')}
              dataCy="labeled-fingerprintsStoredAt"
              value={displayDate(props.fingerprintsStoredAt.value)}
            />}

          { props.fingerprintsVisaNumber.validationError ?
            <PreviewValidationError
              fieldNameLabel={i18n.t('common:applicationForm.fingerprintsVisaNumber')}
              validationError={props.fingerprintsVisaNumber.validationError}
              tabPath={journeyDetailsPath}
            /> :
            <LabeledValue
              labelText={i18n.t('common:applicationForm.fingerprintsVisaNumber')}
              dataCy="labeled-fingerprintsVisaNumber"
              value={props.fingerprintsVisaNumber.value}
            />}
        </>}

      <PreviewEntryPermitView
        entryPermitForFinalDestination={props.entryPermitForFinalDestination}
        entryPermit={props.entryPermit}
      />

      <PreviewEditLink
        tabNameLabel={i18n.t('application.tabs.journeyDetails')}
        tabPath={journeyDetailsPath}
      />
    </div>
  );
};

PreviewJourneyDetails.propTypes = {
  destinationStates: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  borderOfFirstEntryCountry: formFieldShape.isRequired,
  numberOfEntriesRequested: formFieldShape.isRequired,
  dateOfArrival: formFieldShape.isRequired,
  dateOfDeparture: formFieldShape.isRequired,
  fingerprintsAlreadyStored: formFieldShapeFor(PropTypes.bool).isRequired,
  fingerprintsStoredAt: formFieldShape.isRequired,
  fingerprintsVisaNumber: formFieldShape.isRequired,
  entryPermitForFinalDestination: formFieldShape.isRequired,
  entryPermit: formFieldShape.isRequired,
  mainPurposeOfJourney: formFieldShape.isRequired,
  otherPurposesOfJourney: formFieldShape.isRequired,
  journeyPurposeOther: formFieldShape.isRequired,
  journeyPurposeDetails: formFieldShape.isRequired,
  oneDayTripWithoutAccommodation: formFieldShape.isRequired,
};

export default PreviewJourneyDetails;
