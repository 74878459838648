import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import Icon from 'visa-frontend-common/src/components/UI/Icon/Icon';
import Divider from 'visa-frontend-common/src/components/UI/Divider/Divider';

import i18n from '../../services/i18n';

import './Guide.scss';

class Guide extends React.PureComponent {
  timeoutRef = null;

  buttonRef = React.createRef();

  state = {
    modalIsOpen: false,
    display: 'none',
  };

  showModal = (setModalOpen) => {
    clearTimeout(this.timeoutRef);
    if (setModalOpen) {
      this.setState({
        modalIsOpen: true,
        display: null,
      });
    } else {
      this.setState({ modalIsOpen: false });
      // The following lines are to remove the content and it's children from the tab order
      // while keeping the transition effect and not losing focus.
      this.timeoutRef = setTimeout(() => {
        this.setState({ display: 'none' });
        this.buttonRef.current.focus();
      }, 500);
    }
  };

  onKeyUp = (event) => {
    event.stopPropagation();
    const { key } = event;
    if (this.state.modalIsOpen) {
      if (
        key === 'Escape' ||
        key === 'ArrowRight'
      ) {
        this.showModal(false);
      }
    } else if (
      key === 'ArrowLeft'
    ) {
      this.showModal(true);
    }
  };

  componentDidMount() {
    if (this.props.openModalAtStart) {
      this.showModal(true);
    }
  }

  render() {
    const overlayClassNames = classnames(
      'guide__modal-overlay',
      {
        'guide__modal-overlay--open': this.state.modalIsOpen,
        'guide__modal-overlay--closed': !this.state.modalIsOpen,
      },
    );
    const closeModalButtonClassNames = classnames(
      'guide__toggle-button',
      {
        'guide__toggle-button--open': this.state.modalIsOpen,
        'guide__toggle-button--closed': !this.state.modalIsOpen,
      },
    );

    const { display } = this.state;

    return (
      <div className="guide">
        <Button
          ref={this.buttonRef}
          className={closeModalButtonClassNames}
          icon={
            this.state.modalIsOpen ?
              <Icon name="close" ariaLabel={i18n.t('common:accessibleIconLabels.close')} /> :
              'help_outline'
          }
          onClick={() => this.showModal(!this.state.modalIsOpen)}
          onKeyUp={this.onKeyUp}
          label={i18n.t('guide.toggleButtonLabel')}
          dataCy={this.state.modalIsOpen ? 'guide-close-button' : 'guide-open-button'}
        />
        <div
          className={overlayClassNames}
          style={{ display }}
        >
          <div
            data-cy="guide-modal"
            className="guide__modal-content"
            tabIndex="-1"
            role="dialog"
            aria-modal="true"
            aria-labelledby={this.props.describedBy}
            aria-describedby={this.props.describedBy}
          >
            <div
              id="guide-modal-content"
              tabIndex="0"
              role="textbox"
              className="guide__modal-content-right"
              onKeyUp={this.onKeyUp}
            >
              <div className="guide__modal-content-header">
                <h2 id="guide-mainTitle">{i18n.t('guide.mainTitle')}</h2>
                <Divider fullWidth withTopMargin withBottomMargin />
              </div>
              { this.props.children }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Guide.propTypes = {
  children: PropTypes.element,
  openModalAtStart: PropTypes.bool,
  describedBy: PropTypes.string,
};

Guide.defaultProps = {
  children: null,
  openModalAtStart: false,
  describedBy: 'guide-mainTitle',
};

export default Guide;
