import React from 'react';
import PropTypes from 'prop-types';

import { InputGroup, Selector } from '../UI/Form/FormComponents';

class LanguageSelector extends React.Component {
  handleChange = (event) => {
    this.props.updateLocale(event.target.value);
  };

  render() {
    return (
      <InputGroup
        label={this.props.label}
      >
        <Selector
          name="localeCode"
          value={this.props.locale}
          onChange={this.handleChange}
          options={this.props.languageSelectorOptions}
        />
      </InputGroup>
    );
  }
}

LanguageSelector.propTypes = {
  updateLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  languageSelectorOptions: PropTypes.arrayOf(PropTypes.exact({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  label: PropTypes.string,
};

LanguageSelector.defaultProps = {
  label: null,
};

export default LanguageSelector;
